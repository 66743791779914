import { Box, Paper, Typography, Tabs, Tab, Container, Snackbar, Slide } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import PersonalDetails from "./PersonalDetails";
import Address from "./Address";
import BankDetails from "./BankDetails";
import Documents from "./Documents";
// import Alert from '@mui/material/Alert'; 
import { useDispatch } from "react-redux";
import { clearError, setError } from '../../../../Redux/action-creators/errorAction';
import { clearSuccessMessage, setSuccessMessage } from "../../../../Redux/action-creators/successAction";
// import {v4 as uuidv4} from 'uuid'
// import AWS from 'aws-sdk';
import {fetchLastAgentId} from '../../api/pospService'
import { doucmentsUpload } from "../../functions/upload-doc";

 

 
// AWS.config.update({
//     accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
//     secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//     region: process.env.REACT_APP_AWS_REGION
// });
 
// const s3 = new AWS.S3();
 
const SignUp = () => {
 
    const [count, setCount] = useState(0)
    const [personalData, setPersonalData] = useState({
        // firstname : '',
        // lastname : '' ,
        // pan : '',
        // mobile : '',
        // gender : '',
        // birthdate : '',
        // aadhaar : '',
        // email : '',
        // educationalDetails : '',
        // reference : ''
    })
    const [addressData, setAddressData] = useState({
        // address_line : '',
        // land_mark : '',
        // pincode : '',
        // city : '',
        // district : '',
        // state : '',
        // country : ''
    })
    const [bankData, setBankData] = useState({
        // account_holder_name : '',
        // bank_name : '',
        // branch_name : '',
        // account_type : '',
        // account_number : '',
        // ifsc_code : ''
    })
    const [documentsData, setDocumentsData] = useState({ pancard_doc: null,  aadhar_doc: null, degree_doc: null, cheque_doc: null, profile_photo_doc: null, other_doc: null })
 
    const [isLoaderTrue, setIsLoaderTrue] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
   
 
    // upload files to s3
    const uploadFiles = async (files) => {
        const uploadedData ={}
        try{
 
            for (let key in files) {
                if (files[key] !== null){
                    // const params = {
                    //     Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                    //     Key: uuidv4(),
                    //     Body: files[key],
                    //     ContentType: files[key]?.type
                    // };
                    // const response = await s3.upload(params).promise()
                    const response = await doucmentsUpload(files[key], files[key]?.type, 'SIGNUP')
                    uploadedData[key] = response.Location
                }else{
                    uploadedData[key] = null
                }
               
            }
            return uploadedData
 
        }catch(e){ 
            console.error('Error uploading files:', e.message);
        }
 
       
    };

    const generateAgentId = (previesAgntId) =>{
        const agent_id = parseInt(previesAgntId.slice(6, previesAgntId.length)) + 1
        const first_part = previesAgntId.slice(0, previesAgntId.length - (agent_id).toString().length)
        return (first_part + agent_id)
 
    }
 
 
    const callPostApi = async (documentData) => {
        dispatch(clearError());
        dispatch(clearSuccessMessage());
        setIsLoaderTrue(true)
 
        try{
            
            const filesUrl = await uploadFiles(documentData)

            if (Object.keys(filesUrl).length === Object.keys(documentData).length){
                // const agent_code = 'LMVPOS000000' // call api
                const agent_code = await fetchLastAgentId()
                const newAgentId = generateAgentId(agent_code?.data)
                
                const farmateAgentDetails = {
                    org_id: 100,
                    agent_id: newAgentId,
                    agent_name: personalData.firstname + ' ' + personalData.lastname,
                    address: addressData.address_line,
                    state: addressData.state,
                    city: addressData.city,
                    phone: personalData.mobile,
                    email: personalData.email,
                    pan_card: personalData.pan,
                    aadhar_card: "'" + personalData.aadhaar,
                    created_by: "admin",
                    created_date: new Date(),
                    gender: personalData.gender,
                    pincode: addressData.pincode,
                    dob: personalData.birthdate,
                    reference: personalData.reference,
                    qualification: personalData.educationalDetails,
                    district: addressData.district,
                    landmark: addressData.land_mark,
                    account_name: bankData.account_holder_name,
                    bank_name: bankData.bank_name,
                    account_number: "'" + bankData.account_number,
                    ifsc_code: bankData.ifsc_code,
                    account_type: bankData.account_type,
                    branch_name: bankData.branch_name,
                    pan_url: filesUrl.pancard_doc,
                    aadhar_url: filesUrl.aadhar_doc,
                    degree_doc: filesUrl.degree_doc,
                    cheque_doc: filesUrl.cheque_doc,
                    profile_doc: filesUrl.profile_photo_doc,
                    other_doc: filesUrl.other_doc,
                    reference_id: personalData.reference_id,
                    employee_id: personalData.reference === 'employee' ? personalData.reference_id : null
                }
    
                const responseOfPersonalDetails = await fetch('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/profileDetails', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json', // Important to set the content type
                    },
                    body: JSON.stringify(farmateAgentDetails)
                })
    
                if (responseOfPersonalDetails.ok){
                    const jsonOfPersonalDetails = await responseOfPersonalDetails.json()
                    dispatch(setSuccessMessage('User Register Seccussfuly...'))
                    navigate('/posp/')
    
                }else{
                    dispatch(setError('Something went wrong'))
                }
            }else{
                dispatch(setError('Documents Upload failed..'))
            }
 
        }catch(e){
            console.log(e , 'error')
            dispatch(setError('Internal Server Error'))
        }
        setIsLoaderTrue(false)
    }
 
    const clickNextBtn = (data) => {
 
        if (Object.keys(data).includes('firstname')) {
            setPersonalData((prv) => ({ ...prv, ...data }))
        } else if (Object.keys(data).includes('city')) {
            setAddressData((prv) => ({ ...prv, ...data }))
        } else {
            setBankData((prv) => ({ ...prv, ...data }))
        }
 
        // setUserDetails((prv) =>({...prv , ...data}))
        setCount((prv) => prv + 1)
    }
 
    const clickBackBtn = (data) => {
       
        if (Object.keys(data).includes('city')) {
            setAddressData((prv) => ({ ...prv, ...data }))
        } else if (Object.keys(data).includes('bank_name')) {
            setBankData((prv) => ({ ...prv, ...data }))
        } else {
            setDocumentsData((prv) => ({ ...prv, ...data }))
        }
        // if (dataOfDocuments !== undefined) {
        //     setDocumentsData(dataOfDocuments)
        // }
        setCount((prv) => prv - 1)
    }
 
 
    const displayComponents = () => {
        switch (count) {
            case 0:
                return <PersonalDetails clickNextBtn={clickNextBtn} personalData={personalData} />
            case 1:
                return <Address clickNextBtn={clickNextBtn} clickBackBtn={clickBackBtn} addressData={addressData} />
            case 2:
                return <BankDetails clickNextBtn={clickNextBtn} clickBackBtn={clickBackBtn} bankData={bankData} />
            default:
                return <Documents clickBackBtn={clickBackBtn} documentsData={documentsData} callPostApi={callPostApi} isLoaderTrue={isLoaderTrue}/>
        }
    }
 
 
 
    return (
        <Box sx={{ width: '100%', minHeight: '100dvh', backgroundColor: '#f0f2f5', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
            <Container maxWidth={"md"} sx={{ padding: { xs: 0, sm: 'auto' } }}>
                <Paper sx={{ padding: '35px', borderRadius: '10px', minHeight: { xs: '100vh', sm: 'auto' }, }} elevation={3} >
                    <Typography variant="h3" style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '30px' }}>Sign Up</Typography>
 
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        value={count} aria-label="basic tabs example" textColor="primary" indicatorColor="primary" sx={{
 
                            marginBottom: '25px',
 
                        }}>
                        <Tab label="Personal Details" />
                        <Tab label="Address" />
                        <Tab label="Bank Details" />
                        <Tab label="Documents" />
                    </Tabs>
 
                    {displayComponents()}
 
                </Paper>
            </Container>
           
        </Box>
    )
}
 
 
export default SignUp