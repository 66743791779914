import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Badge,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PhoneIcon from "@mui/icons-material/Phone";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import FamilyDetails from "../profile/Profilefamilydetails";
import Assets from "./assets";
import PersonalDetails from "../profile/Personaldetails";
import MainCard from "../../../posp/ui-component/cards/MainCard";
import AddExistingPolicy from "./ExistingPolicies";
import { updateUserProfileData } from "../../api/customerService";
import {
  clearSuccessMessage,
  setSuccessMessage,
} from "../../../../Redux/action-creators/successAction";
import {
  clearError,
  setError,
} from "../../../../Redux/action-creators/errorAction";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_CUSTOMER_SUCCESS } from "../../../../Redux/action-type/actionType";
// import { getProfileById } from "../../api/customerService";

const Mainpage = () => {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state?.customer?.data);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
       setSelectedTab(newValue);
  };

  const updateCustomerDetails = async(object) =>{
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    try{
      const response = await updateUserProfileData(profileData?.phone , object)
      if(response.status === 200){
        dispatch(setSuccessMessage('Done...'))
        dispatch({
          type: FETCH_CUSTOMER_SUCCESS,
          payload: response?.data,
        });
      }
    }catch(e){
      dispatch(setError("Internal Server Error"));
    }
  }

  const RenderComponent = () =>{
    switch (selectedTab) {
      case 0:
        return (
          <PersonalDetails
            profileData={profileData}
            updateCustomerDetails={updateCustomerDetails}
          />
        );
      case 1:
        return (
          <FamilyDetails
            profileData={profileData}
            updateCustomerDetails={updateCustomerDetails}
          />
        );
      case 2:
        return (
          <Assets
            profileData={profileData}
            updateCustomerDetails={updateCustomerDetails}
    
          />
        );
      default :
        return (
          <AddExistingPolicy
            profileData={profileData}
            updateCustomerDetails={updateCustomerDetails}
          />
        );
    }
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={12} marginTop={2} marginBottom={2}>
            <MainCard color="primary">
              <Typography variant="h4">Profile</Typography>
              <Typography variant="body1">
                Manage your family members, contact details and financial assets
              </Typography>
            </MainCard>
          </Grid>

          <Grid item xs={12} marginBottom={2}>
            <Tabs value={selectedTab} onChange={handleTabChange} centered>
              <Tab label="Personal Details" />
              <Tab label="Family Details" />
              <Tab label="Assets"/>
              <Tab label="Add your existing policies"/>
            </Tabs>
          </Grid>

          <Grid item xs={12}>
            <MainCard color="primary" content={false}>
              {RenderComponent()}
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Mainpage;
