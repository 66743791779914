import React, { useEffect, useState } from "react";
import "./Quotationcard.css";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ModalComponent from "./ModalComponent";
import { Images, careSupremeBenefits, productDetails } from "../Data/Benifits";
import { Tooltip, LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedProvider,
  updatedQuotationResult,
  updatePremiumPostObject,
  storeQuote
} from "../../Redux/Health/action";
import { useNavigate } from "react-router-dom";
import { generatePremium } from "../api/PremiumGeneration";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const BajajQuardCard = ({ collectData}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productFeatures, setProductFeatures] = useState([]);
  const [premium, setPremium] = useState(null);
  const [image, setImage] = useState(null);
  const [selectedPEDYear, setSelectedPEDYear] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const premiumPostBody = useSelector(
    (state) => state?.healthReducer?.premiumPostObject
  );
  const [isPEDChecked, setIsPEDChecked] = useState(false);
  const [isICChecked, setIsICChecked] = useState(false);
  const [check, setCheck] = useState(null);

  const [postBody, setPostBody] = useState(premiumPostBody);

  const fetchBajajQuote = async () => {
    setLoading(true);
    try {
      let quote = await generatePremium({
        ...postBody,
        providers: ["bajaj"],
      });
      setQuote(quote?.data?.data[0]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBajajQuote();
  }, [postBody]);

  useEffect(() => {
    setPostBody(premiumPostBody);
  }, [premiumPostBody]);

  const getbenefitsForProduct = (productName) => {
    const benefitsData = productDetails?.find(
      (benefit) =>
        benefit?.product?.toLowerCase() === productName?.toLowerCase()
    );
    return benefitsData?.features;
  };

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  const findImage = (providerName) => {
    let imageObj = Images?.find((e) => e?.provider === providerName);
    return imageObj?.image;
  };

  useEffect(() => {
    let features = getbenefitsForProduct(quote?.productName);
    setProductFeatures(features);
    let image = findImage(quote?.provider);
    setImage(image);
    let premium = quote?.premiumWithGST;
    let premiumValue =
      typeof premium === "string"
        ? parseFloat(premium.replace(/,/g, ""))
        : premium;
    let formattedPremium = premiumValue
      ?.toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setPremium(formattedPremium);
    // ProviderPrimium(premium);
    //  console.log(premium, "ProviderPrimiumProviderPrimium");
 dispatch(storeQuote(quote));
  }, [quote]);
 
  const handleNavigate = (quote) => {
    let provider = quote.provider;
    let code = quote.code;
    const updatedPostBody = {
      ...postBody,
      provider: provider,
      abacusId: code,
    };
    navigate("/health/proposalform");
    dispatch(setSelectedProvider("bajaj"));
    dispatch(updatedQuotationResult(quote));
    dispatch(updatePremiumPostObject(updatedPostBody));
  };

  const handleCheckboxChange = (name, checked) => {
    if (name === "field_35" || name === "field_WB") return;
    const updatedPostBody = { ...postBody, [name]: checked ? "1" : "0" };
    setPostBody(updatedPostBody);

    if (name === "field_43") {
      setIsPEDChecked(checked);
    } else if (name === "field_IC") {
      setIsICChecked(checked);
    }
  };

  const handlePEDYearChange = (e) => {
    const selectedYear = e.target.value;
    setSelectedPEDYear(selectedYear);
    console.log(selectedYear, "eqinqniooi");
    const updatedPostBody = {
      ...postBody,
      field_PED_TENURE: selectedYear,
      field_43: selectedYear ? "1" : "0",
    };
    setPostBody(updatedPostBody);
  };
  const handleChange = (e) => {
    setCheck(e.target.checked);
    collectData(e.target.checked, quote);
  };

  return (
    <>
      <ModalComponent
        open={modalOpen}
        handleClose={handleModal}
        quote={quote}
        features={careSupremeBenefits}
      />
      {loading ? (
        <LinearProgress />
      ) : (
        quote && (
          <div className="health_quotation_main_card  mb_25 ">
            <div className="mt-2">
              <input
                className="form-check-input"
                onChange={(e) => handleChange(e)}
                type="checkbox"
                style={{
                  fontSize: "25px",
                  marginLeft: "10px",
                  border: "1px solid",
                }}
              />
            </div>
            <div className="d_flex quotation_upr_div">
              <div className="logo_container">
                <img src={image} alt="CARE HEALTH" className="logo_box" />
              </div>

              <div className="quotation_content">
                <div className="content_box_cs">
                  <div className="plane_name plan_details">
                    <div className="title_pa">{quote?.productName}</div>
                    <div className="suminsured_text">
                      Sum Insured : ₹{quote?.sumInsured}
                    </div>
                    <b>{quote?.term || quote?.tenure} year policy</b>
                  </div>

                  <div className="plan_feature plan_details">
                    <div
                      className="health_check_compare"
                      style={{ marginRight: "30px" }}
                    >
                      <ul className="features_list">
                        {productFeatures?.slice(0, 4)?.map((item, i) => (
                          <li key={i} className="font-12">
                            <Tooltip
                              title={item.benefit}
                              placement="right-start"
                            >
                              <HealthAndSafetyIcon
                                fontSize="small"
                                style={{ marginRight: "5px", color: "#03a9f4" }}
                              />
                              <span className="font-blue">{item.feature}</span>
                            </Tooltip>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="health_check_compare">
                      <ul className="features_list">
                        {productFeatures?.slice(4, 8)?.map((item, i) => (
                          <li key={i} className="font-12">
                            <Tooltip
                              title={item.benefit}
                              placement="right-start"
                            >
                              <HealthAndSafetyIcon
                                fontSize="small"
                                style={{ marginRight: "5px", color: "#03a9f4" }}
                              />
                              <span className="font-blue">{item.feature}</span>
                            </Tooltip>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="text_right hal_buy_btn_btn plan_details">
                    <a
                      onClick={() => handleNavigate(quote)}
                      className="buy_btn"
                    >
                      <Tooltip title="Buy Plan" placement="bottom">
                        <span>
                          <i className="fa-solid fa-indian-rupee-sign mr_5"></i>
                          ₹ {premium}
                        </span>
                      </Tooltip>
                      <p>*Including GST</p>
                      <small>BUY NOW</small>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="d_flex quotation_bottom_div">
              <button onClick={handleModal} className="features_btn">
                View Features <ChevronRightIcon />
              </button>
            </div>

            {/* addons */}
            <div className="add_addon_div">
              <div className="d_flex addons_flex_container">
                {/* air Ambulance */}
                <div className="health_addons_input_box">
                  <label>
                    <input
                      type="checkbox"
                      name="field_35"
                      checked={true}
                      disabled
                    />
                    <span>Air Ambulance</span>
                  </label>
                </div>

                {/* Wellness Benefit */}
                <div className="health_addons_input_box">
                  <label>
                    <input
                      type="checkbox"
                      name="field_WB"
                      checked={true}
                      disabled
                    />
                    <span>Wellness Benefit</span>
                  </label>
                </div>

                {/* Cumulative Bonus Super */}
                <div className="health_addons_input_box">
                  <label>
                    <input
                      type="checkbox"
                      name="field_NCB"
                      disabled={
                        premiumPostBody.highestElderAge <= 45 ||
                        premiumPostBody.highestElderAge > 75
                      }
                      checked={
                        premiumPostBody.highestElderAge <= 45 ||
                        premiumPostBody.highestElderAge > 75 ||
                        Number(postBody?.field_NCB)
                      }
                      onChange={(e) =>
                        handleCheckboxChange(e.target.name, e.target.checked)
                      }
                    />
                    <span>Cumulative Bonus Super</span>
                  </label>
                </div>

                {/* Be-Fit Benefit */}
                <div className="health_addons_input_box">
                  <label>
                    <input
                      checked={Number(postBody?.field_BFB)}
                      type="checkbox"
                      name="field_BFB"
                      onChange={(e) =>
                        handleCheckboxChange(e.target.name, e.target.checked)
                      }
                    />
                    <span>Be-Fit Benefit</span>
                  </label>
                </div>

                {/* Annual Health Check-up */}
                <div className="health_addons_input_box">
                  <label>
                    <input
                      checked={Number(postBody.field_AHC)}
                      type="checkbox"
                      name="field_AHC"
                      onChange={(e) =>
                        handleCheckboxChange(e.target.name, e.target.checked)
                      }
                    />
                    <span>Annual Health Check-up</span>
                  </label>
                </div>

                {/* Instant Cover */}
                <div className="health_addons_input_box">
                  <label>
                    <input
                      checked={Number(postBody.field_IC)}
                      type="checkbox"
                      name="field_IC"
                      onChange={(e) =>
                        handleCheckboxChange(e.target.name, e.target.checked)
                      }
                      disabled={isPEDChecked}
                    />
                    <span>Instant Cover</span>
                  </label>
                </div>

                {/* Claim Shield */}
                <div className="health_addons_input_box">
                  <label>
                    <input
                      checked={Number(postBody.field_CS)}
                      type="checkbox"
                      name="field_CS"
                      onChange={(e) =>
                        handleCheckboxChange(e.target.name, e.target.checked)
                      }
                    />
                    <span>Claim Shield</span>
                  </label>
                </div>

                {/* Care OPD */}
                <div className="health_addons_input_box">
                  <label>
                    <input
                      checked={Number(postBody.field_OPD)}
                      type="checkbox"
                      name="field_OPD"
                      onChange={(e) =>
                        handleCheckboxChange(e.target.name, e.target.checked)
                      }
                    />
                    <span>Care OPD</span>
                  </label>
                </div>

                {/* PED Wait Period Modification */}
                <div className="health_addons_input_box">
                  <label>
                    <input
                      checked={Number(postBody.field_43)}
                      type="checkbox"
                      name="field_43"
                      onChange={(e) =>
                        handleCheckboxChange(e.target.name, e.target.checked)
                      }
                      disabled={isICChecked}
                    />
                    <span>PED Wait Period Modification</span>
                  </label>
                </div>

                {/* ped-year-dropdown */}
                <div className="health_addons_input_box">
                  {isPEDChecked && (
                    <select
                      className="ped-year-dropdown"
                      value={selectedPEDYear}
                      onChange={handlePEDYearChange}
                    >
                      {selectedPEDYear === "" && (
                        <option value="">Select</option>
                      )}
                      <option value="1 Year">1 Year</option>
                      <option value="2 Year">2 Years</option>
                      <option value="3 Year">3 Years</option>
                    </select>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default BajajQuardCard;
