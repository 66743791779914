import React from 'react';
import { Typography, List, ListItem, ListItemText, Container } from '@mui/material';

const styles = {
  container: {
    marginTop: "32px",
    marginBottom: "32px",
    padding: "20px 30px",
  },
  heading: {
    // marginTop: '10px',
    marginBottom: "2px",
    color: "#23a8fa",
    fontWeight: "bold",
  },
  subHeading: {
    marginTop: "2px",
    fontWeight: "bold",
    color: "#555",
  },
  bodyText: {
    color: '#555',
  },
  listItem: {
    // marginBottom: "2px",
    backgroundColor: "#f5f5f5",
    // borderRadius: "4px",
    textAlign: 'justify',
    padding: '0px',
    margin: '0px',
  },
  ListItemText: {
    // marginBottom: "2px",
    // backgroundColor: "#f5f5f5",
    // borderRadius: "4px",
    textAlign: 'justify',
    padding: '0px',
    margin: '0px',
  },
};

const LifeClaimsPolicy = () => (
  <Container style={styles.container}>
    <Typography variant="h2" textAlign='center' style={styles.heading} gutterBottom>
      Life Claims Policy
    </Typography>
    <Typography variant="body1" mt={1} style={styles.bodyText}>
      A Life Claims Policy for an insurance company like LMV Insurance Broking Private Limited would outline the procedures, eligibility criteria, and responsibilities related to the processing and settlement of life insurance claims. While I don't have access to specific policies of LMV Insurance Broking Private Limited, I can provide a general framework that could apply to such policies.
    </Typography>
    
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>1. Notifying the Insurance Company</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>The first step is to notify the insurance company of the policyholder’s death. The beneficiary or the representative of the estate usually does this.</li>
            <li>Contact information for claims is typically found on the insurance policy or the insurer’s website.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>2. Submitting the Required Documents</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>Death Certificate: The beneficiary needs to submit an official death certificate, which proves that the policyholder has passed away.</li>
            <li>Proof of Identity: Beneficiaries may need to provide their identification (e.g., government-issued ID) to show they are entitled to the benefits.</li>
            <li>Policy Information: The beneficiary may need to provide the policy number and other relevant details.</li>
            <li>Claim Form: The insurance company will likely require a completed claim form, which they provide to the beneficiary.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>3. Reviewing the Claim</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>After receiving the necessary documentation, the insurer will review the claim. The insurer typically checks if the death was due to natural causes, accidental causes, or if it occurred during the contestability period (usually the first two years of the policy).</li>
            <li>If the death occurred during the contestability period, the insurer might conduct a more thorough investigation.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>4. Payout</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>If the claim is approved, the insurer will disburse the death benefit, which may be paid out as a lump sum or in periodic installments, depending on the policy's terms.</li>
            <li>If the policyholder had additional riders, such as a critical illness rider, these could impact the payout.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>5. Denial of the Claim</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>In some cases, the claim may be denied. Reasons for denial might include:</li>
            <li>The death was not covered by the policy (e.g., suicide within the first two years, certain types of accidents).</li>
            <li>The policy was lapsed due to non-payment of premiums.</li>
            <li>The beneficiary is found to be ineligible or was not properly designated.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>6. Appeals</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>If a claim is denied, the beneficiary has the option to appeal the decision. This process typically involves providing additional documentation or clarification to support the claim.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>7. Factors Affecting Life Insurance Claims</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>Type of Life Insurance: There are different types of life insurance policies, including term life, whole life, and universal life insurance. The claims process may vary depending on the policy type.</li>
            <li>Contestability Period: If the policyholder dies within the first two years of the policy (the contestability period), the insurance company may investigate the cause of death more thoroughly to confirm that there was no fraud or misrepresentation on the policy application.</li>
            <li>Exclusions: Some life insurance policies have exclusions, such as death caused by risky activities (e.g., skydiving, suicide within a certain period), which can affect the payout.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>8. Tips for Beneficiaries</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>Keep Documents Ready: Always have important documents like the policy and death certificate on hand to ensure smooth processing.</li>
            <li>Understand the Policy: It’s important for beneficiaries to understand the terms of the policy to avoid surprises.</li>
            <li>Seek Help if Denied: If a claim is denied, consider consulting with an attorney or a life insurance claims expert to review the situation.</li>
          </ul>
        }
      />
    </ListItem>
  </Container>
);

export default LifeClaimsPolicy;
