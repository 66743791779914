export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_SUCCESS = 'SET_SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';

export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS'
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE'
export const FETCH_MASTER_DATA_SUCCESS = 'FETCH_MASTER_DATA_SUCCESS'
export const FETCH_MASTER_DATA_FAILURE = 'FETCH_MASTER_DATA_FAILURE'
export const FETCH_PIN_CODE_MASTER_DATA_SUCCESS = 'FETCH_PIN_CODE_MASTER_DATA_SUCCESS'
export const FETCH_PIN_CODE_MASTER_DATA_FAILURE = 'FETCH_PIN_CODE_MASTER_DATA_FAILURE'
export const FETCH_DOCUMENTS_DATA_SUCCESS = 'FETCH_DOCUMENTS_DATA_SUCCESS'
export const FETCH_DOCUMENTS_DATA_FAILURE = 'FETCH_DOCUMENTS_DATA_FAILURE'
export const ISLANDINGPAGETRUE = 'ISLANDINGPAGETRUE'