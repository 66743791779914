import React from 'react';
import { Typography, Container, List, ListItem, ListItemText } from "@mui/material";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    
  } from '@mui/material';

const styles = {
    container: {
      marginTop: "32px",
      marginBottom: "32px",
      padding: "20px 30px",
    },
    heading: {
        marginTop:'10px',
      marginBottom: "2px",
      color: "#23a8fa",
      fontWeight: "bold",
  
    },
    subHeading: {
      marginTop: "2px",
      fontWeight: "bold",
      color: "#555",
  
    },
    listItem: {
      marginBottom: "2px",

      backgroundColor: "#f5f5f5",
      // padding: "2px",
      borderRadius: "4px",
      textAlign:'justify',
      
      padding:'0px',
      margin:'0px',
    },

    tableHeading: {
        fontWeight: 'bold',
        fontSize: '0.9rem',
        color: '#333',
      },
      tableCell: {
        fontSize: '0.8rem',
        color: '#555',
        fontWeight:'600'
      },
  };

const LMVCarInsurance =  () => {
    const data = [
        { feature: 'Own Damage Cover', description: 'Available for your car that suffered damages from accidents, man-made disasters, natural calamities, etc.' },
        { feature: 'Savings', description: 'Buy a policy online and save up to (75% or 80%) on car insurance premiums.' },
        { feature: 'Cashless Repairs', description: 'We offer cashless services to our customers.' },
        { feature: 'Third-Party Damage Cover', description: 'Third-party injury and property damage are covered.' },
        { feature: 'Car Insurance Add-ons', description: 'Access to 11 add-ons.' },
        { feature: 'Personal Accident Cover', description: 'Up to ₹50 Lakh (Depends on income).' },
        { feature: 'Car Insurance Premium', description: 'Very low premiums (Depends on IDV and Add-on selected).' },
        { feature: 'No Claim Bonus', description: 'Decent discount on car insurance premiums for a certain number of claim-free years.' },
        { feature: 'Buy/Renew Car Insurance Online', description: 'Renew your car insurance on the go with LMV Services.' },
      ];

      const insurancePolicies = [
        { companyName: 'Bajaj Allianz Car Insurance', claimRatio: '88.83%' },
        { companyName: 'Bharti Axa General Insurance', claimRatio: '87.99%' },
        { companyName: 'HDFC Ergo General Insurance', claimRatio: '91.23%' },
        { companyName: 'ICICI Lombard General Insurance', claimRatio: '87.71%' },
        { companyName: 'IFFCO Tokio General Insurance', claimRatio: '95.30%' },
        { companyName: 'Kotak General Insurance', claimRatio: '82.81%' },
        { companyName: 'National General Insurance', claimRatio: '85.71%' },
        { companyName: 'TATA AIG General Insurance', claimRatio: '90.49%' },
        { companyName: 'New India General Insurance', claimRatio: '89.60%' },
        { companyName: 'Reliance General Insurance', claimRatio: '84.26%' },
      ];
    
    return (
<Container style={styles.container}>
      <Typography variant="h2" textAlign='center'   style={styles.heading} gutterBottom>
   LMV CAR INSURANCE
      </Typography>
      <Typography variant="h5" textAlign='center'   style={styles.subHeading} gutterBottom>
  (A great policy is the heart of a great life)
      </Typography>
    
      <Typography variant="h4" style={styles.heading}>
        Why LMV Insurance
      </Typography>

      <Typography variant="body1" style={styles.bodyText}>
        LMV Insurance has been providing exemplary insurance services to customers since 2018. Ever since we ventured into the insurance market, we have ruled the roost and built a strong customer base with our trustworthy services. In no time, we have become customers' preferred insurance broking company. Also, we have professional tie-ups with all major players in the insurance sector. Since our commencement, we have served a good deal of happy customers. We offer insurance for a wide range of products, and our policies are thoroughly instant. We always opt for simple and straight ways to communicate with our customers and ensure they get the point. Buy Car Insurance Online with LMV Insurance.
      </Typography>

      <Typography variant="body1" mt={1} style={styles.bodyText}>
        We are best known for our unbiased advice. We embrace technological advancements very quickly, so we went all online with our services. We revere our customer's time a lot, and that's why we offer rapid insurance services to them without any hassle. We do all the math for you and shortlist the best plans that suit your requirements. You can save a lot of your hard-earned money by getting the best plans here. We also provide claim assistance and settle your most challenging claims promptly. Our claim support experts strive hard to bring triumph to our customers. We provide guaranteed data security to our customers, so data misuse is no question.
      </Typography>

      <Typography variant="h4" style={styles.heading}>
        Advantages of Buying/Renewing Insurance through LMV Insurance
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="body1">We know the importance of hearing, and we are all ears to your requirements.</Typography>}
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="body1">We propose the unsurpassed policies that match the requirements.</Typography>}
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="body1">Our policy suggestions are fair and square and are lite on your pocket.</Typography>}
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="body1">We help you understand the policy better by explaining everything about the coverage and premium without masking anything.</Typography>}
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="body1">There are no delays in the coverage with our quick paperwork.</Typography>}
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="body1">Challenging claims are not a big deal anymore with us by your side.</Typography>}
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="body1">Our online platform helps you compare the policies available with the top insurers in a wink.</Typography>}
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="body1">We offer high insurance coverage at most eye-grabbing premiums.</Typography>}
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="body1">We highly rely on technology and provide impeccable insurance services to our customers at jet speed.</Typography>}
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="body1">Our accuracy is top-notch in suggesting insurance quotes as the calculations are in charge of the computer.</Typography>}
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        About Us
      </Typography>

      <Typography variant="body1" style={styles.bodyText}>
        LMV Insurance Broking Services Private Limited is an IRDAI-approved insurance broking company. We inaugurated our services in February 2018, and ever since, there has been no looking back for us. We widened our vision and services and evolved as one of the most approachable companies in the insurance marketplace. We are determined to deliver authentic services to our customers, and our team always strives hard for it. We have access to extensive protection covers that we recommend to our loyal customers.
      </Typography>

      <Typography variant="body1" mt={1} style={styles.bodyText}>
        We offer comprehensive general insurance covers for both businesses and individuals. Our products include motor insurance, health, general, marine, marine cargo, retirement, ULIPS, Protection, travel, home, life, etc. We are a team of enthusiastic individuals dedicated to providing superior services to our customers on time. Our devoted agents and customer service executives are decisive and deliver phenomenal services. Likewise, our claim experts handle the claim settlements seamlessly.
      </Typography>

      
    <List>
      {/* Car Insurance Introduction */}
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h4" style={styles.heading}>
              Car Insurance
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              Car Insurance, also referred to as four-wheeler insurance, is the coverage provided to a car in case of unexpected events like accidents and natural calamities. Buy Car Insurance Online and get assistance covering expenses from unavoidable events. As per the Motor Vehicles Act of India, third-party insurance is mandatory, ensuring protection without financial burden.
            </Typography>
          }
        />
      </ListItem>

      {/* What is Car Insurance */}
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h4" style={styles.heading}>
              What is Car Insurance?
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              Car Insurance covers damages from accidents, thefts, liabilities, and similar events. Premiums depend on factors like car value, type of plan, and vehicle classification. With LMV Insurance, you can relax and protect your car by simply entering its details to fetch a perfect quote.
            </Typography>
          }
        />
      </ListItem>

      {/* Types of Car Insurance Policies */}
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h4" style={styles.heading}>
              Types of Car Insurance Policies
            </Typography>
          }
          secondary={
            <List>
              <ListItem style={styles.listItem}>
                <ListItemText
                  primary={
                    <Typography variant="h5" style={styles.subHeading}>
                      Comprehensive Car Insurance Policy
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1" style={styles.bodyText}>
                      Offers broad coverage for accidents, natural calamities, and third-party liabilities. Excludes drunk driving and damages outside geographical limits. Includes personal accident cover for the driver/owner.
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem style={styles.listItem}>
                <ListItemText
                  primary={
                    <Typography variant="h5" style={styles.subHeading}>
                      Own Damage Car Insurance Policy
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1" style={styles.bodyText}>
                      Ideal for short-term, low-premium coverage, protecting against natural and man-made disasters. Suited for new vehicle owners.
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem style={styles.listItem}>
                <ListItemText
                  primary={
                    <Typography variant="h5" style={styles.subHeading}>
                      Third-Party Car Insurance Policy
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1" style={styles.bodyText}>
                      Mandatory for every vehicle owner, covering third-party liabilities for injury, disability, or property damage. Does not cover the insured car’s damage.
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          }
        />
      </ListItem>
    </List>

    <TableContainer component={Paper} >
    <Typography variant="h4" style={styles.heading}>
    Key features of Car Insurance Policy             </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={styles.tableHeading}>Key Features</TableCell>
            <TableCell style={styles.tableHeading}>Benefits with LMV Car Insurance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell style={styles.tableCell}>{item.feature}</TableCell>
              <TableCell style={styles.tableCell}>{item.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    <List>
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h4" style={styles.heading}>
              How to Compare Car Insurance Online?
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              Comparing car insurance plans helps you find the best policy for your car. By comparing policies online, you can save on premiums and select the ideal plan based on your requirements. Here are key points to consider when comparing car insurance policies:
            </Typography>
          }
        />
      </ListItem>

    
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Cost Matters
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              Your budget should be the focal point while comparing policies. Comprehensive plans cost more but offer extensive coverage with add-ons like roadside assistance and zero depreciation cover. Third-party plans are more affordable but have minimal coverage.
            </Typography>
          }
        />
      </ListItem>


      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Coverage
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              When comparing policies, assess their features and coverage. Comprehensive policies offer broader coverage compared to third-party policies. Choose the one that best suits your needs.
            </Typography>
          }
        />
      </ListItem>

   
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Claim Settlement Process
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              Different insurers have different claim settlement processes. Ensure you choose an insurer with a quick and hassle-free settlement process that meets your expectations.
            </Typography>
          }
        />
      </ListItem>

      
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Insured Declared Value (IDV)
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              IDV is the value of your car after depreciation and impacts your premiums. Compare IDV offerings from different insurers to ensure you get the best deal.
            </Typography>
          }
        />
      </ListItem>

     
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h4" style={styles.heading}>
              Why Do You Need Car Insurance?
            </Typography>
          }
          secondary={
            <List>
              
              <ListItem style={styles.listItem}>
                <ListItemText
                  primary={
                    <Typography variant="h5" style={styles.subHeading}>
                      Protection Against Damage
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1" style={styles.bodyText}>
                      Proper car insurance covers damage and repair costs, protecting you financially.
                    </Typography>
                  }
                />
              </ListItem>

            
              <ListItem style={styles.listItem}>
                <ListItemText
                  primary={
                    <Typography variant="h5" style={styles.subHeading}>
                      Third-Party Liability Protection
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1" style={styles.bodyText}>
                      Covers liabilities for death, injury, or property damage caused to a third party.
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem style={styles.listItem}>
                <ListItemText
                  primary={
                    <Typography variant="h5" style={styles.subHeading}>
                      Liability Towards Property
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1" style={styles.bodyText}>
                      Protects you from financial liabilities for property damage caused by your car.
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem style={styles.listItem}>
                <ListItemText
                  primary={
                    <Typography variant="h5" style={styles.subHeading}>
                      Personal Accident Cover
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1" style={styles.bodyText}>
                      Offers accident cover of up to ₹15 lakhs for injuries, disability, or death of the policyholder or driver.
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem style={styles.listItem}>
                <ListItemText
                  primary={
                    <Typography variant="h5" style={styles.subHeading}>
                      Adherence to the Law
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1" style={styles.bodyText}>
                      As per Indian laws, third-party insurance is mandatory. Failure to comply can result in heavy fines.
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem style={styles.listItem}>
                <ListItemText
                  primary={
                    <Typography variant="h5" style={styles.subHeading}>
                      Protection from Natural Disasters
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1" style={styles.bodyText}>
                      Covers repair expenses for damage caused by natural disasters like floods, storms, or earthquakes.
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          }
        />
      </ListItem>
    </List>


    <Typography variant="h4" style={styles.heading}>
        Best Car Insurance Policy in India 2022
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '16px', color: '#555' }}>
        Here is a table depicting India's best car insurance policy in 2022. You can compare the policies concerning claim ratio and find out the best policy that addresses your requirements.
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableHeading}>Car Insurers</TableCell>
              <TableCell style={styles.tableHeading}>Claim Ratio as of 2021-22</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {insurancePolicies.map((policy, index) => (
              <TableRow key={index}>
                <TableCell style={styles.tableCell}>{policy.companyName}</TableCell>
                <TableCell style={styles.tableCell}>{policy.claimRatio}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <List>
      <Typography variant="h4" style={styles.heading}>Benefits of Buying Car Insurance on LMV </Typography>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>Buy Policy Online</Typography>}
        secondary={<Typography variant="body1" style={styles.bodyText}>
          With LMV, you can eliminate the offline procedure of taking the car insurance and instead buy it online, saving your time and energy. In simple steps, you can get your Vehicle Insurance Online. You can check the available plans and finalize the policy that meets your requirements. Go paperless with an online car insurance policy.
        </Typography>}
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>Customization with Add-ons</Typography>}
        secondary={<Typography variant="body1" style={styles.bodyText}>
          We have access to many add-ons with the existing policy to extend the coverage. To put it simply, the add-ons help you customize the insurance policy based on your requirements.
        </Typography>}
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>Easy Claim Process</Typography>}
        secondary={<Typography variant="body1" style={styles.bodyText}>
          Our claim process is very easy and convenient to use. You can file a claim online on our website by filling up the online form dedicated to claims. You will need to input your car insurance policy number, contact info, accident info, etc. As soon as you input the details, the claim process will be initiated by us. You can also track the status of your claim online.
        </Typography>}
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h4" style={styles.heading}>Tie-up with a Great Number of Network Garages</Typography>}
        secondary={<Typography variant="body1" style={styles.bodyText}>
          Customers can claim cashless transactions and get the services done in the dedicated garages of the insurance companies. The insurance companies have tie-ups with a wide network of garages across the country, which the customer can make the best use of.
        </Typography>}
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>Assured Security</Typography>}
        secondary={<Typography variant="body1" style={styles.bodyText}>
          Our car insurance policies protect your vehicle from various threats, including loss, theft, damage caused by accidents, and natural and man-made disasters. Our car insurance policies cover almost all kinds of losses.
        </Typography>}
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>High Claim Settlement Ratio</Typography>}
        secondary={<Typography variant="body1" style={styles.bodyText}>
          Buy Car Insurance Online with LMV as our claim settlement ratio is high. Our claim settlement experts strive hard to settle the claims swiftly. Our claim settlement ratio is 98%, which shows our commitment to settling our customers' claims.
        </Typography>}
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>Time-Saving</Typography>}
        secondary={<Typography variant="body1" style={styles.bodyText}>
          Compared to the offline mode of buying car insurance, the online mode is very easy and time-saving. You can skip the steps of going to the insurance company and meeting up with the agent to discuss the premiums. You can simply get all the details by sitting in the comfort of your home.
        </Typography>}
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>Reminders of Payment</Typography>}
        secondary={<Typography variant="body1" style={styles.bodyText}>
          Forgetting about policy payments is a headache as it could badly affect the renewal discounts. However, you will receive reminders of payments before the due date with LMV online car insurance, which will help you clear the payment on time.
        </Typography>}
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText 

        primary={<Typography variant="h5" style={styles.subHeading}>Comparison Made Easy</Typography>}
        secondary={<Typography variant="body1" style={styles.bodyText}>

When you buy car insurance online at LMV, you will easily compare different car policies available in the market. You can compare the coverage and the premium amounts offered by the top aggregators before opting for the best policy.        </Typography>}
      />
    </ListItem>
    
  </List>

  <List>

  <Typography variant="h4" style={styles.heading}>Points to Consider While Choosing Car Insurance Policy </Typography>
  <Typography  style={styles.listItem}>Buying car insurance is not as simple as it seems. Good knowledge about the available policies is required to get the best deal. LMV Insurance understands the need to have a perfect car insurance policy and lists out the best-in-class policies that satisfy the customers' needs. Here are the points to consider while choosing a car insurance policy.  </Typography>
  <ListItem style={styles.listItem}>
    <ListItemText


      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Finalize on Insurance Type
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Before getting started, know the types of car insurance policies available and finalize the policy type you want to go with. With Vehicle Insurance Online, you have to choose between the third-party insurance cover and a comprehensive car policy covering only damage caused to a third-party vehicle or individual and complete coverage in an uneventful incident. Another type of insurance is the Own Damage Car Insurance Policy which is as popular as the above two. Choose among these insurance types wisely.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Policy Coverage
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The policy coverage should also be kept in mind while investing in it. You must note that the policy coverage is affected by the car's make and model and the area you live in. You can use the LMV calculator to determine which coverage suits your needs better.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Add-ons
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          If you already own a policy, you have the option to renew or upgrade it, which is only possible with add-ons. So you have to check if the add-ons are available with the insurer or not while buying the policy. We have the most popular add-ons that you can choose to provide extra protection to your vehicle.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Check Insured Declared Value (IDV)
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The IDV is the car's market value when doing the car insurance. It decides the amount at which your car is insured. In case of any physical damage to the car, the insured sum is given to the policyholder after claim settlement. The policyholder should know that the higher IDV gives full coverage to the car in case of accident or theft, while on the other hand, a lower IDV doesn't give enough coverage.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Network of Cashless Garages
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          If you want to repair your car in case of minor or major damages, you could visit your insurance provider's network garages to have the damages repaired. By doing so, you can avail of the cashless claims, wherein the insurer will directly pay the cost of repairs to the garage, and you won't have to splurge excessive amounts on your repairs!
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Compare Policies
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          With LMV Insurance's calculator, you can analyze different policies offered by different insurance companies. By comparing, you will get an idea about the policies and can easily shortlist the one that suits you.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Check Claim Settlement Ratio
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The claim settlement ratio is also significant to consider while you Buy Car Insurance Online. The high claim settlement ratio denotes high chances of getting the settlement done positively.
        </Typography>
      }
    />
  </ListItem>
</List>
<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h4" style={styles.heading}>
          What is Covered in Our Car Insurance Policy?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          LMV Insurance offers you great coverage against various uneventful events.
          <ul style={{mt:2}}>
            <li>Damage caused to the car due to theft, man-made error, natural calamities, riots, explosions, accidents, etc., is covered under the comprehensive car insurance policy.</li>
            <li>Damage caused during transit, like moving a car through railways, roadways, waterways, airways, etc., is also addressed by the comprehensive car insurance policy.</li>
            <li>Third-party liability is also covered along with the comprehensive policy if damage or death occurred to a third-party individual or property.</li>
            <li>Under the comprehensive policy, the insured will get a personal accident cover of up to ₹15 lakhs.</li>
            <li>Access to add-ons that will further prolong the life of the car insurance policy.</li>
          </ul>
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h4" style={styles.heading}>
          What is Not Covered in Our Car Insurance Policy?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          <ul>
            <li>Damages incurred as a result of driving without a valid license.</li>
            <li>If the vehicle met with an accident outside India's geographical region.</li>
            <li>Damages incurred as a result of drunk driving.</li>
            <li>Wear and tear of the car.</li>
            <li>Damages caused by the driver's carelessness.</li>
            <li>Damages caused by speed racing, crash testing, etc.</li>
            <li>The policy does not cover the general maintenance of the vehicle.</li>
            <li>Damages caused by war or conflict in the country, as well as nuclear danger.</li>
          </ul>
        </Typography>
      }
    />
  </ListItem>
</List>


<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h4" style={styles.heading}>
          Car Insurance Add-ons
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Add-ons are the extended covers provided by the insurers to the insured at various stages. It includes payment of a little extra premium. The add-ons will increase the life of the car insurance policy you have taken. Here are the add-ons we offer to our customers:
          <ul>
            <li>
              <Typography variant="body1" style={styles.bodyText}>
                <Typography variant="h5" style={styles.subHeading}>
                  Vehicle Replacement Cover
                </Typography>
                <ul>
                  <li>Helps get full reimbursement present on the invoice in case of theft or total damage to the vehicle.</li>
                  <li>Available until 5 years after taking the policy for cars up to 10 years old.</li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={styles.bodyText}>
                <Typography variant="h5" style={styles.subHeading}>
                  Key Replacement Cover without Deduction
                </Typography>
                <ul>
                  <li>Provides a new key in case of lost keys due to theft or other reasons.</li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={styles.bodyText}>
                <Typography variant="h5" style={styles.subHeading}>
                  Loss of Baggage
                </Typography>
                <ul>
                  <li>Coverage for lost or stolen baggage from the insured car.</li>
                  <li>Applicable only in case of accidents or theft within the geographical area.</li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={styles.bodyText}>
                <Typography variant="h5" style={styles.subHeading}>
                  NCB Protector Cover Plan
                </Typography>
                <ul>
                  <li>Allows filing claims during the term while enjoying NCB benefits.</li>
                  <li>Available for policyholders with a comprehensive car insurance policy.</li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={styles.bodyText}>
                <Typography variant="h5" style={styles.subHeading}>
                  Road Tax
                </Typography>
                <ul>
                  <li>Covers road tax in the insurance policy.</li>
                  <li>Reimburses road tax amount along with the insured amount in case of theft or loss.</li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={styles.bodyText}>
                <Typography variant="h5" style={styles.subHeading}>
                  Tyre Cover Clause
                </Typography>
                <ul>
                  <li>Provides coverage for damaged tyres and tubes due to accidents or not.</li>
                  <li>Applicable for policyholders with a Buy Car Insurance Online.</li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={styles.bodyText}>
                <Typography variant="h5" style={styles.subHeading}>
                  Aggravation Cover Without Deductibles
                </Typography>
                <ul>
                  <li>Also known as Engine Protector Cover, protecting the engine and its components.</li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={styles.bodyText}>
                <Typography variant="h5" style={styles.subHeading}>
                  Depreciation Waiver
                </Typography>
                <ul>
                  <li>Provides coverage for damages to plastic or metal parts without affecting the No Claim Bonus.</li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={styles.bodyText}>
                <Typography variant="h5" style={styles.subHeading}>
                  Windshield Glass
                </Typography>
                <ul>
                  <li>Helps repair the windshield glass in case of an accident.</li>
                  <li>Does not affect the No Claim Bonus.</li>
                </ul>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" style={styles.bodyText}>
                <Typography variant="h5" style={styles.subHeading}>
                  Spare Car
                </Typography>
                <ul>
                  <li>Provides travel allowance in case of an accident, and your car is in the garage.</li>
                </ul>
              </Typography>
            </li>
          </ul>
        </Typography>
      }
    />
  </ListItem>
</List>


<Typography variant="h4" style={styles.heading}>
  How To Buy Car Insurance Online?
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          1. Visit the LMV Insurance Website
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          <ul>
            <li>Go to our official website.</li>
            <li>Navigate to the “Insurance Products” tab and select Car Insurance.</li>
          </ul>
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          2. Fill Your Details
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          <ul>
            <li>Provide vehicle details like RTO, manufacturer model, tenure, registration date, previous policy details etc.</li>
            <li>Click on the “Get Quote” tab.</li>
          </ul>
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          3. Choose Your Plan
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          <ul>
            <li>Select the plan you wish to buy for your car.</li>
          </ul>
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          4. KYC Details
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          <ul>
            <li>Fill in the engine number, chassis number, previous policy details if any.</li>
            <li>Click on “Proceed to Payment” tab.</li>
          </ul>
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          5. Make Payment
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          <ul>
            <li>Enter your card details on the next page.</li>
            <li>Submit the payment.</li>
          </ul>
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Additional Information for New Cars
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          <ul>
            <li>Choose the make, model, fuel type, and variant for the new car.</li>
          </ul>
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Fetching Quote Without Car Number
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          <ul>
            <li>You can also get a quote without providing the car number.</li>
          </ul>
        </Typography>
      }
    />
  </ListItem>
</List>

<Typography variant="h4" style={styles.heading}>
  How to Calculate Four Wheeler Insurance Premium Online?
</Typography>

<Typography variant="body1" style={styles.bodyText}>
  When you Buy Car Insurance Online, the insured pays the premium to the insurance company monthly. The company decides the premium based on various factors. Our car insurance calculator online will help you easily find the premium amount for your four-wheeler. You need to input your information as prompted, choose the policy type and add-ons, and get the premium amount. The premium amount on the screen is what you need to pay for your car insurance coverage.
</Typography>

<Typography variant="body1" style={styles.bodyText}>
  <Typography variant="h5" style={styles.subHeading}>
    Factors Affecting Premium Calculation:
  </Typography>
  <ul>
    <li>Age and Type of Car</li>
    <li>Insured Declared Value (IDV) of the Vehicle</li>
    <li>Geographical Zone</li>
    <li>Engine's Cubic Capacity</li>
  </ul>
</Typography>

<Typography variant="body1" style={styles.bodyText}>
  <Typography variant="h5" style={styles.subHeading}>
    Premium Calculation Formula:
  </Typography>
  <ul>
    <li>
      Own Damage Insurance Premium = [Insured Declared Value X Car Insurance Premium as decided by the insurer] + Add-ons - [NCB/Discount]
    </li>
    <li>
      Insured Declared Value (IDV) = Showroom Price of the car + Cost of Accessories of the car - Depreciation on Car.
    </li>
  </ul>
</Typography>

<Typography variant="h4" style={styles.heading}>
How to Renew Car Insurance Online?       </Typography>
<List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Visit Our Website
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                First, visit our website and go to the Renewal section from the main menu.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Enter Customer Details
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Select the customer type and input details like name, mobile number, email ID, date of birth, and customer ID, then click on the Search button.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Select Plan Details
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                On the next page, enter the plan details such as dealer code, select business type, customer type, and plan type, then click on Proceed.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Find Your Renewal Amount
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Use the premium calculator to find the renewal amount.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Generate Quote
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Your quote is generated successfully. Click on the View Quote button to display the quote on the screen.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Confirm Renewal
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Click on Convert to Proposal to confirm the renewal.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        Benefits of Renewing Auto Insurance Policy Online
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Easy Customization
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                If you want any changes to your policy, you can do it easily if you renew your policy online. By including the add-ons, you can extend the life of your policy. Nevertheless, you are also increasing your premium amount by upgrading the plan.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Secure
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Make informed decisions with secure renewals. We have secured gateways for online payments so that the money is in safe hands. All the information shared during the online transactions will be kept safe.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Compare and Switch to the Best Insurer
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                As you are doing the renewal online, you can easily compare the insurers and the insurance policies and switch to a better policy. You can select the best plan for features and premium for yourself when opting for online renewal.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                No Claim Bonus Transfer Made Easy
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                It would be best to transfer no claim bonus during policy renewal, which is a lengthy process when done offline. However, if you do it online, it is not just easy but also very time-saving.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        Why Should You Renew an Expired Car Insurance Policy Now?
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Legal Problem
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Every car that gets on the road must and should have a motor insurance policy, and if the policy expires, the car insurance coverage expires. It may result in legal trouble if caught by authority. The same is true even if you buy car insurance online.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                No Coverage
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Suppose your vehicle meets with an accident and its policy has expired. In that case, you won't get any coverage for it, which means you are not eligible to register any claims against the coverage. You have to use money from your pocket to get the repairs done for the vehicle.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                No-Claim Bonus Loss
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                When taking the policy, the no-claim bonus is given to the insurer. If the car insurance policy is expired, the NCB will not be granted to the policyholder. The no-claim bonus is the discount given by the insurer to the insured if no claim is made during the policy term. If you fail to renew your expired car insurance policy, you won't receive any no-claim bonus from the insurer.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Time Taking Renewal Process
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                If you fail to renew your four-wheeler insurance and it expires, it takes time to get it renewed again. You have to check the time and renew it before it expires, but if that is not the case, you will have to wait a little longer to get the process done. The delay could be due to the inspection of the car.
              </Typography>
            }
          />
        </ListItem>
      </List>


      
      <Typography variant="h4" style={styles.heading}>
        How to File an Online Car Insurance Claim?
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Open the LMV Insurance Website
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                First, open the LMV Insurance website.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Sign In
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                From the sign-in page, enter your mobile number and OTP, then click on "Proceed".
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Access Your Dashboard
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                The Dashboard will appear with the policies you have bought.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Click on “Renewals” Tab
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Navigate to the “Renewals” tab to start the claim process.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        Documents Required to File Car Insurance Claim After an Accident
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Policy Documentation for Vehicle Insurance
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Submit the policy documentation for the vehicle insurance.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Photocopy of Your Driver's License
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Include a photocopy of your driver's license.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Copy of the Car's RC
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Provide a copy of the car's Registration Certificate (RC).
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Signed Copy of the Claim
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Submit a signed copy of the claim.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Certificate of Fitness (for commercial vehicles)
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                A certificate of fitness is required for commercial vehicles.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Aadhar Card, PAN Card, and Other Forms of Identification
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Aadhar card, PAN card, and other forms of identification of the insured person are acceptable.
              </Typography>
            }
          />
        </ListItem>
      </List>


      <Typography variant="h4" style={styles.heading}>
        FAQs
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                What does Zero Depreciation mean in Car Insurance Policy?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Zero Depreciation is a popular add-on cover that allows the insured to get the total market value of the car without Depreciation. The policyholder needs to buy this add-on to avail of this service.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                What is the fine for driving a four-wheeler without a car insurance policy?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                A third-party insurance policy is mandatory for every car. If you get caught driving without a valid insurance policy, you will have to pay Rs. 2,000 fine or be imprisoned for up to 3 months. If you get caught for the second time, you may have to pay Rs. 4,000 fine or be jailed for up to 3 months.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Is it possible to transfer car insurance to a new owner when purchasing a used four-wheeler?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                In the event of the sale of your used car, the four-wheeler insurance gets transferred from the previous owner to the new owner. However, the insurance transfer must happen within 14 days of the car's purchase date, according to the Indian Motor Tariff.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                What does Constructive Total Loss mean?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                An insurance company declares the insured car as a constructive total loss when damaged to an extent where repair cost equals or exceeds its price or coverage limit. It states that the insurance company will have to pay out the car's insured value rather than pay for its restoration to its previous condition.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Can I acquire medical coverage for bodily injury or the death of passengers in my vehicle? Should I get a different auto insurance policy for that?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                You can acquire personal accident insurance for passengers commuting in your car with the add-on policy. This coverage will cover the expenses for the treatment of the injured.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Why do car insurance premiums fluctuate from company to company?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                The car insurance premiums are different for every company because they use different formulas to calculate the risk and thus the premium amount.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                What are the documents required to avail No Claim Bonus?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                The policy document is enough to avail yourself of a No Claim Bonus from the company where you have your insurance policy. Still, if you are changing your insurance company, you will have to submit your previous year's car insurance policy document or renewal notice to list the NCB you're qualified for.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                What documents do I need to provide to receive my No Claim Bonus?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                If you're renewing your auto insurance coverage with your insurer, you'll need your policy document to get the No Claim Bonus. However, suppose you're renewing your policy with a new insurance company for your current automobile. In that case, you'll need your previous year's car insurance policy document or renewal notification, which will list the NCB you're qualified for.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Does the model of your car impact the cost of car insurance?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Yes! Different car models have different car insurance premiums. Each car model uses different parts, some of which could be more expensive than other cars.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Can the insurer choose the garage where he wants to repair his car?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Yes! The insurer can get his car repaired at any garage he wants, but the garage should be a part of the insurance company's network to perform the cashless insurance; otherwise, you will have to file a reimbursement claim.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Is it possible to file a car insurance claim six months after the accident?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                No! The car insurance companies provide the policyholders with a date before contacting the company to file a claim. Nevertheless, the time to submit the claim differs from company to company. So, you are advised to file a claim as early as possible.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Can we file multiple claims in a year?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Yes! The insurance companies allow the users to file multiple claims until the IDV reaches the point. The number of claims per year is mentioned in your policy document.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                What is bumper to bumper car insurance policy?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                A bumper-to-bumper car insurance policy offers out-and-out coverage to the insured car, not considering the Depreciation of the car parts. This type of insurance policy compensates the entire market value of the vehicle in case of damage to the car. However, the twist is that the premiums are usually 20% higher than the normal car insurance premiums.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                What risks does a comprehensive car insurance policy cover?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                The comprehensive car insurance policy covers various risks like a liability to the third party, accident by external means, explosion, fire, lightning, self-ignition, riots, terrorism, strikes, malicious acts, flood, earthquake, storm, transit by rail, landslide, air or lift, waterways, theft, burglary, etc.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                What are the exclusions in comprehensive car insurance?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                The comprehensive car insurance policy does not cover wear and tear, mechanical or electrical breakdown, damage to tyres and tubes unless the car is damaged due to an accident, damage to accessories like radio, tapes, etc., depreciation, and consequential loss.
              </Typography>
            }
          />
        </ListItem>
      </List>
        </Container>
         );
       };
    
    export default LMVCarInsurance;