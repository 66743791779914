// actions.js
import * as actionTypes from './actionType';
import axios from 'axios';
import { common_api, health_api } from '../../Config';
export const updateFamilyData = (data) => {
  return {
    type: actionTypes.UPDATE_FAMILY_DATA,
    payload: data
  };
};
 
export const updateSonCount = (count) => {
  return {
    type: actionTypes.UPDATE_SON_COUNT,
    payload: count
  };
};
 
export const updateDaughterCount = (count) => {
  return {
    type: actionTypes.UPDATE_DAUGHTER_COUNT,
    payload: count
  };
};
 
export const storeResponseData = (data) => {
  return {
    type: actionTypes.STORE_RESPONSE_DATA,
    payload: data
  };
};
 
export const storeKycResponse = (data) => {
  return {
    type: actionTypes.STORE_KYC_RESPONSE,
    payload: data
  };
};
 
export const updateProposerGender = (gender) => {
  return {
    type: actionTypes.UPDATE_PROPOSER_GENDER,
    payload: gender
  };
};
 
export const updatePremiumPostObject = (premiumPostObject) => {
  return {
    type: actionTypes.UPDATE_PREMIUM_POST_OBJECT,
    payload: premiumPostObject
  };
};
 
export const updateProposalNumber = (data) => {
  return {
    type: actionTypes.UPDATE_PROPOSAL_NUMBER,
    payload: data
  };
};
 
export const updateProposerData = (data) => {
  return {
    type: actionTypes.UPDATE_PROPOSER_DATA,
    payload: data
  };
};
 
export const updatedQuotationResult = (data) => {
  return {
    type: actionTypes.UPDATED_QUOTATION_RESULT,
    payload: data
  };
};

export const storeQuote = (data)=>{
  return {
    type: actionTypes.STORE_QUOTE,
    payload:data
  };
}
 
export const setSelectedProvider = (providerName) => {
  return {
    type: actionTypes.SELECTED_PROVIDER,
    payload: providerName
  };
};
export const setProposalData = (data) => {
  return {
    type: actionTypes.GET_PROPOSAL_DATA,
    payload: data
  };
};
 
export const selectedHealthPolicyType = (data) => {
  return {
    type: actionTypes.SELECT_HEALTH_POLICY_TYPE,
    payload: data
  };
};
 
// const loadingRequest = () => {
//   return {
//     type: actionTypes.Get_Dashboard_Data_Request
//   };
// };
 
export const postTotalCustomData = (payload) => {
  return {
    type: actionTypes.Add_Customer_Data_Success,
    payload
  };
};
 
export const getSuccessSmsRequest = (payload) => {
  return {
    type: actionTypes.Mobile_OTP_Request,
    payload
  };
};
 
export const getSuccessSmsResponse = (payload) => {
  return {
    type: actionTypes.Mobile_OTP_Verification_Request,
    payload
  };
};

export const getHDFCTxnID = (payload)=>{
  return{
    type:actionTypes.generatedHDFCTxnId,
    payload
  }
}
export const getHDFCPolicydata = (payload) => {
  return{
    type:actionTypes.hdfcPolicydata,
    payload
  }
}