import React, { useState } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  IconButton,
  InputLabel,
  FormControl,
  Typography,
  CircularProgress,
  Stack,
  Modal,
  LinearProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import PolicyIcon from "@mui/icons-material/Policy";
import { useForm } from "react-hook-form";
import {v4 as uuidv4} from 'uuid'
import { useDispatch } from "react-redux";
import { clearError, setError } from "../../../../Redux/action-creators/errorAction";
import { clearSuccessMessage } from "../../../../Redux/action-creators/successAction";



export default function AddExistingPolicy({profileData , updateCustomerDetails}) {
  const dispatch = useDispatch();
   const {
    formState: { errors, isSubmitted },
    register,
    setValue,
    handleSubmit,
    trigger,
    reset
  } = useForm();
  const [isModalTrue, setIsModalTrue] = useState(false);
  const [isAddLoaderTrue , setIsAddLoaderTrue] = useState(false)
  const [deleteLoader , setDeleteLoader] = useState(false)

  function CampareTwoObjects(obj1, obj2) {
    if (Object.keys(obj1)?.length != Object.keys(obj2)?.length) {
      return false;
    }
    for (let key in obj1) {
      if (key !== "id") {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }
    return true;
  }
 

  const addNewRecord = async(data) =>{
     dispatch(clearError());
     dispatch(clearSuccessMessage());
    const newRecord = { id: uuidv4(), ...data };
    const isRecordExist = profileData?.exit_policies?.filter((eachValue) => CampareTwoObjects(eachValue , newRecord)) || []
    if(isRecordExist?.length > 0){
      dispatch(setError(`This Policy is already Exist`));
    }else{
    
    const mapObject = {
      ...profileData,
      family_data: JSON.stringify(profileData?.family_data),
      assets: JSON.stringify(profileData?.assets),
      exit_policies: JSON.stringify([...profileData?.exit_policies || [], newRecord]),
      alternate_numbers: JSON.stringify(profileData?.alternate_numbers),
      alternate_emails: JSON.stringify(profileData?.alternate_emails),
    };
    setIsAddLoaderTrue(true);
    await updateCustomerDetails(mapObject)
    setIsAddLoaderTrue(false);
    reset()
    setIsModalTrue(false)
  }
  }

  const deletePolicy = async(id) =>{
    const updatePolicy = profileData?.exit_policies?.filter((eachValue)=> eachValue?.id !== id)

    const mapObject = {
      ...profileData,
      family_data: JSON.stringify(profileData?.family_data),
      assets: JSON.stringify(profileData?.assets),
      exit_policies: JSON.stringify(updatePolicy),
      alternate_numbers: JSON.stringify(profileData?.alternate_numbers),
      alternate_emails: JSON.stringify(profileData?.alternate_emails),
    };
    setDeleteLoader(true)
  await updateCustomerDetails(mapObject);
  setDeleteLoader(false)
  }

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"100%"}
        padding={3}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            m: 2,
            alignSelf: "flex-start",
          }}
        >
          <PolicyIcon sx={{ fontSize: "2rem", color: "#0000ff", mr: 1 }} />
          <Typography variant="h4">Exit Policy Details</Typography>
        </Box>

        {deleteLoader && (
          <Box width={"100%"} marginTop={2} marginBottom={2}>
            <LinearProgress />
          </Box>
        )}

        {profileData?.exit_policies?.length === 0 || profileData?.exit_policies === null ? (
          <Typography variant="h3" marginTop={5} marginBottom={5}>
            Empty
          </Typography>
        ) : (
          // table
          <TableContainer
            component={Paper}
            sx={{
              width: "70%",
              minHeight: "150px",
              maxHeight: "300px",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: 400 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    POLICY NUMBER
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    COMPANY
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    START DATE
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    END DATE
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    DELETE
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={{ overflow: "auto" }}>
                {profileData?.exit_policies?.map((row) => (
                  <TableRow
                    key={row?.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row?.policy_number}
                    </TableCell>
                    <TableCell align="center">{row?.company}</TableCell>
                    <TableCell align="center">{row.start_date}</TableCell>
                    <TableCell align="center">{row.end_date}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        disabled={deleteLoader}
                        onClick={() => deletePolicy(row?.id)}
                        sx={{
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* // buttons */}
        <Stack
          spacing={2}
          direction={"row"}
          sx={{ alignSelf: "center", marginTop: "30px" }}
        >
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsModalTrue(true)}
          >
            ADD POLICY
          </Button>
        </Stack>
      </Box>

      {/* modal */}
      <Modal
        open={isModalTrue}
        onClose={() => setIsModalTrue(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          width={500}
          height={400}
          bgcolor={"white"}
          padding={5}
          borderRadius={5}
        >
          <form onSubmit={handleSubmit(addNewRecord)}>
            <Grid container columnSpacing={2} rowGap={3}>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"right"}
                marginBottom={2}
              >
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsModalTrue(false);
                    reset();
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Policy Number"
                  name="policyNumber"
                  {...register("policy_number", {
                    required: "Policy Number is required...",
                  })}
                  variant="outlined"
                  error={!!errors.policy_number}
                  helperText={
                    errors.policy_number ? errors.policy_number.message : ""
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Company"
                  name="Company"
                  {...register("company", {
                    required: "Company name is required...",
                  })}
                  variant="outlined"
                  error={!!errors.company}
                  helperText={errors.company ? errors.company.message : ""}
                  onInput={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  type="date"
                  fullWidth
                  label="Start Date"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="Start_date"
                  {...register("start_date", {
                    required: "Start Date is required..",
                  })}
                  error={!!errors.start_date}
                  helperText={
                    errors.start_date ? errors.start_date.message : ""
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  type="date"
                  fullWidth
                  label="End Date"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="end_date"
                  {...register("end_date", {
                    required: "End Date is required..",
                  })}
                  error={!!errors.end_date}
                  helperText={errors.end_date ? errors.end_date.message : ""}
                />
              </Grid>

              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isAddLoaderTrue}
                >
                  {isAddLoaderTrue ? <CircularProgress size={20} /> : "ADD"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
}
