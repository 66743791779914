import React, { useEffect, useState } from "react";
import "./Quotationcard.css";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ModalComponent from "./ModalComponent";
import { Images, careSupremeBenefits, productDetails } from "../Data/Benifits";
import {
  Tooltip,
  LinearProgress,
  Input,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updatedQuotationResult,
  updatePremiumPostObject,
  storeQuote,
  setSelectedProvider,
} from "../../Redux/Health/action";
import { useNavigate } from "react-router-dom";
import { generatePremium } from "../api/PremiumGeneration";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Slider from "@mui/material/Slider";
import axios from "axios";

const HdfcOptimaSecureCard = ({
  handleCheckBox,
  collectData,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productFeatures, setProductFeatures] = useState([]);
  const [premium, setPremium] = useState(null);
  const [image, setImage] = useState(null);
  const [check, setCheck] = useState(false);
  const [isFieldNCBTrue, setFieldNCBTrue] = useState(false);
  const [fieldNCBValue, setFieldNCBValue] = useState(0);
  const [isFieldCSTrue, setFieldCSTrue] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const premiumPostBody = useSelector(
    (state) => state?.healthReducer?.premiumPostObject
  );

  const [postBody, setPostBody] = useState(premiumPostBody);

  const handleChange = (e) => {
    setCheck(e.target.checked);
    collectData(e.target.checked, quote);
  };

  const fetchQuotationData = async () => {
    setLoading(true);
    try {
      let quote = await generatePremium({ ...postBody, providers: ["hdfc"] });
      setQuote(quote?.data?.data[0]);

      // let quote = await axios.post('http://localhost:8083/insurance/v1/health/policy/quote' , { ...postBody, providers: ["hdfc"] , generatedHDFCTxnId : 'LMV0000030983361'})
      // const response = JSON.parse(quote?.data?.body)?.data[0]
      // setQuote(response)
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  //   useEffect(() => {
  //     handleCheckBox(quote, check, image);
  //   }, [check]);
  useEffect(() => {
    fetchQuotationData();
  }, [postBody]);

  useEffect(() => {
    setPostBody(premiumPostBody);
  }, [premiumPostBody]);

  const getbenefitsForProduct = (productName) => {
    const benefitsData = productDetails?.find(
      (benefit) =>
        benefit?.product?.toLowerCase() === productName?.toLowerCase()
    );
    return benefitsData?.features;
  };

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  const findImage = (providerName) => {
    let imageObj = Images?.find((e) => e?.provider === providerName);
    return imageObj?.image;
  };

  useEffect(() => {
    let features = getbenefitsForProduct(quote?.productName);
    setProductFeatures(features);
    let image = findImage(quote?.provider);
    setImage(image);
    let premium = quote?.premiumWithGST;
    let premiumValue =
      typeof premium === "string"
        ? parseFloat(premium.replace(/,/g, ""))
        : premium;
    let formattedPremium = premiumValue
      ?.toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setPremium(formattedPremium);
    // ProviderPrimium(premium);
  }, [quote]);

  const handleNavigate = (quote) => {
    let provider = quote.provider;

    let code = quote.code;
    const updatedPostBody = {
      ...postBody,
      provider: provider,
      abacusId: code,
    };
    navigate("/health/proposalform");
    dispatch(updatedQuotationResult(quote));
    dispatch(setSelectedProvider(provider));

    dispatch(updatePremiumPostObject(updatedPostBody));
  };
  console.log(
    useSelector((state) => state.healthReducer.quotationData),
    "quotationDataquotationDataquotationData"
  );

  const handleCheckboxChange = (name, checked) => {
    if (
      name === "field_NCB" &&
      premiumPostBody.highestElderAge >= 76 &&
      premiumPostBody.highestElderAge <= 99
    )
      return;
    if (name === "field_NCB" || name === "field_CS") {
      const updatedPostBody = { ...postBody, [name]: checked };
      setPostBody(updatedPostBody);
    } else {
      const updatedPostBody = { ...postBody, [name]: checked ? true : false };
      setPostBody(updatedPostBody);
    }
  };

  return (
    <>
      <ModalComponent
        open={modalOpen}
        handleClose={handleModal}
        quote={quote}
        features={careSupremeBenefits}
      />
      {loading ? (
        <LinearProgress />
      ) : quote ? (
        <div className="health_quotation_main_card  mb_25 ">
          <div className="mt-2">
            <input
              className="form-check-input"
              onChange={(e) => handleChange(e)}
              type="checkbox"
              style={{
                fontSize: "25px",
                marginLeft: "10px",
                border: "1px solid",
              }}
            />
          </div>
          {/* <div className="mt-2">
            <input
              className="form-check-input"
              onChange={(e) => handleChange(e)}
              type="checkbox"
              style={{
                fontSize: "25px",
                marginLeft: "10px",
                border: "1px solid",
              }}
            />
          </div> */}
          <div className="d_flex quotation_upr_div">
            <div className="logo_container">
              <img src={image} alt="CARE HEALTH" className="logo_box" />
            </div>

            <div className="quotation_content">
              <div className="content_box_cs">
                <div className="plane_name plan_details">
                  <div className="title_pa">{quote?.productName}</div>
                  <div className="suminsured_text">
                    Sum Insured : ₹{quote?.sumInsured}
                  </div>
                  <b>{quote?.term || quote?.tenure} year policy</b>
                </div>

                <div className="plan_feature plan_details">
                  <div
                    className="health_check_compare"
                    style={{ marginRight: "30px" }}
                  >
                    <ul className="features_list">
                      {productFeatures?.slice(0, 4)?.map((item, i) => (
                        <li key={i} className="font-12">
                          <Tooltip title={item.benefit} placement="right-start">
                            <HealthAndSafetyIcon
                              fontSize="small"
                              style={{ marginRight: "5px", color: "#03a9f4" }}
                            />
                            <span className="font-blue">{item.feature}</span>
                          </Tooltip>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="health_check_compare">
                    <ul className="features_list">
                      {productFeatures?.slice(4, 8)?.map((item, i) => (
                        <li key={i} className="font-12">
                          <Tooltip title={item.benefit} placement="right-start">
                            <HealthAndSafetyIcon
                              fontSize="small"
                              style={{ marginRight: "5px", color: "#03a9f4" }}
                            />
                            <span className="font-blue">{item.feature}</span>
                          </Tooltip>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="text_right hal_buy_btn_btn plan_details">
                  <a onClick={() => handleNavigate(quote)} className="buy_btn">
                    <Tooltip title="Buy Plan" placement="bottom">
                      <span>
                        <i className="fa-solid fa-indian-rupee-sign mr_5"></i>₹{" "}
                        {premium}
                      </span>
                    </Tooltip>
                    <p>*Excluding GST</p>
                    <small>BUY NOW</small>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="d_flex quotation_bottom_div">
            <button onClick={handleModal} className="features_btn">
              View Features <ChevronRightIcon />
            </button>
          </div>

          <div className="add_addon_div">
            <div className="d_flex addons_flex_container">
              <div className="health_addons_input_box">
                <label>
                  <input
                    type="checkbox"
                    name="field_NCB"
                    // disabled={
                    //   premiumPostBody.highestElderAge >= 76 &&
                    //   premiumPostBody.highestElderAge <= 99
                    // }
                    // checked={
                    //   (premiumPostBody.highestElderAge >= 76 &&
                    //     premiumPostBody.highestElderAge <= 99) ||
                    //   Number(postBody.field_NCB)
                    // }
                    checked={isFieldNCBTrue}
                    onChange={(e) => {
                      setFieldNCBTrue((prv) => !prv); // first
                      if (isFieldNCBTrue && postBody?.field_NCB != 0) {
                        setFieldNCBValue(0);
                        setPostBody((prv) => ({ ...prv, field_NCB: 0 }));
                      } // second
                    }}
                  />
                  <span>Critical Illness</span>
                </label>
                {isFieldNCBTrue && (
                  <>
                    <div>
                      <input
                        type="number"
                        value={fieldNCBValue}
                        onChange={(e) => setFieldNCBValue(e.target.value)}
                        style={{ width: "100px", marginRight: "10px" }}
                      />
                      <Button
                        variant="contained"
                        sx={{ height: "30px" }}
                        onClick={() => {
                          if (fieldNCBValue <= 20000000) {
                            handleCheckboxChange("field_NCB", fieldNCBValue);
                          }
                        }}
                      >
                        Set
                      </Button>
                    </div>
                    <p style={{ color: "red" }}>
                      {fieldNCBValue > 20000000 && "Enter Below 20000000"}
                    </p>
                  </>
                )}
              </div>

              <div className="health_addons_input_box">
                <label>
                  <input
                    type="checkbox"
                    name="field_34"
                    checked={Number(postBody?.field_34)}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.name, e.target.checked)
                    }
                  />
                  <span>Unlimited Restore</span>
                </label>
              </div>

              <div className="health_addons_input_box">
                <label>
                  <input
                    checked={Number(postBody?.field_SS)}
                    type="checkbox"
                    name="field_SS"
                    onChange={(e) =>
                      handleCheckboxChange(e.target.name, e.target.checked)
                    }
                  />
                  <span>Personal Accident Rider</span>
                </label>
              </div>

              <div className="health_addons_input_box">
                <label>
                  <input
                    checked={isFieldCSTrue}
                    type="checkbox"
                    name="field_CS"
                    onChange={(e) => {
                      setFieldCSTrue((prv) => !prv); // first
                      if (isFieldCSTrue && postBody?.field_CS != 0) {
                        setPostBody((prv) => ({ ...prv, field_CS: 0 }));
                      } // second
                    }}
                  />
                  <span>Hospital Cash</span>
                </label>
                {isFieldCSTrue && (
                  <select
                    name="field_CS"
                    value={postBody?.field_CS}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.name, e.target.value)
                    }
                  >
                    <option value={0}>0</option>
                    <option value={500}>500</option>
                    <option value={1000}>1000</option>
                    <option value={1500}>1500</option>
                    <option value={2000}>2000</option>
                  </select>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default HdfcOptimaSecureCard;
