import React, { useEffect, useState, useCallback } from "react";
import "./QuotationPage.css";
import CompareArrowsRoundedIcon from "@mui/icons-material/CompareArrowsRounded";
import AddToCompareModal from "../Components/AddToCompareModal";

import QuotationCard from "../../Health/Components/QuotationCard";
import CareAdvantageCard from "../../Health/Components/CareAdvantageCard";
import BajajQuardCard from "../../Health/Components/BajajQuardCard";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
  Badge,
  Button,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { generatePremium } from "../api/PremiumGeneration";
import { updatePremiumPostObject } from "../../Redux/Health/action";
import HdfcOptimaSecureCard from "../Components/HdfcOptimaSecureCard";
import { Row } from "react-bootstrap";

const QuotationPage = () => {
  const navigate = useNavigate();
  const premiumPostBody = useSelector(
    (state) => state?.healthReducer?.premiumPostObject
  );
  console.log(premiumPostBody, "premiumPostBodypremiumPostBody");
  const dispatch = useDispatch();
  const [pincode, setPincode] = useState(premiumPostBody?.pincode || "");
  const [loading, setLoading] = useState(false);
  const [pincodeError, setPincodeError] = useState(false);
  const [open, setOpen] = useState(false);
  const [req, setReq] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [arr, setArr] = useState([]);
  const [provider, setProvider] = useState("Providers");
  const [addons, setAddons] = useState(false);
  const [sumInsured, setSumInsured] = useState(
    premiumPostBody?.selectedCoverage || ""
  );
  const [tenure, setTenure] = useState(premiumPostBody?.tenure || "");
  // let quotationData = useSelector((e) => e?.healthReducer?.quotationData);
  let quotationData = useSelector((e) => {
    return e?.healthReducer?.quote;
  });

  console.log(quotationData, "quotationData");

  let tenureOptions = ["1 Year", "2 Year", "3 Year"];
  let individualSumInsured = [
    "5",
    "7",
    "10",
    "15",
    "25",
    "50",
    "100",
    "200",
    "300",
    "600",
  ];
  let providers = ["Providers", "CARE", "HDFC", "BAJAJ"];
const [premiumData, setPremiumData] = useState({
  CARE: {},
  HDFC: null,
  BAJAJ: null,
});
  // let postBody = { ...premiumPostBody, providers: ["care"] }
  useEffect(() => {
    if (!sumInsured || !tenure || !pincode || provider === "Providers") {
      setAlertOpen(true); 
    }
  }, [sumInsured, tenure, pincode, provider]);
  const handleAlertClose = () => setAlertOpen(false);
  const handleSumInsuredChange = (event) => {
    setSumInsured(event.target.value);
    dispatch(
      updatePremiumPostObject({
        ...premiumPostBody,
        selectedCoverage: event.target.value,
      })
    );
  };
  const handleTenureChange = (event) => {
    setTenure(event.target.value);
    dispatch(
      updatePremiumPostObject({
        ...premiumPostBody,
        tenure: event.target.value,
      })
    );
  };

  const handlePincodeChange = (event) => {
    const value = event.target.value;
    if (value.length <= 6) {
      setPincode(value);
    }
    const isValidPincode = value.length === 6;
    setPincodeError(!isValidPincode);

    if (isValidPincode) {
      dispatch(
        updatePremiumPostObject({
          ...premiumPostBody,
          pincode: value,
        })
      );
    }
  };

  const handleProviderChange = (event) => {
    setProvider(event.target.value);
  };
  // console.log( useSelector((e) => e?.healthReducer?.quotationData),"quotationData in quotation page");

  const handleOpen = () => setOpen(true);
  const handleClose = (e, reason) => {
    // if (reason && reason == "backdropClick") {
    //   return;
    // } else {
    //   setOpen(false);
    //   // setSaodTPDetailsOpen(false);
    // }
    setOpen(false);
  };

 const updatePremium = useCallback((provider, product, premium) => {
   if (premium && premium !== 0 && premium !== null) {
     setPremiumData((prevData) => {
       if (provider === "CARE") {
         return {
           ...prevData,
           CARE: {
             ...prevData.CARE,
             [product]: premium,
           },
         };
       } else if (provider === "HDFC") {
         return {
           ...prevData,
           HDFC: {
             ...prevData.HDFC,
             [product]: premium, 
           },
         };
       } else if (provider === "BAJAJ") {
         return {
           ...prevData,
           BAJAJ: {
             ...prevData.BAJAJ,
             [product]: premium, 
           },
         };
       }
       return prevData;
     });
   }
 }, []);

 useEffect(() => {
   if (quotationData) {
     // Assuming quotationData has the structure { premium, provider, productName }
     const { premiumWithGST, provider, productName } = quotationData;
       updatePremium(quotationData.provider,quotationData.productName,quotationData.premiumWithGST);
   }
 }, [ quotationData,updatePremium]);
 useEffect(()=>{
  setPremiumData({
    CARE: {},
    HDFC: null,
    BAJAJ: null,
  });
 },[tenure,pincode,sumInsured])
 console.log(premiumData,"1766666")

  const collectData = (check, data) => {
    console.log(data, "data from card");
    console.log(JSON.stringify(data));

    if (check) {
      setArr([...arr, data]);
    } else {
      console.log(
        arr.filter((item) => item.productName !== data.productName),
        "data from else"
      );
      setArr(arr.filter((item) => item.productName !== data.productName));
    }
  };
  console.log(
    useSelector((state) => state.healthReducer.premiumPostObject),
    "premiumPostObject"
  );
  console.log(premiumData, "premiumDatapremiumData");

  // const updatePremium = useCallback((provider, premium) => {
  //   if (premium && premium !== 0 && premium !== null) {
  //     setPremiumData((prevData) => {
  //       if (provider === "CARE") {
  //         return { ...prevData, CARE: [...prevData.CARE, premium] };
  //       }
  //       return { ...prevData, [provider]: premium };
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   setPremiumData({
  //     CARE: {},
  //     HDFC: null,
  //     BAJAJ: null,
     
  //   });
  // }, [sumInsured, tenure, pincode]);
  console.log(premiumData, "setPremiumData");

  //   let nonZeroPremiumCount;
  // if (premiumData=== "CARE" && sumInsured > 100) {
  //   nonZeroPremiumCount =
  //     Object.values(premiumData).filter((premium) => premium).length - 1;

  //   return nonZeroPremiumCount;
  // } else {
  //   nonZeroPremiumCount = Object.values(premiumData).filter(
  //     (premium) => premium
  //   ).length;
  // }

  // const nonZeroPremiumCount = Object.values(premiumData)
  //   .flat()
  //   .filter((premium) => premium).length;

  const nonZeroPremiumCount = Object.values(premiumData)
    .flatMap((provider) => {
      if (provider && typeof provider === "object") {
        return Object.values(provider);
      }
      return provider; 
    })
    .filter((premium) => premium && premium !== 0 && premium !== null).length;
  

  return (
    <>
      <Grid container spacing={2} style={{ margin: "10px 0" }}>
        {/* left side container */}
        <Grid item xs={11} md={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              justifyContent: "space-between",
            }}
            gap={2}
          >
            {/* back button */}
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <IconButton
                onClick={() => navigate("/health/Familyagefield")}
                aria-label="Go back"
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="overline">Go back</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { sx: "row", md: "column" },
                gap: { xs: 2, md: 3 },
                flexWrap: "wrap",
              }}
            >
              {nonZeroPremiumCount > 1 && (
                <Badge
                  badgeContent={arr.length}
                  color="primary"
                  sx={{ marginRight: 0 }}
                >
                  <Button
                    onClick={handleOpen}
                    variant="contained"
                    sx={{
                      width: { xs: "80%", sm: "100%", md: "100%" },
                      minWidth: 130,
                      height: "40px",
                      borderRadius: "8px",
                      marginLeft: "20px",
                      boxShadow: 3,
                      "&:hover": {
                        backgroundColor: "#1976d2",
                        boxShadow: 6,
                      },
                      backgroundColor: "#2196f3",
                      color: "white",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "0",
                      paddingRight: "0",
                    }}
                    startIcon={<CompareArrowsRoundedIcon />}
                  >
                    Compare
                  </Button>
                </Badge>
              )}

              <AddToCompareModal
                open={open}
                handleClose={handleClose}
                arr={arr}
                req={req}
              />

              {/* sum insured */}
              <TextField
                disabled={loading}
                label="Sum Insured"
                sx={{ minWidth: 120 }}
                select
                value={sumInsured}
                onChange={handleSumInsuredChange}
              >
                {individualSumInsured.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option} Lakhs
                  </MenuItem>
                ))}
              </TextField>
              {/* tenure */}
              <TextField
                disabled={loading}
                sx={{ minWidth: 120 }}
                label="Tenure"
                select
                value={tenure}
                onChange={handleTenureChange}
              >
                {tenureOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              {/* providers */}
              <TextField
                setLoading={true}
                disabled={loading}
                sx={{ minWidth: 120 }}
                label="Providers"
                placeholder="Select provider"
                select
                value={provider}
                onChange={handleProviderChange}
              >
                {providers.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option}
                    disabled={
                      option !== "Providers" &&
                      (premiumData[option] === 0 || premiumData[option] == null)
                    }
                  >
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              {/* pincode */}
              <TextField
                disabled={loading}
                sx={{ minWidth: 120 }}
                label="Pincode"
                value={pincode}
                onChange={handlePincodeChange}
                error={pincodeError}
                helperText={pincodeError ? "Pincode must be 6 digits" : ""}
              />
            </Box>
          </Box>
        </Grid>

        {/* right side container */}
        <Grid item xs={12} md={9}>
          <Grid container>
            <Grid item xs={12}>
              <div className="quotation_main_container">
                {(provider === "CARE" || provider === "Providers") && (
                  <QuotationCard
                    collectData={collectData}
                    
                  />
                )}
                {(provider === "CARE" || provider === "Providers") && (
                  <CareAdvantageCard
                    sumInsured={sumInsured}
                    tenure={tenure}
                    pincode={pincode}
                    provider={provider}
                    collectData={collectData}
                   
                  />
                )}
                {(provider === "HDFC" || provider === "Providers") && (
                  <HdfcOptimaSecureCard
                    collectData={collectData}
                   
                  />
                )}
                {(provider === "BAJAJ" || provider === "Providers") && (
                  <BajajQuardCard
                    collectData={collectData}
                    
                  />
                )}
              </div>
              {/* {
                !(
                  premiumData.CARE?.length > 0 ||
                  premiumData.HDFC?.length > 0 ||
                  premiumData.BAJAJ?.length > 0
                ) && (
                  <Typography
                    variant="h6"
                    align="center"
                    style={{ margin: "20px 0", color: "#666" }}
                  >
                    No quotations are available.
                  </Typography>
                )} */}
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <Alert
            onClose={handleAlertClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Add Addons and click on amount to proceed
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};

export default QuotationPage;
