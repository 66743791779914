import * as actionTypes from "./actionType";

const initialState = {
  familyData: {
    self: false,
    spouse: false,
    son: false,
    daughter: false,
    father: false,
    mother: false,
    fatherInLaw: false,
    motherInLaw: false,
    sonCount: 0,
    daughterCount: 0,
  },
  proposerGender: "",
  premiumPostObject: null,
  responseData: null,
  kycResponse: "",
  proposalNum: "",
  proposerData: null,
  selectedProvider: "",
  healthproposalData: null,
  selectedHealthPolicyType: "",
  generatedHDFCTxnId: "",
  hdfcPolicydata: {},
  quote: null,
};

const healthReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (action.type) {
    case actionTypes.UPDATE_FAMILY_DATA: // called
      return {
        ...state,
        familyData: { ...state.familyData, ...action.payload },
      };
    case actionTypes.UPDATE_SON_COUNT: // called
      return {
        ...state,
        familyData: {
          ...state.familyData,
          sonCount: action.payload,
        },
      };

    case actionTypes.UPDATE_DAUGHTER_COUNT: // called
      return {
        ...state,
        familyData: {
          ...state.familyData,
          daughterCount: action.payload,
        },
      };
    case actionTypes.STORE_RESPONSE_DATA:
      return {
        ...state,
        responseData: action.payload,
      };
    case actionTypes.STORE_KYC_RESPONSE:
      return {
        ...state,
        kycResponse: action.payload,
      };
    case actionTypes.UPDATE_PROPOSER_GENDER: // called
      return {
        ...state,
        proposerGender: action.payload,
      };
    case actionTypes.UPDATE_PREMIUM_POST_OBJECT: // called
      return {
        ...state,
        premiumPostObject: action.payload,
      };
    case actionTypes.UPDATE_PROPOSAL_NUMBER:
      return {
        ...state,
        proposalNum: action.payload,
      };
    case actionTypes.UPDATE_PROPOSER_DATA:
      return {
        ...state,
        proposerData: action.payload,
      };
    case actionTypes.UPDATED_QUOTATION_RESULT: // called
      return {
        ...state,
        quotationData: action.payload,
      };
     case actionTypes.STORE_QUOTE:
      return{
        ...state,
        quote:action.payload,
      }
    case actionTypes.SELECTED_PROVIDER: // called
      return {
        ...state,
        selectedProvider: action.payload,
      };
    case actionTypes.GET_PROPOSAL_DATA:
      return {
        ...state,
        healthproposalData: action.payload,
      };
    case actionTypes.SELECT_HEALTH_POLICY_TYPE:
      return {
        ...state,
        selectedHealthPolicyType: action.payload,
      };
    case actionTypes.generatedHDFCTxnId:
      return {
        ...state,
        generatedHDFCTxnId: action.payload,
      };
    case actionTypes.hdfcPolicydata:
      return {
        ...state,
        hdfcPolicydata: action.payload,
      };
    default:
      return state;
  }
};

export { healthReducer };
