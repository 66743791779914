import { useState } from 'react';
import { carReqObj, AddToLocalStorage } from '../GlobalObj';
import { useDispatch } from 'react-redux';
import { customerInquiryData } from '../../Redux/Action';
import { Button, Container, Grid, IconButton, TextField, } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Modal from '@mui/material/Modal';
import { contact } from '../Utils/maincontent';
import { useForm } from 'react-hook-form';

const ContactUs = () => {
  const [customerDetails, setCustomerDetails] = useState({ customer_Name: '', phone: '' });
  const { handleSubmit , register , formState: { errors } , reset, watch} = useForm();
  // const [isSubmited, setIsSubmited] = useState(false);
  const [req, setReq] = useState(carReqObj);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  // customer details  kc
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setCustomerDetails((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const SubmitForm = (data) => {
    console.log(data , 'contact data')
    // e.preventDefault();
    // setIsSubmited(true);
    // AddToLocalStorage('globalObj', req);
    // dispatch(customerInquiryData(customerDetails)); // testing
    reset()
    setOpen(true); // mui modal
  };

  const handleClose = () => {
    setOpen(false);
    setCustomerDetails({ customer_Name: '', phone: '' });
    // setIsSubmited(false);
  };

  //mui style object for modal
  const modal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#',
    textAlign: 'center',
    backgroundColor: '#fff',
    boxShadow: 24,
    p: 4
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* Left side: General queries information */}
        <Grid item xs={12} md={6}>
          <div style={{ padding: "20px" }}>
            <Typography variant="h6" gutterBottom>
              General Queries
            </Typography>
            {contact.ITEMS.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
                onClick={item.onClick}
              >
                <item.icon />
                <Typography variant="body1" style={{ marginLeft: "10px" }}>
                  {item.text}
                </Typography>
              </div>
            ))}
          </div>
        </Grid>

        {/* Right side: Contact form */}
        <Grid item xs={12} md={6}>
          <div style={{ padding: "20px" }}>
            {/* <Typography variant="h6" gutterBottom>Contact Form</Typography> */}
            <form onSubmit={handleSubmit(SubmitForm)}>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                margin="normal"
                name="customer_name"
                {...register("customer_name", {
                  required: "Name is required...",
                  pattern: {
                    value: /^[A-Za-z ]*$/,
                    message: "Invalid Name",
                  },
                })}
                // value={customerDetails.customer_Name}
                // onChange={handleChange}
                error={!!errors?.customer_name}
                helperText={
                  errors?.customer_name ? errors?.customer_name.message : ""
                }
              />

              <TextField
                fullWidth
                label="Phone"
                variant="outlined"
                margin="normal"
                name="phone"
                type="number"
                value={watch("phone") || ""}
                onKeyDown={(e) => {
                  if (e.key === "e" || e.key === "E") {
                    e.preventDefault(); // Stops the "e" key from being entered
                  }
                }}
                onInput={(e) => {
                  if (e.target.value.length > 10) {
                    e.target.value = e.target.value.slice(0, 10);
                  }
                }}
                {...register("phone", {
                  required: "Number is required...",
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: "Invalid Phone Number",
                  },
                })}
                // value={customerDetails.phone}
                // onChange={handleChange}
                error={!!errors?.phone}
                helperText={errors?.phone ? errors?.phone.message : ""}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ backgroundColor: "#23a8fa" }}
                type="submit"
              >
                Submit
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <TaskAltIcon style={{ fontSize: "70px", color: "green" }} />
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ fontWeight: 700 }}
          >
            Thank you
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We will get back to you in 24-48 hours.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ContactUs;
