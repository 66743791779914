import React, { useEffect, useState } from "react";
import health from "../Images/Health-Home.png";
import bike from "../Images/Bike.svg";
import life from "../Images/Life.svg";
import 

commercial from "../Images/commercial.svg";
import home from "../Images/home.svg";
import travel from "../Images/plane.png";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import loan from "../Images/Loan.svg";
import Personal from "../Images/Personal Accident.svg";
import { useNavigate } from "react-router-dom";
import car from "../Images/car.svg";
import {
  customerInquiryData,
  InsurerNamesSuccessRequest,
  NCBSuccessRequest,
  RelationSuccessRequest,
  citySuccessRequest,
  getCityData,
  getCityStateWithPincode,
  getInsurerName,
  getNCBValue,
  getRelations,
  getSuccessPincode,
  getVehicleMaster,
} from "../../Redux/Action";
import {
  GetDataLocalStorage,
  carReqObj,
  AddToLocalStorage,
} from "../GlobalObj";
import { useDispatch, useSelector } from "react-redux";
import "../Pages/Main.css";
import RoadBike from "../../Vehicle/Images/Bike-Page/BikeGif.gif";
import RoadImg from "../../Vehicle/Images/commercial-page/truckwithbuilding.gif";
import roadCar from "../../Vehicle/Images/Car-Page/carwithbuilding.gif";
import TestiMonials from "../Pages/TestiMonials/TestiMonials";
import RegistrationNumberFetching from "../../Vehicle/Components/RegistrationNumberFetching/RegistrationNumberFetching";
import ContactUs from "../../Framework/Pages/ContactUs";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Dialog,
  Typography,
  useMediaQuery,
  useTheme,
  DialogContent,
  Paper,
  TextField,
} from "@mui/material";
import { TransitionGroup } from "@mui/material";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";
import { Stack } from "@mui/system";
import {
  ourRecords,
  testimonials,
  ourpartners,
  contact,
} from "../Utils/maincontent";
import icicilombardlogo from "../Images/Group 1icicilombard.svg";
import hdfclogo from "../Images/Group 13hdfcergo.svg";
import bajajAlianzlogo from "../Images/Group 12bajaj alianz.svg";
import carehealthlogo from "../Images/Group 2care.svg";
import sbilifelogo from "../Images/Group 6sbilife.svg";
import godigitlogo from "../Images/Group 4godigit.svg";
import pnblogo from "../Images/Group 11pnbmetlife.svg";
import iciciprudentiallogo from "../Images/Group 3iciciprud.svg";
import bharathiAxalogo from "../Images/Group 10bharathgiAxa.svg";
import starinsurancelogo from "../Images/Group 8starinsurancee.svg";
import tataAialogo from "../Images/Group 9Tata Aia.svg";
import royalSundaramlogo from "../Images/Group 5royalsundaram.svg";

const { title, ITEMS } = ourRecords;

const Main = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {isLandingPageTrue} = useSelector((state) => state?.auth);
  const vehicleData = useSelector((state) => state?.motorReducer?.vehicleData);

  // const [ref, { height }] = useMeasure();
  const [req, setReq] = useState(carReqObj);
  const [activeCards, setActiveCards] = useState({
    "Pvt Car": false,
    MotorBike: false,
    "Goods Carrying": false,
  });
  const [imageSrc, setImageSrc] = useState(roadCar);
  const [inputValues, setInputValues] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);

  //Testing
  const [customerDetails, setCustomerDetails] = useState({
    customer_Name: "",
    phone: "",
  });
  // const [isLandingPageTrue , setIsLandingPageTrue] = useState(true)
  const [reqs, setReqs] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Mui States for modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const customerData = useSelector((store) => store.motorReducer.customerData); //customerdata

  useEffect(() => {
    dispatch({ type: "ISLANDINGPAGETRUE", payload: true });
    if(Object.keys(vehicleData || {})?.length > 0){
      dispatch({ type: "clear_Vehicle_data" });
    }
    let ncbData = GetDataLocalStorage("NcbData");
    let insurenceName = GetDataLocalStorage("InsurenceCompanyName");
    // let vehicleMaster = GetDataLocalStorage("vehicleMasterData");
    let cityData = GetDataLocalStorage("cityData");
    let pincode = GetDataLocalStorage("pincodeData");
    let relation = GetDataLocalStorage("relationData");
    // dispatch(getMarketingData());
    ncbData ? dispatch(NCBSuccessRequest(ncbData)) : dispatch(getNCBValue());
    insurenceName
      ? dispatch(InsurerNamesSuccessRequest(insurenceName))
      : dispatch(getInsurerName());
    cityData ? dispatch(citySuccessRequest(cityData)) : dispatch(getCityData());
    pincode
      ? dispatch(getSuccessPincode(pincode))
      : dispatch(getCityStateWithPincode());
    relation
      ? dispatch(RelationSuccessRequest(relation))
      : dispatch(getRelations());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmited(true);
    AddToLocalStorage("globalObj", req);
    dispatch(customerInquiryData(customerDetails)); // testing
    if (customerDetails.customer_Name !== "" && customerDetails.phone !== "")
      setOpen(true); // mui modal
  };

  /*   const handleChange = (e) => {
      if (e.target.name === 'name') {
        let name1 = e.target.value;
        let name2 = name1.toUpperCase();
        let objrc = { ...req.rc, [e.target.name]: name2 };
        setReq({ ...req, rc: objrc });
      } else {
        let mobileObj = { ...req.rc, [e.target.name]: e.target.value };
        setReq({ ...req, rc: mobileObj });
      }
    }; */

  // customer details  kc
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setCustomerDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //  console.log("details",customerDetails)  // kc

  const handleClick = (value) => {
    // setIsLandingPageTrue(false)
    dispatch({ type: "ISLANDINGPAGETRUE", payload : false});
    const updatedActiveCards = { ...activeCards };
    for (const cardType in updatedActiveCards) {
      updatedActiveCards[cardType] = cardType === value;
    }

    setActiveCards(updatedActiveCards);
    let obj = { ...req };
    if (value == "Pvt Car") {
      setImageSrc(roadCar);
      obj.vehicle.vehicleType = "Car";
      obj.profile.customer.policyType = "Pvt Car";
      AddToLocalStorage("globalObj", obj);
      setReq(obj);
      dispatch(getVehicleMaster(value));
      //  navigate('/motor/car');
    } else if (value == "MotorBike") {
      setImageSrc(RoadBike);
      obj.vehicle.vehicleType = "Bike";
      obj.profile.customer.policyType = "MotorBike";
      AddToLocalStorage("globalObj", obj);
      setReq(obj);
      dispatch(getVehicleMaster(value));
      // navigate('/motor/bike');
    } else if (value == "Goods Carrying") {
      setImageSrc(RoadImg);
      obj.vehicle.vehicleType = "Commercial";
      obj.profile.customer.policyType = "Goods Carrying";
      AddToLocalStorage("globalObj", obj);
      setReq(obj);
      dispatch(getVehicleMaster("Goods Carrying"));
      // navigate('/motor/commercial');
    }
  };

  useEffect(() => {
    let obj = GetDataLocalStorage("globalObj");
    if (obj) {
      setReqs(obj);
    } else {
      setReqs(carReqObj);
    }
  }, []);

  const [cardData, setCardData] = useState([
    {
      id: 1,
      transactionId: "#12345",
      insuraceType: "Health",
      customerName: " Doe",
      proposalDate: "2024-03-02",
      carInsuranceText: "carInsuranceText",
    },
    {
      id: 1,
      transactionId: "#67890",
      insuraceType: "Two wheller",
      customerName: " Akhil",
      proposalDate: "2024-03-02",
      carInsuranceText: "carInsuranceText",
    },
    {
      id: 1,
      transactionId: "#98765",
      insuraceType: "Travel",
      customerName: " John",
      proposalDate: "2024-03-02",
      carInsuranceText: "carInsuranceText",
    },
    // Add more card data as needed
  ]);

  const Title = ({ variant = "h6", sx = {}, children, ...props }) => {
    const _component =
      typeof variant !== "object"
        ? variant
        : variant.xl ||
          variant.lg ||
          variant.md ||
          variant.sm ||
          variant.xs ||
          "h6";

    const _variantStyles =
      typeof variant !== "object"
        ? variant
        : {
            xs: variant.xs,
            sm: variant.sm || variant.xs,
            md: variant.md || variant.sm || variant.xs,
            lg: variant.lg || variant.md || variant.sm || variant.xs,
            xl:
              variant.xl ||
              variant.lg ||
              variant.md ||
              variant.sm ||
              variant.xs,
          };

    return (
      <Typography
        // variant={variant}
        sx={{
          ...sx,
          typography: typeof _variantStyles === "object" && _variantStyles,
          fontWeight: `${sx.fontWeight || 600}!important`,
        }}
        component={_component}
        {...props}
      >
        {children}
      </Typography>
    );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            zIndex: -1,
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <Box
            style={{
              background:
                "linear-gradient(180deg, #fff, rgba(60, 177, 249, 0.55) 100%)",
              height: "100dvh",
            }}
          ></Box>

          <img
            src={imageSrc}
            style={{
              position: "absolute",
              width: "100%",
              right: 0,
              left: 0,
              bottom: 0,
            }}
          />
        </Box>
        {/* Modal for all the products */}
        <Container maxWidth="lg">
          <Grid container spacing={2} mt={1} flexGrow={1}>
            {/* car */}
            <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={200}>
                <div
                  className={`addition-service-card ${
                    activeCards["Pvt Car"] ? "active" : ""
                  }`}
                  onClick={() => handleClick("Pvt Car")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="addition-service-card-img">
                    <img src={car} alt="" />
                  </div>
                  <div className="addition-service-card-title">Car</div>
                </div>
              </Grow>
            </Grid>

            {/* bike */}
            <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={300}>
                <div
                  className={`addition-service-card ${
                    activeCards["MotorBike"] ? "active" : ""
                  }`}
                  onClick={() => handleClick("MotorBike")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="addition-service-card-img">
                    <img src={bike} alt="" />
                  </div>
                  <div className="addition-service-card-title">Bike</div>
                </div>
              </Grow>
            </Grid>

            {/* goods carrying */}
            <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={400}>
                <div
                  className={`addition-service-card ${
                    activeCards["Goods Carrying"] ? "active" : ""
                  }`}
                  onClick={() => handleClick("Goods Carrying")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="addition-service-card-img">
                    <img src={commercial} alt="" />
                  </div>
                  <div className="addition-service-card-title">Commercial</div>
                </div>
              </Grow>
            </Grid>

            {/* health */}
            <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={500}>
                <div
                  className="addition-service-card"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/health")}
                >
                  <div className="addition-service-card-img">
                    <img src={health} alt="" />
                  </div>
                  <div className="addition-service-card-title">Health </div>
                  {/* <div className="tooltip">Coming Soon</div> */}
                </div>
              </Grow>
            </Grid>

            {/* travel */}
            <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={600}>
                <div
                  className="addition-service-card travel-disabled"
                  style={{ cursor: "pointer" }}
                  // onClick={() => navigate("/travel")}
                >
                  <div className="addition-service-card-img">
                    <img src={travel} alt="" />
                  </div>
                  <div className="addition-service-card-title">Travel </div>
                  <div className="coming-soon-overlay">Coming Soon</div>
                </div>
              </Grow>
            </Grid>

            {/* loan */}
            <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={700}>
                <div
                  className="addition-service-card"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/loan")}
                >
                  <div className="addition-service-card-img">
                    <img src={loan} alt="loan" />
                  </div>
                  <div className="addition-service-card-title">Loan </div>
                  {/* <div className="tooltip">Coming Soon</div> */}
                </div>
              </Grow>
            </Grid>
            {/* <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={700}>
                <div className="addition-service-card" style={{ cursor: 'pointer' }} onClick={handleOpen}>
                  <div className="addition-service-card-img">
                    <img src={loan} alt="loan" />
                  </div>
                  <div className="addition-service-card-title">More... </div> */}
            {/* <div className="tooltip">Coming Soon</div> */}
            {/* </div> */}
            {/* </Grow> */}
            {/* </Grid> */}
            {/* <Grid item xs={4} sm={6} md={4} lg={2}>
            <div className="addition-service-card disable">
                  <div className="addition-service-card-img">
                    <img src={Personal} alt="" width="45px" />
                  </div>
                  <div className="addition-service-card-title">Personal Accident</div>
                  <div className="tooltip">Coming Soon</div>
                </div>
            </Grid>
            <Grid item xs={4} sm={6} md={4} lg={2}>
            <div className="addition-service-card disable">
                  <div className="addition-service-card-img">
                    <img src={home} alt="" />
                  </div>
                  <div className="addition-service-card-title">Renewals</div>
                  <div className="tooltip">Coming Soon</div>
                </div>
            </Grid> */}
          </Grid>
        </Container>

        <Slide
          direction="up"
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={400}
        >
          <Container
            maxWidth="sm"
            sx={{
              height: "60vh",
              mt: 8,
              [theme.breakpoints.up("md")]: { mt: 5 },
              [theme.breakpoints.up("xs")]: { mt: 1 },
              // border: "solid"
            }}
          >
            <div className="fetching-section">
              {!isLandingPageTrue ? (
                <RegistrationNumberFetching req={req} setreq={setReq} />
              ) : (
                ""
              )}
            </div>
          </Container>
        </Slide>
      </Box>
      {/* {reqs?.user?.login === 'Login' && reqs?.user?.userType === 'C' ?
          <>

            <div className="col-sm-12 mt-5 mb-3">
              <div className="headline text-center mb-2">
                <h2 className="pb-3 position-relative d-inline-block text-capitalize">Ads</h2>
              </div>
            </div>

            <Grid container spacing={5} px={6} marginTop={2} display='flex' justifyContent='space-between' alignItems='center' >
              <Grid item xs={4}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%', boxShadow: 3, position: "relative" }}>
                  <CardMedia
                    component="img"
                    image="/public/CRM/Assets/Customer/image 2Advertisement.svg" alt="Image 1"
                    style={{ height: 140, objectFit: 'contain', width: '100%' }}
                  />
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%', boxShadow: 3, position: "relative" }}>
                  <CardMedia
                    component="img"
                    image="/public/CRM/Assets/Customer/image 2Advertisement.svg" // Replace with your image URL
                    alt="Image 2"
                    style={{ height: 140, objectFit: 'contain', width: '100%' }}
                  />
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%', boxShadow: 3, position: "relative" }}>
                  <CardMedia
                    component="img"
                    image="/public/CRM/Assets/Customer/image 2Advertisement.svg" alt="Image 3"
                    style={{ height: 140, objectFit: 'contain', width: '100%' }}
                  />
                </Card>
              </Grid>
            </Grid>


            <div className="col-sm-12 mt-5 mb-3">
              <div className="headline text-center mb-2">
                <h2 className="pb-3 position-relative d-inline-block text-capitalize">Policies on Queue</h2>
              </div>
            </div>


            <Grid container spacing={2} px={6} mt={3}>
              {cardData.map((card) => (
                <Grid item lg={12} key={card.id}>
                  <Card
                    sx={{ position: "relative" }}
                  >
                    <Box sx={{ position: "absolute", top: 0, left: 0, backgroundColor: "lightblue", borderRadius: "0 0 12px 0", px: 2, minWidth: "140px" }}>

                      <Typography variant="body1" >
                        {card.insuraceType}
                      </Typography>
                    </Box>
                    <CardContent>
                      <Grid container spacing={2} marginTop='2px' display='flex' justifyContent='space-between' alignItems='center'>
                        <Grid item lg={2} textAlign='center'>
                          <Typography variant="h7">Transaction Id:</Typography>
                          <Typography variant="subtitle1">{card.transactionId}</Typography>
                        </Grid>
                        <Grid item lg={2} textAlign='center'>
                          <Typography variant="h7">Customer Name:</Typography>
                          <Typography variant="subtitle1">{card.customerName}</Typography>
                        </Grid>
                        <Grid item lg={2} textAlign='center'>
                          <Typography variant="h7">Proposal Date:</Typography>
                          <Typography variant="subtitle1">{card.proposalDate}</Typography>
                        </Grid>
                        <Grid item lg={2} textAlign='center'>
                          <Typography variant="h7">Insurance Type:</Typography>
                          <Typography variant="subtitle1">{card.carInsuranceText}</Typography>
                        </Grid>
                        <Grid item lg={2} textAlign='center'>
                          <Button variant="contained" color="primary" fullWidth>
                            Continue <ArrowRightIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}

            </Grid>
          </>
          : */}
      <>
        {/* our records */}
        <Container sx={{ mt: { xs: 5, md: 10 } }}>
          <Container maxWidth="md">
            <Title
              variant={{ xs: "h4", md: "h3" }}
              sx={{ textAlign: "center" }}
            >
              {title}
            </Title>
          </Container>

          <Grid container spacing={3} sx={{ mt: 2 }} justifyContent="center">
            {ITEMS.map(({ name, logo, value }) => (
              <Grid item xs={6} md={3} key={name}>
                <Stack
                  alignItems="center"
                  sx={{
                    boxShadow: 1,
                    borderRadius: 3,
                    padding: { xs: 1, md: 2 },
                  }}
                >
                  <img
                    src={logo}
                    style={{ height: "90px", objectFit: "contain" }}
                  />
                  <Title
                    variant={{ xs: "overline", md: "overline" }}
                    sx={{ textAlign: "center" }}
                    color="#666666"
                  >
                    {name}
                  </Title>
                  <Title
                    variant={{ xs: "h6", md: "h5" }}
                    sx={{ textAlign: "center" }}
                    color="#23a8fa"
                  >
                    {value}
                  </Title>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* testimonials */}
        <Container sx={{ mt: { xs: 10, md: 10 } }}>
          <Container maxWidth="md">
            <Title
              variant={{ xs: "h4", md: "h3" }}
              sx={{ textAlign: "center" }}
            >
              {testimonials.title}
            </Title>
          </Container>

          <Grid container spacing={2} sx={{ mt: 3 }} justifyContent="center">
            <Grid item xs={12}>
              <TestiMonials />
            </Grid>
          </Grid>
        </Container>

        {/* our partners */}
        <Container sx={{ mt: { xs: 10, md: 10 } }}>
          <Container maxWidth="md">
            <Title
              variant={{ xs: "h4", md: "h3" }}
              sx={{ textAlign: "center" }}
            >
              {ourpartners.title}
            </Title>
          </Container>

          <Grid container spacing={7} sx={{ mt: 3 }} justifyContent="center">
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <img src={icicilombardlogo} alt="" className="partnerlogo" />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <img src={hdfclogo} alt="" className="partnerlogo" />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <img src={bajajAlianzlogo} alt="" className="partnerlogo" />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <img src={carehealthlogo} alt="" className="partnerlogo" />
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={2}>
              <img src={sbilifelogo} alt="" className="partnerlogo" />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <img src={godigitlogo} alt="" className="partnerlogo" />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <img src={pnblogo} alt="" className="partnerlogo" />
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={2}>
              <img src={iciciprudentiallogo} alt="" className="partnerlogo" />
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={2}>
              <img src={bharathiAxalogo} alt="" className="partnerlogo" />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <img src={starinsurancelogo} alt="" className="partnerlogo" />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <img src={tataAialogo} alt="" className="partnerlogo" />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <img src={royalSundaramlogo} alt="" className="partnerlogo" />
            </Grid>
          </Grid>
        </Container>

        {/* contact us */}
        <div
          style={{
            backgroundColor: "#dcdcdc",
            marginBottom: 2,
            padding: "20px 0",
          }}
          id="contact"
        >
          <Container>
            <Container maxWidth="md">
              <Title
                variant={{ xs: "h4", md: "h3" }}
                sx={{ textAlign: "center" }}
              >
                {contact.title}
              </Title>
            </Container>
            <Grid container spacing={2} sx={{ mt: 3 }} justifyContent="center">
              <ContactUs />
            </Grid>
          </Container>
        </div>

        {/* <div>
         
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7613.412674863406!2d78.44245937771!3d17.42587510000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97354cf31cef%3A0x43dfcea055a17fcf!2sIDFC%20AMC!5e0!3m2!1sen!2sin!4v1733982279718!5m2!1sen!2sin" 
          width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>           
        </div> */}
        {/* map */}
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "450px",
            margin: "0 auto",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7613.412674863406!2d78.44245937771!3d17.42587510000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97354cf31cef%3A0x43dfcea055a17fcf!2sIDFC%20AMC!5e0!3m2!1sen!2sin!4v1733982279718!5m2!1sen!2sin"
            style={{
              border: "0",
              width: "100%",
              height: "100%",
            }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>

          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "30%",
              height: "50px",
              background: "rgb(255, 255, 255)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "12px",
              fontWeight: "bold",
              zIndex: "1",
            }}
          >
            LMV INSURANCE SERVICES
            <br />
            Door No 6-3-354, 1st Floor, SB Tower, Road No 1, Near Punjagutta
            Circle, Hyderabad - 500082
          </div>
        </div>
      </>

      {/* insurance products */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={3} md={3}>
              <Grow in={true} timeout={200}>
                <div
                  className={`addition-service-card ${
                    activeCards["Pvt Car"] ? "active" : ""
                  }`}
                  onClick={() => handleClick("Pvt Car")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="addition-service-card-img">
                    <img src={car} alt="" />
                  </div>
                  <div className="addition-service-card-title">Car</div>
                </div>
              </Grow>
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
              <Grow in={true} timeout={300}>
                <div
                  className={`addition-service-card ${
                    activeCards["MotorBike"] ? "active" : ""
                  }`}
                  onClick={() => handleClick("MotorBike")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="addition-service-card-img">
                    <img src={bike} alt="" />
                  </div>
                  <div className="addition-service-card-title">Bike</div>
                </div>
              </Grow>
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
              <Grow in={true} timeout={400}>
                <div
                  className={`addition-service-card ${
                    activeCards["Goods Carrying"] ? "active" : ""
                  }`}
                  onClick={() => handleClick("Goods Carrying")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="addition-service-card-img">
                    <img src={commercial} alt="" />
                  </div>
                  <div className="addition-service-card-title">Commercial</div>
                </div>
              </Grow>
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
              <Grow in={true} timeout={500}>
                <div
                  className="addition-service-card"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/health")}
                >
                  <div className="addition-service-card-img">
                    <img src={health} alt="" />
                  </div>
                  <div className="addition-service-card-title">Health </div>
                  {/* <div className="tooltip">Coming Soon</div> */}
                </div>
              </Grow>
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
              <Grow in={true} timeout={600}>
                <div
                  className="addition-service-card"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/travel")}
                >
                  <div className="addition-service-card-img">
                    <img src={travel} alt="" />
                  </div>
                  <div className="addition-service-card-title">Travel </div>
                  {/* <div className="tooltip">Coming Soon</div> */}
                </div>
              </Grow>
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
              <Grow in={true} timeout={700}>
                <div
                  className="addition-service-card"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/loan")}
                >
                  <div className="addition-service-card-img">
                    <img src={loan} alt="loan" />
                  </div>
                  <div className="addition-service-card-title">Loan </div>
                </div>
              </Grow>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Main;
