import React, { useRef, useState } from 'react';
import { Modal, Button, CircularProgress, Snackbar, Alert, Box, Typography, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './Otpmodal.css';
import { useNavigate } from 'react-router-dom';
import { getSmsResponse } from '../../Redux/Action';
import { useDispatch, useSelector } from 'react-redux';

import { sendOtpToNumber } from '../Add&SendOTP';


const Otpmodal = ({ open, handleClose, number, typed, setStepCount }) => {
  const [otp, setOTP] = useState(['', '', '', '']);
  const [otpSentSuccess, setOtpSentSuccess] = useState(false);
  const [loading, setloading] = useState(false);
  const [reqBody, setReqBody] = useState(null)

  let [err, setErr] = useState({ state: false, message: "" });
  // Initialize an array with 4 empty strings`
  const otpInputs = useRef([]);
  const navigate = useNavigate();
  const smsResponse = useSelector((store) => store.motorReducer.smsResponse);

  const dispatch = useDispatch();
  const handleInputChange = (index, event) => {
    const newOtp = [...otp];
    newOtp[index] = event.target.value;
    setOTP(newOtp);
    if (event.target.value && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };
  const handleBackspace = (index, event) => {
    if (index > 0 && !otp[index]) {
      const newOtp = [...otp];
      newOtp[index - 1] = '';
      setOTP(newOtp);
      otpInputs.current[index - 1].focus();
    }
  };

  const handleResendOtp = () => {
    const method = 'RESEND';
    const org_id = '100';
    const agent_id = '1000';
    const phone = number;
    const type = 'C';
    dispatch(getSmsResponse(phone, method, org_id, agent_id, type));
    setOtpSentSuccess(true);
  };

  const handleVerifyOTP = async () => {
    setloading(true);
    const method = "VERIFY";
    const type = "C";
    const code = Number(otp.join(""));
    const phone = number;
    setReqBody({ code, phone, type, method })
    console.log(reqBody);

    try {
      const verifyOtpRes = await sendOtpToNumber({ method, type, code, phone });
      console.log(verifyOtpRes.data)
      if (!verifyOtpRes.data?.success) {
        console.log('failed')
        setloading(false);
        setErr({ state: true, message: "Failed to verify OTP" });
        return
      }
      if (verifyOtpRes.data?.success && typed === "health") {
        setloading(false);
        navigate("/health/quotations");
      } else if (verifyOtpRes.data?.success && typed === "travel"
      ) {
        setloading(false);
        navigate("/travel/quotes");
      } else if (verifyOtpRes.data?.success && typed === "loan") {
        setloading(false);
        setStepCount(2);
      }
    } catch (error) {
      setloading(false);
      setErr({ state: true, message: "Internal Error" })
    }

  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.082)',
    borderRadius: '15px'
  };

  return (
    <>
      <Modal open={open}>
        <Box>
          <Box sx={style}>
            <Box component={Stack} rowGap={2} justifyContent='center' alignItems='center'>
              <Typography textAlign='center' variant='body1'>Enter OTP</Typography>
              <Typography textAlign='center'>We have sent a verification code to your mobile number</Typography>
              <div className="car-details-otp-box-container">
                {otp.map((value, index) => (
                  <input
                    className="car-details-otp-box"
                    type="text"
                    id={`otp-input-${index}`}
                    key={index}
                    value={value}
                    maxLength="1"
                    onChange={(e) => handleInputChange(index, e)}
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace') {
                        handleBackspace(index, e);
                      }
                    }}
                    ref={(input) => (otpInputs.current[index] = input)}
                  />
                ))}
              </div>
              <Button fullWidth onClick={handleVerifyOTP} disabled={loading} sx={{
                padding: '5px 10px',
                maxWidth: 160,
                border: 'none',
                backgroundColor: 'rgb(35 168 250)',
                color: 'white',
                fontWeight: 600,
                cursor: 'pointer',
                borderRadius: '10px',
                transitionDuration: '0.2s'
              }} variant='contained'>{loading ? <CircularProgress color='primary' size={'20px'} /> : 'Verify & Submit'}</Button>
              <p className="">
                Didn't receive the code?{' '}
                <button className="" onClick={() => handleResendOtp()}>
                  Resend Code
                </button>
              </p>
              <CloseIcon sx={{ cursor: 'pointer', position: 'absolute', right: '20px', top: '20px' }} onClick={() => { setOTP(new Array(4).fill('')); handleClose() }} />

            </Box>
          </Box>
          <Snackbar
            open={err.state}
            autoHideDuration={4000}
            onClose={() => {
              setErr({ state: false, message: "" });
            }}
            message="Failed to Create Proposal"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert severity="error" variant="filled">
              {err.message}
            </Alert>
          </Snackbar>
        </Box>
      </Modal>
    </>
  );
};
export default Otpmodal;
