import React from "react";
import { Typography, Container, List, ListItem, ListItemText } from "@mui/material";

const styles = {
  container: {
    marginTop: "32px",
    marginBottom: "32px",
    padding: "20px 30px",
  },
  heading: {
    marginBottom: "2px",
    color: "#23a8fa",
    fontWeight: "bold",
  },
  subHeading: {
    marginTop: "2px",
    fontWeight: "bold",
    color: "#555",
  },
  listItem: {
    // marginBottom: "2px",
    backgroundColor: "#f5f5f5",
    borderRadius: "4px",
    textAlign: 'justify',
    padding: '0px'
  },
};

const TopUpHealthInsurance = () => {
  return (
    <Container style={styles.container}>
      <Typography variant="h2" textAlign='center' style={styles.heading} gutterBottom>
        Top-Up Health Insurance
      </Typography>
      <Typography variant="body1" paragraph>
        Top-Up health insurance is a type of insurance policy that provides additional coverage above a basic health insurance policy. It acts as a supplement to the primary policy and covers the expenses incurred in case of serious illnesses or hospitalization that exceed the coverage limit of the basic policy. The policyholder pays a deductible, after which the top-up policy covers the remaining amount. The aim of top-up insurance is to provide additional financial protection to the policyholder in case of high medical expenses.
      </Typography>

      <Typography variant="h4" style={styles.heading}>
        How Does Top-Up Health Insurance Work?
      </Typography>

      <Typography variant="body1" paragraph>
        Top-up health insurance plans are additional health insurance policies that provide extra coverage on top of a basic health insurance policy. These plans are meant to help policyholders protect themselves from high medical expenses that may not be covered by the basic policy. Here's how top-up health insurance plans work:
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Deductible:</Typography>}
            secondary={
              <Typography variant="body1">
                The policyholder pays a pre-defined deductible amount, which acts as a threshold limit for the activation of the top-up policy. This amount is chosen by the policyholder and should be a high enough limit to cover basic medical expenses.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Coverage:</Typography>}
            secondary={
              <Typography variant="body1">
                Once the deductible limit is met, the top-up policy covers the remaining medical expenses incurred during hospitalization. The coverage limit of a top-up policy can vary and can be selected by the policyholder according to their needs and budget.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Premium:</Typography>}
            secondary={
              <Typography variant="body1">
                The premium for a top-up health insurance plan is generally lower compared to a standalone health insurance policy as it only covers costs that exceed a certain threshold.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Flexibility:</Typography>}
            secondary={
              <Typography variant="body1">
                Policyholders can choose the deductible limit and the coverage limit of their top-up policy, making it a flexible option for those who want to tailor their insurance coverage to their specific needs.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        Advantages of Top-Up Health Insurance Plan
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Flexibility</Typography>}
            secondary={
              <Typography variant="body1">
                Top-up health insurance plans allow policyholders to increase their coverage as and when required, without having to buy a new policy.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Cost-effective</Typography>}
            secondary={
              <Typography variant="body1">
                Top-up health insurance plans are usually cheaper than comprehensive health insurance plans because they only cover costs that exceed a certain threshold.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Supplement existing coverage</Typography>}
            secondary={
              <Typography variant="body1">
                These plans can be used to supplement the coverage provided by an existing health insurance policy, providing an additional layer of protection.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Tax benefits</Typography>}
            secondary={
              <Typography variant="body1">
                Premiums paid for top-up health insurance plans are eligible for tax benefits under section 80D of the Income Tax Act.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>No medical examination</Typography>}
            secondary={
              <Typography variant="body1">
                Unlike comprehensive health insurance plans, top-up health insurance plans do not require a medical examination, making it easier and faster to buy.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Wide range of coverage</Typography>}
            secondary={
              <Typography variant="body1">
                Top-up health insurance plans offer coverage for a wide range of medical expenses, including hospitalization, surgeries, and other medical treatments.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        What is covered in a Top-up Health Insurance Plan?
      </Typography>

      <Typography variant="body1" paragraph>
        Top-up health insurance plans provide coverage above a certain threshold, known as the deductible. These plans typically cover:
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Inpatient hospitalization expenses</Typography>}
            secondary={
              <Typography variant="body1">
                This includes room rent, nursing charges, doctor's fee, and the cost of medicines incurred during hospitalization.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Pre-hospitalization and post-hospitalization expenses</Typography>}
            secondary={
              <Typography variant="body1">
                These cover diagnostic tests, doctor's consultations, and follow-up treatments incurred before and after hospitalization.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Coverage for daycare procedures</Typography>}
            secondary={
              <Typography variant="body1">
                This includes medical procedures that do not require an overnight stay in the hospital.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Flexibility to choose a deductible limit</Typography>}
            secondary={
              <Typography variant="body1">
                The plan allows policyholders to select a deductible limit, which is the threshold above which the top-up insurance plan will provide coverage.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Affordability</Typography>}
            secondary={
              <Typography variant="body1">
                A top-up health insurance plan is more affordable compared to a comprehensive health insurance policy, making it an attractive option for those who already have basic health insurance coverage but want additional protection against high medical costs.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Rider or individual policy option</Typography>}
            secondary={
              <Typography variant="body1">
                The option to add the plan as a rider to an existing health insurance policy or purchase it as an individual policy.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        When Should You Use a Top-up Health Insurance Plan?
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Exhausted sum insured</Typography>}
            secondary={
              <Typography variant="body1">
                If you have incurred medical expenses that have exhausted the sum insured of your regular health insurance policy, a top-up plan can provide additional coverage for the remaining expenses.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Pre-existing medical condition</Typography>}
            secondary={
              <Typography variant="body1">
                If you have a pre-existing medical condition, you may be at higher risk of incurring large medical expenses, and a top-up plan can provide additional financial protection in such cases.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Looking for more comprehensive coverage</Typography>}
            secondary={
              <Typography variant="body1">
                If you are looking for more comprehensive coverage to protect yourself against the high cost of medical treatment, a top-up plan can provide additional coverage above your basic health insurance policy.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Concerned about the rising cost of healthcare</Typography>}
            secondary={
              <Typography variant="body1">
                Healthcare costs are rising, and a top-up plan can help you manage the financial burden of high medical expenses in the event of a serious illness or injury.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Complement your existing health insurance policy</Typography>}
            secondary={
              <Typography variant="body1">
                If you already have a basic health insurance policy but want additional coverage, a top-up plan can complement your existing policy and provide additional financial protection.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        How to Select a Top-Up Health Insurance Plan?
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Coverage amount</Typography>}
            secondary={
              <Typography variant="body1">
                Choose a plan that offers a high coverage amount that exceeds the limit of your existing health insurance policy.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Network hospitals</Typography>}
            secondary={
              <Typography variant="body1">
                Make sure the plan covers a wide network of hospitals in your area to have maximum flexibility in choosing a healthcare provider.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Co-pay and deductibles</Typography>}
            secondary={
              <Typography variant="body1">
                Consider the co-pay and deductibles offered by the top-up plan and ensure that it aligns with your budget and healthcare requirements.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Pre-existing conditions</Typography>}
            secondary={
              <Typography variant="body1">
                Check if the plan covers pre-existing medical conditions and what the waiting period for coverage is.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Renewability</Typography>}
            secondary={
              <Typography variant="body1">
                Make sure the top-up plan is renewable for life so you can continue to benefit from it even as you age.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Claim settlement process</Typography>}
            secondary={
              <Typography variant="body1">
                Research the claim settlement process of the insurance provider to ensure it is hassle-free and efficient.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Premium</Typography>}
            secondary={
              <Typography variant="body1">
                Compare the premium of different top-up plans and choose one that offers the best coverage at an affordable cost.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        Conclusion
      </Typography>

      <Typography variant="body1" paragraph>
        In conclusion, a top-up health insurance plan can provide an additional layer of financial protection for unexpected medical expenses. By supplementing your existing health insurance policy, it can help cover the costs of treatments and procedures that might not be covered under your base policy. By carefully evaluating your options and choosing a plan that meets your specific needs, you can ensure that you have the financial support you need in case of a medical emergency.
      </Typography>
    </Container>
  );
};

export default TopUpHealthInsurance;
