import React from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  Stack,
  InputAdornment,
  MenuItem,
  CardContent,
} from "@mui/material";
import { setLoanDetails } from "../../../Redux/Loan/Action";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CustomTextField from "../../components/CustomTextField";
import { useTranslation } from "react-i18next";


const LoanDetails = ({ setStepCount }) => {
  const { t } = useTranslation();
  const loanContent = t("loan");
  const { loandetails } = loanContent;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  let loanDetails = useSelector((e) => e.loanReducer.RequestBody.loanDetails);
  const today = new Date();
  const maxDate = new Date(today.getTime() + 20 * 24 * 60 * 60 * 1000);
  // submit handler
  function onSubmit(data) {
    // remove the (,) from the value and convert to number (ex: 1,200 => 1200)
    data.loanAmount = Number(data.loanAmount.replace(/,/g, ""));
    // store the data into redux_store
    dispatch(setLoanDetails(data));
    // next step => nomineeDetails.jsx
    setStepCount((prev) => prev + 1);
  }

  return (
    <Box padding={"0 1em"}>
      <Box mb={2}>
        <Typography
          sx={{ fontWeight: 900, color: "#383838" }}
          variant="h5"
          textAlign={"center"}
        >
          {loandetails.loandetails}
        </Typography>
      </Box>
      <Box component={CardContent}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={0} columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                name="accountNumber"
                control={control}
                // defaultValue={loanDetails?.accountNumber}
                rules={{
                  required: loandetails.accnumberrequire,
                }}
                render={({ field }) => (
                  <CustomTextField
                    name="accountNumber"
                    label={loandetails.accnumber}
                    field={{
                      ...field,
                      onChange: (e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        field.onChange(value);
                      },
                    }}
                    errors={errors}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="loanAmount"
                control={control}
                // defaultValue={loanDetails?.loanamount}
                rules={{
                  required: loandetails.loanamountrequire,
                  validate: (value) => {
                    const numericValue = Number(value.replace(/\D/g, "")); // Strip non-digits
                    if (isNaN(numericValue)) return "Invalid amount"; // Handle invalid input
                    if (numericValue < 100000) {
                      return "Amount should be at least ₹1,00,000";
                    }
                    if (numericValue > 5000000) {
                      return "Amount should not exceed ₹50,00,000";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    name="loanAmount"
                    label={loandetails.loanamount}
                    field={{
                      ...field,
                      onChange: (e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        const numericValue = Number(value);
                        const formattedValue =
                          numericValue.toLocaleString("en-IN");
                        field.onChange(formattedValue);
                      },
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeIcon fontSize="sm" />
                          </InputAdornment>
                        ),
                      },

                    }}
                    errors={errors}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} mt={0}>
              <Controller
                name="loanTenure"
                control={control}
                rules={{ required: "Please select Tenure" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Loan Tenure (in Months)"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.loanTenure)}
                    helperText={errors.loanTenure ? errors.loanTenure.message : ""}  
                  >
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="24">24</MenuItem>
                    <MenuItem value="36">36</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                // defaultValue={loanDetails?.loanDt}
                name="loanDt"
                control={control}
                rules={{
                  required: loandetails.loandaterequire,
                  validate: (value) => {
                    const selectedDate = new Date(value);
                    if (selectedDate > maxDate) {
                      return "Date should be within 20 days from today";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={loandetails.loandate}
                    type="date"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.loanDt)}
                    helperText={errors.loanDt ? errors.loanDt.message : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} padding={"0 15px"} paddingBottom={"12px"}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Button
                  onClick={() => {
                    setStepCount((prev) => prev - 1);
                  }}
                  sx={{
                    backgroundColor: "#23a8fa",
                    borderRadius: "999px",
                    fontWeight: 700,
                  }}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Back
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#23a8fa",
                    borderRadius: "999px",
                    fontWeight: 700,
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Next
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export { LoanDetails };
