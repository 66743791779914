import React, { useEffect, useState } from 'react'
import { CardContent, CircularProgress, Grid, Link, TextField, Typography, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { clearError, setError } from '../../../../Redux/action-creators/errorAction';
import { clearSuccessMessage, setSuccessMessage } from '../../../../Redux/action-creators/successAction';
import { useTranslation } from 'react-i18next';
import { setToken } from '../../../../Redux/action-creators/authAction';
import { validatePospUserByPhone, generateOtp, otpValidation, fetchPospData } from '../../api/pospService';
import { getSmsResponse, getSmsVerificationResponse, getSuccessSmsRequest, getSuccessSmsResponse } from "../../../../Redux/Action"; // Import SMS action
import MainCard from '../../ui-component/cards/MainCard';
import { gridSpacing } from '../../../../Redux/crmustomization/constant';
import { FETCH_POSP_SUCCESS  } from '../../../../Redux/action-type/posp/actionType';
// import { fetchPospData } from "../../../../Redux/action-creators/posp/pospAction";
 
 
const PospLogin = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state?.posp?.data);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [countdown, setCountdown] = useState(6);
    const [loading, setLoading] = useState(false); // Loader state
    const navigate = useNavigate();
    const { control, getValues, handleSubmit, formState: { errors } , setValue } = useForm();
    const [step, setStep] = useState(1); // Step 1 for mobile number, Step 2 for OTP
    const smsResponse = useSelector((store) => store.motorReducer.smsResponse);
    const successsResponseFrom = useSelector((store) => store.motorReducer.smsVerificationResponse)
 
    useEffect(() => {
        let timer;
        if (resendDisabled) {
            timer = setTimeout(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [resendDisabled, countdown]);
 
    useEffect(() => {
        if (countdown === 0) {
            setResendDisabled(false);
            setCountdown(60); // Reset countdown
        }
    }, [countdown]);
 
 
    // it helps to render the correct sidebar quickly after submit otp
    useEffect(() => {
     
      if (userData) {

        if (userData?.phone === getValues()?.mobileNumber) {
          setLoading(false);
          dispatch(setToken({phone : userData?.phone}));
          navigate('/posp/personaldetails');
        }
      }
    }, [userData]);
 
    const handleResendOtp = () => {
        setResendDisabled(true);
    };
 
    const handleMobileNumber = async () => {
        setLoading(true); // Start loading
        dispatch(clearError());
        dispatch(clearSuccessMessage());
        const mobileNumber = getValues('mobileNumber');
        const isNumber = () => !isNaN(Number(mobileNumber));
        if (isNumber && mobileNumber.toString().trim().length === 10) {
            validateUser(mobileNumber.toString().trim());
        } else {
            dispatch(setError(t('lmv.customer.invalidMobile')))
            setLoading(false);
        }
    };
 
    const validateUser = async (phone) => {
        dispatch(clearError());
        dispatch(clearSuccessMessage());
        const payload = {
            enrollType: 'SIGNIN',
            phone: phone
        }
        try{
            const response = await validatePospUserByPhone(payload);
            if (response.status > 400) {
              setLoading(false);
              dispatch(setError(t("lmv.serverError")));
            } else {
              if (response?.data?.signInRequestFlag) {
                sendOtp(phone);
              } else {
                dispatch(setError(response?.data?.message));
                setLoading(false);
              }
            }
        }catch(e){
            setLoading(false);
            dispatch(setError(t("lmv.serverError")));
        }
       
    };

    const sendOtp = async(phone) => {
        dispatch(clearError());
        dispatch(clearSuccessMessage());

        const otpData = {
            phone,
            org_id: "100",
            agent_id: "LMVPOS000000",
            method: "SEND",
            type: "P",
          };

          try {
            const response = await generateOtp(otpData);
            if(response.status === 200){
                dispatch(setSuccessMessage(response?.data?.message));
                setStep(2);
                setValue("otp", null);
            }else{
                dispatch(setError("Internal Server Error"))
            }
            // dispatch(getSuccessSmsRequest(response?.data));
            // const response = await dispatch(getSmsResponse(otpData));
            // console.log(response, "response");
            // dispatch(setSuccessMessage(response?.data?.body?.message));
              
          } catch (error) {
            dispatch(setError("Internal Server Error"));    
            console.error("Error sending OTP:", error);
        }finally{
            setLoading(false)
        }
    }
 
    const onSubmit = async(data) => {
      setLoading(true);
      dispatch(clearError());
      dispatch(clearSuccessMessage());
      
      // here this condition is to restrict otp validation fun in the first step because when the user clicks the enter button in the first step this onSubmit is calling
      if (step === 1) {
        handleMobileNumber();
      } else {
        try {
            const method = 'VERIFY';
            const type = 'P'; // Type for customer (adjust as necessary)
            const verification_code = data?.otp; // Using OTP from form data
            const phone = data?.mobileNumber; // Phone number from form data
            const response = await otpValidation({ phone, verification_code, method, type })
            // dispatch(getSuccessSmsResponse(response?.data?.body))
            if (response?.data?.statusCode === 200) {
            dispatch(setSuccessMessage('Login successful! Please wait while we redirect you to your profile page.'));
            const agentResponse = await fetchPospData(data?.mobileNumber)
            if(agentResponse.status === 200){
                dispatch({ type: FETCH_POSP_SUCCESS, payload: agentResponse?.data });
                localStorage.setItem("phone", data?.mobileNumber);
                localStorage.setItem("userType", "agent");
                setLoading(false);
                dispatch(setToken({phone : userData?.phone}));
                navigate('/posp/personaldetails');
            }else{
                dispatch(setError("Something went Wrong while getting agent data"));
            }
            
          } else {
            setLoading(false);
            dispatch(setError("Invalid OTP"));
          }
        } catch (e) {
          setLoading(false);
          dispatch(setError("Internal Server Error"));
        }
      }
    };
 
    const handlePhoneChange = () => {
        setStep(1);
    }
 
    const navigateToNewUser = () => {
        navigate("/posp/posp-signup");
    }
 
 
    return (
        <>
            <CardContent>
                <Grid container spacing={3} justifyContent="center" >
                    <Grid item md={5}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <MainCard content={false} border={true} sx={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }} boxShadow={true} darkTitle={true}>
                                    <CardContent>
                                        <Grid container spacing={gridSpacing}>
                                            <Grid item xs={12}>
                                                <Typography variant="h4" align="center">
                                                    {t('lmv.customer.loginNow')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controller
                                                    name="mobileNumber"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: t('lmv.requiredField'),
                                                        pattern: {
                                                            value: /^[0-9]{10}$/,
                                                            message: t('lmv.customer.invalidMobile'),
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            label={t('lmv.customer.mobileFormFieldLabel')}
                                                            variant="outlined"
                                                            margin="normal"
                                                            id="mobileNumber"
                                                            fullWidth
                                                            error={!!errors.mobileNumber}
                                                            disabled={step !== 1}
                                                            onInput={(e) => {
                                                                if (e.target.value.length > 10) {
                                                                    e.target.value = e.target.value.slice(0, 10);
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors.mobileNumber?.message && (
                                                    <Typography style={{ color: 'red' }} variant="caption">
                                                        {errors.mobileNumber?.message}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            {step === 1 && (
                                                <Grid item xs={12}>
                                                    <Button variant="contained" fullWidth onClick={handleMobileNumber}
                                                    disabled={loading} // Disable while loading
                                                    startIcon={loading && <CircularProgress size={20} />} // Show spinner while loading
                                                    >
                                                    {loading ? `proceeding` : t('lmv.customer.proceed')}
                                                    </Button>
                                                </Grid>
                                            )}
                                            {step === 2 && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Controller
                                                            name="otp"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: t('lmv.requiredField') }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    label={t('lmv.customer.otp')}
                                                                    type="text"
                                                                    id="otp"
                                                                    error={Boolean(errors.otp)}
                                                                    onInput={(e) => {
                                                                        if (e.target.value.length > 4) {
                                                                            e.target.value = e.target.value.slice(0, 4  );
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.otp?.message && (
                                                            <Typography style={{ color: 'red' }} variant="caption">
                                                                {errors.otp?.message}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button type="submit" variant="contained" fullWidth
                                                            disabled={loading} // Disable while loading
                                                            startIcon={loading && <CircularProgress size={20} />} // Show spinner while loading
                                                        >
                                                            {loading ? `loading` : t('lmv.customer.login')}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant="text" fullWidth disabled={resendDisabled} onClick={handleResendOtp}>
                                                            {resendDisabled ? `${t('lmv.customer.resend')} Otp in ${countdown} sec` : t('lmv.customer.resend')}
                                                        </Button>
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={12}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid item sx={{ cursor: 'pointer' }}>
                                                        <Link onClick={navigateToNewUser}>
                                                            {t('lmv.customer.newUserRegister')}
                                                        </Link>
                                                    </Grid>
                                                    {step === 2 && (
                                                        <Grid item sx={{ cursor: 'pointer' }}>
                                                            <Link onClick={handlePhoneChange}>
                                                                {t('lmv.customer.changeMobileNumber')}
                                                            </Link>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </MainCard>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </>
    )
}

export default PospLogin

