import {
  NEWEMPLOYEE,
  SingleRow,
  BRANCHDATA,
  LOGINDATA,
  pendingCasesData,
  profileDetails,
  SalesData,
  SelfInspecData,
  UserIdManagement,
  RenewalNotification,
  RENEWALS,
  Other_Payouts,
  TOGGLE_FORM,
Employee_Logout
} from "./actionType";

export const fetchSalesData = (data) => {
  return { type: SalesData, payload: data };
};

export const fetchSelfInspecData = (data) => {
  return { type: SelfInspecData, payload: data };
};

export const fetchProfileData = (data) => {
  return { type: profileDetails, payload: data };
};

export const fetchPendingCases = (data) => {
  return { type: pendingCasesData, payload: data };
};

export const fetchUsers = (data) => {
  return { type: UserIdManagement, payload: data };
};

export const handleLoginData = (payload) => ({ type: LOGINDATA, payload });

export const handleBranchData = (payload) => {
  return { type: BRANCHDATA, payload };
};

export const handleSingleRow = (payload) => {
  return { type: SingleRow, payload };
};

export const handleNewEmployee = (payload) => {
  return { type: NEWEMPLOYEE, payload };
};

export const handleRenewalNotification = (payload) => {
  return { type: RenewalNotification, payload };
}


export const handleRenewals = (payload) => {
  return { type: RENEWALS, payload };
}

export const handleOtherPayouts = (payload) => {
  return { type: Other_Payouts, payload };
}

export const toggleOfflineForms = (payload) => {
  return { type: TOGGLE_FORM, payload }
}

export const handleEmployeeLogout = (payload) => {
  return { type: Employee_Logout, payload }
}