import React, { useEffect, useState } from "react";
import { fetchHospitalsList } from "../../api/Hospital";
import {
  Box,
  Card,
  CardContent,
  Container,
  IconButton,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LoaderComponent from "../../../Framework/Components/Loader";

const HospitalList = (quote) => {
  const [hospitalList, setHospitalList] = useState([]);
  const [filteredHospitals, setFilteredHospitals] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const hospitalsPerPage = 20;
  const fetchHospitallist = async () => {
    setLoading(true);
    try {
      const list = await fetchHospitalsList(quote.quote.provider);
      setHospitalList(list);
      setFilteredHospitals(list);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const filterHospitals = () => {
    const filteredList = hospitalList.filter((hospital) => {
      const pinCode = (hospital["PIN CODE"] || "").toString();
      const hospitalName = (hospital["HOSPITAL NAME"] || "").toLowerCase();
      const city = (hospital["CITY"] || "").toLowerCase();
      const state = (hospital["STATE"] || "").toLowerCase();

      return (
        pinCode.includes(searchInput) ||
        // hospitalName.includes(searchInput.toLowerCase()) ||
        city.includes(searchInput.toLowerCase()) ||
        state.includes(searchInput.toLowerCase())
      );
    });

    // Ensure filteredList is an array before setting
    setFilteredHospitals(Array.isArray(filteredList) ? filteredList : []);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchHospitallist();
  }, []);

  useEffect(() => {
    filterHospitals();
  }, [searchInput]);

  // Calculate hospitals to display on the current page
  const indexOfLastHospital = currentPage * hospitalsPerPage;
  const indexOfFirstHospital = indexOfLastHospital - hospitalsPerPage;
  const currentHospitals = filteredHospitals.slice(
    indexOfFirstHospital,
    indexOfLastHospital
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Container maxWidth="lg">
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "16px",
          flexDirection: "column",
          borderRadius: "12px",
          backgroundColor: "#f7faff",
          marginBottom: "8px",
          boxShadow: "none",
        }}
      >
        <CardContent style={{ paddingTop: "0px", fontWeight: "bold" }}>
          {hospitalList.length} Cashless hospitals in{" "}
          <span style={{ color: "green" }}>India</span>
        </CardContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            id="name"
            label="Search using PinCode or Location"
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            variant="outlined"
            margin="normal"
            fullWidth
            required
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </div>
        <Typography style={{ fontStyle: "inherit" }}>
          {filteredHospitals.length} hospitals found
        </Typography>
      </Card>
      <div className="more-hospitals-wrapper">
        <Typography
          variant="h5"
          style={{
            fontSize: "20px",
            fontWeight: 700,
            color: "#253858",
            marginBottom: "11px",
            textAlign: "center",
          }}
        >
          Hospital List
        </Typography>
        {loading ? (
          <LoaderComponent />
        ) : currentHospitals && currentHospitals.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  style={{ backgroundColor: "#f5f5f5", fontStyle: "bold" }}
                >
                  <TableRow style={{ color: "#000" }}>
                    <TableCell>Hospital Name</TableCell>
                    <TableCell>Address </TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>PIN Code</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {currentHospitals.map((hospital, index) => (
                    <TableRow key={index}>
                      <TableCell>{hospital["HOSPITAL NAME"]}</TableCell>
                      <TableCell>
                        {hospital["ADDRESS LINE 1"]}
                        {hospital["ADDRESS LINE 2"]}
                        {hospital["LOCATION"]}
                      </TableCell>
                      <TableCell>{hospital["CITY"]}</TableCell>
                      <TableCell>{hospital["STATE"]}</TableCell>
                      <TableCell>{hospital["PIN CODE"]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop="16px"
            >
              <Pagination
                count={Math.ceil(filteredHospitals.length / hospitalsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </>
        ) : (
          <Typography>No hospitals found</Typography>
        )}
      </div>
    </Container>
  );
};

export default HospitalList;
