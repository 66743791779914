import { error } from "highcharts";
import {FETCH_CUSTOMER_FAILURE, FETCH_CUSTOMER_SUCCESS} from "../action-type/actionType";
import {LOGOUT} from '../action-type/actionType'

const initialState = {
    data: null,
    error: null,
};

const customerReducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_CUSTOMER_SUCCESS:
            return { ...state, data: action.payload, error: null };
        case FETCH_CUSTOMER_FAILURE:
            return { ...state, data: null, error: action.payload };
        case LOGOUT:
            return {
                ...state,
                data: null,
                error : null
            };
        default:
            return state;
    }
};

export default customerReducer;