import { FileDownload, SmsFailed } from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { loan_api } from '../../../Config';
import axios from 'axios';
import { useSelector } from 'react-redux';


const PaymentSuccessPage = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [downloading, setDownloading] = useState(false)
  const [policyNum, setPolicyNumber] = useState("")
  let proposaldata = useSelector((e) => e.loanReducer?.ProposalData);
  console.log(proposaldata)
  let proposalNum = useSelector((e) => e.loanReducer?.ProposalData?.intPolicyDataIO?.policy?.proposalNum);
  let proposalNumber = useSelector((e) => e.loanReducer?.proposalNumber);
  console.log(useSelector((e) => e.loanReducer?.proposalNumber), "proposal num")

  // const location = useLocation();

  useEffect(() => {
    let reqBody;

    if (!proposaldata || Object.keys(proposaldata).length === 0) {
      console.log(proposalNumber, "prko")
      reqBody = {
        proposalNum: proposalNumber
      };
    } else {
      reqBody = {
        proposalNum: proposalNum
      };
    }

    const fetchData = async () => {
      try {
        const response = await axios.post(`${loan_api}/insurance/v1/policy/loan/proposalstatus`, reqBody);
        setPaymentDetails(response.data.intGetPolicyStatusIO);
        setPolicyNumber(response?.data?.intGetPolicyStatusIO.policyNum);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    localStorage.clear()
  }, [proposaldata, proposalNumber]);
  
  const handleDownload = async () => {
    let req = {
      policyNum: policyNum
    }
    console.log(req, "request")

    try {
      setDownloading(true)
      const res = await axios.post(
        `${loan_api}/insurance/v1/policy/loan/pdfgenerate`,
        req
      );
      let base64String = res?.data?.intFaveoGetPolicyPDFIO?.dataPDF;

      console.log(base64String, "bytes data")
      let fileName = "lmv_customerId.pdf";
      const data = base64String.replace(/^data:application\/pdf;base64,/, "");
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      console.log(url, "blob url")
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = fileName;
      anchor.style.display = "none";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setDownloading(false)
      localStorage.clear()
    } catch (error) {
      setDownloading(false)
      console.log(error, "couldn't download pdf");
    }
  };


  const showKycStatus = true;

  if (!paymentDetails) {
    return (
      <Box sx={{ height: '700px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="payment-container">
      <div className="payment-success-table-container">
        <div className="payment-success-table">
          <div className="payment-success-table-header">
            Amount
            <span style={{ fontWeight: '500', fontSize: '18px' }}>₹ {paymentDetails?.policyPremium}</span>
          </div>
          <div className="payment-success-table-divider">
            <ul className="payment-success-list">
              <li>
                <span>Proposal Number</span>
                <span className="application-id">
                  {paymentDetails?.proposalNum}
                </span>
              </li>
              <li>
                <span>Policy Number</span>
                <span>
                  {paymentDetails?.policyNum}
                </span>
              </li>
              <li>
                <span>Policy Status</span>
                <span>
                  {paymentDetails?.policyStatus}
                </span>
              </li>
              <li>
                <span>Merchant Name</span>
                <span>LMVIB</span>
              </li>
              <li>
                <span>Payment Method</span>
                <span>Online</span>
              </li>
              <li>
                <span>Policy Start Date</span>
                {paymentDetails?.policyMaturityDt}
              </li>
              <li>
                <span>Policy Provider</span>
                <span>Care</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="kyc-failed-container">
          <div className="kyc-failed-content">
            <p className="kyc-failed-content-title">Download Policy</p>
            <p>
              Download your <span style={{ color: '#23A8FA' }}>Policy</span> here.
            </p>
            <Button className="kyc-failed-btn" onClick={handleDownload}>
              <span style={{ fontWeight: '700', color: 'white' }}>{downloading ? "Downloading..." : "Download"}</span>
            </Button>
          </div>
          <div style={{ marginLeft: '22px' }}>
            {showKycStatus ? <FileDownload /> : <SmsFailed />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccessPage;