import React from 'react';
import { Typography, Container, List, ListItem, ListItemText } from "@mui/material";

const styles = {
    container: {
      marginTop: "32px",
      marginBottom: "32px",
      padding: "20px 30px",
    },
    heading: {
        marginTop:'10px',
      marginBottom: "2px",
      color: "#23a8fa",
      fontWeight: "bold",
  
    },
    subHeading: {
      marginTop: "2px",
      fontWeight: "bold",
      color: "#555",
  
    },
    listItem: {
      marginBottom: "2px",

      backgroundColor: "#f5f5f5",
      // padding: "2px",
      borderRadius: "4px",
      textAlign:'justify',
      
      padding:'0px',
      margin:'0px',
    },
  };

const LMVBikeInsurance =  () => {
    return (
<Container style={styles.container}>
      <Typography variant="h2" textAlign='center'   style={styles.heading} gutterBottom>
   LMV BIKE INSURANCE
      </Typography>
    
    <Typography variant="body1" mt={1} style={styles.bodyText}>
      Bike or two-wheeler insurance provides coverage to the Bike in case of damage incurred due to accidents, natural disasters, etc. You can choose the policy that matches your requirements, and many bike insurance policies are available. The two-wheeler insurance policy protects the Bike from any unexpected damages that occurred to the Bike along with addressing the legal liability of the third parties. With LMV Insurance, you can get your hands on the best bike insurance policy on budget.
    </Typography>
    
    <Typography variant="body1" style={styles.bodyText}>
      As per the Motor Vehicle Act 1988, a third-party two-wheeler insurance policy is mandatory to have at least third-party insurance cover for your Bike. At LMV Insurance, your safety is our top-most priority, and we always wish for you and your vehicle to stay sound and risk-free. With proper bike insurance, you can cover the damages incurred to your Bike and the third-party damage costs. It is very significant to hold a bike insurance policy, given that accidents have increased daily.
    </Typography>
    
    <Typography variant="h4" style={styles.heading}>
      Why Is Bike Insurance Necessary?
    </Typography>

    <List>
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Bypass Legal Fines
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              Riding a bike without a proper two-wheeler insurance policy is illegal. You could end up paying heavy fines if you don't own an insurance policy for your Bike. Keeping the insurance policy even while travelling in the city is essential.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Protection Against Third-Party Liability
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The bike insurance policy protects against damage to your vehicle or third-party vehicle/person. If the insured does not carry the policy document, they may attract huge fines and penalties in case of an accident with a third-party vehicle.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Coverage Against Theft
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              If your vehicle goes missing or gets stolen, you get coverage for your vehicle if you have an insurance policy for your lost vehicle. You need not spend out of your pocket to recover the vehicle or to get it repaired. It further reduces the financial burden on the insured.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Cover Against Natural and Man-Made Disasters
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              If your vehicle has suffered damage due to man-made disasters or natural calamities, your two-wheeler insurance policy will provide the necessary coverage for your Bike. Let the natural disaster be floods, cyclones, lightning, storms or whatever, and the insurance policy will provide complete coverage to the Bike. The policy also covers man-made disasters like terrorism, vandalism etc.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Personal Accident Cover
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The two-wheeler insurance also covers the cost of injuries, minor or significant, if suffered due to an accident. Your two-wheeler insurance covers all the expenses for the treatment you have done for the damages. The insurance coverage will balance the financial loss due to the accident.
            </Typography>
          }
        />
      </ListItem>
    </List>

    <Typography variant="h4" style={styles.heading}>
      Key Features of Buying Bike Insurance Policy With Us
    </Typography>

    <Typography variant="body1" style={styles.bodyText}>
      The insurance market has changed drastically, and the insurers are coming up with attractive policy covers to grab the eyeballs of the commons. If you are considering getting an insurance policy for your Bike, check out the key features of a two-wheeler insurance policy here.
    </Typography>

 
    <List>
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Comprehensive and Liability Only Coverage
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The bike owner can choose from the comprehensive or liability-only policy. While the liability-only cover is mandatory for every bike owner, the comprehensive two-wheeler insurance cover protects against the damages caused to the insured vehicle. Also, it provides personal accident cover for the passenger. It also covers the third-party bike insurance for the rider.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Personal Accident Cover of Rs.15 Lakh
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              If the bike owner meets with an accident and suffers an accident or death, the insurance company shall provide the family with a cover of Rs.15 lakhs. However, if the vehicle's driver is not the owner, there won't be any insurance coverage for that person.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              No Claim Bonus
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The insurance company will issue the No Claim Bonus discount to the new vehicle owner. The NCB will be more for those with zero to no claims in the claim period and for practising safe driving.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Access to Diverse Add-ons
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              We offer various valuable add-ons, and the insurer can get them per their requirement. The add-ons provide extra coverage to your Bike as well as the insurer. Some popular add-ons include zero depreciation, personal accident cover for pillion riders, spare parts & accessories, etc.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Quick Buying/Renewing Process
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The buying/renewing of the vehicle is very easy with LMV Insurance. It is a swift and straightforward process to buy/renew your vehicle insurance using our services.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Lots of Discounts
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              Insured can avail of various discounts on their insurance policy from the insurers, such as a discount for the automotive association, anti-theft vehicle approved vehicles and discounts for not recording any NCB.
            </Typography>
          }
        />
      </ListItem>
    </List>
    <Typography variant="h4" style={styles.heading}>
      Types Of Two Wheeler Insurance By LMV Insurance
    </Typography>

    <Typography variant="body1" style={styles.bodyText}>
      LMV Insurance offers various two-wheeler insurance policies that provide coverage to the Bike and the bike owner under multiple circumstances.
    </Typography>

    

    <List>
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Comprehensive Bike Insurance Policy
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The comprehensive bike insurance policy includes coverage for third-party liabilities, theft, natural and man-made disasters, and personal injury coverage for the owner-driver. The policy, however, does not cover damages if the bike owner is driving the vehicle in an alcoholic state or is outside the geographical limits. In that case, a comprehensive two-wheeler policy provides complete protection to your Bike.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Standalone Own Damage Bike Insurance Policy
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              If your Bike met with an accident, that comes under a standalone own damage bike insurance policy. The policy is beneficial when the vehicle is damaged badly by man-made mistakes. This policy also covers if the Bike gets stolen.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Standalone Third-Party Bike Insurance Policy
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              If your Bike causes damage to a third-party vehicle or a person, this standalone third-party bike insurance policy will help a great deal. Nevertheless, under this policy, you cannot get your Bike wholly covered against the damages or theft of the Bike.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Third-party vs Comprehensive Bike Insurance
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              Motor insurance companies in India offer two-wheeler insurance online in two popular variants - comprehensive bike insurance and third-party bike insurance. Let us look at the difference between comprehensive and 3rd party bike insurance:
            </Typography>
          }
        />
      </ListItem>
    </List>

    <Typography variant="h4" style={styles.heading}>
      What Is Covered In Our Two Wheeler Insurance Policy?
    </Typography>

    <List>
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Covers Costs for Damage Repair
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The comprehensive bike insurance policy provides cover that will cover the damage incurred to your Bike due to natural calamities, man-made mistakes, damages by fire, damages caused by accidents etc.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Bike Theft
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              If your bike gets stolen and has a two-wheeler insurance policy, you will receive reimbursement for the bike cost. Even if the Bike is damaged, the entire repair cost gets reimbursed by the insurer.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Third-Party Liability
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The two-wheeler insurance policy also provides third-party coverage to the third-party liabilities. For example, if you have caused damage to the third-party property or person with your Bike, the policy covers damage incurred for the third-party person and the vehicle.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Personal Accident Cover
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The comprehensive bike insurance policy provides personal accident cover to the driver/owner of the vehicle. Under this cover, the policyholder will get reimbursement for the medical expenses spent on treating the injuries caused by the accident. Furthermore, the beneficiary will receive the cover amount in case of death due to an accident.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Add-ons Coverage
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              LMV Insurance also provides coverage to the vehicles under add-ons. The add-ons strengthen the insurance policy further with their features. They also offer extra coverage in certain situations. The policyholder can upgrade their policy with add-ons to enjoy more benefits.
            </Typography>
          }
        />
      </ListItem>
    </List>
    <Typography variant="h4" style={styles.heading}>
      What Is Not Covered In Our Two Wheeler Insurance Policy?
    </Typography>

    <List>
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              If the Bike suffered a breakdown due to its age.
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              If the Bike sustained damage when it was outside the geographical limits.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Damage caused due to war, nuclear attacks etc.
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              Damage caused to the Bike when used in racing, speed tests etc.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Third-party liability shall not apply in case of own damage or personal accident covers.
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              Drunk and drive and driving without a valid driving license.
            </Typography>
          }
        />
      </ListItem>
    </List>

    <Typography variant="h4" style={styles.heading}>
      Two Wheeler Insurance Add-on Covers
    </Typography>

    <List>
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Zero Depreciation
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              As the name suggests, this add-on will have no depreciation at all. Even if the insured claims and gets the amount for repair or replacement of bike parts, the depreciation will be deducted from the amount and given back to the insured. Nevertheless, this add-on works only for the first two claims.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Return-to-Invoice Cover
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The return-to-invoice cover add-on will help you get the total amount as on the invoice of your vehicle bill in case of theft or damage to your vehicle. In short, the insurer will receive the current market value of the Bike with this add-on. It is also called Insured Declared Value (IDV). The in-voice amount reimbursed with this add-on is inclusive of all the taxes.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Emergency Medical Expenses
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              If you own a bike insurance plan and want to get more benefits like coverage for emergency medical expenses, you can buy this add-on. This add-on reimburses the amount spent on emergency medical and ambulance expenses. If you or the pillion rider met with an accident, you would receive complete reimbursement of the medical costs spent in the hospital.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              No Claim Bonus
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              It is a popular add-on taken by most the two-wheeler owners. This add-on benefits the owner only if they don't make any claims during the policy period. The NCB amount comes to use at the time of renewal of the insurance policy in the form of a discount.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h4" style={styles.heading}>
              Additional Third-Party Property Damage Cover
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              Get additional 3rd party cover reimbursement with this add-on. Third-party liabilities are included already in comprehensive and third-party two-wheeler insurance cover. However, you can buy this add-on if you wish to avail of an additional cover for the 3rd party liabilities.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Helmet Cover
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              If your helmet got damaged in an accident, you could get complete reimbursement for it with this add-on. If you want to go for a replacement, you have to choose the exact model helmet.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              EMI Protection
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              If your Bike has met with an accident and is in a garage for repair, your insurer will take the responsibility of paying the EMIs under the EMI protection cover add-on.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Roadside Assistance
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The roadside assistance add-on will instantly send you the help to get your Bike repaired in case of a flat tyre, fuel shortage, dead battery, mechanical repairs etc. The add-on also offers instant maintenance so you can continue your journey as planned.
            </Typography>
          }
        />
      </ListItem>
    </List>

    <Typography variant="h4" style={styles.heading}>
      Factors Affecting Bike Insurance Premium
    </Typography>

    <List>
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Coverage
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The premium depends on the type of coverage you choose for your two-wheeler. For instance, if you select a third-party insurance cover, the premium will be lower as the coverage is restricted only to the third-party vehicle. But for comprehensive cover, the premium will be higher as it covers third-party, own damage, personal cover etc. The premium may further increase with the add-ons.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Vehicle's Age
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The age of the Bike has an inverse relationship with the approximate market value of the Bike (IDV). Also, it is the amount with which the bike gets insured. So, the IDV directly affects the premium amount. As the age of the bike increases, the market value and IDV decrease, ultimately impacting the premium amount.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Make and Model of the Bike
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The bike's make and model influence the premium amount. If your bike model is basic, it will come at an affordable price, so the premium will be low. Similarly, if your Bike is a high-end model, you will have to pay higher premiums.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              NCB or No Claim Bonus
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The No Claim Bonus or NCB is the amount awarded to the policyholders by the insurers for not filing any claims during the policy tenure. The discount will apply to your premium at the renewal time of the policy. Thus, it reduces the premium amount overall.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Geographical Location
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The premium amount gets decided by the geographical location where the vehicle travels. For example, if your Bike rides in high-risk areas like cities, the premium amount will be higher. On the other hand, if your Bike rides in a village or low-traffic areas, the premium amount will be low.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Insured Age
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The age of the insured also affects the insurance premium amount. For instance, young riders are prone to more risk than older riders. So, the younger rider will have to pay more premium than the older rider. Moreover, riders with problematic driving records in the past have to pay higher premiums than the rest.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Engine Cubic Capacity
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The cubic capacity of the Bike plays a crucial role in determining the premium amount of the Bike. If the cubic capacity of your Bike is high, your premium amount will increase.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Safety Devices
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              If you have fitted your Bike with a safety device like a burglar alarm to increase the safety of your vehicle from your side, your insurance premium is likely to reduce to a lower price.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Add On Covers
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              You can include the add-on cover in your insurance policy as per your requirement, and it gets added to the original premium amount. The add-ons give extra coverage to your Bike in various scenarios and increase the premium once bought from the insurer.
            </Typography>
          }
        />
      </ListItem>
    </List>

    <Typography variant="h4" style={styles.heading}>
      How To Reduce Bike Insurance Premium?
    </Typography>

    <List>
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Avoid Making Small Claims
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              During your policy tenure, if you don't make any claims, you will get a 50% discount on your renewal premium, called the No Claim Bonus (NCB). By avoiding filing small claims, you are at the benefit from receiving discounts on your renewal premium amount. Also, don't forget to apply the discount while renewing the two-wheeler insurance policy.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Go for a Higher Voluntary Deductible
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The deductible is the amount of claim you pay from your side. If you go for a higher deductible, the insurance company's liability towards the insurance claim reduces. As a result, your insurance company reduces your premium amount.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Install Safety Devices
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              You can reduce the premium by installing safety devices like CCTV cameras in the parking lot, using handle locks etc. This is because the safety devices reduce the chances of vehicle theft. This further reduces the risk associated with it and thus reduces the premium amount.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Join Authorized Riding Groups in India
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              You can also reduce the premium amount by being a part of authorized riding groups in India. You can join groups like the Indian Automobile Association, approved by IRDAI, to avail yourself of discounts on your premium amount.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={
            <Typography variant="h5" style={styles.subHeading}>
              Bike's Age and CC
            </Typography>
          }
          secondary={
            <Typography variant="body1" style={styles.bodyText}>
              The bike's age and cubic capacity also affect the premium amount of a two-wheeler. If your bike is new, the premium will be high and vice versa. On the other hand, a higher cubic capacity means a higher premium.
            </Typography>
          }
        />
      </ListItem>
    </List>




    <Typography variant="h4" style={styles.heading}>
  FAQs
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          How to insure a new bike?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          To ensure a new bike, you must first find the best insurance provider in the market. Here is the process of buying insurance for a new bike:
          <br />
          1. Visit our official web portal.
          <br />
          2. Click on Bike Insurance and enter the Bike's registration number.
          <br />
          3. Fill in the details in the form appropriately and choose the coverage and add-ons you require.
          <br />
          4. The premium amount will appear, and buy it by paying the amount.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          What is the cost of two-wheeler insurance?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The cost of the two-wheeler insurance ultimately depends on the type of insurance policy you choose and other factors like make and model of the Bike, age of the Bike, Bike's CC, add-ons, if any, geographical location of the Bike etc.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Is third-party bike insurance enough?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The third-party two-wheeler insurance provides a wide range of coverage to the two-wheelers, but it is not enough to cover every damage that has happened to them. If your Bike suffers an accident, the coverage is provided only to the third-party person, not you or your Bike. Also, you won't receive any medical expenses. So, buying a comprehensive bike insurance policy that covers almost everything is a good idea.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          How to pay a bike insurance premium?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          You can pay the bike insurance premium online through our website. With a click, you can pay your insurance premium using multiple payment options like debit cards, UPI, credit cards etc.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          How to renew two-wheeler insurance online?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          You must use your bike registration number to renew a two-wheeler insurance policy online. You have to click on the 'Renew' option available on our website and submit your details as prompted by the website to renew your insurance policy.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Is it safe to buy bike insurance online?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Yes, absolutely! It is safe to buy bike insurance online. However, you must be careful with the fraudsters and only accept policies from trusted companies. It is also important to read policy documents carefully before purchasing the policy. Also, check if the payment gateway is secured or not.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Does scooty also need vehicle insurance?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          According to the Motor Vehicle Act, all motor vehicles must have motor insurance to ride on the roads. The owner will have to pay fines if the vehicle gets used without a policy.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Is it possible to transfer NCB?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Yes! It is possible to transfer NCB to your new vehicle if you have availed of a discount on the previous Bike.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Is it possible to buy any number of add-ons?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Yes! You can buy any number of add-ons as per your requirement. However, be wise while choosing the add-ons as they add up to your premium amount.
        </Typography>
      }
    />
  </ListItem>
</List>

    </Container>
     );
   };

export default LMVBikeInsurance;
