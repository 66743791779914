export const Get_Data_Request = "Get_Data_Request";
export const Get_Data_Success = "Get_Data_Success";
export const Get_City_Data_Success = "Get_City_Data_Success";
export const Get_Insurer_Name_Data_Success = "Get_Insurer_Name_Data_Success";
export const Get_Relation_Data_Success = "Get_Relation_Data_Success";
export const Get_NCB_Data_Success = "Get_NCB_Data_Success";
export const Get_Kyc_Data_Success = "Get_Kyc_Data_Success";
export const Get_Data_Error = "Get_Data_Error";
export const Get_Quotation_Data = "Get_Quotation_Data";
export const Mobile_OTP_Request = "Mobile_OTP_Request";
export const Mobile_OTP_Verification_Request = "Mobile_OTP_Verification_Request";
export const Vehicle_Master_Success_Request = "Vehicle_Master_Success_Request";
export const RTO_ADDRESS_SUCCESS_REQUEST = "RTO_ADDRESS_SUCCESS_REQUEST";
export const PINCODE_CITY_STATE_SUCCESS_REQUEST = "PINCODE_CITY_STATE_SUCCESS_REQUEST";
export const Agent_Data_Success_Request = "Agent_Data_Success_Request";
export const Get_Claims_Data_Success = "Get_Claims_Data_Success";
export const Get_Usage_Type_Success="Get_Usage_Type_Success"
export const Login_Success = 'Login_Success';
export const Login_Failure = 'Login_Failure';
export const Get_Customer_Inquiry_details_Success = 'Get_Customer_Inquiry_details_Success';
export const Get_POSP_details_Success ="Get_POSP_details_Success"
export const Get_Claims_details_Success ="Get_Claims_details_Success";
export const Add_Customer_Data_Success = "Add_Customer_Data_Success";
export const clear_Vehicle_data = "clear_Vehicle_data"