import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";

// project imports
import MainCard from "../../../../../posp/ui-component/cards/MainCard";
import Transitions from "../../../../../posp/ui-component/extended/Transitions";
import User1 from "../../../../../posp/assets/images/users/user-round.svg";
import { logout } from "../../../../../../Redux/action-creators/authAction";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
// assets
import {
  IconLogout,
  IconSearch,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import {
  MENU_OPEN,
  SET_THEME_MODE,
} from "../../../../../../Redux/crmustomization/actions";
import { handleEmployeeLogout } from "../../../../../../Redux/admin/action";
import greeting from '../../../../../../Framework/Utils/Greetings'

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const [themeMode, setThemeMode] = useState(customization.themeMode);
  const navigate = useNavigate();
  const state = useSelector((state) => state?.adminReducer?.loginData);
  const EmpData = useSelector((state) => state.adminReducer.loginData);
  const customerData = useSelector((state) => state?.customer?.data)
  const agentData = useSelector((state) => state?.posp?.data)
  const userType = localStorage.getItem('userType')
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  
  /*** anchorRef is used on different componets and specifying one type leads to other components throwing an error* */

  const anchorRef = useRef(null);
  const handleLogout = async () => {
    dispatch({ type: MENU_OPEN, id: "home" });
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("phone");
    localStorage.removeItem("userType");
    dispatch(logout());
    dispatch(handleEmployeeLogout(undefined));
    navigate("/");
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  // const userType = localStorage.getItem('userType');
  // console.log(userType)

  // mapping of user types to their corresponding Redux state keys and name fields
  // const userTypeMapping = {
  //   agent: {
  //     stateKey: 'posp.data',
  //     nameField: 'agent_name',
  //   },
  //   admin: {
  //     stateKey: 'admin.data',
  //     nameField: 'admin_name',
  //   },
  //   customer: {
  //     stateKey: 'customer.data',
  //     nameField: 'customer_name',
  //   },
  // };

  // const { stateKey, nameField } = userTypeMapping[userType] || {};

  // const userData = useSelector((state) => {
  //   if (!stateKey) return null; // Return null if stateKey is not defined

  //   const keys = stateKey.split('.');
  // return keys.reduce((acc, key) => (acc && acc[key] !== undefined) ? acc[key] : null, state);
  // });
  // const userData = useSelector((state) => {
  //   const keys = stateKey?.split('.') || [];
  //   return keys.reduce((acc, key) => acc[key], state);
  // });

  // const displayName = userData ? userData[nameField] : '';
  // console.log(userData,'userData')
  // console.log(displayName,'displayName')

  const handleThemeMode = (event) => {
    setThemeMode(event.target.checked ? "dark" : "light");
  };

  useEffect(() => {
    dispatch({ type: SET_THEME_MODE, themeMode });
  }, [dispatch, themeMode]);
  const district = userType === 'customer' ? customerData?.district || '' : userType === 'agent' ? agentData?.district || '' : EmpData?.district || ''
  const stateName = userType === 'customer' ? customerData?.state || '' : userType === 'agent' ? agentData?.state || '' : EmpData?.state || ''
  // const district = state?.district || "";
  // const stateName = state?.state || "";
  return (
    <Stack direction="row" alignItems="center" columnGap={5}>
      {(district || stateName) &&
        <Chip
          size="small"
          icon={<FmdGoodIcon fontSize="small" />}
          label={`${district}${stateName && district ? ", " : ""}${stateName}`}
          color="primary"
          clickable
        />
      }
      <Tooltip title={userType === 'customer' ? customerData?.customer_name : userType === 'agent' ? agentData?.agent_name : `${EmpData?.Role} (${EmpData?.empid})`}>
        <Chip
          sx={{
            height: "48px",
            alignItems: "center",
            borderRadius: "27px",
            transition: "all .2s ease-in-out",
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.light,
            '&[aria-controls="menu-list-grow"], &:hover': {
              borderColor: theme.palette.primary.main,
              background: `${theme.palette.primary.main}!important`,
              color: theme.palette.primary.light,
              "& svg": {
                stroke: theme.palette.primary.light,
              },
              "& .MuiAvatar-root": {
                backgroundColor: theme.palette.primary.light,
                color: `${theme.palette.primary.main} !important` ,
              },
            },
            "& .MuiChip-label": {
              lineHeight: 0,
            },
          }}
          icon={
            userType === 'agent' ? (
            <Avatar
              src={agentData?.profile_doc}
              sx={{
                ...theme.typography.mediumAvatar,
                margin: "8px 0 8px 8px !important",
                cursor: "pointer",
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              color="inherit"
            />
            ) : (
            <Avatar
              sx={{
                ...theme.typography.mediumAvatar,
                margin: "8px 0 8px 8px !important",
                cursor: "pointer",
                backgroundColor: theme.palette.primary.main,
                color: "#ffffff !important",
              }}
            >
              {userType === "customer"
                ? customerData?.customer_name?.charAt(0)?.toUpperCase()
                : userType === "agent"
                  ? agentData?.agent_name?.charAt(0)?.toUpperCase()
                  : EmpData?.Role?.charAt(0)?.toUpperCase()}
            </Avatar>
            )
          }
          label={
            <IconSettings
              stroke={1.5}
              size="1.5rem"
              color={theme.palette.primary.main}
            />
          }
          variant="outlined"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="primary"
        />
      </Tooltip>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ px: 2, pt: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Hii,</Typography>
                        <Typography variant="h4">
                          {userType === 'customer' ? `${customerData?.customer_name} (${customerData?.customer_id})` : userType === 'agent' ? `${agentData?.agent_name} (${agentData?.agent_id})` : `${EmpData?.name} (${EmpData?.empid})`}
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2">{greeting}</Typography>
                    </Stack>
                    <Divider />
                    <Stack>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Logout</Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Stack>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Stack>
  );
};

export default ProfileSection;
