
import React,{useState} from 'react';
import { TextField, Button, Grid, Typography, Box, Stack } from '@mui/material';
import { setPersonalDetails } from '../../../Redux/Loan/Action';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import InfoIcon from '@mui/icons-material/Info';
import CustomTextField from "../../components/CustomTextField";
import { useTranslation } from "react-i18next";
export const PersonalDetails = ({ setOpen, setStepCount }) => {

    let [compare,setCompare] = useState(false);
    const { t } = useTranslation();
    const loanContent = t("loan");
    const { personaldetails } = loanContent;

    let pDefault = useSelector((e) => e.loanReducer.RequestBody.personalDetails);
    let dispatch = useDispatch();
    const handleGenderChange = (e) => {
        const gender = e.target.value;
        setValue('gender', gender);
        setValue('title', gender === 'M' ? 'MR' : 'MS');
    };
    const today = new Date(); 
    const maxDOB = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate()
    )
        .toISOString()
        .split("T")[0];
    const minDOB = new Date(
        new Date().getFullYear() - 65,
        new Date().getMonth(),
        new Date().getDate()
    )
        .toISOString()
        .split("T")[0];



    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });


    // submit handler
    const onSubmit = (data) => {
        // next step => addressDetails
        setStepCount((prev) => ++prev);
        // store the data into redux_store
        dispatch(setPersonalDetails(data));
    };
    const genderOptions = {
        MALE: 'M',
        FEMALE: 'F'
    }
    const titleOptions = {
        MR: "MR",
        MS: "MS"
    }
    const relationOption = {
        SELF: "SELF"
    }


    return (<>

        <Box>
            <Typography sx={{ fontWeight: 900, color: '#383838' }} variant='h5' textAlign={"center"} mb={3}>{personaldetails.personaldetails}</Typography>
        </Box>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
                <Grid item xs={12} md={6} padding={"16px"} py={0}>
                    <Controller
                        name={'firstName'}
                        control={control}
                        // defaultValue={pDefault?.firstName}
                        rules={{ required: personaldetails.firstnamerequired }}
                        render={({ field }) => (
                            <CustomTextField
                                name='firstName'
                                label={personaldetails.firstname}
                                field={{
                                    ...field,
                                    inputProps: {
                                        onInput: (e) => {
                                            e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                                            field.onChange(e);
                                        }
                                    }
                                }}
                                errors={errors}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6} padding={'16px'} py={0}>
                    <Controller
                        name={'lastName'}
                        control={control}
                        // defaultValue={pDefault?.lastName}
                        rules={{ required: personaldetails.lastnamerequired }}
                        render={({ field }) => (
                            <CustomTextField
                                name='lastName'
                                label={personaldetails.lastname}
                                field={{
                                    ...field,
                                    inputProps: {
                                        onInput: (e) => {
                                            e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                                            field.onChange(e);
                                        }
                                    }
                                }}
                                errors={errors}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6} padding={'0 16px'}>
                    <Controller
                        name="title"
                        control={control}
                        // defaultValue={pDefault?.title}
                        rules={{ required: "Please select Title" }}
                        render={({ field }) => (
                            <CustomTextField
                                select={true}
                                options={titleOptions}
                                name='title'
                                label={personaldetails.title}
                                field={{
                                    ...field, onChange: (e) => {
                                        field.onChange(e);
                                        if (e.target.value === "MR") {
                                            setValue("gender", "M");
                                        } else {
                                            setValue('gender', "F")
                                        }
                                    }
                                }}
                                errors={errors}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6} padding={'0 16px'}>
                    <Controller
                        name="gender"
                        control={control}
                        // defaultValue={pDefault?.gender}
                        rules={{ required: "Please select a gender" }}
                        render={({ field }) => (
                            <CustomTextField
                                select={true}
                                options={genderOptions}
                                name='gender'
                                label={personaldetails.gender}
                                field={{
                                    ...field, onChange: handleGenderChange
                                }}
                                errors={errors}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} padding={'16px'} py={0} >
                    <Controller
                        name="dob"
                        control={control}
                        // defaultValue={pDefault?.dob}
                        rules={{
                            required: personaldetails.dobrequired,
                            validate: {
                                ageLimit: (value) => {
                                    const selectedDate = new Date(value);
                                    const ageDifference = today.getFullYear() - selectedDate.getFullYear();
                                    const isWithinAgeLimit = ageDifference >= 18 && ageDifference <= 65;
                                    return isWithinAgeLimit || personaldetails.dobrange;
                                },
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={personaldetails.dob}
                                type="date"
                                variant="outlined"
                                fullWidth
                                margin='dense'
                                InputLabelProps={{ shrink: true }}
                                error={Boolean(errors.dob)}
                                helperText={errors.dob ? errors.dob.message : ""}
                                inputProps={{ max: maxDOB, min: minDOB }}
                            />
                        )}
                    />
                    <Stack direction="row" columnGap={0.5} alignItems={'center'}>
                        <InfoIcon fontSize='0.5em'></InfoIcon><Typography variant='caption' color={'GrayText'}>{personaldetails.alert18}</Typography>
                    </Stack>

                </Grid>
                <Grid item xs={6} padding={'16px'} py={0} pb={'16px'}>
                    <Controller
                        name="relation"
                        control={control}
                        defaultValue="SELF"
                        rules={{ required: "Please select a relation" }}
                        render={({ field }) => (
                            <CustomTextField
                                select={true}
                                options={relationOption}
                                name='relation'
                                label="Relation"
                                field={{
                                    ...field
                                }}
                                errors={errors}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} padding={'0 15px'} pb={"12px"}>
                    <Box>
                        <Button
                            fullWidth
                            sx={{
                                backgroundColor: '#23a8fa', borderRadius: '999px',
                                fontWeight: 700,
                            }}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Next
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: 'fit-content', margin: 'auto' }} onClick={() => setOpen(true)}>
                        <Typography
                            textAlign={'center'}
                            title='Check Your Policy Status'
                            sx={{
                                color: '#6c6c6c', fontWeight: 600,
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                            variant='body2'>check Policy Status</Typography>
                    </Box>
                </Grid>
            </Grid>
        </form>
    </>)

}