import React, { useState } from 'react';
import { Button, Grid, Snackbar, Box, Typography, Stack, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createProposalSecure } from "../../api/service";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { proposalResponse, setNomineeDetails } from '../../../Redux/Loan/Action';
import { useForm, Controller } from 'react-hook-form';
import CustomTextField from "../../components/CustomTextField";
import { useTranslation } from "react-i18next";
import { sendOtp } from '../../../Framework/Add&SendOTP';

const NomineeDetails = ({ setStepCount }) => {
  const { t } = useTranslation();
  const loanContent = t("loan");
  const { nomineedetails } = loanContent;
  let nDetails = useSelector((e) => e.loanReducer.RequestBody.nomineeDetails)
  let totalReq = useSelector((e) => e.loanReducer.RequestBody);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm();

  const documentType = watch('documentType');

  // validate the document number based on document type
  const getDocumentNumberValidation = () => {
    switch (documentType) {
      case 'AADHAR':
        return {
          required: nomineedetails.aadhaar,
          pattern: {
            value: /^[0-9]{12}$/,
            message: nomineedetails.invalidaadhaar,
          },
        };
      case 'PAN':
        return {
          required: nomineedetails.pan,
          pattern: {
            value: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,
            message: nomineedetails.invalidpan,
          },
        };
      case 'PASSPORT':
        return {
          required: nomineedetails.passport,
          pattern: {
            value: /^[A-Z]{1}[0-9]{7}$/,
            message: nomineedetails.invalidpassport
          }
        };
      default:
        return { required: nomineedetails.documentnumberrequire }
    }
  };

  let dispatch = useDispatch();
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState({ state: false, message: '' });
  const navigate = useNavigate();

  let dataMapping = (req, res) => {


    var dateParts = res?.intPolicyDataIO.policy.loanDisbursalDt.split('/');
    var today = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    var nextYear = new Date(today);
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    var day = nextYear.getDate();
    var month = nextYear.getMonth() + 1;
    var year = nextYear.getFullYear();
    var formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;







    let data = {
      org_id: 100,
      agent_id: 1000,
      customer_name: req.personalDetails.firstName + ' ' + req.personalDetails.lastName,
      address: req.addressDetails.permanentAddress.addressLine1,
      city: req.addressDetails.permanentAddress.city,
      state: req.addressDetails.permanentAddress.state,
      pincode: req.addressDetails.permanentAddress.pincode,
      phone: req.addressDetails.permanentAddress.phno,
      email: req.addressDetails.permanentAddress.email,
      dob: req.personalDetails.dob,
      gender: req.personalDetails.gender === 'MALE' ? "M" : "F",
      marital_status: "S",
      document_type: req.nomineeDetails.document_type,
      document_number: req.nomineeDetails.document_number,
      policyData: {
        product_type: "LON",
        product_subtype: "COM",
        rc_number: "",
        policy_type: "IND",
        policy_sub_type: "",
        provider: "CARE",
        policy_number: "",
        application_id: res?.intPolicyDataIO.policy.proposalNum,
        policy_status: "CP",
        payment_link: "",
        kyc_link: "",
        pdf_link: "",
        policy_startdate: res?.intPolicyDataIO.policy.loanDisbursalDt,
        policy_enddate: formattedDate,
        premium: res.intPolicyDataIO.policy.premium,
        updated_by: "System",
        proposal_res: res,
        proposal_req: req
      }
    }
    return data
  }


  // call create proposal api
  const handleResponse = async (requestBody) => {
    try {
      setLoading(true);
      let res = await createProposalSecure(requestBody);
      if (res?.responseData.status === '1') {
        dispatch(proposalResponse(res))
        //store response in redux_store
        // add the policy status and other details to db
        // let policyData = dataMapping(requestBody, res);
        // insert policyData into policy table
        // let response = await axios.post(`${common_api}/crm/v1/org/policy`, policyData)
        navigate('/loan/proposalDetails'); // naivgate to ProposalDetails
      } else {
        setError({ state: true, message: 'failed create Proposal' })
      }
    } catch (error) {
      setError({ state: true, message: 'Internal Error' })
    }
    finally {
      setLoading(false);
    }
  }

  // submit handler
  async function onSubmit(data) {
    dispatch(setNomineeDetails(data)); //STORE DATA INTO REDUX
    totalReq = { ...totalReq, nomineeDetails: data }
    let { addressDetails, personalDetails, nomineeDetails, loanDetails
    } = totalReq;
    console.log(totalReq, 'seethere')
    let remainingCustomerData = {
      customer_name: personalDetails?.firstName + " " + personalDetails?.lastName,
      phone: Number(addressDetails.communicationAddress.phno),
      account_number: loanDetails?.accountNumber,
      document_type: nomineeDetails?.documentType,
      document_number: nomineeDetails?.documentNumber,
      nominee_name: nomineeDetails?.nomineeName,
      nominee_relation: nomineeDetails?.nomineeRelation,
      nominee_dob: nomineeDetails?.nomineeAge
    }
    // await sendOtp(remainingCustomerData, {}, true); //update remaining customer data
    handleResponse(totalReq); // CALLING CREATE_POLICY_SECURE API
  }

  let realtionOptions = {
    SON: "SON",
    DAUGHTER: 'DAUGHTER',
    WIFE: 'WIFE',
    FATHER: 'FATHER',
    MOTHER: 'MOTHER',
    BROTHER: 'BROTHER',
    SISTER: 'SISTER',
    GRAND_FATHER: 'GRAND FATHER',
    GRAND_MOTHER: 'GRAND MOTHER',
    SISTER_IN_LAW: 'SISTER-IN-LAW',
    BROTHER_IN_LAW: 'BROTHER-IN-LAW'
  }
  let docOptions = {
    PAN: 'PAN',
    AADHAAR: 'AADHAR',
    PASSPORT: 'PASSPORT'
  }

  return (
    <Box padding={'0 1em'}>
      <Box>
        <Typography sx={{ fontWeight: 900, color: '#383838' }} variant='h5' textAlign={"center"}>{nomineedetails.nomineedetails}</Typography>
      </Box>
      <Box component={CardContent}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={0} columnSpacing={2}>
            <Grid item xs={6}>
              <Controller
                name={'nomineeName'}
                control={control}
                // defaultValue={nDetails?.nomineeName}
                rules={{ required: nomineedetails.namerequire }}
                render={({ field }) => (
                  <CustomTextField
                    name='nomineeName'
                    label={nomineedetails.nominename}
                    field={{
                      ...field,
                      inputProps: {
                        onInput: (e) => {
                          e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                          field.onChange(e);
                        }
                      }
                    }}
                    errors={errors}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name='nomineeRelation'
                control={control}
                // defaultValue={nDetails?.nomineeRelation}
                rules={{ required: nomineedetails.relationrequire }}
                render={({ field }) => (
                  <CustomTextField
                    select={true}
                    options={realtionOptions}
                    name="nomineeRelation"
                    label={nomineedetails.nomineerealation}
                    field={{
                      ...field
                    }}
                    errors={errors}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="nomineeAge"
                control={control}
                rules={{
                  required: nomineedetails.agerequire,
                  min: { value: 18, message: nomineedetails.agelimit },
                  max: { value: 99, message: nomineedetails.agelimit },
                }}
                render={({ field }) => (
                  <>
                    <CustomTextField
                      name='nomineeAge'
                      label={nomineedetails.nomineeage}
                      field={{
                        ...field,
                        onChange: (e) => {
                          const value = e.target.value.replace(/\D/g, '');
                          field.onChange(value);
                        }
                      }}
                      errors={errors}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={'documentType'}
                control={control}
                // defaultValue={""}
                rules={{ required: nomineedetails.documentrequire }}
                render={({ field }) => (
                  <CustomTextField
                    select={true}
                    options={docOptions}
                    name='documentType'
                    label={nomineedetails.documenttype}
                    field={{
                      ...field
                    }}
                    errors={errors}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={"documentNumber"}
                control={control}
                // defaultValue={""}
                rules={getDocumentNumberValidation()}
                render={({ field }) => (
                  <CustomTextField
                    name='documentNumber'
                    label={nomineedetails.documentnumber}
                    field={{
                      ...field,
                      inputProps: {
                        onInput: (e) => {
                          let inputValue = e.target.value;

                          // For Aadhaar, only allow numeric input
                          if (documentType === 'AADHAR') {
                            // Replace all non-numeric characters
                            inputValue = inputValue.replace(/[^0-9]/g, "");

                            // Limit input to 12 digits
                            if (inputValue.length > 12) {
                              inputValue = inputValue.slice(0, 12);
                            }
                          }
                          if (documentType === 'PAN') {
                            if (inputValue.length > 10) {
                              inputValue = inputValue.slice(0, 10);
                            }
                          }
                          if (documentType === 'PASSPORT') {
                            if (inputValue.length > 8) {
                              inputValue = inputValue.slice(0, 8);
                            }
                          }
                          // Update the field value with sanitized input
                          e.target.value = inputValue.toUpperCase();
                          field.onChange(e); // Notify React Hook Form of the change
                        }
                      }
                    }}
                    errors={errors}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Snackbar
                open={error.state}
                autoHideDuration={4000}
                onClose={() => { setError({ state: false, message: '' }) }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <Alert
                  severity="error"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  Failed to Create Proposal
                </Alert>
              </Snackbar>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Button
                  onClick={() => { setStepCount((prev) => prev - 1) }}
                  sx={{
                    backgroundColor: '#23a8fa', borderRadius: '999px',
                    fontWeight: 700,
                  }}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Back
                </Button>
                <Button

                  sx={{
                    backgroundColor: '#23a8fa', borderRadius: '999px',
                    fontWeight: 700,
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Stack justifyContent={'center'} rowGap={"1em"}>
          <CircularProgress color="inherit" sx={{ margin: 'auto' }} />
          <Typography>Creating Policy Secure</Typography>
        </Stack>
      </Backdrop>
    </Box>
  )
}

export { NomineeDetails }