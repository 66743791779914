import {
  LOGINDATA,
  pendingCasesData,
  profileDetails,
  SalesData,
  SelfInspecData,
  UserIdManagement,
  BRANCHDATA,
  SingleRow,
  NEWEMPLOYEE,
  RenewalNotification,
  RENEWALS,
  Other_Payouts,
  TOGGLE_FORM,
  Employee_Logout,
} from "./actionType";
let initialState = {
  loginData: undefined,
  salesData: [],
  selfInspecData: [],
  profileData: {},
  pendingCases: [],
  userIdManagement: [],
  branches: [],
  singleRow: {},
  NewEmployee: {
    PersonalDetails: {},
    EmpoyeeDetails: {},
    PermissionsForm: {},
  },
  RenewalNotificationData: [],
  Renewals: [],
  Other_Payouts: [],
  offline_forms: {
    activeForm: "car",
  },
};

function adminReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SalesData:
      return { ...state, salesData: payload };
    case SelfInspecData:
      return { ...state, selfInspecData: { ...state.selfInspecData, payload } };
    case pendingCasesData:
      return { ...state, pendingCases: payload };
    case profileDetails:
      return { ...state, profileData: payload };
    case UserIdManagement:
      return { ...state, userIdManagement: payload };
    case LOGINDATA:
      return { ...state, loginData: payload };
    case BRANCHDATA:
      return { ...state, branches: payload };
    case SingleRow:
      return { ...state, singleRow: payload };
    case NEWEMPLOYEE:
      return {
        ...state,
        NewEmployee: {
          ...state.NewEmployee,
          [action.payload.form]: {
            ...state.NewEmployee[action.payload.form],
            ...action.payload.data,
          },
        },
      };

    case RenewalNotification:
      return { ...state, RenewalNotificationData: payload };

    case RENEWALS:
      return { ...state, Renewals: payload };

    case Other_Payouts:
      return { ...state, Other_Payouts: payload };

    case TOGGLE_FORM:
      return {
        ...state,
        offline_forms: {
          activeForm: payload,
        },
      };
    case Employee_Logout:
      return {
        ...state,
        loginData: payload,
      };

    default:
      return state;
  }
}

export { adminReducer };
