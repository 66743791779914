import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import {
  Autorenew as AutorenewIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import MainCard from "../../../posp/ui-component/cards/MainCard";
import { gridSpacing } from "../../../../Redux/crmustomization/constant";
import { getClaimsByCustomerId } from "../../api/customerService";
import { useDispatch, useSelector } from "react-redux";
import {
  clearError,
  setError,
} from "../../../../Redux/action-creators/errorAction";
import { clearSuccessMessage } from "../../../../Redux/action-creators/successAction";
// import PolicyClaimStatusCard from "./PolicyClaimStatusCard";
// import RegisterPolicyClaimModal from "./RegisterPolicyClaimModal";

const CustPolicyClaim = () => {
  const [isLoading, setLoading] = useState(true);
  const [isRegisterModalTrue, setIsRegisterModalTrue] = useState(false);
  const [data, setData] = useState([
    { value: "30", label: "Total Claims" },
    { value: "₹ 21,213", label: "Claims Pending" },
    { value: "₹ 12,234", label: "Claims Rejected" },
    { value: "₹ 12,234", label: "Claims Rejected" },
  ]);

  const dispatch = useDispatch();
  const customerData = useSelector((state) => state?.customer?.data);
  const [claims, setClaims] = useState([]);
  const [isLoaderTrue, setIsLoaderTrue] = useState(false);

  useEffect(() => {
    getClaimsData();
  }, []);

  const getClaimsData = async () => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    setIsLoaderTrue(true);
    try {
      const response = await getClaimsByCustomerId(customerData?.customer_id);
      if (response?.status === 200) {
        setClaims(response?.data);
      } else {
        dispatch(setError("Somthing Went Wrong"));
      }
    } catch (error) {
      dispatch(setError("Internal Server Error"));
    } finally {
      setIsLoaderTrue(false);
    }
  };

  const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
  const xLabels = [
    "Page A",
    "Page B",
    "Page C",
    "Page D",
    "Page E",
    "Page F",
    "Page G",
  ];

  const renderColumn = (p, params) => {
    return (
      <>
        <span style={{ marginLeft: "15px" }}>
          <Tooltip title="Download">
            <DownloadIcon />
          </Tooltip>
        </span>
      </>
    );
  };

  const defaultColDef = useMemo(() => ({
    filter: true, // Enable filtering on all columns
    cellStyle: { textAlign: "center" },
    editable: true,
    sortable: true,
    resizable: true,
  }));

  /* Custom Cell Renderer (Display tick / cross in 'Successful' column) */
  const MissionResultRenderer = ({ value }) => (
    <span
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
      }}
    >
      {
        <img
          alt={`${value}`}
          src={`https://www.ag-grid.com/example-assets/icons/${
            value ? "tick-in-circle" : "cross-in-circle"
          }.png`}
          style={{ width: "auto", height: "auto" }}
        />
      }
    </span>
  );

  const columnDefs = [
    // {
    //   headerName: "Select",
    //   field: "select",
    //   checkboxSelection: true,
    //   width: "100px",
    // },
    {
      headerName: "Register ID",
      field: "register_id",
      // filter: true,
    },
    {
      headerName: "Policy Number",
      field: "policy_number",
    },
    {
      headerName: "Product Type",
      field: "product_type",
    },
    {
      headerName: "Claim Amount",
      field: "claim_amount",
      valueFormatter: (params) => {
        return "₹" + params.value.toLocaleString();
      },
    },
    {
      headerName: "Claim Reason",
      field: "claim_reason",
    },
    // {
    //   headerName: "Payment Status",
    //   field: "paymentStatus",
    //   cellRenderer: MissionResultRenderer,
    // },
    {
      headerName: "Status",
      field: "status",
      // cellRenderer: renderChecked,
      cellClassRules: {
        redFont: (params) => {
          return params.value === "Applied";
        },
        claimStatus: (params) => {
          return params.value === "In Progress";
        },
        pending: (params) => {
          return params.value === "Pending";
        },
        rejected: (params) => {
          return params.value === "Rejected";
        },
        approved: (params) => {
          return params.value === "Approved";
        },
        settled: (params) => {
          return params.value === "Settled";
        },
      },
    },
    {
      headerName: "Reject Reason",
      field: "reject_reason",
    },
    {
      headerName: "Claim Date",
      field: "created_date",
    },
    {
      headerName: "Claim By",
      field: "created_by",
    },
    // {
    //   headerName: "Print/ Download",
    //   field: "download",
    //   cellRenderer: renderColumn,
    // },

    // Add other columns as needed
  ];
  // Sample data
  // Define the initial row data for the table
  const rowData = [
    {
      userId: "12459",
      date: "6-07-2023",
      claimDate: "21/5/2023",
      claimedAmount: "140",
      amountClaimed: "100",
      type: "Car Insurance",
      paymentStatus: "Paid",
      claimStatus: "Applied",
      amountClaimedInDays: "3 Days",
      download: "",
    },
    {
      userId: "12452",
      date: "6-07-2023",
      claimDate: "21/5/2023",
      claimedAmount: "140",
      amountClaimed: "100",
      type: "Car Insurance",
      paymentStatus: "In Progress",
      claimStatus: "In Progress",
      amountClaimedInDays: "3 Days",
      download: "",
    },
    {
      userId: "12452",
      date: "6-07-2023",
      claimDate: "21/5/2023",
      claimedAmount: "140",
      amountClaimed: "100",
      type: "Car Insurance",
      paymentStatus: "Unpaid",
      claimStatus: "Pending",
      amountClaimedInDays: "3 Days",
      download: "",
    },
    {
      userId: "12452",
      date: "6-07-2023",
      claimDate: "21/5/2023",
      claimedAmount: "140",
      amountClaimed: "100",
      type: "Car Insurance",
      paymentStatus: "Paid",
      claimStatus: "Rejected",
      amountClaimedInDays: "3 Days",
      download: "",
    },
    {
      userId: "12452",
      date: "6-07-2023",
      claimDate: "21/5/2023",
      claimedAmount: "140",
      amountClaimed: "100",
      type: "Car Insurance",
      paymentStatus: "In Progress",
      claimStatus: "Approved",
      amountClaimedInDays: "3 Days",
      download: "",
    },
    {
      userId: "12452",
      date: "6-07-2023",
      claimDate: "21/5/2023",
      claimedAmount: "140",
      amountClaimed: "100",
      type: "Car Insurance",
      paymentStatus: "In Progress",
      claimStatus: "Approved",
      amountClaimedInDays: "3 Days",
      download: "",
    },

    // Add other rows as needed
  ];

  const openRegisterModal = () => {
    setIsRegisterModalTrue((prv) => !prv);
  };

  return (
    <>
      {isLoaderTrue ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={35} />
        </Box>
      ) : claims.length !== 0 ? (
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12} md={12} marginTop={1}>
                <MainCard title="Policy Claims">
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <div className="ag-theme-quartz" style={{ height: 430 }}>
                        <AgGridReact
                          columnDefs={columnDefs}
                          rowData={claims}
                          pagination={true}
                          defaultColDef={defaultColDef}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </MainCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Empty</Typography>
        </Box>
      )}
    </>
  );
};

export default CustPolicyClaim;
