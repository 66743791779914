import React from 'react';
import { Typography, List, ListItem, ListItemText, Container } from '@mui/material';

const styles = {
  container: {
    marginTop: "32px",
    marginBottom: "32px",
    padding: "20px 30px",
  },
  heading: {
    marginBottom: "2px",
    color: "#23a8fa",
    fontWeight: "bold",
  },
  subHeading: {
    marginTop: "2px",
    fontWeight: "bold",
    color: "#555",
  },
  bodyText: {
    color: '#555',
  },
  listItem: {
    backgroundColor: "#f5f5f5",
    textAlign: 'justify',
    padding: '0px',
    margin: '0px',
  },
  ListItemText: {
    textAlign: 'justify',
    padding: '0px',
    margin: '0px',
  },
};

const FooterCarClaims = () => (
  <Container style={styles.container}>
    <Typography variant="h2" textAlign='center' style={styles.heading} gutterBottom>
      Car Claims Under LMV Insurance
    </Typography>
    <Typography variant="body1" mt={1} style={styles.bodyText}>
      When it comes to car claims under an LMV Insurance policy with a broker like LMV Insurance Broking Private Limited, the claims process follows a similar structure to other insurance providers, but there may be specific steps or services provided through the broker. Here's a detailed look at how car claims are handled in such a scenario:
    </Typography>
    
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>1. Types of Coverage under LMV Insurance (Car Insurance)</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>Third-Party Liability Coverage: This covers the damages or injury caused to a third party (other drivers, pedestrians, etc.) due to an accident involving your vehicle. It includes property damage, bodily injury, and even death. Does not cover damages to your own car.</li>
            <li>Own Damage (Comprehensive Coverage): Covers damages to your own car in case of an accident, fire, theft, or natural calamities (flood, earthquake, etc.). Includes damage due to external factors such as accidents or vandalism.</li>
            <li>Personal Accident Cover: This coverage protects the driver (and sometimes passengers) of the vehicle in case of accidents leading to death or permanent disability. This is generally offered as an add-on or a standard benefit depending on the policy.</li>
            <li>Add-ons: Policies can also offer add-ons such as Zero Depreciation, Engine Protect, Roadside Assistance, and Return to Invoice that enhance the basic coverage.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>2. Steps to File a Car Insurance Claim (with LMV Insurance Broking Pvt. Ltd.)</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>Step 1: Immediately Report the Incident: Inform your broker promptly as soon as the incident (accident, theft, damage, etc.) occurs. The broker will assist in initiating the claim with the insurance provider. Most insurance companies and brokers offer a 24/7 helpline to report accidents and emergencies.</li>
            <li>Step 2: Document the Accident: Take clear photographs of the damages to your vehicle and the accident scene. If it’s a third-party accident, document the third party's details (vehicle number, insurance details, etc.).</li>
            <li>Step 3: Submit the Claim Form and Required Documents: The broker or insurance company will provide you with a claim form that you need to complete. Along with the claim form, submit necessary documents such as the policy document, FIR (if applicable), driving license, RC (Registration Certificate), photos of the incident, and repair estimates.</li>
            <li>Step 4: Survey and Investigation: The insurance company (through the broker) will assign a surveyor to assess the damage to the car. The surveyor may ask you to bring it to a network garage for a detailed inspection. In cases involving third-party damages or theft, the insurer might conduct a further investigation.</li>
            <li>Step 5: Claim Approval and Settlement: After reviewing the submitted documents, the insurer will approve or deny the claim. Once approved, you may receive the payout directly (cashless claim at network garages) or have to bear the repair costs first and claim reimbursement. If the car is declared a total loss, you will receive the Insured Declared Value (IDV) of the vehicle.</li>
            <li>Step 6: Payout/Repair Completion: After the claim is processed, either you receive a reimbursement for repairs or the garage is paid directly (in cashless claims). For total loss cases, the IDV of the car will be paid.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>3. Common Exclusions in Car Insurance Claims</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>Driving Under the Influence: Claims may be rejected if the driver was under the influence of alcohol or drugs.</li>
            <li>Non-Disclosure: If you did not inform the insurer about any important details during policy issuance (e.g., car modifications, usage, etc.), they may deny your claim.</li>
            <li>Wear and Tear: Regular maintenance issues or wear and tear (e.g., brake pad replacement, tire wear) are typically not covered.</li>
            <li>Mechanical Breakdown: If the damage is due to mechanical failure, it will not be covered under the policy.</li>
            <li>Intentional Damage: Any damage caused intentionally (including self-inflicted) will not be covered.</li>
            <li>Policy Lapse: If your policy is expired or lapsed, no claim can be made.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>4. Tips for a Smooth Car Insurance Claim Process</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>Ensure Your Policy Is Active: Always keep your policy renewed to avoid claims being rejected due to a lapsed policy.</li>
            <li>Understand Your Coverage: Read the terms of your LMV insurance policy carefully, including exclusions and limits on coverage.</li>
            <li>Keep Documents Handy: Ensure you have copies of essential documents (policy, FIR, repair estimates) readily available when needed.</li>
            <li>Use Network Garages: For a faster cashless settlement, get your car repaired at a network garage authorized by the insurance company.</li>
            <li>Act Quickly: Notify the broker and insurer as soon as the incident occurs to avoid delays in claim processing.</li>
          </ul>
        }
      />
    </ListItem>
  </Container>
);

export default FooterCarClaims;
