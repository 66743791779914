import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, MenuItem, Button, Paper, Typography, Badge, IconButton, Tabs, Tab,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, LinearProgress } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import flagimg from '../../images/2922516_27130.jpg'
import mailimg from '../../images/gmail-old-svgrepo-com.svg'
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { clearError, setError } from '../../../../Redux/action-creators/errorAction';
import { clearSuccessMessage } from '../../../../Redux/action-creators/successAction';


// Child Component
const MobileNumbersCard = ({ number, type, deleteNumber, deleteLoader }) => {

  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        mt: 2,
        padding: "10px",
        borderRadius: "8px",
        borderColor: "#e0e0e0",
        borderStyle: "solid",
        borderWidth: "1px",
      }}
    >
      <img
        src={flagimg}
        alt="India flag"
        style={{ marginRight: "10px", width: "40px", height: "25px" }}
      />
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {number}
      </Typography>
      <Button
        variant="contained"
        size="small"
        sx={{
          bgcolor: "#e0e0e0",
          color: "#7e57c2",
          borderRadius: "8px",
          textTransform: "none",
          mr: 2,
        }}
      >
        {type}
      </Button>
      {type !== "Primary" && (
        <IconButton onClick={() => deleteNumber(number)} disabled={deleteLoader}>
          <DeleteIcon sx={{ color: "#ba000d" }} />
        </IconButton>
      )}
    </Paper>
  );
};

// Child Component
const EmailCard = ({ email, type, deleteLoader, deleteEmail }) => (
  <Paper
    sx={{
      display: "flex",
      alignItems: "center",
      mt: 2,
      padding: "10px",
      borderRadius: "8px",
      borderColor: "#e0e0e0",
      borderStyle: "solid",
      borderWidth: "1px",
    }}
  >
    <Typography variant="h6" sx={{ flexGrow: 1 }}>
      <img
        src={mailimg}
        alt="Mail icon"
        style={{ marginRight: "10px", width: "30px", height: "30px" }}
      />
      {email}
    </Typography>
    <Button
      variant="contained"
      size="small"
      sx={{
        bgcolor: "#e0e0e0",
        color: "#7e57c2",
        borderRadius: "8px",
        textTransform: "none",
        mr: 2,
      }}
    >
      {type}
    </Button>
    {type !== "Primary" && (
      <IconButton onClick={() => deleteEmail(email)} disabled={deleteLoader}>
        <DeleteIcon sx={{ color: "#ba000d" }} />
      </IconButton>
    )}
  </Paper>
);

// Child Component
const MobileAndEmailDialog = (props) => {
  const { selectedTab, openMobileOrEmailDialog , handleCloseMobileOrEmailDialog , handleMobileAndEmailSubmit} = props
  const {
    formState: { errors, isSubmitted },
    register,
    setValue,
    handleSubmit,
    trigger,
    reset
  } = useForm();

  useEffect(()=>reset() , [])

  return (
    <Dialog
      open={openMobileOrEmailDialog}
      onClose={handleCloseMobileOrEmailDialog}
    >
      {/* <DialogTitle>Add Mobile Number</DialogTitle> */}
      <form onSubmit={handleSubmit(handleMobileAndEmailSubmit)}>
        <DialogContent>
          <DialogContentText>
            Enter your {selectedTab === "mobile" ? "mobile number" : "Email ID"}{" "}
            to receive an OTP.
          </DialogContentText>
          <TextField
            autoFocus
            sx={{ marginTop: "15px" }}
            margin="dense"
            label={selectedTab === "mobile" ? "Mobile Number" : "Email ID"}
            type="text"
            fullWidth
            {...register("mobile_or_email", {
              required: `${
                selectedTab === "mobile" ? "mobile number" : "Emil ID"
              } is required`,
              pattern: {
                value:
                  selectedTab === "mobile"
                    ? /^[6-9]\d{9}$/
                    : /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: `Invalid ${
                  selectedTab === "mobile" ? "Mobile number" : "Email ID"
                }`,
              },
            })}
            error={errors?.mobile_or_email}
            helperText={
              errors?.mobile_or_email && errors.mobile_or_email.message
            }
            // value={mobileNumber}
            // onChange={(e) => setMobileNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMobileOrEmailDialog}>Cancel</Button>
          <Button type="submit">Send OTP</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

// Child Component
const OtpDialog = (props) =>{
  const {openOtpDialog , handleCloseOtpDialog , selectedTab , handleOtpSubmit , LoaderOfOtpVerify} = props
  const {
    formState: { errors, isSubmitted },
    register,
    setValue,
    handleSubmit,
    trigger,
    reset,
  } = useForm();
  return (
    <Dialog open={openOtpDialog} onClose={handleCloseOtpDialog}>
      {/* <DialogTitle>Enter OTP</DialogTitle> */}
      <form onSubmit={handleSubmit(handleOtpSubmit)}>
        <DialogContent>
          <DialogContentText>
            Please enter the OTP sent to your
            {selectedTab === "mobile" ? "Mobile number" : "Email"}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="OTP"
            type="text"
            fullWidth
            {...register("otp", {
              required: `Otp is required`,
              pattern: {
                value: /^\d{4}$/,
                message: `Invalid Otp`,
              },
            })}
            error={errors?.otp}
            helperText={errors?.otp && errors.otp.message}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOtpDialog}>Cancel</Button>
          <Button type="submit" disabled={LoaderOfOtpVerify}>
            {LoaderOfOtpVerify ? <CircularProgress size={20} /> : "Submit"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}


// Main Component
const PersonalDetails = ({ profileData, updateCustomerDetails}) => {
  const dispatch = useDispatch();
  const {
    formState: { errors, isSubmitted },
    register,
    setValue,
    handleSubmit,
    trigger,
  } = useForm();
  const [selectedTab, setSelectedTab] = useState("mobile");
  const [openMobileOrEmailDialog, setOpenMobileOrEmailDialog] = useState(false);
  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [mobileNumberOrEmail, setMobileNumberOrEmail] = useState("");
  const [alternateMobileNumbers , setAlternateMobileNumbers] = useState([])
  const [alternateEmails , setAlternateEmails] = useState([])
  const [LoaderOfOtpVerify , setLoaderOfOtpVerify] = useState(false)
  const [LoaderOfProfile , setLoaderOfProfile] = useState(false)
  const [LoaderOfMobileOrEmailVerify, setLoaderOfMobileOrEmailVerify] = useState(false);
  const [deleteLoader , setDeleteLoader] = useState(false)
 
  useEffect(() => {
    setValue("name", profileData?.customer_name);
    const mapTheGender = (value) => {
      if (value === "MALE" || value === "M") {
        return "M";
      } else if (value === "FEMALE" || value === "F") {
        return "F";
      }
      return null;
    };
    setValue("gender", mapTheGender(profileData?.gender));
    setValue("mobile", profileData?.phone);
    setValue("email", profileData?.email);
    setValue("dateOfBirth", profileData?.dob);
    setValue("marital", profileData?.marital_status);
    setValue("annualIncome", profileData?.annual_income);
    setValue("city", profileData?.city);
    setAlternateMobileNumbers(profileData?.alternate_numbers || []);
    setAlternateEmails(profileData?.alternate_emails || []);
  }, [profileData]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    {
      isSubmitted && (await trigger(name));
    }
  };

  const submitProfileDetails = async(data) => {
    const mapObject = {
      name: data?.name,
      gender: data?.gender,
      mobile: data?.mobile,
      email: data?.email,
      dateOfBirth: data?.dateOfBirth,
      marital: data?.marital,
      annual_income: data?.annualIncome,
      city: data?.city,
      family_data: JSON.stringify(profileData?.family_data),
      assets: JSON.stringify(profileData?.assets),
      exit_policies: JSON.stringify(profileData?.exit_policies),
      alternate_numbers: JSON.stringify(alternateMobileNumbers),
      alternate_emails: JSON.stringify(alternateEmails),
    };
    setLoaderOfProfile(true)
    await updateCustomerDetails(mapObject)
    setLoaderOfProfile(false)
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleOpenMobileOrEmailDialog = () => {
    setOpenMobileOrEmailDialog(true);
  };

  const handleCloseMobileOrEmailDialog = () => {
    setOpenMobileOrEmailDialog(false);
  };

  const handleOpenOtpDialog = () => {
    setOpenMobileOrEmailDialog(false); // Close the mobile dialog
    setOpenOtpDialog(true); // Open the OTP dialog
  };

  const handleCloseOtpDialog = () => {
    setOpenOtpDialog(false);
  };

  const handleMobileAndEmailSubmit = (data) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
  
    let filterValue;
    if(selectedTab === 'mobile'){
      filterValue = alternateMobileNumbers?.filter((eachValue)=> eachValue === data?.mobile_or_email)
    }else{
      filterValue = alternateEmails?.filter((eachValue)=> eachValue === data?.mobile_or_email)
    }
    
    if (filterValue?.length > 0 || profileData?.phone === data?.mobile_or_email || profileData?.email === data?.mobile_or_email) {
        dispatch(setError(`This ${selectedTab === 'mobile' ? 'Number' : 'Email'} is already exist`));
    }else{
    setMobileNumberOrEmail(data?.mobile_or_email);
    handleOpenOtpDialog();
    }
    
  };

  const handleOtpSubmit = async(otp) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    setLoaderOfOtpVerify(true)

    const mapObject = {
      ...profileData,
      family_data: JSON.stringify(profileData?.family_data),
      assets: JSON.stringify(profileData?.assets),
      exit_policies: JSON.stringify(profileData?.exit_policies),
      alternate_numbers: JSON.stringify(selectedTab === 'mobile' ? [...alternateMobileNumbers , mobileNumberOrEmail] : alternateMobileNumbers ),
      alternate_emails: JSON.stringify(selectedTab !== 'mobile' ? [...alternateEmails , mobileNumberOrEmail] : alternateEmails ),
    };

    if (otp?.otp == 1111) {
      await updateCustomerDetails(mapObject)
      handleCloseOtpDialog();
    } else {
      dispatch(setError("Otp Miss Match"));
    }
    setLoaderOfOtpVerify(false)
  };

  const deleteNumber = async(number) =>{
    setDeleteLoader(true)
     const mapObject = {
      ...profileData,
      family_data: JSON.stringify(profileData?.family_data),
      assets: JSON.stringify(profileData?.assets),
      exit_policies: JSON.stringify(profileData?.exit_policies),
      alternate_emails: JSON.stringify(alternateEmails),
      alternate_numbers: JSON.stringify(alternateMobileNumbers.filter((eachValue)=> eachValue !== number)),
     };
     await updateCustomerDetails(mapObject)
     setDeleteLoader(false);
  }

  const deleteEmail = async(email)=>{
    setDeleteLoader(true);
    const mapObject = {
      ...profileData,
      family_data: JSON.stringify(profileData?.family_data),
      assets: JSON.stringify(profileData?.assets),
      exit_policies: JSON.stringify(profileData?.exit_policies),
      alternate_numbers: JSON.stringify(alternateMobileNumbers),
      alternate_emails: JSON.stringify(
        alternateEmails.filter((eachValue) => eachValue !== email)
      ),
    };
    await updateCustomerDetails(mapObject);
    setDeleteLoader(false);
  };

  return (
    <Box>
      <Box sx={{ padding: 2 }}>
        {/* heading */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <PersonPinIcon sx={{ fontSize: "2rem", color: "#0000ff", mr: 1 }} />
          <Typography variant="h4">Personal Details</Typography>
        </Box>
        <form onSubmit={handleSubmit(submitProfileDetails)}>
          <Grid
            container
            spacing={2}
            display="flex"
            justifyContent="space-between"
          >
            {/* name */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                defaultValue={profileData?.customer_name}
                fullWidth
                label="Full Name"
                variant="outlined"
                name="fullName"
                {...register("name", {
                  required: "Please Enter Your Name",
                  pattern: {
                    value: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,
                    message: "Invalid First Name",
                  },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                // value={formData.fullName}
                // onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
              />
            </Grid>

            {/* gender */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                name="gender"
                fullWidth
                label="Select Gender"
                select
                defaultValue={profileData?.gender}
                color="primary"
                {...register("gender", {
                  required: "Please Select gender",
                })}
                // value={formData.gender}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                error={!!errors.gender}
                helperText={errors.gender ? errors.gender.message : ""}
              >
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
              </TextField>
            </Grid>

            {/* number */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                label="Mobile Number"
                variant="outlined"
                name="mobileNumber"
                defaultValue={profileData?.phone}
                // value={formData.mobileNumber}
                {...register("mobile", {
                  required: "Please Enter Mobile Number",
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: "Invalid mobile Number",
                  },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 10, // Restricts the maximum length of input
                }}
                disabled
                // onChange={handleChange}
                error={!!errors.mobile}
                helperText={errors.mobile ? errors.mobile.message : ""}
              />
            </Grid>

            {/* email */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                label="Email ID"
                variant="outlined"
                name="email"
                defaultValue={profileData?.email}
                // value={formData.email}
                {...register("email", {
                  required: "Please Enter Email",
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    message: "Invalid email",
                  },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            </Grid>

            {/* dob */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                type="date"
                fullWidth
                label="Date of Birth"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                name="dateOfBirth"
                defaultValue={profileData.dob}
                // value={formData.dateOfBirth}
                {...register("dateOfBirth", {
                  required: "Please Select date of birth",
                })}
                // onChange={handleChange}
                error={!!errors.dateOfBirth}
                helperText={
                  errors.dateOfBirth ? errors.dateOfBirth.message : ""
                }
              />
            </Grid>

            {/* marital status */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                label="Marital status"
                select
                color="primary"
                name="marital"
                defaultValue={profileData?.marital_status}
                // value={formData.marital}
                {...register("marital", {
                  required: "Please Select marital status",
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                error={!!errors.marital}
                helperText={errors.marital ? errors.marital.message : ""}
              >
                <MenuItem value="Y">Married</MenuItem>
                <MenuItem value="N">Single</MenuItem>
              </TextField>
            </Grid>

            {/* annual income */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                label="Annual Income"
                select
                color="primary"
                name="annualIncome"
                defaultValue={profileData?.annual_income}
                // value={formData.annualIncome}
                {...register("annualIncome", {
                  required: "Please Select Annual Income",
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                error={!!errors.annualIncome}
                helperText={
                  errors.annualIncome ? errors.annualIncome.message : ""
                }
              >
                <MenuItem value="upto 3 lakhs">Upto 3 lakhs</MenuItem>
                <MenuItem value="3-6 lakhs">3-6 lakhs</MenuItem>
                <MenuItem value="above 6 lakhs">Above 6 lakhs</MenuItem>
              </TextField>
            </Grid>

            {/* city */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                label="City"
                variant="outlined"
                name="city"
                // value={formData.city}
                defaultValue={profileData?.city}
                {...register("city", {
                  required: "Please Enter City",
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.city}
                helperText={errors.city ? errors.city.message : ""}
                // onChange={handleChange}
              />
            </Grid>
          </Grid>

          {/* save btn */}
          <Box sx={{ marginTop: 3, display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={LoaderOfProfile}
            >
              {LoaderOfProfile ? <CircularProgress size={20} /> : "Save"}
            </Button>
          </Box>
        </form>
      </Box>

      {/* contact details */}
      <Paper sx={{ padding: "20px", borderRadius: "8px", bgcolor: "#f5f8fd" }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <PhoneIcon sx={{ fontSize: "2rem", color: "#0000ff", mr: 1 }} />
          <Typography variant="h4">Contact details</Typography>
        </Box>

        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          disabled={deleteLoader}
          textColor="primary"
          indicatorColor="primary"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tab
            value="mobile"
            label={
              <Badge badgeContent={alternateMobileNumbers?.length + (profileData?.phone && 1)} color="secondary">
                Mobile Numbers
              </Badge>
            }
          />
          <Tab
            value="email"
            label={
              <Badge badgeContent={alternateEmails?.length + (profileData?.email && 1)} color="secondary">
                Email address
              </Badge>
            }
          />
        </Tabs>

        {deleteLoader && (
          <Box width={"100%"} marginTop={2}>
            <LinearProgress />
          </Box>
        )}

        {selectedTab === "mobile" && (
          <>
            {profileData?.phone !== null && <MobileNumbersCard number={profileData?.phone} type={"Primary"} />}
            {alternateMobileNumbers?.map((eachValue, i) => (
              <MobileNumbersCard
                key={i}
                number={eachValue}
                type={"Secondary"}
                deleteNumber={deleteNumber}
                deleteLoader={deleteLoader}
              />
            ))}
          </>
        )}

        {selectedTab === "email" && (
          <>
            {profileData?.email !== null && <EmailCard email={profileData?.email} type={"Primary"} />}
            {alternateEmails?.map((eachValue, i) => (
              <EmailCard
                key={i}
                email={eachValue}
                type={"Secondary"}
                deleteLoader={deleteLoader}
                deleteEmail={deleteEmail}
              />
            ))}
          </>
        )}

        {/* * add phone or email btn */}
        <Box textAlign="center" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              bgcolor: "#007bff",
              color: "#fff",
            }}
            onClick={handleOpenMobileOrEmailDialog}
          >
            <AddIcon sx={{ mr: 1 }} />{" "}
            {selectedTab === "mobile" ? "Add Phone" : "Add Email"}
          </Button>
        </Box>
      </Paper>

      {/* Mobile Number or email Dialog */}
      {openMobileOrEmailDialog && (
        <MobileAndEmailDialog
          openMobileOrEmailDialog={openMobileOrEmailDialog}
          handleCloseMobileOrEmailDialog={handleCloseMobileOrEmailDialog}
          selectedTab={selectedTab}
          handleMobileAndEmailSubmit={handleMobileAndEmailSubmit}
        />
      )}

      {/* OTP Dialog */}
      {openOtpDialog && (
        <OtpDialog
          handleOtpSubmit={handleOtpSubmit}
          openOtpDialog={openOtpDialog}
          handleCloseOtpDialog={handleCloseOtpDialog}
          selectedTab={selectedTab}
          LoaderOfOtpVerify={LoaderOfOtpVerify}
        />
      )}
    </Box>
  );
};

export default PersonalDetails;
