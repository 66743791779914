import React from "react";
import { Typography, Container, List, ListItem, ListItemText } from "@mui/material";

const styles = {
  container: {
    marginTop: "32px",
    marginBottom: "32px",
    padding: "20px 30px",
  },
  heading: {
    marginBottom: "2px",
    color: "#23a8fa",
    fontWeight: "bold",

  },
  subHeading: {
    marginTop: "2px",
    fontWeight: "bold",
    color: "#555",

  },
  listItem: {
    marginBottom: "2px",
    backgroundColor: "#f5f5f5",
    // padding: "2px",
    borderRadius: "4px",
    textAlign:'justify',
    padding:'0px'
  },
};

const HealthInsuranceForParents = () => {
  return (
    <Container style={styles.container}>
      <Typography variant="h2" textAlign='center'   style={styles.heading} gutterBottom>
        Health Insurance for Parents
      </Typography>
      <Typography variant="body1" paragraph>
        Health insurance for parents is a type of insurance policy that provides financial coverage for the medical expenses
        of an individual's parents. This type of insurance policy is designed to help individuals provide for the medical
        needs of their aging parents, who may require frequent or expensive medical attention. The policy covers the cost
        of hospitalization, doctor's visits, and other medical expenses, offering peace of mind and financial security to
        the policyholder and their parents. The policy can be taken as an individual or a family policy, and the coverage,
        premium, and terms and conditions may vary based on the insurance company and the policy selected.
      </Typography>

      <Typography variant="h4" style={styles.heading} >
        Why do you Need a Health Insurance Cover for your Parents?
      </Typography>

      <Typography variant="body1" paragraph>
        Health insurance is an essential aspect of financial planning, especially when it comes to ensuring the well-being
        of our loved ones, including our parents. As our parents age, their health becomes more vulnerable, and the chances
        of them requiring medical attention increase. In such situations, having a health insurance cover for parents can
        provide peace of mind and financial security.
      </Typography>

      <Typography variant="h4" style={styles.heading} >
        Here are some of the reasons why you may need a health insurance cover for your parents:
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Rising Medical Costs:</Typography>}
            secondary={
              <Typography variant="body1">
                Medical expenses have been continuously increasing, making it difficult for families to bear the costs of
                unexpected medical emergencies or hospitalization. A health insurance cover for your parents can help
                cover the cost of medical treatments, hospitalization, and other related expenses.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Aging-related health issues</Typography>}
            secondary={
              <Typography variant="body1">
                As people age, they become more susceptible to health issues such as high blood pressure, diabetes, heart
                disease, and other chronic conditions. These medical conditions may require frequent or long-term
                treatment, and a health insurance cover can help you bear the cost.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>No burden on your finances</Typography>}
            secondary={
              <Typography variant="body1">
                Having a health insurance cover for your parents can help you avoid dipping into your savings or taking on
                debt to pay for their medical expenses. This can be particularly beneficial if you have other financial
                obligations, such as a mortgage or children's education.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Peace of mind</Typography>}
            secondary={
              <Typography variant="body1">
                Knowing that your parents are financially protected in case of a medical emergency can provide peace of
                mind and reduce the stress and worry that often accompany such situations.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Avoids depletion of savings</Typography>}
            secondary={
              <Typography variant="body1">
                Without a health insurance cover, your parents may have to rely on their savings to pay for medical
                expenses. This could deplete their savings and leave them vulnerable in their golden years.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Customizable coverage</Typography>}
            secondary={
              <Typography variant="body1">
                Health insurance covers for parents can be customized to suit their specific needs and requirements. For
                example, you can opt for a cover that includes pre-existing medical conditions, or one that provides
                coverage for specific treatments or procedures.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Tax Benefits</Typography>}
            secondary={
              <Typography variant="body1">
                The premiums that you pay under the Health insurance for your parents are eligible for tax exemption under
                section 80 D. If your parents are below the age of 60 years, you can gain a total tax benefit of Rs 50,000
                on the premium. Similarly, if your parents are above 60 years of age then the limit goes up to Rs 75,000.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading} gutterBottom>
        What is Covered Under a Health Insurance Policy for your Parents?
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Hospitalization expenses</Typography>}
            secondary={
              <Typography variant="body1">
                This covers the cost of room rent, doctor's fees, nursing expenses, and other hospital-related expenses incurred during hospitalization.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Pre and post-hospitalization expenses</Typography>}
            secondary={
              <Typography variant="body1">
                This covers the cost of medical treatments and tests before and after hospitalization.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Daycare procedures</Typography>}
            secondary={
              <Typography variant="body1">
                This covers the cost of medical procedures that do not require an overnight stay in the hospital, such as cataract surgery, chemotherapy, dialysis, etc.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Ambulance cover</Typography>}
            secondary={
              <Typography variant="body1">
                This covers the cost of ambulance services used during the hospitalization of the insured individual.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Critical Illness Cover</Typography>}
            secondary={
              <Typography variant="body1">
                This covers the cost of treatment for specified critical illnesses, such as cancer, heart attack, kidney failure, etc.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Domiciliary treatment</Typography>}
            secondary={
              <Typography variant="body1">
                This covers the cost of medical treatment provided at home for illnesses that cannot be treated at a hospital.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>No Claim Bonus</Typography>}
            secondary={
              <Typography variant="body1">
                This is a bonus provided to the policyholder for each claim-free year, increasing the sum insured.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        What is Not Covered in a Health Insurance for your Parents?
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Pre-existing conditions:</Typography>}
            secondary={
              <Typography variant="body1">
                Certain medical conditions that existed before taking out the insurance policy may not be covered.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Cosmetic surgeries:</Typography>}
            secondary={
              <Typography variant="body1">
                Procedures for cosmetic purposes, such as facelifts or tummy tucks, are generally not covered by health insurance.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Alternative treatments:</Typography>}
            secondary={
              <Typography variant="body1">
                Treatments such as acupuncture, chiropractic care, or homeopathic medicine may not be covered by some health insurance policies.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Experimental treatments:</Typography>}
            secondary={
              <Typography variant="body1">
                Treatments that have not been fully tested or approved by regulatory bodies, such as experimental drugs or procedures, are typically not covered.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Dental or vision care:</Typography>}
            secondary={
              <Typography variant="body1">
                Dental and vision care may not be covered under basic health insurance policies and may require a separate policy or riders.
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Typography variant="h4" style={styles.heading}>
        How to Choose Health Insurance for your Parents
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Determine their specific needs</Typography>}
            secondary={
              <Typography variant="body1">
                Consider your parents' current and future health conditions, medications, and the type of care they may need.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Evaluate coverage options</Typography>}
            secondary={
              <Typography variant="body1">
                Compare plans from different insurance providers, including their costs, benefits, and exclusions.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Consider cost-sharing options</Typography>}
            secondary={
              <Typography variant="body1">
                Look at the premiums, deductibles, co-payments, and co-insurance amounts to see what works best for your budget.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Check for network providers</Typography>}
            secondary={
              <Typography variant="body1">
                Make sure the plan covers the doctors and hospitals that your parents prefer or need to see.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Check for any restrictions</Typography>}
            secondary={
              <Typography variant="body1">
                Ensure the plan does not have any restrictions, such as prior authorization or referrals for certain services.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Consider additional benefits</Typography>}
            secondary={
              <Typography variant="body1">
                Look for plans that offer extra benefits, such as vision or dental coverage, that may be important to your parents.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Read the fine print</Typography>}
            secondary={
              <Typography variant="body1">
                Carefully review the plan documents to understand the details and exclusions of the coverage.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Seek advice</Typography>}
            secondary={
              <Typography variant="body1">
                Consult a healthcare professional, insurance agent, or financial advisor if you need additional help in making this decision.
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Typography variant="h4" style={styles.heading}>
        FAQs
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Which health insurance strategy is best for my parents?</Typography>}
            secondary={
              <Typography variant="body1">
                The ideal health insurance strategy for your parents will depend on their individual medical requirements, financial situation, and preferences. The type of coverage they require, their age, their current and projected health conditions, and the premium cost they can afford should all be taken into account.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>If my parents are over 60, can I purchase health insurance for them?</Typography>}
            secondary={
              <Typography variant="body1">
                Yes, even if your parents are older than 60, you can still purchase health insurance for them. However, some insurance policies have age restrictions, so before choosing, be sure to review the terms and conditions of the policy.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>If my parents have pre-existing conditions, can I still purchase health insurance for them?</Typography>}
            secondary={
              <Typography variant="body1">
                Yes, you can purchase health insurance for your parents despite any pre-existing conditions they may have. Check to see if there are any waiting periods before coverage starts and if the insurance plan covers pre-existing conditions.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Can I purchase a health insurance plan for my family that includes my parents as well?</Typography>}
            secondary={
              <Typography variant="body1">
                You can, in fact, purchase a family health insurance plan that includes both your parents and your own family. All family members, including your parents, are covered by this kind of policy under a single contract.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>How much does my parents' health insurance cost?</Typography>}
            secondary={
              <Typography variant="body1">
                Your parents' health insurance premiums will vary depending on their age, current health status, required level of coverage, and financial ability to pay them. By comparing different insurance plans and their premium costs, you can get an idea of the price.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>How can I compare my parents' health insurance options?</Typography>}
            secondary={
              <Typography variant="body1">
                When comparing health insurance policies for your parents, take into account things like the premium cost, deductible, co-payments, provider network, and pre-existing condition coverage. Additionally, you can compare the plans provided by various insurance companies to locate the best protection at a reasonable cost.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Are there any tax advantages for parents who have health insurance?</Typography>}
            secondary={
              <Typography variant="body1">
                Yes, there are times when you may qualify for a tax credit to offset the cost of your parents' health insurance. The specific tax advantages that are available will vary depending on your unique situation and the kind of health insurance you select.
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Container>
  );
};



export default HealthInsuranceForParents;
