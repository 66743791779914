import * as React from "react";
import { LineChart } from "@mui/x-charts";
import { useForm, Controller } from "react-hook-form";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import {
  DirectionsCar as MotorIcon,
  LocalHospital as HealthIcon,
  CreditCard as LoanIcon,
  AirplanemodeActive as TravelIcon,
  Favorite as LifeIcon,
  CurrencyRupee as TotalIcon,
} from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { setError as setErrorMessage } from "../../../Redux/action-creators/errorAction";
import { useDispatch } from "react-redux";

export default function BusinessManagement() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      frequency: "",
      category: "ALL",
      provider: "ALL",
      fromDate: "",
      endDate: "",
    },
  });
  const dispatch = useDispatch();
  const [premiumData, setPremiumData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    totalsales: [],
    totalsalesbyproduct: [],
  });
  const [graphSales, setGraphSales] = useState({
    data: [],
    labels: [],
  });

  const getSalesGraph = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/business/report`
      );    
      const data = res.data;

      // Handle Weekly Data (ensure all 5 weeks: Week 1, Week 2, Week 3, Week 4, Week 5)
      const allWeeks = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];

      // Map the data to include sales and corresponding color for each week
      const weeklySalesData = allWeeks.map((week, index) => {
        const weekData = data.find(
          (item) => parseInt(item.week_no_in_month) === index + 1
        ); // Match week number (convert to integer)

        // If no data for week, set sales to 0 and pick corresponding color
        const sales = weekData ? parseInt(weekData.weekly_sales) : 0;
        return {
          sales, // Weekly sales
          label: week, // Week label
        };
      });

      // Update the state with the data
      setGraphSales({
        data: weeklySalesData.map((item) => item.sales), // Extract only sales data for the graph
        labels: allWeeks,
      });
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
    } finally {
      setLoading(false);
    }
  };

  const allProductTypes = [
    { product_type: "MOTOR", icon: <AirportShuttleIcon />, color: "#FF5722" },
    { product_type: "HEALTH", icon: <HealthAndSafetyIcon />, color: "#4CAF50" },
    { product_type: "LOAN", icon: <CreditCardIcon />, color: "#2196F3" },
    { product_type: "TRAVEL", icon: <FlightTakeoffIcon />, color: "#FF9800" },
    { product_type: "LIFE", icon: <AccessibilityNewIcon />, color: "#9C27B0" },
  ];

  const Premium = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/channelrevenue`
      );
      setPremiumData(res.data);
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
    } finally {
      setLoading(false);
    }
  };

  // Function to merge API data with default values
  const getMergedProductData = (apiData) => {
    const mergedData = allProductTypes.map((product) => {
      // Find the product from API data or use default count 0
      const apiProduct = apiData.totalsalesbyproduct.find(
        (item) => item.product_type === product.product_type
      );
      return {
        product_type: product.product_type,
        icon: product.icon,
        color: product.color,
        count: apiProduct ? apiProduct.count : "0", // Use 0 if the product is missing
      };
    });
    return mergedData;
  };

  // Inside the useEffect where you fetch the API data
  const SalesCount = async () => {
    setLoading(true);
    try {
      const apiData = await axios.get(
        `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/business/sales`
      );
      // Merge the API data with default values
      const transformedData = {
        totalsales: apiData.data.totalsales,
        totalsalesbyproduct: getMergedProductData(apiData.data),
      };

      setData(transformedData); // Set the merged data into state
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Premium();
    SalesCount();
    getSalesGraph();
  }, []);

  const colorMap = {
    MOTOR: "#FF5722", // Orange
    HEALTH: "#4CAF50", // Green
    LOAN: "#2196F3", // Blue
    TRAVEL: "#FF9800", // Amber
    LIFE: "#9C27B0", // Purple
    TOTAL: "#3F51B5", // Indigo (for Total Sales)
  };

  const providers = [
    "ALL",
    "Acko General Insurance Limited",
    "Agriculture Insurance Company of India Limited",
    "Bajaj Allianz General Insurance Company Limited",
    "Cholamandalam MS General Insurance Company Limited",
    "Go Digit General Insurance Limited",
    "Zurich Kotak General Insurance Company (India) Limited",
    "Kshema General Insurance Limited",
    "Liberty General Insurance Limited",
    "Magma HDI General Insurance Company Limited",
    "National Insurance Company Limited",
    "Navi General Insurance Limited",
    "Raheja QBE General Insurance Co. Ltd.",
    "Reliance General Insurance Company Limited",
    "Royal Sundaram General Insurance Company Limited",
    "SBI General Insurance Company Limited",
    "Shriram General Insurance Company Limited",
    "Tata AIG General Insurance Company Limited",
    "United India Insurance Company Limited",
    "Universal Sompo General Insurance Company Limited",
    "Zuno General Insurance Ltd.",
    "Future Generali India Insurance Company Limited",
    "The Oriental Insurance company Limited",
    "The New India Assurance Company Limited",
    "IFFCO TOKIO General Insurance company Limited",
    "HDFC ERGO General Insurance Company Limited",
    "ICICI LOMBARD General Insurance Company Limited",
    "Aditya Birla Health Insurance Company Limited",
    "Manipal Cigna Health Insurance Company Limited",
    "Niva Bupa Health Insurance Company Limited",
    "Care Health Insurance Company Limited",
    "Star Health & Allied Insurance Company Limited",
  ];

  const PremiumRow = ({ icon: Icon, color, label, value }) => (
    <TableRow>
      <TableCell align="center">
        <Icon sx={{ color }} />
      </TableCell>
      <TableCell sx={{ fontWeight: "bold", fontSize: 13, color }}>
        {label}
      </TableCell>
      <TableCell sx={{ fontWeight: "bold", fontSize: 13, color }}>
        {value}
      </TableCell>
    </TableRow>
  );

  const EndDate = watch("endDate");
  const FromDate = watch("fromDate");
  const Frequency = watch("frequency");

  // This function will be called when the form is submitted
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/business/report`,
        data
      );

      // Check if there's no data in the response
      if (!res.data || res.data.length === 0) {
        // Set a message when no data is available
        setGraphSales({
          data: [],
          labels: [],
          message: "No data available for the selected frequency.",
        });
        return;
      }

      if (data.frequency === "WEEKLY") {
        const allWeeks = Array.from({ length: 5 }, (_, i) => `Week ${i + 1}`);
        const weeklySalesData = allWeeks.map((week) => {
          const weekData = res.data.find(
            (item) =>
              parseInt(item.week_no_in_month) === parseInt(week.split(" ")[1])
          );
          return weekData ? parseInt(weekData.weekly_sales) : 0;
        });

        setGraphSales({
          data: weeklySalesData,
          labels: allWeeks,
          message: "", // Clear the message if data is available
        });
      } else if (data.frequency === "MONTHLY") {
        const allMonths = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        const monthlySalesData = allMonths.map((month, i) => {
          const monthData = res.data.find(
            (item) => parseInt(item.month) === i + 1
          );
          return monthData ? parseInt(monthData.sales) : 0;
        });

        setGraphSales({
          data: monthlySalesData,
          labels: allMonths,
          message: "", // Clear the message if data is available
        });
      } else if (data.frequency === "YEARLY") {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 4 }, (_, i) => currentYear - i);

        const yearlySalesData = years.map((year) => {
          const yearData = res.data.find(
            (item) => item.year === year.toString()
          );
          return yearData ? parseInt(yearData.sales) : 0;
        });

        setGraphSales({
          data: yearlySalesData,
          labels: years.map((year) => year.toString()),
          message: "", // Clear the message if data is available
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setGraphSales({
        data: [],
        labels: [],
        message: "Error fetching data. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  const colorArray = [
    "#3F51B5", // Week 1
    "#2196F3", // Week 2
    "#FFC107", // Week 3
    "#FF9800", // Week 4
    "#FF5722", // Week 5
    "#795548", // Year 1
    "#9E9E9E", // Year 2
    "#00BCD4", // Year 3
    "#8BC34A", // Year 4
    "#FFEB3B", // Year 5
    "#E91E63", // Month 1 (optional, if needed)
    "#9C27B0", // Month 2 (optional, if needed)
  ];

  const colorMaps = (index) => {
    if (index < 5) {
      // For the first 5 data points (Weeks)
      return colorArray[index];
    } else if (index >= 5 && index < 10) {
      // For the next 5 data points (Years)
      return colorArray[index];
    } else {
      // For additional data points (Months or other categories)
      return colorArray[index] || "#000000"; // Default color if out of bounds
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress
          size={50}
          style={{ position: "absolute", top: "50%", left: "50%" }}
        />
      ) : (
        <Grid container justifyContent="end" mt={2} rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" pb={3}>
              Sales Graph
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={2}
                justifyContent="space-evenly"
                alignItems="center"
              >
                {/* From Date Field */}
                {Frequency === "" && (
                  <Grid item xs={6} md={3} lg={2}>
                    <Controller
                      name="fromDate"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="date"
                          label="From Date"
                          variant="outlined"
                          fullWidth
                          error={!!errors.fromDate}
                          helperText={
                            errors.fromDate ? "This field is required" : ""
                          }
                          InputLabelProps={{
                            shrink: true, // Ensures the label is above the field for date inputs
                          }}
                        />
                      )}
                      rules={{ required: "From Date is required" }} // Validation rule
                    />
                  </Grid>
                )}

                {/* End Date Field */}
                {Frequency === "" && (
                  <Grid item xs={6} md={3} lg={2}>
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="date"
                          label="End Date"
                          variant="outlined"
                          fullWidth
                          error={!!errors.endDate}
                          helperText={
                            errors.endDate ? "This field is required" : ""
                          }
                          InputLabelProps={{
                            shrink: true, // Ensures the label is above the field for date inputs
                          }}
                        />
                      )}
                      rules={{ required: "End Date is required" }} // Validation rule
                    />
                  </Grid>
                )}

                {/* Frequency Field */}
                {(FromDate === "" || EndDate === "") && (
                  <Grid item xs={6} md={3} lg={2}>
                    <Controller
                      name="frequency"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          label="Frequency"
                          variant="outlined"
                          fullWidth
                          error={!!errors.frequency}
                          helperText={
                            errors.frequency ? "This field is required" : ""
                          }
                        >
                          <MenuItem value="" disabled>
                            Select Frequency
                          </MenuItem>
                          <MenuItem value="WEEKLY">WEEKLY</MenuItem>
                          <MenuItem value="MONTHLY">MONTHLY</MenuItem>
                          <MenuItem value="YEARLY">YEARLY</MenuItem>
                        </TextField>
                      )}
                      rules={{ required: "Frequency is required" }} // Validation rule
                    />
                  </Grid>
                )}

                {/* Category Field */}
                <Grid item xs={6} md={3} lg={2}>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Category"
                        variant="outlined"
                        fullWidth
                        error={!!errors.category}
                        helperText={
                          errors.category ? "This field is required" : ""
                        }
                      >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="MOTOR">MOTOR</MenuItem>
                        <MenuItem value="HEALTH">HEALTH</MenuItem>
                        <MenuItem value="TRAVEL">TRAVEL</MenuItem>
                        <MenuItem value="LOAN">LOAN</MenuItem>
                        <MenuItem value="LIFE">LIFE</MenuItem>
                      </TextField>
                    )}
                    rules={{ required: "Category is required" }} // Validation rule
                  />
                </Grid>

                {/* Provider Field */}
                <Grid item xs={6} md={3} lg={2}>
                  <Controller
                    name="provider"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Provider"
                        variant="outlined"
                        fullWidth
                        error={!!errors.provider}
                        helperText={
                          errors.provider ? "This field is required" : ""
                        }
                      >
                        {providers.map((provider) => (
                          <MenuItem key={provider} value={provider}>
                            {provider}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    rules={{ required: "Provider is required" }} // Validation rule
                  />
                </Grid>

                {/* Submit Button */}
                <Grid item xs={6} md={3} lg={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    endIcon={<FilterAltIcon />}
                  >
                    Apply Filters
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12}>
            <LineChart
              sx={{ width: "100%" }}
              height={300}
              series={[
                {
                  data: graphSales.data || [], // Sales data
                  label: "Sales",
                  id: "salesId",
                  color: graphSales.data.map((_, index) => colorMaps(index)), // Apply colors dynamically
                },
              ]}
              xAxis={[{ data: graphSales.labels || [], scaleType: "band" }]} // Week labels
              yAxis={[{ scaleType: "linear" }]} // Ensures proper scaling for sales data
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ flexGrow: 1, p: 2 }}>
              <Typography variant="h3" pb={3}>
                Number Of Sales
              </Typography>
              <Grid container mt={2} rowSpacing={2} columnSpacing={1}>
                {/* Total Sales Card */}
                <Grid item xs={12} sm={6} md={2}>
                  <Card sx={{ backgroundColor: "#3F51B5" }}>
                    <CardContent>
                      <Grid container alignItems="center">
                        {/* Total Sales Icon */}
                        <Grid item xs={3}>
                          <Box sx={{ mr: 2, fontSize: 40, color: "#fff" }}>
                            <TotalIcon />
                          </Box>
                        </Grid>
                        {/* Total Sales */}
                        <Grid item xs={9}>
                          <Typography variant="h6" gutterBottom color="#fff">
                            TOTAL SALES
                          </Typography>
                          <Typography variant="h4" color="#fff">
                            {data?.totalsales || "0"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Product Cards */}
                {Array.isArray(data?.totalsalesbyproduct) &&
                data?.totalsalesbyproduct.length > 0 ? (
                  data.totalsalesbyproduct.map((item, index) => (
                    <Grid item xs={12} sm={6} md={2} key={index}>
                      <Card sx={{ backgroundColor: item.color }}>
                        <CardContent>
                          <Grid container alignItems="center">
                            {/* Icon */}
                            <Grid item>
                              <Box sx={{ mr: 2, fontSize: 40, color: "#fff" }}>
                                {item.icon}
                              </Box>
                            </Grid>
                            {/* Product Name and Sales Count */}
                            <Grid item>
                              <Typography
                                variant="h6"
                                gutterBottom
                                color="#fff"
                              >
                                {item.product_type}
                              </Typography>
                              <Typography variant="h4" color="#fff">
                                {item.count}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="h6" color="textSecondary">
                      No product sales data available.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3" pb={3}>
              Premiums According To Channels
            </Typography>
            <Grid container spacing={3}>
              {premiumData.map((channelData, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <Typography variant="h3" gutterBottom textAlign="center">
                        {channelData.channels}
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            {/* Render premium categories dynamically */}
                            <PremiumRow
                              icon={TotalIcon}
                              color={colorMap.TOTAL}
                              label="Total Premium"
                              value={channelData.total_premium}
                            />
                            <PremiumRow
                              icon={MotorIcon}
                              color={colorMap.MOTOR}
                              label="Motor Premium"
                              value={channelData.motor_premium}
                            />
                            <PremiumRow
                              icon={HealthIcon}
                              color={colorMap.HEALTH}
                              label="Health Premium"
                              value={channelData.health_premium}
                            />
                            <PremiumRow
                              icon={LoanIcon}
                              color={colorMap.LOAN}
                              label="Loan Premium"
                              value={channelData.loan_premium}
                            />
                            <PremiumRow
                              icon={TravelIcon}
                              color={colorMap.TRAVEL}
                              label="Travel Premium"
                              value={channelData.travel_premium}
                            />
                            <PremiumRow
                              icon={LifeIcon}
                              color={colorMap.LIFE}
                              label="Life Premium"
                              value={channelData.life_premium}
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
