import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import { BrowserRouter as Router } from "react-router-dom";
import './i18n'
// if (process.env.REACT_APP_NODE_ENV === 'production') {
//   console.log = () => { };
//   console.warn = () => { };
//   console.error = () => { };
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals1
