import * as types from "./ActionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  vehicleData: {},
  cityData: {},
  insurerNames: [],
  relation: {},
  ncbData: {},
  kycData: {},
  quotationData: {},
  vehicleMaster: {},
  smsResponse: {},
  smsVerificationResponse: {},
  pincodeData: {},
  agentData: {},
  usageType: [],
  loginData: {},
  // marketingData:{}
};

const motorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case types.Get_Data_Request:
      return {
        ...state,
        isLoading: true,
      };

    case types.Get_Data_Success:
      return {
        ...state,
        isLoading: false,
        isError: false,
        vehicleData: payload,
      };
    case types.clear_Vehicle_data :
      return{
        ...state,
        isLoading: false,
        isError: false,
        vehicleData: {}
      };

    case types.Get_City_Data_Success:
      return {
        ...state,
        isLoading: false,
        isError: false,
        cityData: payload,
      };

    case types.Get_Insurer_Name_Data_Success:
      return {
        ...state,
        isLoading: false,
        isError: false,
        insurerNames: payload,
      };

    case types.Get_Usage_Type_Success:
      return {
        ...state,
        isLoading: false,
        isError: false,
        usageType: payload,
      };

    case types.Get_Relation_Data_Success:
      return {
        ...state,
        isLoading: false,
        isError: false,
        relation: payload,
      };

    case types.Get_NCB_Data_Success:
      return {
        ...state,
        isLoading: false,
        isError: false,
        ncbData: payload,
      };

    case types.Get_Kyc_Data_Success:
      return {
        ...state,
        isLoading: false,
        isError: false,
        kycData: payload,
      };

    case types.Get_Quotation_Data:
      return {
        ...state,
        isLoading: false,
        isError: false,
        quotationData: payload,
      };

    case types.Vehicle_Master_Success_Request:
      return {
        ...state,
        isLoading: false,
        isError: false,
        vehicleMaster: payload,
      };

    case types.Mobile_OTP_Request:
      return {
        ...state,
        isLoading: false,
        isError: false,
        smsResponse: payload,
      };
    case types.Mobile_OTP_Verification_Request:
      return {
        ...state,
        isLoading: false,
        isError: false,
        smsVerificationResponse: payload,
      };
    case types.RTO_ADDRESS_SUCCESS_REQUEST:
      return {
        ...state,
        isLoading: false,
        isError: false,
        rto: payload,
      };

    case types.PINCODE_CITY_STATE_SUCCESS_REQUEST:
      return {
        ...state,
        isLoading: false,
        isError: false,
        pincodeData: payload,
      };

    case types.Agent_Data_Success_Request:
      return {
        ...state,
        isLoading: false,
        isError: false,
        pincodeData: payload,
      };

    case types.Get_Data_Error:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case types.Login_Success:
      return {
        ...state,
        isLoading: false,
        isError: false,
        loginData: payload,
      };
    // case types.Get_Marketing_Data_Request:
    //   return{
    //     ...state,
    //     isLoading:false,
    //     isError:false,
    //     marketingData:payload,
    //   }
    default:
      return state;
  }
};

export { motorReducer };
