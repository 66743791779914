import {
  Autocomplete,
  Box,
  Button,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { cardStyle, imgStyle, textBoxStyle } from "./RegisterClaimStyles";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  clearSuccessMessage,
  setSuccessMessage,
} from "../../../../Redux/action-creators/successAction";
import {
  clearError,
  setError,
} from "../../../../Redux/action-creators/errorAction";
import { useTranslation } from "react-i18next";
import {
  getAllPolicies,
  getLastClaimID,
  getPoliciesDataById,
  postClaimDetail,
} from "../../api/customerService";
import { gridSpacing } from "../../../../Redux/crmustomization/constant";
import MainCard from "../../../posp/ui-component/cards/MainCard";

const RegisterClaim = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state?.customer?.data);
  // const masterData = useSelector(state => state.masterData.list);
  const [policies, setPolicies] = useState([]);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    register,
    reset,
  } = useForm({
    defaultValues: {
      mobile_number: customerData?.phone,
    },
  });
  const [isPolicyLoaderTrue, setPolicyLoaderTrue] = useState(false);
  const [isLoaderTrue, setIsLoaderTrue] = useState(false);
  // const claim_type = watch('claim_type');

  useEffect(() => {
    getPolicies();
  }, []);

  const getPolicies = async () => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    setPolicyLoaderTrue(true);
    try {
      const response = await getPoliciesDataById(customerData?.customer_id);
      if (response.status > 400) {
        dispatch(setError(t("lmv.serverError")));
      } else {
        const filterPolicy = response?.data?.filter(
          (eachValue) => new Date(eachValue?.policy_enddate) >= new Date()
        );
        setPolicies(filterPolicy);
      }
    } catch (error) {
      dispatch(setError(t("lmv.serverError") + " " + error.toString()));
    } finally {
      setPolicyLoaderTrue(false);
    }
  };

  const generateNewClaimID = (oldID) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const date = currentDate.getDate();

    const claimId = oldID;
    const splitList = claimId.split("-");
    const incrementNumber = parseInt(splitList[splitList.length - 1]) + 1;
    const remainingZeros = "0".repeat(5 - incrementNumber.toString().length);
    const fullCustomerID =
      "CLAIM-" + year + month + date + "-" + remainingZeros + incrementNumber;
    return fullCustomerID;
  };

  const onSubmit = async (data) => {
    setIsLoaderTrue(true);
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const compareData = policies?.filter(
      (eachValue) =>
        eachValue?.policy_number === data?.policy_number &&
        eachValue?.product_type === data?.product_type
    );

    const payload = {
      register_id: "",
      customer_id: customerData?.customer_id,
      agent_id : customerData?.agent_id != '1000' ? customerData?.agent_id : null,
      product_type: data?.product_type,
      claim_amount: data?.claim_amount,
      policy_number: data?.policy_number,
      mobile_number: data?.mobile_number,
      claim_reason: data?.reason,
    };

    try {
      if (compareData?.length === 1) {
        let newClaimId;
        // get last claim ID
        const responseOfClaimId = await getLastClaimID();
        if (responseOfClaimId?.data?.length > 0) {
          newClaimId = generateNewClaimID(
            responseOfClaimId?.data[0]?.register_id
          );
        } else {
          newClaimId = generateNewClaimID("CLAIM-20241213-00000");
        }
        payload["register_id"] = newClaimId;

        // insert claim
        const response = await postClaimDetail(payload);

        if (response?.data?.status >= 400) {
          dispatch(setError(t(response?.data?.msg)));
        } else {
          reset();
          dispatch(setSuccessMessage(t(response?.data?.msg)));
        }
      } else {
        dispatch(setError(t("Product Type and Policy Number Mismatch")));
      }
    } catch (error) {
      dispatch(setError(t("lmv.serverError") + error.toString()));
    } finally {
      setIsLoaderTrue(false);
    }
  };

  return (
    <>
      <Box sx={{ marginBottom: "10px" }}>
        <MainCard content={false}>
          <CardContent>
            <Grid container spacing={gridSpacing}>
              {/* <Grid item xs={4}>
                        <Typography variant="h4">
                            {t('lmv.customer.claimPageHeading')}
                        </Typography>
                    </Grid> */}
              <Grid item>
                <Typography variant="h6">
                  {t("lmv.customer.claimPageSubHeading")}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="body1" gutterBottom>
                  {t("lmv.customer.claimPageSubHeading2")}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </MainCard>
      </Box>

      {/* <Grid container sx={{ px: 2 }} gap={1}>*/}

      <MainCard content={false} title={t("lmv.customer.claimPageHeading")}>
        <CardContent>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={gridSpacing}>
                  {/* Product Type */}
                  <Grid item xs={12} md={4}>
                    {/* <Typography variant="subtitle1">Product Type</Typography> */}
                    <Controller
                      name="product_type"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          label="Product Type"
                          error={!!errors.product_type}
                          helperText={errors.product_type?.message}
                          value={field.value || ""}
                        >
                          <MenuItem value="HEALTH">HEALTH</MenuItem>
                          <MenuItem value="MOTOR">MOTOR</MenuItem>
                          <MenuItem value="LIFE">LIFE</MenuItem>
                          <MenuItem value="TRAVEL">TRAVEL</MenuItem>
                          <MenuItem value="LOAN">LOAN</MenuItem>
                        </TextField>
                      )}
                      rules={{ required: "Product Type is required" }}
                    />
                  </Grid>

                  {/* policy number */}
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="policy_number"
                      control={control}
                      // defaultValue={props.personalData.reference_id || ''}
                      rules={{ required: "Policy Number is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          disablePortal
                          options={policies}
                          fullWidth
                          value={
                            policies.find(
                              (option) => option.policy_number === field.value
                            ) || null
                          } // Match the selected object
                          onChange={(event, newValue) => {
                            field.onChange(
                              newValue ? newValue.policy_number : ""
                            ); // Update the policy_number value in the form
                          }}
                          getOptionLabel={
                            (option) => option.policy_number || "" // Ensure a string is returned for display
                          }
                          loading={isPolicyLoaderTrue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Policy Number"
                              error={!!errors.policy_number}
                              helperText={
                                errors.policy_number
                                  ? errors.policy_number.message
                                  : ""
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  {/* Claim Amount */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Claim Amount"
                      variant="outlined"
                      name="claim_amount"
                      // value={formData.mobileNumber}
                      {...register("claim_amount", {
                        required: "Claim Amount is required",
                      })}
                      // onChange={handleChange}
                      error={!!errors.claim_amount}
                      helperText={
                        errors.claim_amount ? errors.claim_amount.message : ""
                      }
                    />
                  </Grid>

                  {/* phone number */}
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      {t("lmv.customer.mobileFormFieldLabel")}
                    </Typography>
                    <Controller
                      name="mobile_number"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          margin={"dense"}
                          fullWidth
                          error={!!errors.mobile_number}
                          helperText={errors.mobile_number?.message}
                          disabled={true}
                        />
                      )}
                      rules={{ required: t("lmv.requiredField") }}
                    />
                  </Grid>

                  {/* reason field */}
                  <Grid item xs={12} md={12}>
                    <Typography variant="subtitle1">
                      {t("lmv.customer.reasonFormFieldLabel")}
                    </Typography>
                    <Controller
                      name="reason"
                      control={control}
                      rules={{ required: t("lmv.requiredField") }}
                      defaultValue=""
                      // error={!!errors.reason}
                      // helperText={errors.reason?.message}
                      render={({ field }) => (
                        <TextareaAutosize
                          {...field}
                          minRows={4}
                          label="Reasons for Claim"
                          placeholder={t("lmv.customer.reasonPlaceholder")}
                          style={{
                            width: "100%",
                            minHeight: "5%",
                            padding: "12px",
                            border: errors.reason
                              ? "2px solid red"
                              : "1px solid #ccc",
                          }}
                        />
                      )}
                    />
                    {errors.reason && (
                      <Typography variant="body2" color="error">
                        {errors.reason.message}
                      </Typography>
                    )}
                  </Grid>

                  {/* submit */}
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      size="large"
                      color="info"
                      type="submit"
                      disabled={isLoaderTrue}
                    >
                      {isLoaderTrue ? <CircularProgress size={25} /> : "Submit"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </MainCard>
    </>
  );
};

export default RegisterClaim;
