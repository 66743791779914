import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { clearSuccessMessage } from '../../Redux/action-creators/successAction';
import { useEffect } from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ErrorMessage = () => {
    const dispatch = useDispatch();
    const success = useSelector((state) => state.success);

    const handleClose = () => {
        dispatch(clearSuccessMessage());
    };

    // Clear error when component is first rendered (mounted)
    useEffect(() => {
        dispatch(clearSuccessMessage());
    }, [dispatch]);

    return (
        <Snackbar open={success.success != null} autoHideDuration={6000} onClose={handleClose}
                  anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleClose} severity="success"
                   sx={{width: '100%'}}>
                {success.success}
            </Alert>
        </Snackbar>
    );
};

export default ErrorMessage;
