import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid'


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();


export const doucmentsUpload = async (file, type , typeOfUpload) => {
    const params = {
        Bucket: typeOfUpload === 'SIGNUP' ? process.env.REACT_APP_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME_POLICY_DOCUMENT,
        Key: uuidv4(),
        Body: file,
        ContentType: type
    };
    const response = await s3.upload(params).promise()
    return response
}



export const deleteDocument = async (key) => {
    try {
        const params = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
            Key: key
        };

        const response = await s3.deleteObject(params).promise();
        console.log("Document deleted successfully:", response);
        return response;
    } catch (error) {
        console.error("Error deleting document:", error);
        throw error;
    }
};