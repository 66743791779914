import React from 'react';
import { Typography, List, ListItem, ListItemText, Container } from '@mui/material';

const styles = {
    container: {
        marginTop: "32px",
        marginBottom: "32px",
        padding: "20px 30px",
      },
      heading: {
          marginTop:'10px',
        marginBottom: "2px",
        color: "#23a8fa",
        fontWeight: "bold",
    
      },
  
      subHeading: {
        marginTop: "2px",
        fontWeight: "bold",
        color: "#555",
    
      },
      bodyText:{
        color:'#555'
      },
  
  listItem: {
    marginBottom: "2px",

    backgroundColor: "#f5f5f5",
    // padding: "2px",
    borderRadius: "4px",
    textAlign:'justify',
    
    padding:'0px',
    margin:'0px',
  },
};

const HealthClaimsPolicy = () => (
    <Container style={styles.container}>
    <Typography variant="h2" textAlign='center'   style={styles.heading} gutterBottom>
    Health Claims Policy
    </Typography>
    <Typography variant="body1" mt={1} style={styles.bodyText}>
    A Health Claims Policy for an insurance company like LMV Insurance Broking Private Limited would outline the procedures, eligibility criteria, and responsibilities related to the processing and settlement of health insurance claims. While I don't have access to specific policies of LMV Insurance Broking Private Limited, I can provide a general framework that could apply to such policies.    </Typography>
    <ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>1. Eligibility Criteria</Typography>}
    secondary={
      <ul style={styles.bodyText}>
        <li>Policyholders: Only policyholders who have an active health insurance policy with LMV Insurance Broking Private Limited are eligible to make health claims.</li>
        <li>Covered Events: Claims can be made for medical treatment related to illness, injury, hospitalization, surgeries, and other covered events as specified in the health insurance policy.</li>
        <li>Waiting Period: There may be a waiting period for certain conditions or treatments before claims can be made. This can vary depending on the type of policy.</li>
      </ul>
    }
  />
</ListItem>

<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>2. Types of Claims</Typography>}
    secondary={
      <ul style={styles.bodyText}>
        <li>Cashless Claims: If the insured person seeks treatment at a network hospital, they may be eligible for cashless treatment, where the insurer settles the bill directly with the hospital.</li>
        <li>Reimbursement Claims: If treatment is received outside of the network or cashless facility, the policyholder can pay the bill upfront and then submit a claim for reimbursement.</li>
      </ul>
    }
  />
</ListItem>

<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>3. Claim Intimation Process</Typography>}
    secondary={
      <ul style={styles.bodyText}>
        <li>Timeframe: Claims should be intimated as soon as possible after the treatment or event occurs, ideally within 24-48 hours of hospitalization or emergency treatment.</li>
        <li>Mode of Intimation: Claims can usually be intimated via the company’s online portal, customer service helpline, or email.</li>
        <li>Documentation: Policyholders must submit relevant documents such as hospital bills, medical records, prescriptions, and any other paperwork as per the policy requirements.</li>
      </ul>
    }
  />
</ListItem>

<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>4. Required Documents</Typography>}
    secondary={
      <ul style={styles.bodyText}>
        <li>Hospital Bills: Itemized bills with a breakdown of medical expenses.</li>
        <li>Discharge Summary: A detailed summary of the treatment provided, including diagnosis and procedures performed.</li>
        <li>Prescription: The doctor's prescription for medication or treatment.</li>
        <li>Medical Reports: Blood tests, X-rays, MRIs, or other diagnostic reports.</li>
        <li>Policy Details: Copy of the health insurance policy document.</li>
      </ul>
    }
  />
</ListItem>

<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>5. Settlement Process</Typography>}
    secondary={
      <ul style={styles.bodyText}>
        <li>Claim Assessment: Once a claim is filed, LMV Insurance Broking Private Limited will review the submitted documents to assess the claim’s validity and coverage.</li>
        <li>Verification: The insurer may verify medical details and treatment necessity. Third-party verification or investigation may be conducted in cases of suspicious claims.</li>
        <li>Approval/Rejection: After assessment, the claim will either be approved or rejected based on the policy terms, the eligibility of the treatment, and other relevant factors.</li>
        <li>Settlement Time: Claims should generally be settled within a specified time, usually 30-60 days, from the date of claim intimation, barring any complications.</li>
      </ul>
    }
  />
</ListItem>

<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>6. Exclusions</Typography>}
    secondary={
      <ul style={styles.bodyText}>
        <li>Pre-existing Conditions: Any treatment related to pre-existing conditions that are not covered under the policy.</li>
        <li>Cosmetic Procedures: Non-medically necessary procedures (e.g., cosmetic surgery) may be excluded.</li>
        <li>Self-inflicted Injuries: Claims for injuries resulting from self-harm or suicide attempts may not be covered.</li>
        <li>Non-medical Expenses: Any expenses that are not directly related to the medical treatment, such as personal items or accommodations.</li>
      </ul>
    }
  />
</ListItem>

<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>7. Dispute Resolution</Typography>}
    secondary={
      <ul style={styles.bodyText}>
        <li>Internal Grievance Redressal: If a policyholder is dissatisfied with the settlement decision, they can file a complaint through the insurer’s internal grievance mechanism.</li>
        <li>Regulatory Bodies: If the dispute remains unresolved, the policyholder can approach the Insurance Regulatory and Development Authority of India (IRDAI) or an insurance ombudsman.</li>
      </ul>
    }
  />
</ListItem>

<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>8. Fraud Prevention</Typography>}
    secondary={
      <ul style={styles.bodyText}>
        <li>Claims Investigation: LMV Insurance Broking Private Limited will investigate claims for signs of fraud, such as exaggerated or falsified medical bills, misrepresentation of the medical condition, or unnecessary treatments.</li>
        <li>Penalties: Fraudulent claims can result in rejection, penalties, or even legal action.</li>
      </ul>
    }
  />
</ListItem>

<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>9. Other Considerations</Typography>}
    secondary={
      <ul style={styles.bodyText}>
        <li>No Claim Bonus: Some policies offer a "No Claim Bonus," where the policyholder may receive a discount on premiums if no claims are made during a certain period.</li>
        <li>Portability: If a policyholder switches insurers, they can transfer their policy benefits (such as waiting period) to the new insurer.</li>
      </ul>
    }
  />
</ListItem>

    </Container>
);

export default HealthClaimsPolicy;
