
import React from "react";
import { Typography, Container, List, ListItem, ListItemText } from "@mui/material";

const styles = {
    container: {
      marginTop: "32px",
      marginBottom: "32px",
      padding: "20px 30px",
    },
    heading: {
      marginBottom: "2px",
      color: "#23a8fa",
      fontWeight: "bold",
  
    },
    subHeading: {
      marginTop: "2px",
      fontWeight: "bold",
      color: "#555",
  
    },
    listItem: {
      marginBottom: "2px",
      marginTop:'3px',
      backgroundColor: "#f5f5f5",
      // padding: "2px",
      borderRadius: "4px",
      textAlign:'justify',
      
      padding:'0px',
      margin:'0px',
    },
  };

const HealthInsuranceForSeniorCitizen = () => {
  return (
    <Container style={styles.container}>
      <Typography variant="h2" textAlign='center'   style={styles.heading} gutterBottom>
      Senior Citizen Health Insurance
      </Typography>
      <Typography variant="body1" paragraph>
      Senior citizen health insurance refers to insurance plans specifically designed for elderly people (usually above 60 years of age). These plans typically offer comprehensive coverage for medical expenses, including hospitalization, 
      surgeries, and outpatient treatments. Some senior citizen plans also provide additional benefits such as regular health check-ups, cashless hospitalization, and discounts on medicines. In India, the most common senior citizen health insurance plans are provided by government-run insurance companies, such as the New India Assurance Company and the United India Insurance Company. 
      </Typography>
    
      <Typography variant="h4" style={styles.heading}>
        Key Features of Senior Citizen Health Insurance
      </Typography>
      <Typography variant="body1">
        Some key features and benefits of senior citizen health insurance are:
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Age-specific coverage
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Coverage is usually available for people above 60 years of age,
                and the upper age limit can be up to 80 or 90 years.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Comprehensive coverage
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Most plans cover medical expenses such as hospitalization,
                surgeries, and outpatient treatments.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                No pre-existing condition exclusion
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Most senior citizen plans do not exclude pre-existing medical
                conditions, unlike regular health insurance plans.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Higher sum insured
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Senior citizen plans usually offer a higher sum insured
                compared to regular health insurance plans, to cover the
                increased healthcare expenses in old age.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Cashless hospitalization
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Many senior citizen plans offer the option of cashless
                hospitalization at network hospitals.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Regular health check-ups
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Some plans offer regular health check-ups as a part of the
                coverage, to help monitor the health of senior citizens.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Discounts on medicines
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Many plans offer discounts on medicines purchased from network
                pharmacies.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Tax benefits
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Premiums paid for senior citizen health insurance are eligible
                for tax benefits under section 80D of the Income Tax Act, 1961.
              </Typography>
            }
          />
        </ListItem>
      </List>

    
      
      <Typography variant="h4" style={styles.heading}>
        Why Invest in a Senior Citizen Health Insurance?
      </Typography>
      <Typography variant="body1" style={styles.paragraph}>
        There are several reasons to invest in a senior citizen health
        insurance policy. As people age, they become more prone to illnesses
        and medical conditions that require frequent and expensive medical
        attention. A senior citizen health insurance policy helps to mitigate
        the financial burden of these expenses. Also, senior citizen plans
        usually offer a higher sum insured compared to regular health insurance
        plans, to cover the increased healthcare expenses in old age. A senior
        citizen health insurance policy provides peace of mind, knowing that
        the costs of medical treatments will be covered, even in the event of a
        medical emergency. In conclusion, a senior citizen health insurance
        policy is a necessary investment for older people to ensure they have
        access to quality medical care and financial security in their golden
        years.
      </Typography>

      {/* Section 2 */}
      <Typography variant="h4" style={styles.heading} mt={3}>
        How To Make a Claim for Senior Citizen Health Insurance Policy?
      </Typography>
      <Typography variant="body1" style={styles.paragraph}>
        The process of making a claim for a senior citizen health insurance
        policy typically involves the following steps:
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Inform the insurance company
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                In the event of hospitalization, inform the insurance company
                as soon as possible to start the claims process. Provide the
                policy number and details of the hospital and treatment
                required.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Cashless or reimbursement
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                If the hospital is a part of the insurance company's network,
                avail of the cashless facility. If not, pay for the treatment
                and get reimbursed later.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Submit required documents
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Collect all necessary documents such as medical bills,
                discharge summary, and prescription and submit them to the
                insurance company along with a claim form.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Intimation of claim
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                The insurance company will acknowledge the receipt of the claim
                and provide a reference number for future correspondence.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Processing of claim
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                The insurance company will process the claim and check if it is
                eligible as per the terms and conditions of the policy.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Settlement of claim
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                If the claim is approved, the insurance company will settle the
                claim by paying the amount directly to the hospital or
                reimbursing it to you.
              </Typography>
            }
          />
        </ListItem>
      </List>

      {/* Section 3 */}
      <Typography variant="h4" style={styles.heading}>
        Why Buy Senior Health Insurance Online?
      </Typography>
      <Typography variant="body1" style={styles.paragraph}>
        There are several reasons to buy senior citizen health insurance
        online, including:
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Convenience
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Buying senior citizen health insurance online is convenient and
                saves time, as you can compare different plans and buy a policy
                from the comfort of your home.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Comparison of plans
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Online portals allow you to compare different senior citizen
                health insurance plans, their features, and premium rates,
                making it easier to choose a plan that best fits your needs.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Transparent pricing
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Online portals provide transparent pricing information for
                different plans, so you can make an informed decision based on
                your budget and coverage requirements.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
                Instant policy issuance
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                Online purchases usually result in instant policy issuance,
                eliminating the need for a lengthy and time-consuming process.
              </Typography>
            }
          />
        </ListItem>



 



 


        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
        Easy access to policy details 
        </Typography>
            }
            secondary={
              <Typography variant="body1">
              With an online purchase, you have easy access to all your policy details, including the policy documents, premium payment history, and claim status. 

              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
Round-the-clock support 
</Typography>
            }
            secondary={
              <Typography variant="body1">
               Most online portals offer round-the-clock customer support, making it easy to resolve any issues or clarify any doubts regarding the policy. 

              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h6" style={styles.subHeading}>
Discounts and offers 
</Typography>
            }
            secondary={
              <Typography variant="body1">
                Online purchases often come with special discounts and offers, making them a cost-effective option compared to offline purchases. 

              </Typography>
            }
          />
        </ListItem>
      </List>
      <Typography variant="h4" style={styles.heading}>
    Inclusions in a Senior Citizen Health Insurance Policy
  </Typography>

  <Typography variant="body1">
    A senior citizen health insurance policy typically includes the following coverage:
  </Typography>

  <List>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Hospitalization expenses
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            This includes coverage for room rent, ICU charges, nursing expenses, and other medical expenses incurred during hospitalization.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Pre and post-hospitalization expenses
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            This covers expenses incurred before and after hospitalization, such as diagnostic tests, doctor's fees, and medicines.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Daycare treatments
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            This covers expenses incurred for treatments that do not require 24-hour hospitalization, such as dialysis, chemotherapy, and radiotherapy.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Ambulance cover
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            This covers expenses incurred for ambulance services during hospitalization.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Critical illnesses
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            This covers expenses incurred for specified critical illnesses such as cancer, heart attack, and kidney failure.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            No claim bonus
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            This is an additional benefit that increases the sum insured for each claim-free year.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Tax benefits
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Premiums paid for senior citizen health insurance are eligible for tax benefits under section 80D of the Income Tax Act, 1961.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Renewability
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Most senior citizen health insurance policies are renewable for life, ensuring continuous coverage as you age.
          </Typography>
        }
      />
    </ListItem>
  </List>
  <Typography variant="h4" style={styles.heading}>
    Exclusions of Senior Citizen Health Insurance Policy
  </Typography>

  <Typography variant="body1">
    Exclusions are the conditions or situations that are not covered by a senior citizen health insurance policy. Common exclusions in senior citizen health insurance policies include:
  </Typography>

  <List>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Pre-existing medical conditions
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Most insurance policies exclude coverage for pre-existing medical conditions for a specified period, typically 2-4 years.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Cosmetic treatments
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Treatments for cosmetic purposes are usually excluded from coverage.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Lifestyle-related illnesses
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Illnesses or treatments arising from lifestyle choices, such as smoking or excessive alcohol consumption, are usually excluded.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Mental and psychiatric conditions
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Mental and psychiatric conditions are often excluded from coverage.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Experimental treatments
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Treatments that are experimental or not widely accepted by the medical community are often excluded.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Self-inflicted injuries
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Injuries or illnesses that are self-inflicted or arising from criminal activities are excluded from coverage.
          </Typography>
        }
      />
    </ListItem>
  </List>

  <Typography variant="h4" style={styles.heading}>
    Things to Consider While Buying a Health Insurance Policy for Senior Citizens
  </Typography>

  <List>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Age and Health Condition
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Most insurance companies have an upper age limit for buying health insurance, so it's important to consider this while buying a policy. The senior citizens' health condition and medical history can also impact the premium amount and policy coverage.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Coverage
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Make sure the policy covers all the necessary medical expenses such as hospitalization, pre and post-hospitalization, daycare procedures, etc.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Network Hospitals
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Look for a policy that covers a wide network of hospitals in your area so that you can avail cashless treatment at the time of need.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Pre-existing Conditions
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Some insurance companies exclude coverage for pre-existing conditions in their policies. Ensure that the policy covers any pre-existing conditions that you might have.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Waiting Period
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Some policies have a waiting period for pre-existing conditions, so be aware of this while choosing the policy.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Premiums
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            The premium amount can vary based on the policy coverage and the age of the senior citizen. Look for an affordable policy that offers adequate coverage.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Renewability
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Check if the policy is renewable for life, so that the senior citizen can continue to be covered even as they age.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Claim Settlement Process
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Make sure the policy has a smooth and hassle-free claim settlement process. Check with the insurance firm about their claim settlement ratio before heading forward. A good claim settlement ratio assures you that you could settle a claim in the future on a quick note.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Customer Service
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Good customer service can make the process of buying and using the policy much easier. Consider the insurance company's reputation for customer service.
          </Typography>
        }
      />
    </ListItem>
  </List>

  <Typography variant="h4" style={styles.heading}>
    Claim Settlement Process for Senior Citizen Health Insurance
  </Typography>

  <Typography variant="body1">
    The policyholder is liable to file a claim with the insurance firm over hospitalization or emergency medical treatment taken. Only when the claim is raised, the policyholder will get eligible to avail cashless treatment or get the reimbursement.
  </Typography>

  <List>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Notifying the insurance company
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            The policyholder or their representative should inform the insurance company of the hospitalization or medical procedure that requires a claim.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Filing the claim
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            The policyholder or their representative should then submit a claim form along with all the required documents such as medical bills, discharge summary, and prescriptions.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Verification by the insurance company
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            The insurance company will verify the authenticity of the claim and the related documents.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Approval or rejection of the claim
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            Based on the verification process, the insurance company will either approve or reject the claim.
          </Typography>
        }
      />
    </ListItem>
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={
          <Typography variant="h6" style={styles.subHeading}>
            Payment
          </Typography>
        }
        secondary={
          <Typography variant="body1">
            If the claim is approved, the insurance company will process the payment as per the terms and conditions mentioned in the policy.
          </Typography>
        }
      />
    </ListItem>
  </List>

  <Typography variant="body1">
    It's advisable to read the policy document carefully and understand the terms and conditions related to claim settlement before availing of senior citizen health insurance.
  </Typography>



  <Typography variant="h4" mt={3} style={styles.heading}>
  FAQs - Senior Citizen Health Insurance
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          What is the coverage provided by senior citizen health insurance?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The coverage provided by senior citizen health insurance varies depending on the policy selected. Generally, it covers medical expenses incurred due to hospitalization, illness, or injury, including pre and post-hospitalization expenses, doctor's consultation fees, and diagnostic tests.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          What is the premium for senior citizen health insurance?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The premium for senior citizen health insurance is higher than regular health insurance due to the higher risk involved. The premium amount is based on factors such as age, medical history, and coverage amount.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Can pre-existing illnesses be covered under senior citizen health insurance?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Pre-existing illnesses are usually covered after a waiting period of 2-4 years, as per the policy terms and conditions.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Is there a limit on the number of claims that can be made under senior citizen health insurance?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The number of claims that can be made under senior citizen health insurance is limited to the policy terms and conditions. Some policies have an annual or lifetime limit on the number of claims.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          Is there a cashless facility available under senior citizen health insurance?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Yes, many senior citizen health insurance policies offer cashless facility at network hospitals. This allows the insured to avail medical treatment without paying out of pocket and directly settling the expenses with the insurance company.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={
        <Typography variant="h5" style={styles.subHeading}>
          What is the claim settlement process for senior citizen health insurance?
        </Typography>
      }
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The claim settlement process for senior citizen health insurance involves informing the insurance company about the hospitalization, submitting the required documents, initial review by the insurance company, approval or rejection of the claim, and payment. The exact process may vary depending on the insurance company and the policy terms and conditions.
        </Typography>
      }
    />
  </ListItem>
</List>
</Container>
  );
};



export default HealthInsuranceForSeniorCitizen;