import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  MenuItem,
  Button,
  IconButton,
  Stack,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import {
  clearError,
  setError,
} from "../../../../Redux/action-creators/errorAction";
import { clearSuccessMessage } from "../../../../Redux/action-creators/successAction";

const FamilyDetailsPage = ({
  profileData,
  NextBtn,
  PreviousBtn,
  updateCustomerDetails,
}) => {
  const dispatch = useDispatch();
  const {
    formState: { errors, isSubmitted },
    register,
    setValue,
    handleSubmit,
    trigger,
    reset,
  } = useForm();

  const [showForm, setShowForm] = useState(false);
  const [isAddLoaderTrue, setIsAddLoaderTrue] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const handleAddFamilyMember = () => {
    setShowForm(true);
  };

  function CampareTwoObjects(obj1, obj2) {
    if (Object.keys(obj1)?.length != Object.keys(obj2)?.length) {
      return false;
    }
    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }

  const handleSaveMember = async (data) => {

    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const newRecord = {
      relation: data?.relation,
      name: data?.name,
      dob: data?.dateOfBirth,
    };
    
    const isRecordExist = profileData?.family_data?.filter((eachValue) => {
      return CampareTwoObjects(eachValue, newRecord);
    }) || []

    if (isRecordExist?.length > 0) {
      dispatch(setError(`This Person is already Exist`));
    } else {
      const mapObject = {
        ...profileData,
        family_data: JSON.stringify([...profileData?.family_data || [], newRecord]),
        assets: JSON.stringify(profileData?.assets),
        exit_policies: JSON.stringify(profileData?.exit_policies),
        alternate_numbers: JSON.stringify(profileData?.alternate_numbers),
        alternate_emails: JSON.stringify(profileData?.alternate_emails),
      };
      setIsAddLoaderTrue(true);
      await updateCustomerDetails(mapObject);
      setIsAddLoaderTrue(false);
      setShowForm(false);
      reset();
    }
  };

  const handleDeleteMember = async (index) => {
    const updatedMembers = profileData?.family_data?.filter(
      (_, i) => i !== index
    );
    const mapObject = {
      ...profileData,
      family_data: JSON.stringify(updatedMembers),
      assets: JSON.stringify(profileData?.assets),
      exit_policies: JSON.stringify(profileData?.exit_policies),
      alternate_numbers: JSON.stringify(profileData?.alternate_numbers),
      alternate_emails: JSON.stringify(profileData?.alternate_emails),
    };
    setDeleteLoader(true);
    await updateCustomerDetails(mapObject);
    setDeleteLoader(false);
  };

  return (
    <Box
      sx={{
        margin: "0",
        padding: "15px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", m: 2 }}>
        <FamilyRestroomIcon
          sx={{ fontSize: "2rem", color: "#0000ff", mr: 1 }}
        />
        <Typography variant="h4">Family Details</Typography>
      </Box>

      {deleteLoader && (
        <Box width={"100%"} marginTop={2}>
          <LinearProgress />
        </Box>
      )}

      {/* data */}
      {profileData?.family_data?.length !== 0 &&
      profileData?.family_data !== null ? (
        profileData?.family_data?.map((member, index) => (
          <Paper
            key={index}
            sx={{
              padding: "20px",
              borderRadius: "8px",
              bgcolor: "#f5f8fd",
              marginBottom: "16px",
            }}
          >
            <Typography variant="h4" sx={{ mb: 2 }}>
              {member.relation}
            </Typography>

            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item xs={10} md={5}>
                <TextField
                  fullWidth
                  label="Member Name"
                  variant="outlined"
                  value={member.name}
                  disabled
                />
              </Grid>

              <Grid item xs={10} md={5}>
                <TextField
                  fullWidth
                  label="Date Of Birth"
                  variant="outlined"
                  value={member.dob}
                  disabled
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => handleDeleteMember(index)}
                  sx={{ fontSize: "50px" }}
                  disabled={deleteLoader}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        ))
      ) : (
        // empty text
        <Typography
          variant="h3"
          textAlign={"center"}
          marginTop={5}
          marginBottom={5}
        >
          Empty
        </Typography>
      )}

      {/* form */}
      {showForm ? (
        <Paper sx={{ borderRadius: "8px", bgcolor: "#f5f8fd" }}>
          <form onSubmit={handleSubmit(handleSaveMember)}>
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "2px", padding: "10px" }}
            >
              <Grid item xs={12} display={"flex"} justifyContent={"right"}>
                <CloseIcon
                  sx={{ marginRight: "15px", cursor: "pointer" }}
                  onClick={() => {
                    setShowForm(false);
                    reset();
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  select
                  fullWidth
                  label="Select Relation"
                  {...register("relation", {
                    required: "Please Select relation",
                  })}
                  error={!!errors.relation}
                  helperText={errors.relation ? errors.relation.message : ""}
                  // value={selectedRelation}
                  onChange={async (e) => {
                    setValue(e.target.name, e.target.value);
                    isSubmitted && (await trigger(e.target.name));
                  }}
                >
                  <MenuItem value="Spouse">Spouse</MenuItem>
                  <MenuItem value="Son">Son</MenuItem>
                  <MenuItem value="Daughter">Daughter</MenuItem>
                  <MenuItem value="Father">Father</MenuItem>
                  <MenuItem value="Mother">Mother</MenuItem>
                  <MenuItem value="Brother">Brother</MenuItem>
                  <MenuItem value="Sister">Sister</MenuItem>
                  <MenuItem value="Father-in-law">Father-in-law</MenuItem>
                  <MenuItem value="Mother-in-law">Mother-in-law</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Member Name"
                  variant="outlined"
                  {...register("name", {
                    required: "Please Enter Your Name",
                    pattern: {
                      value: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,
                      message: "Invalid First Name",
                    },
                  })}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                  // value={memberName}
                  // onChange={(e) => setMemberName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Date Of Birth"
                  variant="outlined"
                  type="date"
                  {...register("dateOfBirth", {
                    required: "Please Select date of birth",
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.dateOfBirth}
                  helperText={
                    errors.dateOfBirth ? errors.dateOfBirth.message : ""
                  }
                  // value={dob}
                  // onChange={(e) => setDob(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleSaveMember}
                  sx={{ marginTop: 2 }}
                  type="submit"
                  disabled={isAddLoaderTrue}
                >
                  {isAddLoaderTrue ? <CircularProgress size={20} /> : "Add"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      ) : (
        // buttons
        <Stack
          spacing={2}
          direction={"row"}
          sx={{ alignSelf: "center", marginTop: "30px" }}
        >
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddFamilyMember}
          >
            Add a Family Member
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default FamilyDetailsPage;
