import React from 'react';
import { Typography, Container, List, ListItem, ListItemText } from "@mui/material";

const IndividualHealthInsurance = () => {
  const styles = {
    container: {
        marginTop: "32px",
        marginBottom: "32px",
        padding: "20px 30px",
      },
      heading: {
        marginBottom: "2px",
        color: "#23a8fa",
        fontWeight: "bold",
    
      },
      subHeading: {
        marginTop: "2px",
        fontWeight: "bold",
        color: "#555",
    
      },
      listItem: {
        marginBottom: "2px",
        backgroundColor: "#f5f5f5",
        // padding: "2px",
        borderRadius: "4px",
        textAlign:'justify',
        padding:'0px'
      },
  };

  return (
    <Container style={styles.container}>
      <Typography variant="h2" textAlign='center' style={styles.heading}>
        Individual Health Insurance
      </Typography>

      <Typography variant="body1" >
        Individual health insurance is a type of health insurance coverage that individuals can purchase for themselves and their families without relying on employer sponsorship. This option allows individuals to have more control over their health insurance choices and to customize their coverage to meet their specific needs and budget. Whether you are self-employed, a small business owner, or simply looking for an alternative to employer-sponsored coverage, individual health insurance can be a valuable option for ensuring that you and your loved ones have access to the medical care you need.
      </Typography>

      <Typography variant="h4" mt={2} style={styles.heading}>
        Key Features of Individual Health Insurance
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Customization</Typography>}
            secondary={
              <Typography variant="body1">
                Individual health insurance plans allow individuals to tailor their coverage to their specific needs, budget, and lifestyle.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Choice of Plan</Typography>}
            secondary={
              <Typography variant="body1">
                Individuals can choose from a range of health insurance plans offered by different insurance companies, each with its own set of benefits and coverage options.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Cost-sharing</Typography>}
            secondary={
              <Typography variant="body1">
                With individual health insurance, individuals are typically responsible for paying a portion of their healthcare costs, including monthly premiums, deductibles, copayments, and coinsurance.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Provider Network</Typography>}
            secondary={
              <Typography variant="body1">
                Individual health insurance plans may have a network of healthcare providers, including doctors, hospitals, and other medical professionals, who have agreed to offer services to plan members at a discounted rate.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Coverage Options</Typography>}
            secondary={
              <Typography variant="body1">
                Individual health insurance plans may offer coverage for doctor visits, hospitalization, prescription drugs, and preventive care, among other medical expenses.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Flexibility</Typography>}
            secondary={
              <Typography variant="body1">
                Individual health insurance plans may offer flexible options for changing or updating coverage, such as adding or removing family members, changing the level of coverage, or switching to a different plan.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Portability</Typography>}
            secondary={
              <Typography variant="body1">
                Individual health insurance plans are portable, meaning that individuals can take their coverage with them if they change jobs or move to a new location.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Tax Benefits</Typography>}
            secondary={
              <Typography variant="body1">
                In some cases, individuals may be eligible for tax credits or deductions to help pay for their health insurance coverage.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="body1" style={{ marginTop: 20 }}>
        These are some of the key features of individual health insurance. It's important to consider your individual needs and budget when choosing a plan to make sure you have the coverage you need.
      </Typography>

      <Typography variant="h4" mt={2} style={styles.heading}>
        What is Covered under Individual Health Insurance Plans?
      </Typography>

      <Typography variant="body1" py={1}>
        The specific benefits and coverage offered by individual health insurance plans can vary, but many plans include coverage for the following types of medical expenses:
      </Typography>

      <Typography variant="h4" style={styles.heading}>
        Covered Medical Expenses
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Doctor Visits</Typography>}
            secondary={
              <Typography variant="body1">
                Coverage for routine and specialized medical care, including primary care physician visits, specialist visits, and preventive care.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Hospitalization</Typography>}
            secondary={
              <Typography variant="body1">
                Coverage for inpatient hospital care, including room and board, surgical procedures, and other related expenses.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Prescription Drugs</Typography>}
            secondary={
              <Typography variant="body1">
                Coverage for prescription medications, including brand name and generic drugs.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Preventive Care</Typography>}
            secondary={
              <Typography variant="body1">
                Coverage for preventive services, such as annual physicals, immunizations, and cancer screenings, to help maintain good health and prevent the onset of illness.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Mental Health Services</Typography>}
            secondary={
              <Typography variant="body1">
                Coverage for mental health and substance abuse treatment, including counseling, therapy, and other related services.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Rehabilitation Services</Typography>}
            secondary={
              <Typography variant="body1">
                Coverage for physical therapy, rehabilitation services, and related expenses for individuals recovering from an illness or injury.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Laboratory Services</Typography>}
            secondary={
              <Typography variant="body1">
                Coverage for laboratory tests, including blood tests, imaging tests, and other diagnostic procedures.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="body1">
        These are some of the most common types of medical expenses covered by individual health insurance plans. However, it's important to note that the specific benefits and coverage offered by different plans can vary, so it's important to carefully review the details of each plan to understand what is covered.
      </Typography>

      <Typography variant="h4" mt={2} style={styles.heading}>
        What is Not Covered under Individual Health Insurance Plans?
      </Typography>

      <Typography variant="body1">
        Individual health insurance plans typically don't cover:
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Pre-existing Conditions</Typography>}
            secondary={
              <Typography variant="body1">
                Health conditions that existed before the policy was purchased are usually not covered.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Elective or Cosmetic Procedures</Typography>}
            secondary={
              <Typography variant="body1">
                Surgeries or procedures that are not medically necessary, such as cosmetic surgery, are usually not covered.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Experimental Treatments</Typography>}
            secondary={
              <Typography variant="body1">
                Treatments that are still in the experimental stage and have not been widely accepted as standard medical practices are usually not covered.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Routine Dental and Vision Care</Typography>}
            secondary={
              <Typography variant="body1">
                Dental and vision care are often not covered under individual health insurance plans unless specifically included as a rider.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Alternative Therapies</Typography>}
            secondary={
              <Typography variant="body1">
                Treatments such as acupuncture, chiropractic care, and homeopathy may not be covered under individual health insurance plans.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="body1" >
        Note that coverage may vary from plan to plan and from state to state, so it is important to carefully review the policy details and talk to the insurance provider to understand what is covered.
      </Typography>

      <Typography variant="h4" mt={2} style={styles.heading}>
    How to Buy an Individual Health Insurance Policy Online?
</Typography>

<Typography variant="body1">
    Things to Keep in Mind While Buying an Individual Health Insurance Policy
</Typography>



<List>
    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Coverage needs</Typography>}
            secondary={
                <Typography variant="body1">
                    Determine what types of medical services you need covered and consider factors such as doctor and hospital network access, prescription drug coverage, and mental health and substance abuse treatment.
                </Typography>
            }
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Cost</Typography>}
            secondary={
                <Typography variant="body1">
                    Consider the monthly premium, deductible, co-payments, and co-insurance amounts. Take into account your monthly budget and your ability to pay for unexpected medical expenses.
                </Typography>
            }
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Network</Typography>}
            secondary={
                <Typography variant="body1">
                    Check if your preferred doctors and hospitals are included in the insurance plan's network. Out-of-network care can be significantly more expensive.
                </Typography>
            }
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Pre-existing conditions</Typography>}
            secondary={
                <Typography variant="body1">
                    Review the insurance company's policies on pre-existing conditions and make sure they align with your needs.
                </Typography>
            }
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Waiting periods</Typography>}
            secondary={
                <Typography variant="body1">
                    Some health insurance policies have waiting periods for coverage of certain conditions, such as a 12-month waiting period for a pre-existing condition. Make sure you understand these waiting periods before purchasing a policy.
                </Typography>
            }
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Coverage limits</Typography>}
            secondary={
                <Typography variant="body1">
                    Be aware of any annual or lifetime coverage limits for specific medical conditions or treatments.
                </Typography>
            }
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Renewability</Typography>}
            secondary={
                <Typography variant="body1">
                    Make sure the insurance policy is renewable and that you will not lose coverage due to changes in your health status.
                </Typography>
            }
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Claims process</Typography>}
            secondary={
                <Typography variant="body1">
                    Familiarize yourself with the insurance company's claims process, including the procedures for filing a claim and the time frame for claim processing.
                </Typography>
            }
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Customer service</Typography>}
            secondary={
                <Typography variant="body1">
                    Consider the insurance company's reputation for customer service, as well as its responsiveness to customer needs and concerns.
                </Typography>
            }
        />
    </ListItem>
</List>

<Typography variant="body1">
    By taking these factors into consideration, you can make an informed decision when purchasing an individual health insurance policy.
</Typography>

<Typography variant="h4" mt={2} style={styles.heading}>
    FAQs
</Typography>

<List>
    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>What exactly is a personal health insurance plan?</Typography>}
            secondary={<Typography variant="body1">A type of insurance coverage known as an individual health insurance policy is one that a person purchases for themselves or their family as opposed to having it provided by their employer.</Typography>}
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>What is covered by personal health insurance?</Typography>}
            secondary={<Typography variant="body1">Depending on the policy, coverage may vary, but it usually covers medical costs such as hospital stays, doctor visits, and prescription medications. Additionally, some insurance plans might cover routine checkups and vaccinations as well as preventive care.</Typography>}
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Who is eligible to purchase an individual health insurance policy?</Typography>}
            secondary={<Typography variant="body1">Self-employed people, early retirees, and those without coverage through their employer are all eligible to purchase an individual health insurance policy.</Typography>}
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>What elements affect the price of individual health insurance?</Typography>}
            secondary={<Typography variant="body1">Age, location, policy type, and coverage level are just a few of the variables that affect the price of individual health insurance. The price of insurance may also be impacted by health status and pre-existing conditions.</Typography>}
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>How do I pick the best personal health insurance plan?</Typography>}
            secondary={<Typography variant="body1">When selecting an individual health insurance policy, take into account elements like cost, coverage, and your medical requirements. Comparing various policies and speaking with an insurance agent or broker may be beneficial.</Typography>}
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Can I modify my personal health insurance plan?</Typography>}
            secondary={<Typography variant="body1">If you experience a qualifying life event, such as getting married, having a child, or losing coverage through your employer, you may be able to make changes to your individual health insurance policy, usually during the open enrollment period.</Typography>}
        />
    </ListItem>

    <ListItem style={styles.listItem}>
        <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Is personal health insurance mandated by the law?</Typography>}
            secondary={<Typography variant="body1">There will be no federal law requiring people to have health insurance as of 2021. However, some states have their own unique requirements, and failing to comply with them may result in financial penalties.</Typography>}
        />
    </ListItem>
</List>


    </Container>
  );
};

export default IndividualHealthInsurance;
