import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import total from "../Images/Vector Smart Object2-01 1.svg";
import Insured from "../Images/Vector Smart Object3-01 1.svg";
import TotalInsurance from "../Images/Vector Smart Object5-01 1.svg";
import pos from "../Images/Vector Smart Object4-01 1.svg";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export const ourRecords = {
  title: "Our Records",
  ITEMS: [
    { logo: total, name: "Total Policy Sold", value: "1000" },
    { logo: pos, name: "Total POS Partners", value: "5000" },
    { logo: Insured, name: "Total Insured ", value: "8000" },
    { logo: TotalInsurance, name: "Total Insurance", value: "12000" },
  ],
};
export const testimonials = {
  title: "Testimonials",
  ITEMS: [
    { logo: total, name: "Total Policy Sold" },
    { logo: pos, name: "Total POS Partners" },
    { logo: Insured, name: "Total Insured Premium" },
    { logo: TotalInsurance, name: "Total Insurance" },
  ],
};
export const ourpartners = {
  title: "Our Partners",
  ITEMS: [
    { logo: total, name: "Total Policy Sold" },
    { logo: pos, name: "Total POS Partners" },
    { logo: Insured, name: "Total Insured Premium" },
    { logo: TotalInsurance, name: "Total Insurance" },
  ],
};
export const contact = {
  title: "Contact Us",
  ITEMS: [
    {
      icon: PhoneIcon,
      text: "94933 26920/ 8142044044",
      onClick: () => {
        window.location.href = "tel:+91493326920";
      },
    },
    {
      icon: EmailIcon,
      text: "customersupport@lmvinsurance.com",
      onClick: () => {
        window.location.href = "mailto:customersupport@lmvinsurance.com";
      },
    },
    {
      icon: LocationOnIcon,
      text: "Door No 6-3-354, 1st Floor, SB Tower, Road No 1, Near Punjagutta Circle, Hyderabad - 500082",
      onClick: () => {
        window.open(
          "https://www.google.com/maps?q= 7-29, Mothi Nagar, Prakash Nagar, Nagarjuna Hills, Punjagutta, Hyderabad, Telangana 500082",
          "_blank"
        );
      },
    },
  ],
};

export const footerContent = {
  protocols: {
    title: "Health Insurance",
    links: [
      { title: "Family" , path :'/Footer/HealthInsuranceForFamily'},
      { title: "Senior Citizen", path :'/Footer/HealthInsuranceForSeniorCitizen' },
      { title: "For Parents", path :'/Footer/HealthInsuranceForParents' },
      { title: "Top-Up ", path :'/Footer/TopUpHealthInsurance' },
      { title: "Maternity ", path :'/Footer/MaternityHealthInsurance' },
      { title: "Individual ", path :'/Footer/IndividualHealthInsurance' },
    ],
  },
  governance: {
    title: "Life Insurance",
    links: [
      { title: "Term" ,path:'/Footer/'},
      { title: "Child Plan",path:'/Footer/' },
      { title: "Retirement Plan",path:'/Footer/' },
      { title: "Guaranteed  Plan",path:'/Footer/' },
    ],
  },
  support: {
    title: "Motor Insurance",
    links: [
      { title: "Bike" ,path:'/Footer/LMVBikeInsurance'},
      { title: "Car",path:'/Footer/LMVCarInsurance' },
      { title: "Commercial",path:'/Footer/CommercialVehicleInsurance' },
    ],
  },
  developers: {
    title: "Claims",
    links: [
      { title: "Health Claims",path:'/Footer/HealthClaimsPolicy' },
      { title: "Life Claims" ,path:'/Footer/LifeClaimsPolicy'},
      { title: "Bike Claims",path:'/Footer/BikeClaimsPolicy' },
      { title: "Car Claims",path:'/Footer/FooterCarClaims' },
    ],
  },
  subscribe: {
    title: "Contact Us",
    subtitle: "Get the latest news and updates",
  },
  socials: [
    { icon: FacebookIcon , url : ''},
    { icon: TwitterIcon, url: 'https://x.com/i/flow/login?redirect_after_login=%2Flmv_investment'},
    { icon: LinkedInIcon, url: 'https://www.linkedin.com/company/lmv-insurance-broking-services-pvt-ltd/' },
  ],
  copyright: {
    left: "© 2018-2025 All Rights Reserved.",
    center: "lmvinsurance.com",
    right: " Designed and developed by lmvit.com",
  },
  license: {
    text: "LMV is registered as a Direct Broker | Registration No. 662, Registration Code No. IRDA/DB731/2018, Valid till 30/05/2025, License category- Direct Broker (Life & General)| Visitors are hereby informed that their information submitted on the website may be shared with insurers.Product information is authentic and solely based on the information received from the insurers.© Copyright 2021-2025 lmvinsurance.com. All Rights Reserved.",
  },
};
