import { Routes, Route } from "react-router-dom";
import HealthInsuranceForParents from "./ParentsHealthinsurnace";
import Footer from "../Components/Footer";
import NavbarMain from "../Components/NavbarMain";
import { Box } from "@mui/material";
import React from "react";
import HealthInsuranceForFamily from "./familyHealthInsurance";
import HealthInsuranceForSeniorCitizen from "./SeniorcitizenHealthinsurance";
import LMVBikeInsurance from "./BikeInsurance";
import LMVCarInsurance from "./CarInsurance";
import CommercialVehicleInsurance from "./CommercialVehicle";
import HealthClaimsPolicy from "./HealthClaims";
import LifeClaimsPolicy from "./LifeClaimsPolicy";
import BikeClaimsPolicy from "./BikeClaims";
import FooterCarClaims from "./CarClaims";
import TopUpHealthInsurance from "./TopUpHealthInsurance";
import MaternityHealthInsurance from "./MaternityHealth";
import IndividualHealthInsurance from "./IndividualHealthInsurance";
import ScrollToTop from "./ScrollToTop.jsx";


function FooterRoutes() {
     const [open, setOpen] = React.useState(false);
      const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
      };
  return (
    <div className="App">
      <ScrollToTop />
       <NavbarMain toggleDrawer={toggleDrawer} /> 
      <Routes>
        <Route exact path ="/HealthInsuranceForParents" Component={HealthInsuranceForParents}/>
        <Route exact path ="/HealthInsuranceForFamily" Component={HealthInsuranceForFamily}/>
        <Route exact path ="/HealthInsuranceForSeniorCitizen" Component={HealthInsuranceForSeniorCitizen}/>
        <Route exact path ="/LMVBikeInsurance" Component={LMVBikeInsurance}/>
        <Route exact path ="/LMVCarInsurance" Component={LMVCarInsurance}/>
        <Route exact path ="/CommercialVehicleInsurance" Component={CommercialVehicleInsurance}/>
        <Route exact path ="/HealthClaimsPolicy" Component={HealthClaimsPolicy}/>
        <Route exact path ="/LifeClaimsPolicy" Component={LifeClaimsPolicy}/>
        <Route exact path ="/BikeClaimsPolicy" Component={BikeClaimsPolicy}/>
        <Route exact path ="/FooterCarClaims" Component={FooterCarClaims}/>
        <Route exact path ="/TopUpHealthInsurance" Component={TopUpHealthInsurance}/>
        <Route exact path ="/MaternityHealthInsurance" Component={MaternityHealthInsurance}/>
        <Route exact path ="/IndividualHealthInsurance" Component={IndividualHealthInsurance}/>
      </Routes>
      <Box sx={{ bgcolor: "black", color: 'white', position: "relative" }}>
        <Footer />
      </Box>
    </div>
  );
}

export default FooterRoutes;
