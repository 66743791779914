import React from 'react';
import { Box, Chip } from '@mui/material';
import HealthIcon from '@mui/icons-material/HealthAndSafety'; // Example icon
import MotorIcon from '@mui/icons-material/DirectionsCar'; // Example icon
import TravelIcon from '@mui/icons-material/Flight'; // Example icon
import SavingsIcon from "@mui/icons-material/Savings"; 
import FavoriteIcon from "@mui/icons-material/Favorite";

const filterOptions = [
  { label: "All", icon: null },
  { label: "Health", icon: <HealthIcon /> },
  { label: "Motor", icon: <MotorIcon /> },
  { label: "Life", icon: <FavoriteIcon /> },
  { label: "Travel", icon: <TravelIcon /> },
  { label: "Loan", icon: <SavingsIcon /> },
];

const FilterBar = ({ selectedFilter, onFilterClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'auto',
        gap: 2,
        '::-webkit-scrollbar': { display: 'none' },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      {filterOptions.map((option) => (
        <Chip
          key={option.label}
          icon={option.icon}
          label={option.label}
          clickable
          color={selectedFilter === option.label ? 'primary' : 'default'}
          onClick={() => onFilterClick(option.label)}
          sx={{
            flex: '0 0 auto',
            padding: '10px',
            fontSize: '1rem',
            height: '38px',
            alignItems: 'center',
            ...(selectedFilter !== option.label && {
                color: 'primary.main',
                '& .MuiChip-icon': {
                  color: 'primary.main',
                },
              }),
          }}
        />
      ))}
    </Box>
  );
};

export default FilterBar;
