import React from 'react';
import vectorobject from '../../Images/Car-Page/Vector-Smart-Object1-01-01.png';
import carnumberela from '../../Images/Car-Page/Car-and-U-Vector-Smart-Object-01.png';
import carreverse from '../../Images/Car-Page/car-and-u-reverse.png';
import VehicleDetails from '../../Components/Vehicle Detail/VehicleDetails';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CallIcon from '@mui/icons-material/Call';
import {useNavigate} from "react-router"
const CarDetialsPage = () => {
  const naviagte = useNavigate();

  const handlebackBtn = () => {
    // naviagte('/motor/car');
    naviagte("/");

  };
  return (
    <div>
      <main className="vehical_detail_main_container">
        <div className="vehical_detail_main_container-contact">
          <div className="back_arrow_button">
            <KeyboardBackspaceIcon onClick={handlebackBtn} />
          </div>
          <div className="address-box">
            <div className="address">
              <p>Helpline</p>
              <a href="tel:+919825152468">98 2515 2468</a>
            </div>
            <CallIcon style={{ color: '#23A8FA' }} />
            {/* <div className='phone-icon'>
            </div> */}
          </div>

          <div className="vehical_detail_main_container-heading">
            Private Car Details
            <div id="container-stars">
              <div id="stars"></div>
            </div>
            <div id="glow">
              <div class="circle"></div>
              <div class="circle"></div>
            </div>
          </div>

          <div className="pagination">
            <div className="pagination_counter">1</div>
            <span className="number_of_pages">2</span>
          </div>
        </div>
        <VehicleDetails />
      </main>
    </div>
  );
};

export default CarDetialsPage;
