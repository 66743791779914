import React, { useState } from 'react'
import {
    HealthOfflineForm,
    LifeOfflineForm,
    VehicleBikeOfflineForm,
    VehicleCarOfflineForm,
    VehicleCommercialOfflineForm
} from "./OfflineForms";

import axios from "axios";
import { Suspense } from "react";
import DirectionsCarRoundedIcon from "@mui/icons-material/DirectionsCarRounded";
import TwoWheelerRoundedIcon from "@mui/icons-material/TwoWheelerRounded";
import DirectionsBusFilledRoundedIcon from "@mui/icons-material/DirectionsBusFilledRounded";
import VolunteerActivismRoundedIcon from "@mui/icons-material/VolunteerActivismRounded";
import LocalAirportRoundedIcon from "@mui/icons-material/LocalAirportRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import { setSuccessMessage } from "../../../../Redux/action-creators/successAction";
import { setError as setErrorMessage } from "../../../../Redux/action-creators/errorAction"
import UploadIcon from "@mui/icons-material/Upload";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, CircularProgress, Grid, IconButton, InputAdornment, Paper, TextField, Button, Container, Stack } from '@mui/material';
import { toggleOfflineForms } from "../../../../Redux/admin/action";

const OfflinePolicy = () => {
    const dispatch = useDispatch()
    const [fileLoading, setFileLoading] = useState(false); // Loading state for file upload
    const [fileData, setFileData] = useState(null); // Store file data (if needed for upload)
    const activeForm = useSelector((state) => state.adminReducer?.offline_forms?.activeForm);
    const switchActiveForm = (form) => dispatch(toggleOfflineForms(form));

    const renderOfflineForms = (formType) => {
        switch (formType) {
            case "car":
                return (
                    <VehicleCarOfflineForm />
                );

            case "bike":
                return (
                    <VehicleBikeOfflineForm />
                );

            case "commercial":
                return (
                    <VehicleCommercialOfflineForm />
                );

            case "health":
                return (
                    <HealthOfflineForm />
                );

            // case "travel":
            //     return (
            //         <TravelOfflineForm
            //             watch={watch}
            //             control={control}
            //             errors={errors}
            //             register={register}
            //             loading={loading}
            //             setValue={setValue}
            //             clearErrors={clearErrors}
            //             setError={setError}
            //             activeForm={activeForm}
            //             reset={reset}
            //         />
            //     );

            // case "loan":
            //     return (
            //         <LoanOfflineForm
            //             errors={errors}
            //             register={register}
            //             loading={loading}
            //             control={control}
            //             clearErrors={clearErrors}
            //             activeForm={activeForm}
            //             reset={reset}
            //         />
            //     );

            case "life":
                return (
                    <LifeOfflineForm />
                );

            default:
                return <>please select form</>;
        }
    };



    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === "text/csv") {
            setFileData(selectedFile); // Store file data for later upload
        } else {
            dispatch(setErrorMessage("Please upload a valid CSV file."))
            setFileData(null); // Clear the file state
        }
    };
    const handleUploadFile = async () => {
        if (!fileData) {
            dispatch(setErrorMessage("No file selected."));
            return
        }

        setFileLoading(true); // Show loading spinner
        // setError(""); // Clear any previous errors

        try {
            const formData = new FormData();
            formData.append("file", fileData);

            // Upload the file
            const res = await axios.post('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/upload', formData);
            if (res.status === 200) {
                dispatch(setSuccessMessage('Upload successful'))
            } else {
                dispatch(setErrorMessage('Upload failed. Please try again.'))
            }
        } catch (error) {
            dispatch(setErrorMessage("An error occurred while uploading the file. Please try again."))
        } finally {
            setFileLoading(false);
        }
    };

    return (
        <>
            <Box>
                <Container maxWidth="lg">
                    <Box mt={2} />
                    <Stack gap={2} direction="row" flexWrap='wrap'>
                        <Chip
                            clickable
                            onClick={() => switchActiveForm("car")}
                            icon={<DirectionsCarRoundedIcon fontSize="medium" />}
                            label="Car"
                            color="primary"
                            variant={activeForm === "car" ? "filled" : "outlined"}
                        />
                        <Chip
                            clickable
                            onClick={() => switchActiveForm("bike")}
                            icon={<TwoWheelerRoundedIcon fontSize="medium" />}
                            label="Two Wheeler"
                            color="primary"
                            variant={activeForm === "bike" ? "filled" : "outlined"}
                        />
                        <Chip
                            clickable
                            label="Commercial"
                            onClick={() => switchActiveForm("commercial")}
                            icon={<DirectionsBusFilledRoundedIcon />}
                            color="primary"
                            variant={activeForm === "commercial" ? "filled" : "outlined"}
                        />
                        <Chip
                            clickable
                            label="Health"
                            onClick={() => switchActiveForm("health")}
                            icon={<VolunteerActivismRoundedIcon />}
                            color="primary"
                            variant={activeForm === "health" ? "filled" : "outlined"}
                        />
                        <Chip
                            clickable
                            label="Travel"
                            onClick={() => switchActiveForm("travel")}
                            icon={<LocalAirportRoundedIcon />}
                            color="primary"
                            variant={activeForm === "travel" ? "filled" : "outlined"}
                        />
                        <Chip
                            clickable
                            label="Loan"
                            onClick={() => switchActiveForm("loan")}
                            icon={<MonetizationOnRoundedIcon />}
                            color="primary"
                            variant={activeForm === "loan" ? "filled" : "outlined"}
                        />
                        <Chip
                            clickable
                            label="Life"
                            onClick={() => switchActiveForm("life")}
                            icon={<FavoriteRoundedIcon />}
                            color="primary"
                            variant={activeForm === "life" ? "filled" : "outlined"}
                        />
                    </Stack>
                    {<Stack direction='row' sx={{ mt: 2, maxWidth: '500px', gap: 1 }}>
                        <Button startIcon={<CloudDownloadIcon />} variant="outlined" fullWidth LinkComponent="a" href="https://samplecsvfiles.s3.ap-south-1.amazonaws.com/combined_policy_customer_data+(1).csv">
                            Sample Download
                        </Button>
                        <TextField
                            type="file"
                            onChange={handleFileChange}
                            label="Upload File"
                            variant="outlined"
                            inputProps={{ accept: ".csv" }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton disabled={fileLoading} aria-label="submit" onClick={handleUploadFile}>
                                            {fileLoading ? <CircularProgress size={24} /> : <UploadIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth

                        />
                    </Stack>}
                </Container>

                {activeForm && (
                    <Suspense fallback={<CircularProgress size={18} />}>
                        {renderOfflineForms(activeForm)}
                    </Suspense>
                )}
            </Box>
        </>
    )
}

export default React.memo(OfflinePolicy)