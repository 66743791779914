import React from "react";
import { Typography, Container, List, ListItem, ListItemText } from "@mui/material";

const styles = {
    container: {
      marginTop: "32px",
      marginBottom: "32px",
      padding: "20px 30px",
    },
    heading: {
      marginBottom: "2px",
      color: "#23a8fa",
      fontWeight: "bold",
  
    },
    subHeading: {
      marginTop: "2px",
      fontWeight: "bold",
      color: "#555",
  
    },
    listItem: {
      marginBottom: "2px",
      marginTop:'3px',
      backgroundColor: "#f5f5f5",
      // padding: "2px",
      borderRadius: "4px",
      textAlign:'justify',
      
      padding:'0px',
      margin:'0px',
    },
  };

const HealthInsuranceForFamily = () => {
  return (
    <Container style={styles.container}>
      <Typography variant="h2" textAlign='center'   style={styles.heading} gutterBottom>
      Family Health Insurance
      </Typography>
      <Typography variant="body1" paragraph>
      Family health insurance is a type of health insurance policy that covers multiple members of a family, typically including the policyholder, their spouse, 
and their dependent children. This type of insurance is designed to provide comprehensive medical coverage for a family, including doctor visits, hospital stays, prescription drugs, and other medical expenses. Family health insurance policies can be obtained through an employer, a private insurance company, or a government program or the Children's Health Insurance Program (CHIP). It often covers more than one person and can be more cost-effective than purchasing separate policies for each individual family member.

      </Typography>
       



     <Typography variant="h4" style={styles.heading} >
      Benefits of Buying Health Insurance Plans for Family?
      </Typography>

      <Typography variant="body1" paragraph>
      The health insurance plans for family come with many major benefits. As we said earlier, you can attain coverage for all your dependents under one plan with a family insurance. Here are some of the benefits offered by Health Insurance Plans for Family:
 
      </Typography>
<List>
<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>Financial Protection</Typography>}
    secondary={
      <Typography variant="body1">
        Health insurance helps to protect a family's finances by covering the costs of medical care, including doctor visits, hospital stays, and prescription drugs. This can help to prevent unexpected medical expenses from becoming a financial burden.
      </Typography>
    }
  />
</ListItem>


<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>Access to Medical Care</Typography>}
    secondary={
      <Typography variant="body1">
        Having health insurance gives families access to medical care, including preventative care and screenings. This can help to catch health issues early and improve overall health outcomes.
      </Typography>
    }
  />
</ListItem>


<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>Flexibility</Typography>}
    secondary={
      <Typography variant="body1">
        Family health insurance plans often provide more coverage options and flexibility than individual plans, which can be beneficial for families with children.
      </Typography>
    }
  />
</ListItem>


<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>Peace of Mind</Typography>}
    secondary={
      <Typography variant="body1">
        Knowing that you and your loved ones have access to medical care can provide peace of mind and alleviate stress in the event of an unexpected illness or injury.
      </Typography>
    }
  />
</ListItem>

<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>Cost-effective</Typography>}
    secondary={
      <Typography variant="body1">
        Family health insurance plans are often more cost-effective than purchasing separate policies for each family member. As family health insurance plans cover the entire family under one plan, there is no need to buy separate plan for each individual, which eliminates the stress of paying multiple premiums.
      </Typography>
    }
  />
</ListItem>


<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>Coverage for Dependents</Typography>}
    secondary={
      <Typography variant="body1">
        Family health insurance plans typically cover dependents, including children, which is a great benefit for parents. You need not worry about the medical expenses of your dependents anymore in case of medical emergencies or compromise on the treatment.
      </Typography>
    }
  />
</ListItem>


<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>COVID Health Insurance for Family</Typography>}
    secondary={
      <Typography variant="body1">
        Secure yourself and your dependents financially in case of medical emergencies like Covid-19 with Covid-specific plans. Many insurance firms are providing specific plans that cover covid medical treatment.
      </Typography>
    }
  />
</ListItem>

<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>Tax Benefits</Typography>}
    secondary={
      <Typography variant="body1">
        Policyholders can avail of tax benefits under Section 80D of the Income Tax Act. If the health insurance is taken for your family, you are eligible for tax benefits.
      </Typography>
    }
  />
</ListItem>


<ListItem style={styles.listItem}>
  <ListItemText
    primary={<Typography variant="h5" style={styles.subHeading}>Important Considerations</Typography>}
    secondary={
      <Typography variant="body1">
        It is important to note that health insurance plans can vary widely, so it's important to review the details and compare plans before purchasing.
      </Typography>
    }
  />
</ListItem>
</List>

<Typography variant="h4" style={styles.heading}>
  What is Covered in a Family Health Insurance Plan?
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Outpatient Care:</Typography>}
      secondary={
        <Typography variant="body1">
          Coverage for visits to a primary care physician, specialists, or urgent care centers for treatment of illnesses and injuries.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Inpatient Care:</Typography>}
      secondary={
        <Typography variant="body1">
          Coverage for hospital stays for surgeries, other medical procedures, and care in skilled nursing facilities or rehab centers.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Prescription Drugs:</Typography>}
      secondary={
        <Typography variant="body1">
          Coverage for a wide range of prescription drugs, including those for chronic conditions like diabetes, hypertension, and asthma.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Mental Health and Substance Abuse Services:</Typography>}
      secondary={
        <Typography variant="body1">
          Coverage for therapy, counseling, psychiatric care, and treatment for substance abuse issues.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Preventive Care:</Typography>}
      secondary={
        <Typography variant="body1">
          Coverage for regular check-ups, screenings, and vaccinations to help prevent illnesses and injuries.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Pediatric Services:</Typography>}
      secondary={
        <Typography variant="body1">
          Coverage for well-child visits and routine vaccinations for children.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Dental and Vision Care:</Typography>}
      secondary={
        <Typography variant="body1">
          Some plans may include coverage for dental care (cleanings, fillings, orthodontics) and vision care (eye exams, glasses, and contact lenses).
        </Typography>
      }
    />
  </ListItem>
</List>
<Typography variant="h4" style={styles.heading}>
      What is Not Covered in a Family Health Insurance Plan?
    </Typography>

    <List>
      <ListItem style={styles.listItem}>
        <ListItemText
          primary={<Typography variant="h5" style={styles.subHeading}>Elective Procedures or Treatments:</Typography>}
          secondary={
            <Typography variant="body1">
              These include procedures or treatments that are not medically necessary, such as cosmetic surgery or weight loss procedures.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={<Typography variant="h5" style={styles.subHeading}>Experimental Treatments or Procedures:</Typography>}
          secondary={
            <Typography variant="body1">
              Treatments or procedures that are still being tested or are not widely accepted by the medical community are typically not covered.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={<Typography variant="h5" style={styles.subHeading}>Alternative Therapies:</Typography>}
          secondary={
            <Typography variant="body1">
              Treatments that are not considered to be conventional medicine, such as acupuncture or chiropractic care, may not be covered.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={<Typography variant="h5" style={styles.subHeading}>Long-term Care:</Typography>}
          secondary={
            <Typography variant="body1">
              Some family health insurance plans may not cover long-term care, such as care in a nursing home or assisted living facility.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={<Typography variant="h5" style={styles.subHeading}>Pre-existing Conditions:</Typography>}
          secondary={
            <Typography variant="body1">
              Some plans may not cover pre-existing conditions, which are medical conditions that existed before the individual was enrolled in the plan.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={<Typography variant="h5" style={styles.subHeading}>Dental and Vision Care:</Typography>}
          secondary={
            <Typography variant="body1">
              Some family health insurance plans may not include coverage for dental or vision care.
            </Typography>
          }
        />
      </ListItem>

      <ListItem style={styles.listItem}>
        <ListItemText
          primary={<Typography variant="h5" style={styles.subHeading}>Prescription Drugs:</Typography>}
          secondary={
            <Typography variant="body1">
              Some plans may not cover certain prescription drugs, or may require higher co-pays or deductibles for specific drugs.
            </Typography>
          }
        />
      </ListItem>
    </List>

    <Typography variant="h4" style={{ marginTop: '10px', fontWeight: 'bold',color:'#23a8fa' }}>
      Eligibility Criteria to Buy a Family Health Insurance Plan
    </Typography>

    <Typography variant="body1" style={{ marginTop: '20px' }}>
      The eligibility criteria to buy a family health insurance plan can vary depending on the insurance provider and the specific plan. However, generally, most insurance providers will have the following criteria in place:
    </Typography>

    <List>
      <ListItem variant="body1" style={styles.listItem} >
       1. The primary policyholder should be an adult, typically above 18 years of age, who will be responsible for paying
        the premiums and making the claims.
      </ListItem>
      <ListItem variant="body1" style={styles.listItem}>
      2. The policyholder should be married or in a domestic partnership, or should have dependents who are covered under
        the policy.
      </ListItem>
      <ListItem variant="body1" style={styles.listItem}>
       3. The dependents who are covered under the policy can include children, spouse, and in some cases, parents or
        grandparents.
      </ListItem>
      <ListItem variant="body1" style={styles.listItem}>
      4.  The policyholder and dependents should be legal residents of the country where the insurance is being purchased,
        and should have a valid identification number such as a Social Security number.
      </ListItem>
      <ListItem variant="body1" style={styles.listItem}>
       5. The policyholder should not have any pre-existing medical conditions that would make them ineligible for
        coverage.
      </ListItem>
      <ListItem variant="body1" style={styles.listItem}>
      6. It's important to note that some insurance providers may have additional criteria in place, such as minimum
        income requirements or waiting periods for coverage to begin.
      </ListItem>
    </List>

    <Typography variant="body1" style={{ marginTop: '20px' }}>
      It's also worth mentioning that some family health insurance plans are offered through an employer, and in those cases, the eligibility criteria may be different. Employers may have specific rules and requirements for enrolling in the plan, such as being a full-time employee, or being employed for a certain period of time.
    </Typography>

    <Typography variant="h4" style={styles.heading} mt={2}>
        Benefits of Buying Health Insurance Plans for Family Online
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Convenience:</Typography>}
            secondary={
              <Typography variant="body1">
                Online health insurance shopping allows you to compare plans and prices from the comfort of your own home, 
                without having to schedule appointments or meet with insurance agents.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Time-saving:</Typography>}
            secondary={
              <Typography variant="body1">
                Online platforms allow you to quickly and easily compare different plans, coverage options, and prices, 
                saving you time and effort.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Better Comparison:</Typography>}
            secondary={
              <Typography variant="body1">
                Online platforms provide a wider range of options and plans to compare. It allows you to compare plans from 
                different insurers and different states, so you can find the best plan for your family's needs.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Easy to Understand:</Typography>}
            secondary={
              <Typography variant="body1">
                Many online platforms have user-friendly interfaces and provide detailed information about the plans and their coverage, 
                making it easy for you to understand what you're buying.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Access to Customer Support:</Typography>}
            secondary={
              <Typography variant="body1">
                Online platforms often have customer support teams available to answer any questions you may have about the plans, 
                coverage options, and enrollment process.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Cost-effective:</Typography>}
            secondary={
              <Typography variant="body1">
                In most cases, buying health insurance online can save you money. Online platforms often have lower overhead costs, 
                which means they can offer lower prices.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Faster Processing:</Typography>}
            secondary={
              <Typography variant="body1">
                Once you have chosen a plan, the enrollment process can be completed quickly and easily online, with no need to wait 
                for paperwork to be processed or for an agent to contact you.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        Things to Consider Before Buying a Family Health Insurance Plan
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Coverage:</Typography>}
            secondary={
              <Typography variant="body1">
                Understand what is covered under the plan, including treatments, procedures, and medications, as well as any exclusions or limits.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Network:</Typography>}
            secondary={
              <Typography variant="body1">
                Ensure the plan has a wide network of providers, including doctors, hospitals, and specialists, in your area.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Cost:</Typography>}
            secondary={
              <Typography variant="body1">
                Compare costs, including the monthly premium, co-payments, deductibles, and out-of-pocket maximums.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Prescription Coverage:</Typography>}
            secondary={
              <Typography variant="body1">
                Check if the plan covers prescription drugs and any limits on the types or amounts of drugs covered.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Out-of-network Coverage:</Typography>}
            secondary={
              <Typography variant="body1">
                Check if the plan covers out-of-network services and at what cost.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Lifetime and Annual Limits:</Typography>}
            secondary={
              <Typography variant="body1">
                Verify if there are any lifetime or annual limits on the amount the insurer will pay for medical expenses.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Renewability:</Typography>}
            secondary={
              <Typography variant="body1">
                Check if the plan is renewable and the conditions that need to be met for renewal.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Pre-existing Conditions:</Typography>}
            secondary={
              <Typography variant="body1">
                Verify if the plan covers pre-existing conditions and any waiting periods before coverage begins.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Additional Benefits:</Typography>}
            secondary={
              <Typography variant="body1">
                Look for additional benefits such as wellness programs, telemedicine, or maternity coverage.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Customer Service:</Typography>}
            secondary={
              <Typography variant="body1">
                Ensure the plan has a reliable customer service team to assist with claims and other issues.
              </Typography>
            }
          />
        </ListItem>
      </List>

      {/* Section: How to File a Claim */}
      <Typography variant="h4" style={styles.heading}>
        How to File a Claim for a Family Health Insurance Plan?
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Gather Necessary Information:</Typography>}
            secondary={
              <Typography variant="body1">
                Have all the necessary details like policy number, date of service, and provider name.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Check Policy Details:</Typography>}
            secondary={
              <Typography variant="body1">
                Review the policy to confirm the service received is covered.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Get the Required Forms:</Typography>}
            secondary={
              <Typography variant="body1">
                Obtain the claim form from the insurance company online or by contacting customer service.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Fill Out the Form:</Typography>}
            secondary={
              <Typography variant="body1">
                Complete the form accurately with all the required details.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Attach Supporting Documents:</Typography>}
            secondary={
              <Typography variant="body1">
                Include relevant bills, receipts, or medical reports.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Submit the Form:</Typography>}
            secondary={
              <Typography variant="body1">
                Send the completed form and documents to the insurance company.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Follow Up:</Typography>}
            secondary={
              <Typography variant="body1">
                Wait for the claim to be processed and follow up if needed.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        FAQs
      </Typography>
      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                How does a family health insurance plan work?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Under the family health insurance plan, a fixed sum shall be
                shared among a group of family members. The insured sum will be
                used whenever a family member avails of medical services and
                files a claim. Once the sum insured exhausts, the policyholder
                needs to renew the policy or choose the restore benefit to
                renew the plan.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                What are the documents required to raise a reimbursement claim?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Following documents are required to raise a family health
                insurance claim:
                <ul>
                  <li>Policy document</li>
                  <li>Duly filled health insurance claim form</li>
                  <li>Hospital discharge report</li>
                  <li>Consultation report and investigation results</li>
                  <li>Hospital bills</li>
                  <li>Bill receipts</li>
                </ul>
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                How to add family members to the existing family health policy?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                The policyholder can add family members to the existing family
                health policy at the time of renewal. The premium adds up to
                the newly added member.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Do I need to take a separate health policy if my family and I
                are already covered in a corporate health policy?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Yes, a corporate health policy provided by the company of the
                policyholder may not cover everything in terms of treatment.
                Moreover, the policyholder is eligible to avail the corporate
                health policy only until he is working with the organization. So,
                it is highly recommended to buy a separate health cover for your
                family.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                What happens in the event the main insured individual passes
                away?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Any surviving family members who have a family floater policy may
                renew it. Yes, the other adult policyholder may continue to be
                covered under the policy and receive the continuity benefit even
                if the primary insured passes away or reaches the maximum age for
                renewal. In order to change the policy's proposer, they must
                notify the insurer.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Which is better? Family health insurance plan or individual health
                plan?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                A family health insurance plan covers the entire family while an
                individual health plan covers only the individual policyholder.
                If you want to protect your family against the medical odds and
                save up on your hard-earned money, it is recommended to buy a
                family health plan over an individual health plan.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Are cashless services offered as part of a family health insurance
                plan?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Yes, all health insurance firms in the country offer cashless
                hospitalization services under family health insurance plans. You
                can confirm the same with your insurer while buying a policy.
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                Is there any waiting period for family health insurance policies?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                Yes. The common waiting periods include:
                <ul>
                  <li>2 to 4 years of waiting for pre-existing conditions</li>
                  <li>A 30-day initial waiting period</li>
                  <li>
                    A one or two-year waiting time for particular conditions or
                    procedures
                  </li>
                  <li>9 to 12 month waiting period for maternity insurance</li>
                  <li>A 90-day waiting period for serious sickness</li>
                </ul>
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={
              <Typography variant="h5" style={styles.subHeading}>
                How long may my child remain covered by the family health
                insurance?
              </Typography>
            }
            secondary={
              <Typography variant="body1" style={styles.bodyText}>
                The majority of family health insurance plans include dependent
                children up to age 25. Depending on the plan, the exit age may be
                different. Additionally, the child will not be eligible for the
                coverage if he or she marries or begins working before turning 25.
              </Typography>
            }
          />
        </ListItem>
      </List>
</Container>
  );
};



export default HealthInsuranceForFamily;