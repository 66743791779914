import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Button,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  InputAdornment,
  Autocomplete
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { doucmentsUpload } from "../../functions/upload-doc.js"; 
import MainCard from "../../ui-component/cards/MainCard.js";
import { useDispatch, useSelector } from "react-redux";
import { getPincode } from "../../../../Loan/api/service";
import axios from "axios";
import { common_api } from "../../../../Config";
import { setSuccessMessage } from "../../../../Redux/action-creators/successAction";
import { setError as setErrorMessage } from "../../../../Redux/action-creators/errorAction.js";
import { getLifePlains } from "../../../admin/api/adminService.js";
 
const authorRoles = ['Process Manager', 'Admin', 'Sales Head'];
const LifeOfflineForm = () => {
  const dispatch = useDispatch();
//   let role = useSelector((state) => state.adminReducer.loginData.Role);
let role = "Admin";
  const empId = useSelector((state) => state?.adminReducer?.loginData?.empid);
  const { watch, register, handleSubmit, formState: { errors }, clearErrors, reset, control, setValue } = useForm()
  const [loading, setLoading] = useState(false);
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [pospOptions, setPospOptions] = useState([]);
  const [teleOptions, setTeleOptions] = useState([]);
  const selectedEmployeeId = watch('selectedEmployee', '');
  const selectedPospId = watch('selectedPosp', '');
  const selectedTeleId = watch('selectedTele', '');
  const channel = watch('channel', '');
  const docType = watch('documentType', '');
  const insurerName = watch('insurerName')
  const planType = watch('planType');
  const issueDate = watch('policy_issuedate')
  const planName = watch('planName');
  const theme = useTheme();
  const [plans, setPlans] = useState({});
  const getPlans = async () => {
    const plans1 = await getLifePlains();
    setPlans(plans1.data)
  }
 
  useEffect(() => {
    getPlans()
  }, [])
 
 
  const getReportEmployee = () => {
    if (selectedPospId && !loading && channel === 'Posp') {
      const [foundReport] = pospOptions.filter((posp) => (posp.id == selectedPospId.id));
      if (foundReport) {
        setValue('reportingEmployee', foundReport && foundReport.report_employee
          ? `${foundReport.report_employee.emp_name} (${foundReport.report_employee.empid})`
          : '---');
      }
    }
    if (selectedTeleId && !loading && channel === 'Tele') {
      const [foundReport] = teleOptions.filter((employee) => (employee.id == selectedTeleId.id));
      if (foundReport) {
        setValue('reportingEmployee', foundReport && foundReport.report_employee
          ? `${foundReport.report_employee.emp_name} (${foundReport.report_employee.empid})`
          : '---');
      }
    }
    if (selectedEmployeeId && !loading && channel === 'DIRECT') {
      const [foundReport] = employeeOptions.filter((employee) => (employee.id == selectedEmployeeId.id));
      if (foundReport) {
        setValue('reportingEmployee', foundReport && foundReport.report_employee
          ? `${foundReport.report_employee.emp_name} (${foundReport.report_employee.empid})`
          : '---');
      }
    }
  }
  useEffect(() => {
    getReportEmployee()
  }, [selectedEmployeeId, selectedPospId, selectedTeleId])
  useEffect(() => {
 
    const fetchPospOptions = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/poplist"
        );
        const formattedOptions = res.data.map((agent) => ({
          id: agent.agent_id,
          label: `${agent.agent_name} (${agent.agent_id})`,
          report_employee: agent.reporting_manager
        }));
        setPospOptions(formattedOptions);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
 
    const fetchEmployeeOptions = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/employeedata"
        );
        const formattedOptions = res.data.map((employee) => ({
          id: employee.empid,
          label: `${employee.emp_name} (${employee.empid})`,
          report_employee: JSON.parse(employee.reporting_manager)
        }));
        setEmployeeOptions([
          // { id: '', label: 'Select Employee', report_employee: {}},
          ...formattedOptions]);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
 
    const fetchTelecaller = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/telesalesdata"
        );
        const formattedOptions = res.data.map((employee) => ({
          id: employee.empid,
          label: `${employee.emp_name} (${employee.empid})`,
          report_employee: JSON.parse(employee.reporting_manager)
        }));
        setTeleOptions(formattedOptions);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
 
    if (channel === "DIRECT") {
      authorRoles.includes(role) ?
        fetchEmployeeOptions() : setValue('selectedEmployee', empId)
    }
    if (channel === "Posp") {
      fetchPospOptions();
    }
    if (channel === "Tele") {
      fetchTelecaller();
    }
    setValue('reportingEmployee', '')
  }, [channel]);
 
 
  const handleFileChange = async (file) => {
    if (file.type === "application/pdf") {
      try {
        setLoading(true)
        const response = await doucmentsUpload(file, file.type);
        console.log("Upload successful:", response);
        // setSnackbarMessage("Upload successful!");
        return response;
      } catch (error) {
        console.error("Upload error:", error);
        // setSnackbarMessage("Upload failed. Please try again.");
      }
      finally {
        setLoading(false)
      }
    } else {
      // setError("file", {
      //   type: "manual",
      //   message: "Please select a valid PDF file.",
      // });
      // setSnackbarMessage("Please select a valid PDF file.");
    }
    // setSnackbarOpen(true);
    return null;
  };
 
  // pincode_search
  async function searchPincode(pincode) {
    setPincodeLoading(true);
    try {
      let res = await getPincode();
      if (res.status === 200) {
        let state = res.data.filter((state) => state.PINCODE === pincode);
        clearErrors('state')
        clearErrors('city')
        setValue("state", state[0].STATECD);
        setValue("city", state[0].CITYCD);
      } else {
        setValue("state", "");
        setValue("city", "");
      }
    } catch (error) {
      console.log({ error: "internal error" });
      setPincodeLoading(false);
    } finally {
      setPincodeLoading(false);
    }
  }
  // validate the document number based on document type
  const getDocumentNumberValidation = () => {
    switch (docType) {
      case "AADHAR":
        return {
          required: "aadhar number required",
          pattern: {
            value: /^[0-9]{12}$/,
            message: "invalid aadhar",
          },
        };
 
      case "PAN":
        return {
          required: "Pan number required",
          pattern: {
            value: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,
            message: "invalid pan",
          },
        };
 
      case "PASSPORT":
        return {
          required: "passport required",
          pattern: {
            value: /^[A-Z]{1}[0-9]{7}$/,
            message: "passport invalid",
          },
        };
      default:
        return { required: "document number required" };
    }
  };
 
  const submitLifeForm = async (data) => {
    console.log(data)
    const customer_create_obj = {
      org_id: 100,
      employee_id: data?.channel === 'DIRECT' ? (authorRoles.includes(role) ? data?.selectedEmployee.id : data?.selectedEmployee) : null,
      agent_id: data?.channel === 'Posp' ? data?.selectedPosp.id : 1000,
      customer_name: data?.name,
      address: data?.address,
      state: data?.state,
      city: data?.city,
      pincode: data?.pincode,
      phone: data?.phone,
      email: data?.email,
      dob: data?.dob === '' ? null : data?.dob,
      gender: data?.gender,
      marital_status: data?.marital_status,
      document_type: data?.documentType === '' ? null : data?.documentType,
      document_number: data?.documentType === '' ? null : data?.documentNumber,
    };
    const policy_create_obj = {
      org_id: 100,
      employee_id: data?.channel === 'DIRECT' ? (authorRoles.includes(role) ? data?.selectedEmployee.id : data?.selectedEmployee) : null,
      agent_id: data?.channel === 'Posp' ? data?.selectedPosp.id : 1000,
      phone: data?.phone,
      application_id: `'${data?.application_id} (OFFLINE)`,
      product_type: "LIFE",
      product_subtype: data?.planType,
      policy_type: "NEW_BUSSINESS",
      policy_sub_type: "INDIVIDUAL",
      policy_issue_date: data?.policy_issuedate !== '' ? data?.policy_issuedate : null,
      provider: data?.insurerName,
      plan_name: data?.planName,
      policy_number: "'" + data?.application_id,
      policy_startdate: data?.policy_startdate,
      policy_enddate: data?.policy_enddate,
      sum_insured: Number(data?.cover_amount),
      premium_without_gst: Number(data?.netpremium),
      premium_with_gst: Number(data?.grosspremium),
      channels: data?.channel,
      pdf_link: data?.policy_copy,
      policy_status: "RECONCILIATION",
      tenure: data?.cover_,
      policy_payment_term: data?.ppt,
      payment_term: data?.pt,
      payment_mode: data?.payment_mode
    }
    console.log(customer_create_obj)
    console.log(policy_create_obj)
    try {
      setLoading(true);
      await axios.post(
        `${common_api}/insurance/crm/v1/org/agent/customer`,
        customer_create_obj
      );
 
      const policyRes = await axios.post(
        `${common_api}/insurance/policy`,
        policy_create_obj
      );
      if (policyRes.data.statusCode === 200) {
        reset();
        dispatch(setSuccessMessage("Data saved successfully"));
      }
      if (policyRes.data.statusCode === 209) {
        dispatch(setErrorMessage(policyRes.data.message));
      }
    } catch (error) {
      dispatch(setErrorMessage(JSON.stringify(error)));
    } finally {
      setLoading(false);
    }
  }
 
 
 
  return (
    <MainCard>
      <Box mt={2} component='form' onSubmit={handleSubmit(submitLifeForm)}>
        <Typography variant="caption">Customer Details</Typography>
        <Divider />
        <Grid container rowSpacing={3} mt={0} columnSpacing={3}>
          <Grid item xs={12} md={3}>
            <Controller
              name="name"
              control={control}
              defaultValue=""  // Default value is an empty string
              rules={{
                required: 'Name is required',
                minLength: { value: 3, message: 'Name must be at least 3 characters long' }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={<>Name <span style={{ color: "red" }}>*</span></>}
                  variant="outlined"
                  fullWidth
                  error={!!errors.name}
                />
              )}
            />
            {errors.name && <FormHelperText error>{errors.name.message}</FormHelperText>}
          </Grid>

          <Grid item xs={12} md={3}>
            <Controller
              name="address"
              control={control}
              defaultValue=""
              rules={{
                required: 'Address is required',
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={<>Address <span style={{ color: "red" }}>*</span></>}
                  variant="outlined"
                  fullWidth
                  error={!!errors.address}
                />
              )}
            />
            {errors.address && <FormHelperText error>{errors.address.message}</FormHelperText>}
          </Grid>
 
          <Grid item xs={12} md={3}>
            <Controller
              name="marital_status"
              control={control}
              defaultValue=""
              rules={{ required: 'Marital Status is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={<>Marita Status <span style={{ color: "red" }}>*</span></>}
                  variant="outlined"
                  fullWidth
                  error={!!errors.marital_status}
                >
                  <MenuItem value="">
                    <em>Select Status</em>
                  </MenuItem>
                  <MenuItem value="Y">Married</MenuItem>
                  <MenuItem value="N">UnMarried</MenuItem>
 
                </TextField>
              )}
            />
            {errors.marital_status && <FormHelperText error>{errors.marital_status.message}</FormHelperText>}
          </Grid>
 
          <Grid item xs={12} md={3}>
            <Controller
              name="gender"
              control={control}
              defaultValue=""
              rules={{ required: 'Gender is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
 
                  label={<>Gender <span style={{ color: "red" }}>*</span></>}
                  variant="outlined"
                  fullWidth
                  error={!!errors.gender}
                >
                  <MenuItem value="">
                    <em>Select Status</em>
                  </MenuItem>
                  <MenuItem value="M">Male</MenuItem>
                  <MenuItem value="F">Female</MenuItem>
                </TextField>
              )}
            />
            {errors.gender && <FormHelperText error>{errors.gender.message}</FormHelperText>}
          </Grid>
 
          <Grid item xs={12} md={3}>
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{ required: "Phone is Required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={<>Phone <span style={{ color: "red" }}>*</span></>}
                  error={!!errors.phone}
                  helperText={errors.phone ? errors.phone.message : ""}
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "").slice(0, 10);
                    field.onChange(value);
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Controller
              name="email"
              control={control}
              defaultValue=""  // Default value is an empty string
              rules={{
                required: 'email is required',
              }}  // Validation for Name field
              render={({ field }) => (
                <TextField
                  {...field}
                  label={<>Email <span style={{ color: "red" }}>*</span></>}
                  variant="outlined"
                  fullWidth
                  error={!!errors.email}
                />
              )}
            />
            {errors.email && <FormHelperText error>{errors.email.message}</FormHelperText>}
          </Grid>
 
          <Grid item xs={12} md={3}>
            <Controller
              name="pincode"
              control={control}
              defaultValue=""
              rules={{ required: "pincode is Required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={<>Pincode <span style={{ color: "red" }}>*</span></>}
                  error={!!errors.pincode}
                  helperText={errors.pincode ? errors.pincode.message : ""}
                  inputProps={{ maxLength: 6 }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "").slice(0, 6);
                    field.onChange(value);
                    if (value.length === 6) {
                      searchPincode(value)
                    } else {
                      setValue('state', '')
                      setValue('city', '')
                    }
                  }}
                  InputProps={
                    {
                      endAdornment: <InputAdornment position="end">
                        {pincodeLoading && <CircularProgress size={20} color="secondary" />}
                      </InputAdornment>
                    }
                  }
                />
              )}
            />
          </Grid>
 
          <Grid item xs={12} md={3}>
            <Controller
              name="city"
              control={control}
              defaultValue=""  // Default value is an empty string
              rules={{
                required: 'City is required'
              }}  // Validation for Name field
              render={({ field }) => (
                <TextField
                  {...field}
 
                  label={<>City <span style={{ color: "red" }}>*</span></>}
                  variant="outlined"
                  fullWidth
                  error={!!errors.city}
                />
              )}
            />
            {errors.city && <FormHelperText error>{errors.city.message}</FormHelperText>}
          </Grid>

          <Grid item xs={12} md={3}>
            <Controller
              name="state"
              control={control}
              defaultValue=""  // Default value is an empty string
              rules={{
                required: 'State is required',
              }}  // Validation for Name field
              render={({ field }) => (
                <TextField
                  {...field}
                  label={<>State <span style={{ color: "red" }}>*</span></>}
                  variant="outlined"
                  fullWidth
                  error={!!errors.state}
                />
              )}
            />
            {errors.state && <FormHelperText error>{errors.state.message}</FormHelperText>}
          </Grid>

          <Grid item xs={12} md={3}>
            <Controller
              name="dob"
              control={control}
              defaultValue=""  // Default value is an empty string
              // rules={{
              //   required: 'Date of is required',
              // }}  // Validation for Name field
              render={({ field }) => (
                <TextField
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...field}
                  label={<>Date of Birth <span style={{ color: "red" }}>*</span></>}
                  variant="outlined"
                  fullWidth
                // error={!!errors.dob}
                />
              )}
            />
            {/* {errors.dob && <FormHelperText error>{errors.dob.message}</FormHelperText>} */}
          </Grid>
 
          <Grid item xs={12} md={3}>
            <Controller
              name="documentType"
              control={control}
              defaultValue=""
              rules={{ required: 'Document Type is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label={<>Document Type <span style={{ color: "red" }}>*</span></>}
                  variant="outlined"
                  fullWidth
                  error={!!errors.documentType}
                >
                  <MenuItem value="">
                    <em>Select Status</em>
                  </MenuItem>
                  <MenuItem value={"PAN"}>PAN</MenuItem>
                  <MenuItem value={"AADHAR"}>AADHAAR</MenuItem>
                  <MenuItem value={"PASSPORT"}>PASSPORT</MenuItem>
                </TextField>
              )}
            />
            {errors.documentType && <FormHelperText error>{errors.documentType.message}</FormHelperText>}
          </Grid>
 
          {docType !== '' && 
          <Grid item xs={12} md={3}>
            <Controller
              defaultValue=''
              name={"documentNumber"}
              control={control}
              rules={{
                ...getDocumentNumberValidation(),
              }}
              render={({ field }) => (
                <TextField
                  name="documentNumber"
                  label={<>Document Number <span style={{ color: "red" }}>*</span></>}
                  {...field}
                  inputProps={{ maxLength: 12 }}
                  onChange={(e) => {
                    const value = e.target.value.toUpperCase().slice(0, 12);
                    field.onChange(value);
                  }}
                  error={!!errors.documentNumber}
                />
              )}
            />
            {errors.documentNumber && <FormHelperText error>{errors.documentNumber.message}</FormHelperText>}
          </Grid>}
 
        
        {/* policy details */}
          <Grid item xs={12}>
            <Typography variant="caption">Life Details</Typography>
            <Divider />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth error={!!errors.insurerName}>
              <InputLabel id="simple-select-label">Insurer company <span style={{ color: "red" }}>*</span></InputLabel>
              <Controller
                name="insurerName"
                control={control}
                defaultValue=""
                rules={{ required: "Please select an option" }} // Validation rule
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Insurer company"
 
                  >
                    {Object.keys(plans).map((cmpny) => {
                      return <MenuItem value={cmpny}>{cmpny}</MenuItem>
                    })}
                  </Select>
                )}
              />
              {errors.insurerName && <FormHelperText>{errors.insurerName.message}</FormHelperText>}
            </FormControl>
          </Grid>
 
          <Grid item xs={12} md={3}>
            <FormControl fullWidth error={!!errors.planType}>
              <InputLabel id="simple-select-label">Plan Type <span style={{ color: "red" }}>*</span></InputLabel>
              <Controller
                name="planType"
                control={control}
                defaultValue=""
                rules={{ required: "Please select an option" }} // Validation rule
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Plan Type"
                  >
                    {
                      insurerName && Object.keys(plans[insurerName || '']).map((planType) => {
                        return <MenuItem value={planType}>{planType}</MenuItem>
                      })
                    }
                  </Select>
                )}
              />
              {errors.insurerName && <FormHelperText>{errors.insurerName.message}</FormHelperText>}
            </FormControl>
          </Grid>
 
          <Grid item xs={12} md={3}>
            <FormControl fullWidth error={!!errors.planName}>
              <InputLabel id="simple-select-label">Plan Name <span style={{ color: "red" }}>*</span></InputLabel>
              <Controller
                name="planName"
                control={control}
                defaultValue=""
                rules={{ required: "Please select an option" }} // Validation rule
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Plan Name"
                  >
                    {
                      planType && plans[insurerName][planType].map((planType) => {
                        return <MenuItem value={planType}>{planType}</MenuItem>
                      })
                    }
                  </Select>
                )}
              />
              {errors.planName && <FormHelperText>{errors.planName.message}</FormHelperText>}
            </FormControl>
          </Grid>
 
          <Grid item xs={12} md={3}>
            <TextField
              type="number"
              inputMode="numeric"
              {...register("application_id", { required: "Org is Required" })}
              error={!!errors.application_id}
              helperText={
                errors.application_id ? errors.application_id.message : ""
              }
              fullWidth
              label={<>Proposal Number <span style={{ color: "red" }}>*</span></>}
            />
          </Grid>
 
          <Grid item xs={12} md={3}>
            <TextField
              type="number"
              inputMode="numeric"
              {...register("cover_amount", { required: "Org is Required" })}
              error={!!errors.cover_amount}
              helperText={
                errors.cover_amount ? errors.cover_amount.message : ""
              }
              fullWidth
              label={<>Sum Insured <span style={{ color: "red" }}>*</span></>}
            />
          </Grid>
 
          <Grid item xs={12} md={3}>
            <TextField
              {...register("cover_", { required: "Org is Required" })}
              error={!!errors.cover_}
              helperText={errors.cover_ ? errors.cover_.message : ""}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label={<>Cover till Age <span style={{ color: "red" }}>*</span></>}
            ></TextField>
          </Grid>
 
          <Grid item xs={12} md={3}>
            <TextField
              {...register("ppt", { required: "Org is Required" })}
              error={!!errors.ppt}
              helperText={errors.ppt ? errors.ppt.message : ""}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label={<>Policy payment term (ppt) <span style={{ color: "red" }}>*</span></>}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              {...register("pt", { required: "Org is Required" })}
              error={!!errors.pt}
              helperText={errors.pt ? errors.pt.message : ""}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label={<>payment term (pt) <span style={{ color: "red" }}>*</span></>}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth error={!!errors.payment_mode}>
              <InputLabel id="role-label">Payment Mode</InputLabel>
              <Controller
                name="payment_mode"
                control={control}
                defaultValue="" // Empty value initially
                rules={{ required: 'Payment Mode is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="role-label"
                    label="Payment Mode"
                    defaultValue=""
                  >
                    <MenuItem value="" disabled>Select a Mode</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="quaterly">Quarterly</MenuItem>
                    <MenuItem value="halfyearly">Half</MenuItem>
                    <MenuItem value="yearly">Yearly</MenuItem>
                  </Select>
                )}
              />
              {errors.role && <FormHelperText>{errors.role.message}</FormHelperText>}
            </FormControl>
          </Grid>
 
          <Grid item xs={12} md={3}>
            <TextField
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("policy_issuedate")}
              fullWidth
              label="Policy issue Date"
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("policy_startdate", {
                required: "StartDate is Required",
              })}
              error={!!errors.policy_startdate}
              helperText={
                errors.policy_startdate ? errors.policy_startdate.message : ""
              }
              fullWidth
              label={<>Policy Start Date <span style={{ color: "red" }}>*</span></>}
            />
          </Grid>
 
          <Grid item xs={12} md={3}>
            <TextField
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("policy_enddate", {
                required: "EndDate is Required",
              })}
              error={!!errors.policy_enddate}
              helperText={
                errors.policy_enddate ? errors.policy_enddate.message : ""
              }
              fullWidth
              label={<>Policy End Date <span style={{ color: "red" }}>*</span></>}
            />
          </Grid>
 
          <Grid item xs={12} md={3}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              {...register("netpremium", {
                required: "Net Premium is Required",
              })}
              error={!!errors.netpremium}
              helperText={errors.netpremium ? errors.netpremium.message : ""}
              fullWidth
              label={<>Net Premium <span style={{ color: "red" }}>*</span></>}
            />
          </Grid>
 
          <Grid item xs={12} md={3}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              {...register("grosspremium", {
                required: "Gross Premium is Required",
              })}
              error={!!errors.grosspremium}
              helperText={
                errors.grosspremium ? errors.grosspremium.message : ""
              }
              fullWidth
              label={<>Gross Premium <span style={{ color: "red" }}>*</span></>}
            />
          </Grid>
 
          <Grid item xs={12} md={3}>
            <Controller
              name="policy_copy"
              control={control}
              defaultValue={null}
              rules={{ required: issueDate ? 'Policy Copy is required' : false, }}
              render={({ field, fieldState }) => (
                <TextField
                  type="file"
                  inputProps={{ accept: "application/pdf" }}
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    if (file) {
                      clearErrors("file");
                      await handleFileChange(file);
 
                      const response = await handleFileChange(file);
                      if (response) {
                        field.onChange(response.Location);
                      }
                    }
                  }}
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ""}
                  label="Upload Policy Copy (PDF)"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
 
          <Grid item xs={12}>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              fullWidth
              sx={{ borderRadius: theme.shape.borderRadius }}
            >
              {loading && <CircularProgress color="inherit" size={16} />}Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </MainCard>
  );
};
 
export default LifeOfflineForm;
 