import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  clearError,
  setError,
} from "../../../../Redux/action-creators/errorAction";
import {
  clearSuccessMessage,
  setSuccessMessage,
} from "../../../../Redux/action-creators/successAction";
import {
  fetchCustomerData,
  updateProfileSetting,
} from "../../api/customerService";
import Button from "@mui/material/Button";
import { fetchPospData } from "../../../../Redux/action-creators/posp/pospAction";
import { store } from "../../../../Redux/Store";
import CircularProgress from "@mui/material/CircularProgress";
import { FETCH_CUSTOMER_SUCCESS } from "../../../../Redux/action-type/actionType";

const ProfileSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.customer?.data);

  const [isLoaderTrue, setIsLoaderTrue] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: userData.mobilepermissions,
  });
  const { control: controlEmail, handleSubmit: handleSubmitEmail } = useForm({
    defaultValues: userData.emailpermissions,
  });
  const [selectedOption, setSelectedOption] = useState("phone");

  const formFieldsMobile = [
    { key: "policyUpdate", label: "Policy Update" },
    { key: "prePolicyUpdate", label: "Pre Purchase Updates" },
    { key: "newsAndOffers", label: "News And Offers" },
  ];

  const formFieldsEmail = [
    { key: "adminNotification", label: "Admin Notification" },
    { key: "policyUpdate", label: "Policy Update" },
    { key: "prePolicyUpdate", label: "Pre Purchase Updates" },
    { key: "newsAndOffers", label: "News And Offers" },
  ];

  const onSubmit = async (data) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const payload = { mobilepermissions: data };
    setIsLoaderTrue(true);
    try {
      const response = await updateProfileSetting(
        userData?.customer_id,
        payload
      );
      if (response.status > 400) {
        dispatch(setError(t("lmv.serverError")));
      } else {
        const response = await fetchCustomerData(userData?.phone);
        dispatch(setSuccessMessage("Data Updated successfully"));
        dispatch({
          type: FETCH_CUSTOMER_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch(setError("Internal Server Error: " + error.toString()));
    }
    setIsLoaderTrue(false);
  };

  const onSubmitEmail = async (data) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const payload = { emailpermissions: data };
    setIsLoaderTrue(true);
    try {
      const response = await updateProfileSetting(
        userData?.customer_id,
        payload
      );
      if (response.status > 400) {
        dispatch(setError(t("lmv.serverError")));
      } else {
        const response = await fetchCustomerData(userData?.phone);
        dispatch(setSuccessMessage("Data Updated successfully"));
        dispatch({
          type: FETCH_CUSTOMER_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch(setError("Internal Server Error: " + error.toString()));
    }
    setIsLoaderTrue(false);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Grid container spacing={2} sx={{ minHeight: "90vh", px: 2 }}>
      <Grid item xs={10} sm={10} md={11} lg={12}>
        <Typography variant="h5" gutterBottom>
          Profile Settings
        </Typography>

        <Grid container className="prof-img">
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Select a mobile number/email address to set communication
              preferences
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* Select input for choosing mobile number or email address */}
            <Select
              value={selectedOption}
              onChange={handleOptionChange}
              sx={{ width: "300px", marginBottom: "20px" }}
            >
              <MenuItem value="phone">{userData.phone}</MenuItem>
              <MenuItem value="email">{userData.email}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography variant="subtitle2" gutterBottom sx={{ color: "red" }}>
              Manage contact details
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" gutterBottom>
          Set communication preferences
        </Typography>
      </Grid>

      {selectedOption === "phone" && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} sx={{ px: 2 }}>
            <Grid item xs={10} sm={10} md={11} lg={12}>
              <Box
                sx={{
                  py: 2,
                  px: 1,
                  backgroundColor: "#f4f5f7",
                  borderRadius: 4,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Typography variant="body1" color="#6b778c">
                      Label
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body1" color="#6b778c">
                      SMS
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body1" color="#6b778c">
                      Email
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body1" color="#6b778c">
                      Call
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body1" color="#6b778c">
                      WhatsApp
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {formFieldsMobile.map((field) => (
              <Grid item xs={10} sm={10} md={11} lg={12} key={field.key}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Typography variant="h6">{field.label}</Typography>
                  </Grid>
                  {["sms", "email", "call", "whatsApp"].map((method) => (
                    <Controller
                      key={method}
                      name={`${field.key}.${method}`}
                      control={control}
                      render={({ field }) => (
                        <Grid item xs={1}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                {...field}
                                disabled={
                                  method === "email" &&
                                  selectedOption === "phone"
                                }
                              />
                            }
                          />
                        </Grid>
                      )}
                    />
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>

          {isLoaderTrue ? (
            <Button variant="contained" color="primary" type="button">
              <CircularProgress
                color="inherit"
                style={{ width: "30px", height: "30px" }}
              />
            </Button>
          ) : (
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          )}
        </form>
      )}

      {selectedOption === "email" && (
        <form onSubmit={handleSubmitEmail(onSubmitEmail)}>
          <Grid container spacing={2} sx={{ px: 2 }}>
            <Grid item xs={10} sm={10} md={11} lg={12}>
              <Box
                sx={{
                  py: 2,
                  px: 1,
                  backgroundColor: "#f4f5f7",
                  borderRadius: 4,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Typography variant="body1" color="#6b778c">
                      Label
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body1" color="#6b778c">
                      SMS
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body1" color="#6b778c">
                      Email
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body1" color="#6b778c">
                      Call
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body1" color="#6b778c">
                      WhatsApp
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {formFieldsEmail.map((field) => (
              <Grid item xs={10} sm={10} md={11} lg={12} key={field.key}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Typography variant="h6">{field.label}</Typography>
                  </Grid>
                  {["sms", "email", "call", "whatsApp"].map((method) => (
                    <Controller
                      key={method}
                      name={`${field.key}.${method}`}
                      control={controlEmail}
                      render={({ field }) => (
                        <Grid item xs={1}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                {...field}
                                disabled={
                                  method !== "email" &&
                                  selectedOption === "email"
                                }
                              />
                            }
                          />
                        </Grid>
                      )}
                    />
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>

          {isLoaderTrue ? (
            <Button variant="contained" color="primary" type="button">
              <CircularProgress
                color="inherit"
                style={{ width: "30px", height: "30px" }}
              />
            </Button>
          ) : (
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          )}
        </form>
      )}
    </Grid>
  );
};

export default ProfileSettings;
