import React from 'react';
import { Typography, Container, List, ListItem, ListItemText } from "@mui/material";
const styles = {
    container: {
        marginTop: "32px",
        marginBottom: "32px",
        padding: "20px 30px",
      },
      heading: {
        marginBottom: "2px",
        color: "#23a8fa",
        fontWeight: "bold",
    
      },
      subHeading: {
        marginTop: "2px",
        fontWeight: "bold",
        color: "#555",
    
      },
      listItem: {
        marginBottom: "2px",
        backgroundColor: "#f5f5f5",
        // padding: "2px",
        borderRadius: "4px",
        textAlign:'justify',
        padding:'0px'
      },
};

const MaternityHealthInsurance = () => {
  return (
    <Container style={styles.container}>
      <Typography variant="h2" textAlign='center' style={styles.heading} gutterBottom>
        Maternity Health Insurance
      </Typography>
      <Typography variant="body1" paragraph>
        A maternity insurance plan is a specific kind of health insurance that covers costs related to pregnancy, childbirth, and care after delivery. Pregnant women can get financial security during one of the most crucial times in their lives by purchasing this insurance policy. Prenatal and postpartum care, delivery costs, pre- and postnatal care, and paediatric care for the newborn are just a few of the costs that maternity insurance plans may cover.
      </Typography>

      <Typography variant="h4" style={styles.heading}>
        What is Maternity Health Insurance?
      </Typography>

      <Typography variant="body1" paragraph>
        You can buy maternity insurance policies separately or as an addition to a regular health insurance plan. Pregnant women can then tailor their coverage to suit their individual needs. Cashless hospitalisation, or the ability to receive medical care without having to pay up front, is another benefit that some maternity insurance plans may provide.
      </Typography>

      <Typography variant="body1" paragraph>
        In conclusion, maternity insurance is a great way to protect your finances while you're pregnant, having a baby, and afterward. Pregnant women who have maternity insurance can concentrate on the health of themselves and their unborn child without having to worry about the expense of medical care.
      </Typography>

      <Typography variant="h4" style={styles.heading}>
        Why Do You Need Maternity Insurance?
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>High costs of pregnancy and childbirth</Typography>}
            secondary={
              <Typography variant="body1">
                Costs associated with pregnancy and childbirth can include prenatal care, delivery, post-partum care, and hospitalisation. Pregnant women and their families may be able to more easily afford it with the help of maternity insurance.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Quality medical care is available</Typography>}
            secondary={
              <Typography variant="body1">
                Prenatal care is essential for the health and wellbeing of both mother and child, and is typically covered by maternity insurance policies. Pregnant women can receive the care they require without worrying about the cost by purchasing maternity insurance.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Mental Calm</Typography>}
            secondary={
              <Typography variant="body1">
                For families, pregnancy and childbirth can be an emotionally trying and stressful time. Knowing that necessary medical costs are covered and that mothers and babies will get the care they need when they have maternity insurance can be reassuring.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Coverage For Unforeseen Costs</Typography>}
            secondary={
              <Typography variant="body1">
                Pregnancy complications are possible, and having maternity insurance can help cover the price of additional medical costs, such as a caesarean delivery, NICU care, or premature delivery.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        Maternity Health Insurance Eligibility
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Enrollment Time Frame</Typography>}
            secondary={
              <Typography variant="body1">
                People may only be able to sign up for maternity health insurance during a certain enrollment period. It is crucial to confirm the specifics with your insurance company.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Age</Typography>}
            secondary={
              <Typography variant="body1">
                Some insurance policies have restrictions on the mother-to-be's age, usually between 18 and 45.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Pregnancy status</Typography>}
            secondary={
              <Typography variant="body1">
                To qualify for maternity health insurance, expectant mothers must be pregnant or actively trying to get pregnant.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Waiting period</Typography>}
            secondary={
              <Typography variant="body1">
                A waiting period, during which the policyholder is not permitted to submit any claims, is common with maternity insurance policies. Depending on the insurance provider and the policy, the waiting period can last anywhere from nine months to forty eight months.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Pre-existing conditions</Typography>}
            secondary={
              <Typography variant="body1">
                Pre-existing conditions and pregnancy-related complications may not be covered by all insurance policies. Understanding what is and is not covered by the policy requires a careful review.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Policy term</Typography>}
            secondary={
              <Typography variant="body1">
                A specific policy term, during which coverage is in effect, may be included in maternity health insurance policies. It's critical to comprehend the duration of the policy term and renew the coverage as necessary.
              </Typography>
            }
          />
        </ListItem>
      </List>
      
   
   
      <Typography variant="h4" style={styles.heading}>
        Maternity Health Insurance Features
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Maternity Care</Typography>}
            secondary={
              <Typography variant="body1">
                Coverage for prenatal exams, scans, and doctor visits during pregnancy.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Delivery</Typography>}
            secondary={
              <Typography variant="body1">
                Hospitalisation costs for both normal and caesarean deliveries are covered.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Infant Care</Typography>}
            secondary={
              <Typography variant="body1">
                Payment for the newborn's medical costs, including hospital stays and checkups.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Maternity Leave</Typography>}
            secondary={
              <Typography variant="body1">
                While on maternity leave, some plans provide financial assistance.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Pre-existing conditions</Typography>}
            secondary={
              <Typography variant="body1">
                Pre-existing conditions might not be covered by some plans.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Waiting period</Typography>}
            secondary={
              <Typography variant="body1">
                There may be a waiting period before maternity expenses are covered by some plans.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Lifetime insurance cap</Typography>}
            secondary={
              <Typography variant="body1">
                The total amount that will be covered for maternity expenses may be subject to a cap under some plans.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        Advantages of Maternity Health Insurance
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Financial Security</Typography>}
            secondary={
              <Typography variant="body1">
                Maternity insurance eases the financial burden on the family by providing financial support during pregnancy, childbirth, and post-delivery care.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Insurance for Medical Costs</Typography>}
            secondary={
              <Typography variant="body1">
                Medical costs incurred during pregnancy, childbirth, and postpartum care, such as doctor visits, hospital stays, and lab tests, are typically covered by maternity insurance plans.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Tax Advantages</Typography>}
            secondary={
              <Typography variant="body1">
                Section 80D of the Indian Income Tax Act provides tax benefits for maternity insurance premium payments.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Cashless Hospitalization</Typography>}
            secondary={
              <Typography variant="body1">
                Cashless hospitalisation is a benefit offered by many maternity insurance plans, allowing policyholders to receive care without worrying about paying out of pocket.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Newborn Coverage (Optional)</Typography>}
            secondary={
              <Typography variant="body1">
                Some maternity insurance policies also cover hospitalisation and vaccination expenses for newborns.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Peace of Mind</Typography>}
            secondary={
              <Typography variant="body1">
                By covering medical costs and easing the stress and uncertainty related to the high cost of medical care during pregnancy and childbirth, maternity insurance gives expecting parents peace of mind.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h4" style={styles.heading}>
        Maternity Insurance Coverages
      </Typography>

      <List>
        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Prenatal and Postpartum Care</Typography>}
            secondary={
              <Typography variant="body1">
                Coverage for prenatal vitamins, prenatal visits, and other expenses related to prenatal and postpartum care.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Hospitalization</Typography>}
            secondary={
              <Typography variant="body1">
                Coverage for hospitalisation costs incurred during childbirth, such as room and board, medical costs, and surgical costs.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Delivery Costs</Typography>}
            secondary={
              <Typography variant="body1">
                Coverage for costs associated with deliveries, including natural birth, Caesarean section, or artificial induction of labour.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Newborn Protection</Typography>}
            secondary={
              <Typography variant="body1">
                The cost of newborn care, including hospitalisation, shots, and other medical costs, may be covered by some insurance plans.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Ambulance Services</Typography>}
            secondary={
              <Typography variant="body1">
                Required during hospitalisation is coverage for ambulance services.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Pre-existing Conditions</Typography>}
            secondary={
              <Typography variant="body1">
                Pre-existing medical conditions, such as gestational diabetes or hypertension, are covered.
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={styles.listItem}>
          <ListItemText
            primary={<Typography variant="h5" style={styles.subHeading}>Waiting Period</Typography>}
            secondary={
              <Typography variant="body1">
                There is typically a 9–12 month waiting period before most maternity insurance plans allow claims.
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Typography variant="h4" style={styles.heading}>
  Exclusions in Maternity Health Insurance
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Pre-existing Conditions</Typography>}
      secondary={
        <Typography variant="body1">
          Coverage may not include pre-existing medical conditions such as gynaecological disorders.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Elective Treatments</Typography>}
      secondary={
        <Typography variant="body1">
          Coverage typically does not include elective procedures like cosmetic surgery or abortions.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Non-allopathic Treatments</Typography>}
      secondary={
        <Typography variant="body1">
          Non-allopathic therapies such as homoeopathy and ayurveda are often not covered.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Complications Caused by Non-pregnancy Factors</Typography>}
      secondary={
        <Typography variant="body1">
          Complications arising from non-pregnancy-related factors like accidents or pre-existing conditions might not be covered.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Self-inflicted Injuries</Typography>}
      secondary={
        <Typography variant="body1">
          Injuries resulting from self-inflicted actions like suicide or suicide attempts are generally not covered.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Congenital Illnesses</Typography>}
      secondary={
        <Typography variant="body1">
          Coverage may not include expenses related to congenital disorders or abnormalities of a newborn.
        </Typography>
      }
    />
  </ListItem>
</List>

<Typography variant="h4" style={styles.heading}>
  When Should a Pregnant Person Buy Maternity Insurance?
</Typography>

<Typography variant="body1">
  The best time to purchase maternity health insurance is before conception or at the planning stage of pregnancy.
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Waiting Time</Typography>}
      secondary={
        <Typography variant="body1">
          Most maternity insurance policies have a waiting period of 9 to 12 months during which no claims may be made. By purchasing insurance early, the waiting period can be completed before conception.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Existing Conditions</Typography>}
      secondary={
        <Typography variant="body1">
          Pre-existing medical conditions like gestational diabetes or hypertension during pregnancy may not be covered by all insurance plans. Buying insurance early can help avoid exclusions for these conditions.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Protection Against Unforeseen Costs</Typography>}
      secondary={
        <Typography variant="body1">
          Maternity insurance provides financial security and peace of mind for expecting parents, covering the high and unexpected costs associated with pregnancy and childbirth.
        </Typography>
      }
    />
  </ListItem>
</List>

<Typography variant="h4" style={styles.heading}>
  Documents Required to Apply for Maternity Health Insurance
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Proof of Identity</Typography>}
      secondary={
        <Typography variant="body1">
          A government-issued photo ID such as a passport, driving license, or PAN card.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Proof of Age</Typography>}
      secondary={
        <Typography variant="body1">
          A birth certificate, school leaving certificate, or passport.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Proof of Address</Typography>}
      secondary={
        <Typography variant="body1">
          Utility bill, bank statement, or passport.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Income Proof</Typography>}
      secondary={
        <Typography variant="body1">
          Salary slip, Form 16, or other proof of income.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Medical Reports</Typography>}
      secondary={
        <Typography variant="body1">
          Some insurance companies may require medical reports to evaluate any pre-existing conditions, such as gynaecological and obstetrical reports.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Proposal Form</Typography>}
      secondary={
        <Typography variant="body1">
          A fully filled proposal form containing personal and medical details about the applicant.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Declaration of Good Health</Typography>}
      secondary={
        <Typography variant="body1">
          A statement declaring that the applicant is in good health with no known medical conditions.
        </Typography>
      }
    />
  </ListItem>
</List>

    </Container>
  );
};

export default MaternityHealthInsurance;
