import React from 'react';
import { Typography, Container, List, ListItem, ListItemText } from "@mui/material";

const styles = {
    container: {
      marginTop: "32px",
      marginBottom: "32px",
      padding: "20px 30px",
    },
    heading: {
        marginTop:'10px',
      marginBottom: "2px",
      color: "#23a8fa",
      fontWeight: "bold",
  
    },
    subHeading: {
      marginTop: "2px",
      fontWeight: "bold",
      color: "#555",
  
    },
    listItem: {
      marginBottom: "2px",

      backgroundColor: "#f5f5f5",
      // padding: "2px",
      borderRadius: "4px",
      textAlign:'justify',
      
      padding:'0px',
      margin:'0px',
    },
    ListItemText:{
      margin:'0',
      padding:'0'
    }
  };

const CommercialVehicleInsurance =  () => {
    return (
<Container style={styles.container}>
      <Typography variant="h2" textAlign='center'   style={styles.heading} gutterBottom>
   COMMERCIAL VEHICLE INSURANCE
      </Typography>

      <Typography variant="body1" mt={1} style={styles.bodyText}>
  Commercial Vehicle Insurance is a specialized insurance coverage designed for vehicles used for business purposes. It provides financial protection for the owner and operator of the vehicle in case of accidents, theft, or damage. This type of insurance is legally required for commercial vehicles and is essential to protect businesses against potential financial losses arising from vehicle use for work purposes.
</Typography>

<Typography variant="body1" style={styles.bodyText}>
  Commercial Vehicle Insurance can cover a variety of vehicles including delivery vans, trucks, buses, taxi cabs, and construction vehicles. The coverage can differ depending on the policy and the specific type of vehicle being insured. Some common types of coverage include:
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Liability Coverage</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Protects the business against financial losses due to liability for injury to others or damage to their property.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Physical Damage Coverage</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Covers damage or loss to the commercial vehicle from accidents, theft, or other covered events.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Medical Payments Coverage</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Pays for medical expenses incurred by the driver or passengers in the event of an accident, regardless of fault.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Uninsured/Underinsured Motorist Coverage</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Provides protection for the business and its drivers in case of an accident with an uninsured or underinsured driver.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Rental Reimbursement Coverage</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Offers financial assistance for a rental vehicle while the commercial vehicle is being repaired after a covered loss.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Roadside Assistance</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Provides assistance with services like towing, tire changes, and jump-starting a dead battery in case of a breakdown.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Personal Injury Protection (PIP)</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Provides financial protection for the driver and passengers in case of an accident, regardless of fault.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Custom Equipment Coverage</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Protects custom equipment or tools permanently attached to the commercial vehicle.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Trailer Interchange Coverage</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Provides protection for commercial trailers when they are used with the commercial vehicle.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Non-Trucking Liability Coverage</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Provides protection for commercial vehicles when they are not used for business purposes, such as during personal use.</Typography>}
    />
  </ListItem>
</List>

<Typography variant="h4" style={styles.heading}>
  Types of Commercial Vehicle Insurance
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Liability Insurance</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>The most basic type of commercial vehicle insurance, covering damages or injuries caused to others while operating the vehicle.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Collision Insurance</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Covers damages to your commercial vehicle in case of an accident, regardless of fault.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Comprehensive Insurance</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Protects against non-collision-related damages, such as theft, vandalism, fire, and weather-related events.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Medical Payments Coverage</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Covers medical expenses for you and your passengers in the event of an accident.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Uninsured/Underinsured Motorist Coverage</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Protects you and your commercial vehicle if hit by an uninsured or underinsured driver.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Cargo Insurance</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Provides protection for goods and products transported in your commercial vehicle.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Hired Auto Liability Insurance</Typography>}
      secondary={<Typography variant="body1" style={styles.bodyText}>Provides protection when your commercial vehicle is used by someone not listed on your policy.</Typography>}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>  Commercial Vehicle Insurance Inclusions</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
  Commercial vehicle insurance is an essential coverage for businesses that operate vehicles for commercial purposes. It provides protection for financial losses that may arise from accidents, theft, or other unexpected events. The inclusions in a commercial vehicle insurance policy vary depending on the insurance company and the type of policy selected, but here are some common coverage types to consider:
  </Typography>
      }
    />
  </ListItem>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Liability Coverage</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Pays for damages or injuries caused to other people or their property while operating the commercial vehicle. This includes damage to other vehicles, buildings, or property as well as medical expenses for others.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Physical Damage Coverage</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Covers damage to the policyholder's commercial vehicle caused by accidents, theft, fire, or other covered events, including repairs or replacement if deemed a total loss.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Medical Expenses</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Pays for medical costs for the driver and passengers in case of an accident, including emergency care, hospitalization, and rehabilitation.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Uninsured/Underinsured Motorist Coverage</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Provides protection when an accident is caused by a driver who has no insurance or insufficient insurance to cover the damages.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Roadside Assistance</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Offers emergency services like towing, tire changes, and jump-starting a dead battery during breakdowns.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Rental Reimbursement</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Covers the cost of a rental vehicle when the commercial vehicle is damaged or stolen and cannot be driven.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Loss of Use Coverage</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Compensates for the loss of income caused by the inability to use the commercial vehicle due to a covered event.
        </Typography>
      }
    />
  </ListItem>
</List>
<Typography variant="h4" style={styles.heading}>
  Importance of Commercial Vehicle Insurance
</Typography>

<Typography variant="body1" mt={1} style={styles.bodyText}>
  Commercial vehicle insurance is important for a number of reasons:
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Protects Your Business Finances</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The main purpose of commercial vehicle insurance is to protect your business from financial losses caused by accidents, theft, or unexpected events. Without coverage, you could face significant expenses for repairs, replacements, legal fees, and medical costs.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Compliance with the Law</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          In most countries, insurance for vehicles used for commercial purposes is legally required. Failure to comply can lead to fines, penalties, and suspension of driving privileges.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Provides Peace of Mind</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Commercial vehicle insurance gives you peace of mind knowing your business is protected in case of an accident or covered event. This allows you to focus on business operations without worrying about financial risks.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Offers Additional Benefits</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Policies often include benefits like roadside assistance, rental car reimbursement, and loss of use coverage, helping your business recover faster and run smoothly.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Demonstrates Responsibility</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Carrying commercial vehicle insurance shows your commitment to safety and responsibility, building trust with customers, employees, and the public.
        </Typography>
      }
    />
  </ListItem>
</List>
<Typography variant="h4" style={styles.heading}>
  Commercial Vehicle Insurance Claim Process
</Typography>

<Typography variant="body1" mt={1} style={styles.bodyText}>
  The commercial vehicle insurance claim process typically involves the following steps:
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Notify the Insurance Company</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Immediately notify the insurance company about the incident, providing essential details such as date, time, location, and the nature of the damage.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Submit a Claim Form</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Fill out the claim form provided by the insurer and submit it along with supporting documents like the police report, photographs of the damage, and repair estimates.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Surveyor Inspection</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The insurance company appoints a surveyor to inspect the vehicle and assess the damage. The surveyor submits a report with repair costs and damage details.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Approval or Rejection of the Claim</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Based on the surveyor's report, the insurer will approve or reject the claim and inform you about the estimated compensation.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Repair of the Vehicle</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Once approved, you can get the vehicle repaired at an authorized workshop. Retain repair bills and documents for submission.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Payment of the Claim</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          After repairs, the insurance company pays the compensation amount to the workshop or directly to you, based on policy terms.
        </Typography>
      }
    />
  </ListItem>
</List>

<Typography variant="h4" style={styles.heading}>
  Documents Required to Claim Commercial Vehicle Insurance
</Typography>

<List>
  <ListItem style={styles.listItem}>
  
    <ListItemText
      primary="A filled-out claim form that provides details about the incident and the damage to the vehicle."
      style={styles.bodyText}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
  
    <ListItemText
      primary="A copy of the commercial vehicle insurance policy, including the policy number and the policyholder's details."
      style={styles.bodyText}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
  
    <ListItemText
      primary="Documentation that proves you are the owner of the vehicle, such as the registration certificate or the bill of sale."
      style={styles.bodyText}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
  
    <ListItemText
      primary="A valid driver's license of the person who was driving the vehicle at the time of the incident."
      style={styles.bodyText}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
  
    <ListItemText
      primary="A copy of the First Information Report (FIR) or the police report, if the incident was reported to the police."
      style={styles.bodyText}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
  
    <ListItemText
      primary="A detailed estimate of the repair costs from an authorized repair workshop."
      style={styles.bodyText}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
  
    <ListItemText
      primary="The original receipts and invoices for the repairs carried out on the vehicle."
      style={styles.bodyText}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
  
    <ListItemText
      primary="Documentation that proves that the vehicle was insured at the time of the incident."
      style={styles.bodyText}
    />
  </ListItem>

  <ListItem style={styles.listItem}>
  
    <ListItemText
      primary="Photographs of the damaged vehicle, taken from different angles to show the extent of the damage."
      style={styles.bodyText}
    />
  </ListItem>
</List>




<Typography variant="h4" style={styles.heading}>
  Importance of Renewing Commercial Vehicle Insurance Policy
</Typography>

<Typography variant="body1" style={styles.bodyText} paragraph>
  Renewing your commercial vehicle insurance policy is crucial for both legal compliance and financial security. In many countries, having valid insurance is legally mandatory, and failure to renew can lead to fines, legal repercussions, or driving suspensions.
</Typography>

<Typography variant="body1" style={styles.bodyText} paragraph>
  Renewing your policy ensures continuous coverage against accidents, theft, fire, and natural disasters. Without it, you may be held liable for costly repairs, medical expenses, or other damages arising from unexpected incidents.
</Typography>

<Typography variant="body1" style={styles.bodyText} paragraph>
  Commercial vehicle insurance policies have a fixed term, and renewal before expiration ensures uninterrupted protection. To avoid lapses, it’s wise to renew well in advance.
</Typography>
<Typography variant="h4" style={styles.heading}>
  Factors to Consider While Buying a Commercial Vehicle Insurance Policy
</Typography>

<Typography variant="body1" mt={1} style={styles.bodyText}>
  You should take into account a number of variables when purchasing a commercial car insurance policy to make sure you obtain the greatest coverage possible for your requirements:
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Vehicle Type</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The kind and extent of insurance coverage you require will depend on the type of vehicle you need to insure as well as its value and intended use.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Limits on Coverage</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Decide how much insurance you need for your car, taking into account liability coverage and coverage for vehicle damage in case of incidents involving other people or their property.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Deductibles</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The deductibles you're willing to pay in the event of a claim should be taken into account. Although a greater deductible can lower your premiums, it will also mean that you'll have to pay more out of pocket when an accident occurs.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Exclusions</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Review the exclusions in the policy carefully to make sure the coverage is adequate for your needs. Some common exclusions include damage caused by natural disasters or acts of terrorism.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Third-party liability coverage</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Consider the level of third-party liability coverage you need in the event of an accident involving another vehicle or pedestrian.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Add-on covers</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Evaluate whether you need any additional coverage, such as personal accident cover for the driver, towing and roadside assistance, or coverage for electronic equipment and other accessories.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Cost</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Compare the cost of different insurance policies from different insurance providers to ensure you're getting the best value for your money.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Reputation of the insurance company</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Consider the financial stability and reputation of the insurance company to ensure they will be able to pay out claims when required.
        </Typography>
      }
    />
  </ListItem>
</List>

<Typography variant="h4" style={styles.heading}>
  Factors To Consider While Comparing Commercial Vehicle Insurance Policy
</Typography>

<Typography variant="body1" mt={1} style={styles.bodyText}>
  When comparing commercial vehicle insurance policies, it is important to consider the following factors:
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Coverage</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Ensure that the policy you choose provides adequate coverage for your business needs, including liability coverage, collision coverage, and any other required coverage.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Premiums</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Compare the premiums of different policies to determine the most cost-effective option for your business. Keep in mind that a lower premium may come with lower coverage limits, or higher deductibles.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Deductibles</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Consider the deductible amount you are comfortable paying in the event of a claim. A higher deductible can lower your premium, but it also means that you will have to pay more out-of-pocket in the event of a claim.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Claims process</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Look into the process for making a claim and the track record of the insurance provider in handling claims. Choose an insurance provider with a transparent and efficient claims process.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Reputation</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Research the reputation of the insurance provider you are considering. Consider factors such as financial stability, customer service, and the provider's track record for settling claims.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Discounts</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Look for insurance providers that offer discounts for things such as a clean driving record, multiple vehicles, or bundling insurance policies.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Policy terms</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Review the policy terms and conditions carefully, including the duration of the policy and any conditions that may void the policy.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Customer support</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Consider the level of customer support offered by the insurance provider. Look for an insurance provider that offers responsive customer support and is available to assist you with any questions or concerns.
        </Typography>
      }
    />
  </ListItem>
</List>
<Typography variant="h4" style={styles.heading}>
  How To Calculate Commercial Vehicle Insurance Premium
</Typography>

<Typography variant="body1" mt={1} style={styles.bodyText}>
  The calculation of a commercial vehicle insurance premium is based on several factors, including:
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Type of Vehicle</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The type of commercial vehicle you own will impact the cost of your insurance premium. Vehicles that are considered to be higher risk, such as delivery trucks or construction vehicles, will typically have a higher premium.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Coverage</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The amount of coverage you choose will impact your premium. More comprehensive coverage, such as liability coverage and collision coverage, will typically cost more than basic coverage.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Driving History</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Your driving history can also impact your premium. If you have a history of accidents or traffic violations, your insurance premium will likely be higher.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Location</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The location of your business can also affect the cost of your insurance. If you operate in a high-crime area or a region with a high incidence of natural disasters, your insurance premium may be higher.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Age and Make of the Vehicle</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The age and make of your vehicle can also impact your premium. Newer vehicles are typically more expensive to insure than older vehicles.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Insurance Provider</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The insurance provider you choose can also impact the cost of your insurance. Different insurance providers may offer different rates for the same coverage.
        </Typography>
      }
    />
  </ListItem>
</List>

<Typography variant="body1" mt={1} style={styles.bodyText}>
  The insurance provider will use this information to calculate the estimated premium for your commercial vehicle insurance policy. It is important to obtain quotes from multiple insurance providers to compare the costs and coverage options and make an informed decision.
</Typography>
<Typography variant="h4" style={styles.heading}>
  FAQs
</Typography>

<List>
  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>What factors affect the cost of commercial vehicle insurance?</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          The cost of commercial vehicle insurance can be impacted by factors such as the type of vehicle, coverage limits, driving history, location, age and make of the vehicle, and the insurance provider.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Can I add multiple vehicles to a commercial vehicle insurance policy?</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Yes, most insurance providers offer the option to add multiple vehicles to a commercial vehicle insurance policy.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>Can I cancel my commercial vehicle insurance policy?</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          Yes, you can cancel your commercial vehicle insurance policy at any time, but you may be subject to a cancellation fee or lose any unearned premium.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>What should I do if I have an accident while driving a commercial vehicle?</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          If you have an accident while driving a commercial vehicle, it is important to report the accident to your insurance provider as soon as possible. Follow the steps outlined in your insurance policy for making a claim, and be sure to collect any relevant information such as the contact information of any witnesses or any damage to the vehicles involved.
        </Typography>
      }
    />
  </ListItem>

  <ListItem style={styles.listItem}>
    <ListItemText
      primary={<Typography variant="h5" style={styles.subHeading}>What is IDV in commercial vehicle insurance?</Typography>}
      secondary={
        <Typography variant="body1" style={styles.bodyText}>
          IDV (Insured Declared Value) is a term used in commercial vehicle insurance policies. It refers to the maximum amount of money that the insurance company will pay out in the event of a total loss or theft of the vehicle. The IDV is calculated based on the current market value of the vehicle and takes into account depreciation, age, and other factors that can impact the value of the vehicle.
        </Typography>
      }
    />
  </ListItem>
</List>

       </Container>
           );
         };
      
      export default CommercialVehicleInsurance;