import {lazy } from "react"
import Loadable from "../../posp/ui-component/Loadable"
import { useRoutes } from "react-router"

const CustomerLayout = Loadable(lazy(() => import("../layout")))
const PolicyPurchase = Loadable(lazy(() => import("../../admin/pages/PolicyPurchase")))
const Mainpage = Loadable(lazy(() => import("../pages/profile/Mainpage")))
const Policies = Loadable(lazy(() => import("../pages/policies/Policies")))
const CustPolicyClaim = Loadable(lazy(() => import("../pages/policy-claims/Claims")))
const RegisterClaim = Loadable(lazy(() => import("../pages/register-claims/RegisterClaim")))
const ProfileSettings = Loadable(lazy(() => import("../pages/setting/Settings")))
const Helpline = Loadable(lazy(() => import("../pages/helpline/Helpline")))


export default function CustomerRoutes(){
    return useRoutes([
        {
            path: '/',
            element: <CustomerLayout />,
            children: [
                {
                    path: '/dashboard',
                    element: <PolicyPurchase />
                },
                {
                    path: '/profile',
                    element: <Mainpage />
                },
                {
                    path: '/Policies',
                    element: <Policies />
                },
                {
                    path: '/register-claims',
                    element: <RegisterClaim />
                },
                {
                    path: '/policy-claims',
                    element: <CustPolicyClaim />
                },
                {
                    path: '/setting',
                    element: <ProfileSettings />
                },
                {
                    path: '/helpline',
                    element: <Helpline />
                }
            ]
        }
    ])
}
