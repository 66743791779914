import React, { useState } from "react";
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MainCard from "../../../posp/ui-component/cards/MainCard";
import axios from "axios";
import { clearError, setError } from "../../../../Redux/action-creators/errorAction";
import { clearSuccessMessage } from "../../../../Redux/action-creators/successAction";
import { useDispatch } from "react-redux";
 
export const RecentPolicyCard = React.memo((props) => {
   const dispatch = useDispatch();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
 const {policy , logo} = props
 const [downLoadTrue , setDownLoadTrue] = useState(false)
 const isPolicyExpired = new Date() > new Date(policy?.policy_enddate);
  const bgCOlor = isPolicyExpired ? '#FF0000' : policy?.policy_status === 'APPROVED' ? '#388E3C' : policy?.policy_status === 'PENDING' ? '#FF9800' : '#1565C0'



  const handleDownload = async() =>{
    setDownLoadTrue(true)
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    try {
      const response = await axios.get(
        policy?.pdf_link,
        {
          responseType: 'blob', // Make sure the response is a binary blob
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'policy.pdf'); // Set the filename
      document.body.appendChild(link);
      link.click(); // Trigger the download without redirect
      document.body.removeChild(link); // Clean up
    } catch (error) {
      dispatch(setError("Error downloading the file"));
      console.error('Error downloading the file:', error.message);
    }finally{
      setDownLoadTrue(false)
    }
  }

  return(
    <Stack sx={{background : '#ffffff' , borderRadius : '20px' , padding : '30px', width : {xs : '100%' , md : '49%'} , boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.2)'}}>
      <Stack direction={'row'} alignItems={'center'} width={'100%'} marginBottom={2}>
        {/* <img src={logo} alt={'provider'} style={{ width : '100px' , height : '60px' , marginRight : '15px' , borderRadius : '10px'}}/> */}
        <img src="https://lmv-web-staging.s3.ap-south-1.amazonaws.com/Images/Dashboard/bajaj_allianz.avif" alt={'provider'} style={{ width : '100px' , height : '60px' , marginRight : '15px' , borderRadius : '10px'}}/>

        <Stack direction={'column'} spacing={1}>
          <Typography variant="h4" fontWeight={'bold'}>Plan Name: <span style={{fontWeight : 'normal', marginLeft : '6px'}}>{policy?.plan_name}</span></Typography>
          <Typography variant="h4" fontWeight={'bold'}>Policy Number: <span style={{fontWeight : 'normal', marginLeft : '6px'}}>{policy?.policy_number}</span></Typography>
        </Stack>
      </Stack>

      <Stack direction={'row'} width={'100%'} marginBottom={2}>
        <Stack direction={'column'} width={'50%'} spacing={1}>
          <Typography variant="body1" fontWeight={'bold'}>Premium: <span style={{fontWeight : 'normal', marginLeft : '8px'}}>{policy?.premium_with_gst}</span></Typography>
          <Typography variant="body1" fontWeight={'bold'}>Policy Start: <span style={{fontWeight : 'normal', marginLeft : '8px'}}>{policy?.policy_startdate}</span></Typography>
          {policy?.product_type === 'MOTOR' && <Typography variant="body1" fontWeight={'bold'}>Vehicle Number: <span style={{fontWeight : 'normal', marginLeft : '8px'}}>{policy?.rc_number}</span></Typography>}
          <Typography variant="body1" fontWeight={'bold'}>Product: <span style={{fontWeight : 'normal' ,marginLeft : '8px'}}>{policy?.product_type}</span></Typography>
          <Typography variant="body1" fontWeight={'bold'}>Provider: <span style={{fontWeight : 'normal' ,marginLeft : '8px'}}>{policy?.provider}</span></Typography>
        </Stack>

        <Stack direction={'column'} width={'50%'} spacing={1}>
          <Typography variant="body1" fontWeight={'bold'}>Sum Insured: <span style={{fontWeight : 'normal' , marginLeft : '8px'}}>{policy?.sum_insured}</span></Typography>
          <Typography variant="body1" fontWeight={'bold'}>Policy End: <span style={{fontWeight : 'normal', marginLeft : '8px'}}>{policy?.policy_enddate}</span></Typography>
          {policy?.product_type === 'MOTOR' && <Typography variant="body1" fontWeight={'bold'}>Vehicle Type: <span style={{fontWeight : 'normal', marginLeft : '8px'}}>{policy?.product_subtype}</span></Typography>}
          <Typography variant="body1" fontWeight={'bold'}>Status:  <span style={{fontWeight : 'normal' , textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' ,backgroundColor: bgCOlor, padding: '0 4px', borderRadius: '4px', marginLeft : '8px' , color : '#ffffff'}}>{isPolicyExpired ? 'Expired' :policy?.policy_status}</span></Typography>
          {policy?.customer_name && <Typography variant="body1" fontWeight={'bold'}>Customer: <span style={{fontWeight : 'normal' ,marginLeft : '8px'}}>{policy?.customer_name}</span></Typography>}
        </Stack>
      </Stack>

      <Stack width={'100%'} direction={'row'} justifyContent={'center'}>
          <Button variant="contained" color="primary" disabled={!policy?.pdf_link || downLoadTrue} onClick={handleDownload}>{downLoadTrue ? <CircularProgress size={20} color="primary"/> : 'Download'}</Button>
        </Stack>
    </Stack>
  )
});