
import React from 'react';
import { Typography, List, ListItem, ListItemText, Container } from '@mui/material';

const styles = {
  container: {
    marginTop: "32px",
    marginBottom: "32px",
    padding: "20px 30px",
  },
  heading: {
    marginBottom: "2px",
    color: "#23a8fa",
    fontWeight: "bold",
  },
  subHeading: {
    marginTop: "2px",
    fontWeight: "bold",
    color: "#555",
  },
  bodyText: {
    color: '#555',
  },
  listItem: {
    backgroundColor: "#f5f5f5",
    textAlign: 'justify',
    padding: '0px',
    margin: '0px',
  },
  ListItemText: {
    textAlign: 'justify',
    padding: '0px',
    margin: '0px',
  },
};

const BikeClaims = () => (
  <Container style={styles.container}>
    <Typography variant="h2" textAlign='center' style={styles.heading} gutterBottom>
      Bike Claims Under LMV Insurance
    </Typography>
    <Typography variant="body1" mt={1} style={styles.bodyText}>
      In general, LMV insurance refers to a type of motor vehicle insurance that covers light motor vehicles like cars, SUVs, and bikes. When it comes to bike claims, these are typically associated with motorcycle or bike insurance, which may be covered under a broader LMV insurance policy. However, insurance for bikes specifically might be different from car insurance. That said, it is important to understand how bike claims work under LMV insurance, especially if the policy includes coverage for both.
    </Typography>
    
    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>1. Types of Coverage Under LMV Insurance for Bike Claims</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>Third-Party Liability Coverage: Protects against claims made by third parties for damages caused by your bike.</li>
            <li>Own Damage Coverage (Comprehensive Insurance): Provides coverage for damage to your own bike, regardless of fault.</li>
            <li>Personal Accident Coverage: Covers injuries sustained by the rider or pillion in an accident.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>2. Steps for Filing a Bike Claim Under LMV Insurance</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>Report the Incident: Notify the insurer promptly (within 24 to 48 hours). Use the insurer's toll-free helpline or online portal.</li>
            <li>Document the Damage: Take clear photos of the damage and accident scene. File an FIR (if applicable) for theft or serious accidents.</li>
            <li>Provide Required Documents: Submit policy documents, registration certificate, driver's license, FIR (if applicable), repair estimates, and accident details.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>3. Claim Investigation and Settlement</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>The insurer may assign an investigator to assess the damage.</li>
            <li>For third-party liability claims, the insurer evaluates the extent of damages to the other party’s property.</li>
            <li>If the claim is approved, the insurer will either reimburse repair costs (if cashless claim) or pay directly (reimbursement claim).</li>
            <li>For total loss cases, the insurer may pay out the Insured Declared Value (IDV) of the bike.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>4. Common Exclusions in Bike Claims</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>Driving without a valid license.</li>
            <li>Negligence (e.g., reckless driving, ignoring traffic laws).</li>
            <li>Wear and tear, mechanical breakdowns.</li>
            <li>Theft in unsecured locations.</li>
            <li>Intentional damage or fraudulent claims.</li>
            <li>Exclusions related to natural disasters, depending on the policy.</li>
          </ul>
        }
      />
    </ListItem>

    <ListItem style={styles.listItem}>
      <ListItemText
        primary={<Typography variant="h5" style={styles.subHeading}>5. Tips for Smooth Bike Claims</Typography>}
        secondary={
          <ul style={styles.bodyText}>
            <li>Read your policy carefully to understand the coverage and exclusions.</li>
            <li>Maintain proper documentation (insurance policy, bike registration).</li>
            <li>Choose network garages for faster claim processing.</li>
            <li>Understand your deductibles and opt for cashless claims if possible.</li>
          </ul>
        }
      />
    </ListItem>
  </Container>
);

export default BikeClaims;
