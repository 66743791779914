import React from 'react';
// import './Navbar.css';
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { footerContent } from '../Utils/maincontent';
import { Link } from 'react-router-dom';

const {
  subscribe,
  protocols,
  governance,
  support,
  developers,
  copyright,
  socials,
  license,
} = footerContent;



const LinkSection = ({ title, links }) => {
  console.log(title , 'title')
  console.log(links , 'links')
  return(
  <Stack spacing={2.5}>
    <Title>{title}</Title>

    {links.map(({ title , path}) => (
      <Link to={path}>
      <Typography
        key={title}
        variant="body2"
        color="rgba(255, 255, 255, 0.6)"
        sx={{
          cursor: "pointer",
          "&:hover": {
            color: "#fff",
          },
        }}
      >
        {title}
      </Typography>
      </Link>
    ))}
  </Stack>
)};

const Title = ({ variant = "h6", sx = {}, children, ...props }) => {
  const _component =
    typeof variant !== "object"
      ? variant
      : variant.xl ||
      variant.lg ||
      variant.md ||
      variant.sm ||
      variant.xs ||
      "h6";

  const _variantStyles =
    typeof variant !== "object"
      ? variant
      : {
        xs: variant.xs,
        sm: variant.sm || variant.xs,
        md: variant.md || variant.sm || variant.xs,
        lg: variant.lg || variant.md || variant.sm || variant.xs,
        xl:
          variant.xl || variant.lg || variant.md || variant.sm || variant.xs,
      };

  return (
    <Typography
      // variant={variant}
      sx={{
        ...sx,
        typography: typeof _variantStyles === "object" && _variantStyles,
        fontWeight: `${sx.fontWeight || 600}!important`,
      }}
      component={_component}
      {...props}
    >
      {children}
    </Typography>
  );
};

const OutlinedButton = ({ sx = {}, arrow, children, fit, ...props }) => {
  return (
    <Button
      variant="outlined"
      sx={{
        borderRadius: 2,
        color: "#fff",
        borderColor: "#fff",
        width: fit ? "fit-content" : "100%",
        ...sx,
      }}
      {...props}
    >
      {children}
      {arrow && <KeyboardArrowRightIcon fontSize="small" sx={{ ml: 0.5 }} />}
    </Button>
  );
};



const Footer = () => {
  return (
    <>
      <Box>
        <Divider sx={{ mb: 10, backgroundColor: "#fff" }} />

        <Container>
          <Grid container spacing={8} flexWrap="wrap-reverse">
            {/* Links */}
            <Grid item xs={12} md={6} lg={7} xl={8}>
              <Grid container spacing={2}>
                {/* Protocols */}
                <Grid item xs={6} sm={3} md={6} lg={3}>
                  <LinkSection {...protocols} />
                </Grid>

                {/* Governance */}
                <Grid item xs={6} sm={3} md={6} lg={3}>
                  <LinkSection {...governance} />
                </Grid>

                {/* Support */}
                <Grid item xs={6} sm={3} md={6} lg={3}>
                  <LinkSection {...support} />
                </Grid>

                {/* Developers */}
                <Grid item xs={6} sm={3} md={6} lg={3}>
                  <LinkSection {...developers} />
                </Grid>
              </Grid>
            </Grid>

            {/* Subscribe */}
            <Grid item xs={12} md={6} lg={5} xl={4}>
              <Stack>
                <Title sx={{ mb: 1 }}>{subscribe.title}</Title>

                <Typography variant="body2" color="#fff9" sx={{ mb: 2 }}>
                  {subscribe.subtitle}
                </Typography>

                {/* <OutlinedButton arrow sx={{ height: 60, my: 3 }}>
                  Contact
                </OutlinedButton> */}

                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-start"
                  flexWrap="wrap"
                >
                  {socials.map((item, i) => (
                    <IconButton
                      key={i}
                      onClick={() => {
                        window.open(item.url, "_blank");
                      }}
                    >
                      <item.icon sx={{ color: "#fff" }} />
                    </IconButton>
                  ))}
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 5, mb: 2, backgroundColor: "#fff" }} />
          <Stack
            direction={{ xs: "column" }}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Stack direction={"row"} spacing={5}>
              <Typography variant="body2" color="#fff9">
                Registered By
              </Typography>
              <Typography variant="body2" color="#fff9">
                Payment By
              </Typography>
            </Stack>
            <Typography variant="body2" color="#fff9">
              LmvInsuranceBrokingServices
            </Typography>
          </Stack>
          <Divider sx={{ mt: 2, mb: 5, backgroundColor: "#fff" }} />

          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            spacing={1}
            sx={{ pb: 6 }}
          >
            <Typography variant="body2" color="#fff9">
              {license.text}
            </Typography>
          </Stack>

          <Divider sx={{ mt: 1, mb: 1 }} />

          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{ pb: 6 }}
          >
            <Typography variant="body2" color="#fff9">
              {copyright.left}
            </Typography>

            <Typography variant="body2" color="#fff9">
              {copyright.center}
            </Typography>

            <Typography variant="body2" color="#fff9">
              {copyright.right}
            </Typography>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
