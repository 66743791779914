import {ISLANDINGPAGETRUE, LOGIN_SUCCESS, LOGOUT} from "../action-type/actionType";

const initialState = {
    data: null,
    isLandingPageTrue : true
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                data: action?.payload,
            };
        case LOGOUT:
            return {
                ...state,
                data: null,
            };
        case ISLANDINGPAGETRUE:
            return {
                ...state,
                isLandingPageTrue : action?.payload
            }
        default:
            return state;
    }
};

export default authReducer;
