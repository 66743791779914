const currentHour = new Date().getHours();

// Determine the greeting based on the time
let greeting;

if (currentHour >= 5 && currentHour < 12) {
    greeting = "Good Morning";
} else if (currentHour >= 12 && currentHour < 16) {
    greeting = "Good Afternoon";
} else if (currentHour >= 16 && currentHour < 21) {
    greeting = "Good Evening";
} else {
    greeting = "Good Night";
}

export default greeting