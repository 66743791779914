import axios from 'axios';
import * as types from './ActionTypes';
import { common_api, vehicle_api } from '../Config';
import { getMasterData } from '../Framework/Utils/masterData';

const loadingRequest = () => {
  return {
    type: types.Get_Data_Request
  };
};

export const vehicleSuccessRequest = (payload) => {
  return {
    type: types.Get_Data_Success,
    payload
  };
};

export const quotationSuccessRequest = (payload) => {
  console.log('in payload', payload);
  return {
    type: types.Get_Quotation_Data,
    payload
  };
};

export const InsurerNamesSuccessRequest = (payload) => {
  return {
    type: types.Get_Insurer_Name_Data_Success,
    payload
  };
};

export const RelationSuccessRequest = (payload) => {
  return {
    type: types.Get_Relation_Data_Success,
    payload
  };
};

export const NCBSuccessRequest = (payload) => {
  return {
    type: types.Get_NCB_Data_Success,
    payload
  };
};

export const citySuccessRequest = (payload) => {
  return {
    type: types.Get_City_Data_Success,
    payload
  };
};

export const kycSuccessRequest = (payload) => {
  return {
    type: types.Get_Kyc_Data_Success,
    payload
  };
};

export const getSuccessSmsRequest = (payload) => {
  return {
    type: types.Mobile_OTP_Request,
    payload
  };
};

export const getSuccessSmsResponse = (payload) => {
  return {
    type: types.Mobile_OTP_Verification_Request,
    payload
  };
};

export const getSuccessVehicleMaster = (payload) => {
  return {
    type: types.Vehicle_Master_Success_Request,
    payload
  };
};

export const getSuccessPincode = (payload) => {
  return {
    type: types.PINCODE_CITY_STATE_SUCCESS_REQUEST,
    payload
  };
};

export const getSuccessAgentData = (payload) => {
  return {
    type: types.Agent_Data_Success_Request,
    payload
  };
};



export const getSuccessUsageType = (payload) => {
  return {
    type: types.Get_Usage_Type_Success,
    payload
  };
};

export const loginSuccessrequest = (payload) => {
  return {
    type: types.Login_Success,
    payload
  };
};

export const loginErrorrequest = (payload) => {
  return {
    type: types.Login_Failure,
    payload
  };
};

export const postTotalCustomData = (payload) => {
  return {
    type: types.Add_Customer_Data_Success,
    payload
  };
};

export const getCustomerInquirySuccessResponse = (payload) => {
  return {
    type: types.Get_Customer_Inquiry_details_Success,
    payload
  };
};
export const getPOSPSuccessResponse = (payload) => {
  return {
    type: types.Get_POSP_details_Success,
    payload
  };
};
export const getClaimsSuccessResponse = (payload) => {
  return {
    type: types.Get_Claims_details_Success,
    payload
  };
};

export const getCustomerSuccess = (payload) => {
  return {
    type: types.Add_Customer_Data_Success,
    payload
  };
};

const getVehicleData = (quaryParams) => (dispatch) => {
  console.log(quaryParams, "quary params")
  dispatch(loadingRequest());
  axios
    .post(`${vehicle_api}/rto/v1/rc`, { vehicleNumber: quaryParams })
    .then((response) => {
      console.log(' vehicle data response', response);
      dispatch(vehicleSuccessRequest(response.data));
    })
    .catch((error) => {
      console.log('error in vehicle data', error);
    });
};
const getCityData = (quaryParams) => (dispatch) => {
  dispatch(loadingRequest());
  axios
    .get(`https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/Vehicle/city+rto+master+data.json`)
    .then((response) => {
  // AddToLocalStorage('cityData', cityRtoMaster);
  dispatch(citySuccessRequest(response.data));
    })
    .catch((error) => {
      console.log('error in city data', error);
    });
};

const getInsurerName = (quaryParams) => (dispatch) => {
  dispatch(loadingRequest());
  axios
  .get(`https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/Vehicle/insurer+(1).json`)
  .then((response) => {
  console.log('insurence company Data Response', response);
  // AddToLocalStorage('InsurenceCompanyName', insurerData);
  dispatch(InsurerNamesSuccessRequest(response.data));
  })
  .catch((error) => {
    console.log('error in insurence company data', error);
  });
};

const addingCustomerData = (requestBody) => (dispatch) => {
  dispatch(loadingRequest());
  axios
    .post(`${common_api}/insurance/crm/v1/org/agent/customer`, requestBody)
    .then((response) => {
      dispatch(postTotalCustomData(response.data));
    })
    .catch((error) => console.log(error, 'err'));
};

const getRelations = (quaryParams) => (dispatch) => {
  dispatch(loadingRequest());
  axios
  .get(`https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/Vehicle/relation+data.json
 `)
  .then((response) => {
  console.log('relation Data Response', response);
  // AddToLocalStorage('relationData', relationData);
  dispatch(RelationSuccessRequest(response.data));
  })
  .catch((error) => {
    console.log('error in relation data', error);
  });
};

const getNCBValue = (quaryParams) => (dispatch) => {
  dispatch(loadingRequest());
  axios
    .get(`https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/Vehicle/ncb+master+data.json`)
    .then((response) => {
      console.log('ncb Data Response', response);
  // AddToLocalStorage('NcbData', NCBMaster);
     dispatch(NCBSuccessRequest(response.data));
  })
  .catch((error) => {
    console.log('error in ncb data', error);
  });
};


const getKycStatus = (quaryParms) => (dispatch) => {
  dispatch(loadingRequest());
  axios
    .get(`${vehicle_api}/insurance/v1/motor/policy/status/kyc/${quaryParms}`)
    .then((response) => {
      console.log('in kyc response', response);
      dispatch(kycSuccessRequest(response.data.body));
    })
    .catch((error) => {
      console.log(error);
    });
};

const postLogindata = (queryParams) => {
  return axios({
    method: 'post',
    url: `${vehicle_api}/messages/v1/sms`,
    data: JSON.stringify(queryParams),
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

const getQuotationData = (quaryParms) => (dispatch) => {
  console.log(quaryParms, 'quaryParms')
  dispatch(loadingRequest());
  axios({
    method: 'post',
    url: `${vehicle_api}/insurance/v1/motor/policy/quote`,
    data: JSON.stringify(quaryParms),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((response) => {
      console.log('quoatation response', response);
      dispatch(quotationSuccessRequest(response.data));
    })
    .catch((error) => {
      console.log('error in getting qoute', error);
    });
};



const getSmsResponse = (params) => (dispatch) => {
  console.log(params, "phone, method, org_id, agent_id, type")
  dispatch(loadingRequest());
  axios
    .post(`${common_api}/messages/v1/sms`, params)
    .then((response) => {
      dispatch(getSuccessSmsRequest(response.data));
      console.log(response, 'response');
    })
    .catch((error) => {
      console.log(error);
    });
};

// const AddingCustomerData = (customerData) => {
//   dispatch(loadingRequest());
//   axios.post(`${common_api}/insurance/crm/v1/org/agent/customer`, customerData)
//   .then((response) =>{
//     dispatch(getCustomerSuccess(response))
//   }) 
// };

const getSmsVerificationResponse = (phone, verification_code, method, type) => (dispatch) => {
  dispatch(loadingRequest());
  axios
    .post(`${common_api}/messages/v1/sms`, { phone, verification_code, method, type })
    .then((response) => {
      console.log(response, 'response after kyc')
      dispatch(getSuccessSmsResponse(response.data.statusCode));
    })
    .catch((error) => {
      console.log(error);
    });
};

const getVehicleMaster = (quaryParms) => async (dispatch) => {
  dispatch(loadingRequest());
  let vehicle = await getMasterData('vehical', quaryParms);
  dispatch(getSuccessVehicleMaster(vehicle));
};

const getUsageType = (quaryParams) => async (dispatch) => {
  let usageType = await getMasterData('usageType');
  dispatch(getSuccessUsageType(usageType));
};

const getAgentData = () => (dispatch) => {
  dispatch(loadingRequest());
  axios
    .get(`https://c103ge43eb.execute-api.ap-south-1.amazonaws.com/dev2/users`)
    .then((response) => {
      dispatch(getSuccessAgentData(response[0]));
    })
    .catch((error) => {
      console.log(error);
    });
};

const getCityStateWithPincode = () => (dispatch) => {
  dispatch(loadingRequest());
  axios
    .get(`https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/Vehicle/pincode+data.json`)
    .then((response) => {
      console.log('in pincode data response', response.data);
  // AddToLocalStorage('pincodeData', pincodeData);
  dispatch(getSuccessPincode(response.data));
  })
  .catch((error) => {
    console.log(error);
  });
};

const customerInquiryData = (customerData) => (dispatch) => {
  dispatch(loadingRequest());
  axios
    .post(`${vehicle_api}/public/v1/customer-inquiry`, { customerData })
    .then((response) => {
      dispatch(getCustomerInquirySuccessResponse(response.data));
    })

    .catch((error) => {
      console.log(error);
    });
};

//POSP

const POSPData = (POSPData) => (dispatch) => {
  dispatch(loadingRequest());
  axios
    .post(`${vehicle_api}/user/v1/organisation/posp`, POSPData)
    .then((response) => {
      dispatch(getPOSPSuccessResponse(response.data));
    })

    .catch((error) => {
      console.log(error);
    });
};

// Claims
const claimsData = (claimData) => (dispatch) => {
  dispatch(loadingRequest());
  axios
    .post(``, { claimsData })
    .then((response) => {
      dispatch(getClaimsSuccessResponse(response.data));
    })

    .catch((error) => {
      console.log(error);
    });
};

export { claimsData, POSPData, getVehicleData, getCityData, getInsurerName, getRelations, getNCBValue, getKycStatus, getQuotationData, getSmsResponse, getSmsVerificationResponse, getVehicleMaster, getCityStateWithPincode, getAgentData, getUsageType, postLogindata, customerInquiryData, addingCustomerData };
