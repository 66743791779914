export const UPDATE_FAMILY_DATA = "UPDATE_FAMILY_DATA";
export const UPDATE_SON_COUNT = "UPDATE_SON_COUNT";
export const UPDATE_DAUGHTER_COUNT = "UPDATE_DAUGHTER_COUNT";
// actionType.js
export const STORE_RESPONSE_DATA = "STORE_RESPONSE_DATA";
//store kyc response
export const STORE_KYC_RESPONSE = "STORE_KYC_RESPONSE";
export const UPDATE_PROPOSER_GENDER = "UPDATE_PROPOSER_GENDER";
export const UPDATE_PREMIUM_POST_OBJECT = "UPDATE_PREMIUM_POST_OBJECT";
export const UPDATE_PROPOSER_DATA = "UPDATE_PROPOSER_DATA";

export const UPDATE_PROPOSAL_NUMBER = "UPDATE_PROPOSAL_NUMBER";

export const UPDATED_QUOTATION_RESULT = "UPDATED_QUOTATION_RESULT";
export const STORE_QUOTE = "STORE_QUOTE";
export const SELECTED_PROVIDER = "SELECTED_PROVIDER";
export const GET_PROPOSAL_DATA = "GET_PROPOSAL_DATA";
export const SELECT_HEALTH_POLICY_TYPE = "SELECT_HEALTH_POLICY_TYPE";
export const Add_Customer_Data_Success = "Add_Customer_Data_Success";
export const Mobile_OTP_Request = "Mobile_OTP_Request";
export const Mobile_OTP_Verification_Request =
  "Mobile_OTP_Verification_Request";
  export const generatedHDFCTxnId = "generatedHDFCTxnId";
  export const hdfcPolicydata = "hdfcPolicydata";
